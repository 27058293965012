/* eslint-disable react/destructuring-assignment,no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, useFormikContext } from 'formik';
import { Select, Switch } from 'formik-mui';
import getSymbolFromCurrency from 'currency-symbol-map';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { merchant, storeCredit } from '../../../state';
import { TransactionTable, CustomInputField } from '../../custom';

const useStyles = makeStyles(() => ({
  fullwidthChild: {
    '& > div': {
      width: '100%',
    },
  },
  '@keyframes blinker': {
    from: { opacity: 1 },
    to: { opacity: 0.5 },
  },
  blink: {
    animationName: '$blinker',
    animationDuration: '1s',
    animationTimingFunction: 'ease',
    animationIterationCount: 'infinite',
  },
  generateButton: {
    backgroundColor: '#284C0D !important',
    color: '#fff !important',
    minWidth: '190px',
  },
}));
const StoreCreditsIssue = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const colsTitles = [
    t('firstName'),
    t('lastName'),
    t('storeCredit.storeCreditsList.emailAddress'),
    t('storeCredit.storeCreditsList.storeCredit'),
    t('storeCredit.storeCreditsIssue.issuedDate'),
    t('storeCredit.storeCreditsList.storeCreditAmount'),
    t('storeCredit.storeCreditsList.currentBalance'),
  ];

  const rowKeys = [
    'firstName',
    'lastName',
    'email',
    'code',
    'createdAt',
    'amount',
    'balance',
  ];

  const [params] = React.useState({
    status: 'NEW',
  });
  const [noDataMsg, setNoDataMsg] = React.useState(t('storeCredit.noData'));
  const dispatch = useDispatch();

  const mods = {
    createdAt: (val) => val && moment(`${val}Z`).format('MMM Do YYYY, HH:mm'),
    amount: (val, row) =>
      t('cardValue', {
        amount: val,
        formatParams: {
          amount: { currency: row.currency || 'USD' },
        },
      }),
    balance: (val, row) =>
      val &&
      t('cardValue', {
        amount: val,
        formatParams: {
          amount: { currency: row.currency || 'USD' },
        },
      }),
  };
  const isLoading = useSelector(storeCredit.selectors.selectIsLoading);
  const { data, total, totalAmount, ...storeCredits } = useSelector(
    storeCredit.selectors.selectStoreCreditsList
  );
  const currency = useSelector(merchant.selectors.selectCurrency);
  const storeCreditConfig = useSelector(
    storeCredit.selectors.selectStoreCreditConfig
  );
  const syncing = useSelector(storeCredit.selectors.selectSyncing);
  const { plans } = storeCreditConfig;
  const {
    values,
    setFieldValue,
    handleSubmit,
    isSubmitting,
  } = useFormikContext();

  const planOptions = useSelector(storeCredit.selectors.selectPlans) || [];
  const [currencySymbol, setCurrencySymbol] = useState(
    getSymbolFromCurrency((plans && plans[0] && plans[0].currency) || 'USD')
  );
  const onPlanChange = (val) => {
    const partnerPlan =
      planOptions.find(
        (p) => p.partnerSubscriptionPlanId === val.props.value
      ) || {};
    setFieldValue('plans[0].originalAmount', partnerPlan.originalAmount || 0);
    setFieldValue('plans[0].amount', partnerPlan.originalAmount || 0);
    setFieldValue('plans[0].name', partnerPlan.name || '');
    setCurrencySymbol(getSymbolFromCurrency(partnerPlan.currency || 'USD'));
  };

  React.useEffect(() => {
    if (
      storeCreditConfig.enableTrigger &&
      storeCreditConfig.plans.length &&
      storeCreditConfig.plans[0].id
    ) {
      dispatch(storeCredit.actions.getStoreCreditsList(params));
    }
  }, [params]);
  useEffect(() => {
    if (syncing) {
      setNoDataMsg(
        t('storeCredit.generated', {
          total,
          totalAmount: t('cardValue', {
            amount: totalAmount,
            formatParams: {
              amount: { currency },
            },
          }),
        })
      );
    }
  }, [syncing]);

  useEffect(() => {
    if (isLoading) {
      setNoDataMsg(t('storeCredit.noData'));
    }
  }, [isLoading]);

  return (
    <>
      {storeCreditConfig.businessId && (
        <>
          <Box mt={2}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} lg="auto">
                <Typography>
                  {t('storeCredit.storeCreditsIssue.trigger')}
                  <Field
                    component={Switch}
                    type="checkbox"
                    name="enableTrigger"
                    color="primary"
                  />
                </Typography>
              </Grid>
              {values.enableTrigger && (
                <>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={3}
                    className={classes.fullwidthChild}
                  >
                    <Field
                      component={Select}
                      as="select"
                      autoWidth={false}
                      fullWidth
                      label={t('storeCredit.planNameToIssue')}
                      name="plans[0].partnerSubscriptionPlanId"
                      variant="outlined"
                      onChange={(event, val) => onPlanChange(val)}
                      style={{ height: '47px' }}
                    >
                      <MenuItem value={undefined}>---</MenuItem>
                      {planOptions.map((opt) => (
                        <MenuItem
                          value={opt.partnerSubscriptionPlanId}
                          key={opt.partnerSubscriptionPlanId}
                        >
                          {opt.name}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item xs={12} md={4} lg={2}>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      fullWidth
                      disabled
                      startAdornment={currencySymbol}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      maskType="numbers"
                      label={t('storeCredit.originalAmount')}
                      value={values.plans[0].originalAmount}
                      name="plans[0].originalAmount"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={2}>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      fullWidth
                      startAdornment={currencySymbol}
                      type="number"
                      maskType="numbers"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label={t('storeCredit.amount')}
                      value={values.plans[0].amount}
                      name="plans[0].amount"
                      variant="outlined"
                    />
                  </Grid>
                </>
              )}

              <Grid item style={{ marginLeft: 'auto', marginRight: '20px' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  type="submit"
                  disabled={isSubmitting}
                >
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {isSubmitting ? (
                    <CircularProgress size={24} />
                  ) : values.enableTrigger ? (
                    t('getList')
                  ) : (
                    t('saveChanges')
                  )}
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box mt={2}>
            <Grid container alignItems="center">
              {isLoading ? (
                <Skeleton width="50%" />
              ) : (
                <>
                  <Grid item>
                    <Box mr={4}>
                      <Typography variant="h6">Count: {total}</Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box>
                      <Typography variant="h6">
                        {t('storeCredit.storeCreditsIssue.totalAmount')}:{' '}
                        {t('cardValue', {
                          amount: totalAmount,
                          formatParams: {
                            amount: { currency },
                          },
                        })}
                      </Typography>
                    </Box>
                  </Grid>
                </>
              )}
              <Grid item style={{ marginLeft: 'auto' }}>
                <Box ml={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.generateButton}
                    onClick={() =>
                      dispatch(
                        storeCredit.actions.syncStoreCredits({
                          businessId: storeCreditConfig.businessId,
                          ...params,
                        })
                      )
                    }
                    disabled={isLoading || !data.length || syncing}
                  >
                    {syncing ? (
                      <CircularProgress size={24} />
                    ) : (
                      t('storeCredit.storeCreditsIssue.btnGenerate')
                    )}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      <Box py={2}>
        {syncing ? (
          <Box py={10} alignItems="center">
            <Typography variant="h4" align="center" className={classes.blink}>
              {t('storeCredit.generating')}
            </Typography>
          </Box>
        ) : (
          <TransactionTable
            cols={colsTitles}
            rowKeys={rowKeys}
            data={data}
            mods={mods}
            isLoading={isLoading}
            noDataMsg={noDataMsg}
          />
        )}
      </Box>
    </>
  );
};

export default StoreCreditsIssue;
