/* eslint-disable react/prop-types */
import * as React from 'react';
import {
  Dialog,
  DialogContent,
  Box,
  Grid,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { Close } from '@material-ui/icons';
import { merchant } from '../../../../state';
import ticket from '../../../../state/ticket';

const useStyles = makeStyles(() => ({
  bold: {
    fontWeight: '500',
  },
}));

const OrderPreviewModal = ({ onClose, item }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const currency = useSelector(merchant.selectors.selectCurrency);
  const orderDetails = useSelector(ticket.selectors.selectOrderItems);

  React.useEffect(() => {
    if (item.id) {
      dispatch(ticket.actions.getOrderItems(item.id));
    }
  }, [item]);

  return (
    <Dialog
      open={!!item}
      onClose={onClose}
      fullWidth
      PaperProps={{
        style: {
          maxWidth: 500,
        },
      }}
    >
      <DialogContent>
        <Box pb={4}>
          <Typography
            variant="h5"
            style={{ paddingBottom: 15 }}
            className={classes.bold}
          >
            {`${t('tickets.order')} #:`}
          </Typography>
          <Typography
            variant="h6"
            style={{ paddingBottom: 10 }}
            className={classes.bold}
          >
            {`${t('tickets.orderOverview.chargeBreakdown')}:`}
          </Typography>
          {orderDetails && orderDetails.tickets && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                {orderDetails.tickets.map(
                  ({
                    ticketName,
                    quantity,
                    groupDiscountType,
                    groupDiscountValue,
                  }) => {
                    return (
                      <>
                        <Typography variant="subtitle1">
                          {ticketName} x {quantity}
                        </Typography>
                        {groupDiscountValue && (
                          <Typography
                            variant="subtitle2"
                            style={{ paddingLeft: 10 }}
                          >
                            Group Discount {groupDiscountValue}
                            {groupDiscountType === 0 ? '%' : '$'}
                          </Typography>
                        )}
                      </>
                    );
                  }
                )}
                <Typography variant="subtitle1">
                  {t('tickets.orderOverview.tax')}
                </Typography>
                <Typography variant="subtitle1">
                  {t('tickets.orderOverview.serviceFee')}
                </Typography>
                <Typography variant="subtitle1" style={{ paddingBottom: 15 }}>
                  {t('tickets.orderOverview.memberDiscount', {
                    amount: orderDetails.memberDiscountValue,
                    type: orderDetails.memberDiscountType === 0 ? '%' : '$',
                  })}
                </Typography>
                <Typography variant="subtitle1" className={classes.bold}>
                  {t('tickets.orderOverview.amountCharged')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} style={{ textAlign: 'right' }}>
                {orderDetails.tickets.map(
                  ({
                    quantity,
                    price,
                    groupDiscountType,
                    groupDiscountValue,
                  }) => {
                    return (
                      <>
                        <Typography variant="subtitle1">
                          {t('cardValue', {
                            amount: price * quantity,
                            formatParams: {
                              amount: { currency },
                            },
                          })}
                        </Typography>
                        {groupDiscountValue && (
                          <Typography
                            variant="subtitle2"
                            style={{ paddingLeft: 10 }}
                          >
                            &#45;
                            {t('cardValue', {
                              amount:
                                groupDiscountType === 0
                                  ? (groupDiscountValue / 100) *
                                    price *
                                    quantity
                                  : groupDiscountValue,
                              formatParams: {
                                amount: { currency },
                              },
                            })}
                          </Typography>
                        )}
                      </>
                    );
                  }
                )}
                <Typography variant="subtitle1">
                  {t('cardValue', {
                    amount: orderDetails.tax,
                    formatParams: {
                      amount: { currency },
                    },
                  })}
                </Typography>
                <Typography variant="subtitle1">
                  {t('cardValue', {
                    amount: orderDetails.fee,
                    formatParams: {
                      amount: { currency },
                    },
                  })}
                </Typography>
                <Typography variant="subtitle1" style={{ paddingBottom: 15 }}>
                  &#45;
                  {t('cardValue', {
                    amount: orderDetails.memberDiscountAmount,
                    formatParams: {
                      amount: { currency },
                    },
                  })}
                </Typography>
                <Typography variant="subtitle1" className={classes.bold}>
                  {t('cardValue', {
                    amount: orderDetails.total,
                    formatParams: {
                      amount: { currency },
                    },
                  })}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Box>
      </DialogContent>
      <IconButton
        aria-label="clear"
        onClick={onClose}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          width: 20,
          height: 20,
        }}
      >
        <Close fontSize="small" />
      </IconButton>
    </Dialog>
  );
};

export default OrderPreviewModal;
