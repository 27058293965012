/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import { Box, Grid, Button, IconButton } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { merchant, ticket } from '../../../state';
import { TransactionTable } from '../../custom';
import Editing from '../../../images/Memberships/editing.png';
import AddPricingModal from './Modals/AddPricingModal';

const TicketPricingList = ({ isEdit = true }) => {
  const activeTicketEvent = useSelector(ticket.selectors.selectTicketEvent);
  const [params, setParams] = React.useState({
    limit: 10,
    page: 1,
    ticketEventId: activeTicketEvent?.id,
  });
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const colsTitles = [
    t('tickets.ticketType.ticketType'),
    t('tickets.pricing.ticketPrice'),
    t('tickets.pricing.totalQuantity'),
    t('tickets.pricing.ticketSold'),
    t('tickets.pricing.ticketUnSold'),
    t('tickets.pricing.ticketDescription'),
    t('tickets.pricing.minimumTicketPerOrder'),
    t('tickets.pricing.maximumTicketPerOrder'),
    t('tickets.pricing.groupDiscountQuantity'),
    `${t('tickets.pricing.groupDiscount')} %`,
    '',
  ];

  const rowKeys = [
    'ticketType.name',
    'price',
    'totalQuantity',
    'totalSold',
    'quantity',
    'description',
    'minPerOrder',
    'maxPerOrder',
    'groupDiscountQuantity',
    'groupDiscount',
    'id',
  ];
  const [modalItem, setModalItem] = React.useState(false);
  const { currency } = useSelector(merchant.selectors.selectMerchantDetails);

  const mods = {
    id: (val, item) => (
      <Tooltip title={t('edit')} placement="top" arrow>
        <IconButton
          aria-haspopup="true"
          onClick={() => (isEdit ? setModalItem(item) : null)}
          cursor="pointer"
        >
          <img src={Editing} alt="" style={{ width: 20 }} />
        </IconButton>
      </Tooltip>
    ),
    price: (val) =>
      t('cardValue', {
        amount: val,
        formatParams: {
          amount: { currency },
        },
      }),
  };

  React.useEffect(() => {
    if (activeTicketEvent?.id) {
      dispatch(ticket.actions.getTicketPricing(params));
    }
  }, [params]);

  const isLoading = useSelector(ticket.selectors.selectIsLoading);
  const { data: ticketList, total } = useSelector(
    ticket.selectors.selectTicketPricing
  );

  const handleChangePage = (event, page) => {
    setParams({ ...params, page: page + 1 });
  };
  const handleChangeRowsPerPage = (event) => {
    setParams({ ...params, limit: event.target.value, page: 1 });
  };

  const pagination = {
    total,
    pageSize: params.limit,
    page: params.page,
    handleChangePage,
    handleChangeRowsPerPage,
  };

  const searchBox = () => (
    <Grid item>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setModalItem({})}
      >
        {t('tickets.pricing.add')}
      </Button>
    </Grid>
  );

  const sortedTicketList = useMemo(() => {
    if (ticketList && ticketList.length > 0) {
      return [...ticketList].sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    }
    return [];
  }, [ticketList]);

  return (
    <>
      <Box py={2}>
        <TransactionTable
          cols={colsTitles}
          rowKeys={rowKeys}
          data={sortedTicketList}
          mods={mods}
          isLoading={isLoading}
          pagination={pagination}
          searchBox={isEdit ? searchBox : null}
          noDataMsg={t('noDataMsg')}
        />
      </Box>

      <AddPricingModal
        item={modalItem}
        onClose={() => setModalItem(false)}
        params={params}
      />
    </>
  );
};
TicketPricingList.propTypes = {
  isEdit: PropTypes.func.isRequired,
};
export default TicketPricingList;
