/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import {
  Box,
  Grid,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Close } from '@material-ui/icons';

import { ticket, alert } from '../../../../state';
import { CustomInputField } from '../../../custom';
import i18n from '../../../../i18n';

const EditTicketModal = ({ item, onClose }) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {}, []);

  const openAlert = (payload) => dispatch(alert.actions.open(payload));

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, i18n.t('validation.tooShort'))
      .max(50, i18n.t('validation.tooLong'))
      .required(i18n.t('validation.required')),
    lastName: Yup.string()
      .min(2, i18n.t('validation.tooShort'))
      .max(50, i18n.t('validation.tooLong'))
      .required(i18n.t('validation.required')),
    email: Yup.string()
      .email(i18n.t('invalidEmail'))
      .required(i18n.t('validation.required')),
  });

  const onSubmitTicket = async (formData, resetForm) => {
    const body = {
      id: formData.id,
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
    };
    const response = await dispatch(ticket.actions.editTicket(body));
    if (response.payload) {
      setTimeout(() => {
        openAlert({
          message: t('tickets.ticket.editSuccessfully'),
          severity: 'success',
        });
        setLoading(false);
        onClose();
        resetForm();
      }, 2000);
    } else {
      openAlert({
        message: response.error?.message || t('tickets.ticket.editFail'),
        severity: 'error',
      });
      setLoading(false);
    }
  };

  const body = (
    <Box>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={{
          id: item?.id || '',
          firstName: item?.firstName || '',
          lastName: item?.lastName || '',
          email: item?.email || '',
        }}
        onSubmit={async (values, { resetForm }) => {
          const formData = values;
          setLoading(true);
          try {
            await onSubmitTicket(formData, resetForm);
          } catch (error) {
            console.log(error);
          }
          return true;
        }}
      >
        {({ handleSubmit }) => {
          return (
            <Form>
              <Box mt={2}>
                <Grid container spacing={2}>
                  <Grid item sm={12}>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      fullWidth
                      required
                      label={t('firstName')}
                      name="firstName"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      fullWidth
                      required
                      label={t('lastName')}
                      name="lastName"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      trim
                      fullWidth
                      required
                      type="email"
                      label={t('email')}
                      name="email"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box mt={3} mb={2}>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Button
                      style={{ marginRight: 12 }}
                      onClick={handleSubmit}
                      disabled={loading}
                      variant="contained"
                      color="primary"
                    >
                      {t('save')}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );

  return (
    <Dialog
      id={item?.id}
      open={!!item}
      onClose={onClose}
      fullWidth
      PaperProps={{
        style: {
          maxWidth: 500,
        },
      }}
      disableEnforceFocus
    >
      <DialogTitle>
        {t('tickets.ticket.editTicket')} {item?.ticketNumber}
      </DialogTitle>
      <DialogContent>{body}</DialogContent>
      <IconButton
        aria-label="clear"
        onClick={onClose}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          width: 20,
          height: 20,
        }}
      >
        <Close fontSize="small" />
      </IconButton>
    </Dialog>
  );
};

EditTicketModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  item: PropTypes.object,
};

EditTicketModal.defaultProps = {
  item: null,
};

export default EditTicketModal;
