/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import { Box, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Delete } from '@material-ui/icons';
import { membership } from '../../../state';
import { TransactionTable } from '../../custom';
import AddPerksForm from './AddPerksForm';
import ConfirmationDialog from '../GiftCardDetails/ConfirmDialog';

const MembershipPerkConfigs = () => {
  const [params] = React.useState({ limit: 10, page: 1 });
  const [isOpen, setIsOpen] = React.useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(membership.actions.getMembershipFreeItemConfigs());
  }, [params]);

  const colsTitles = [
    t('membership.perkConfigs.planName'),
    t('membership.perkConfigs.freeItemPerks'),
    t('membership.perkConfigs.quantity'),
    t('membership.perkConfigs.dateTime'),
    t('membership.perkConfigs.status'),
  ];

  const rowKeys = [
    'membershipPlanName',
    'name',
    'quantity',
    'createdAt',
    'status',
  ];

  const isLoading = useSelector(membership.selectors.selectIsLoading);
  // const isSending = useSelector(transactions.selectors.selectIsSending);
  const freeItemConfigs = useSelector(
    membership.selectors.selectFreeItemConfigs
  );
  const [item, setItem] = React.useState(false);
  const onDelete = async () => {
    await dispatch(
      membership.actions.updateMembershipPerks({ ...item, status: 1 })
    );
    dispatch(membership.actions.getMembershipFreeItemConfigs());
  };
  const handleOpenModal = () => {
    setIsOpen(true);
  };
  const mods = {
    createdAt: (val) => moment(`${val}Z`).format('MMMM DD, YYYY HH:mm:ss'),
    status: (status, i) => (
      <Delete onClick={() => setItem(i)} cursor="pointer" />
    ),
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button
        onClick={() => handleOpenModal()}
        variant="contained"
        color="primary"
      >
        {t('membership.perkConfigs.definePerksFreeItems')}
      </Button>
      <Box py={2}>
        <TransactionTable
          cols={colsTitles}
          rowKeys={rowKeys}
          data={freeItemConfigs}
          mods={mods}
          isLoading={isLoading}
          noDataMsg={t('noDataMsg')}
        />
      </Box>

      <AddPerksForm isOpen={isOpen} closeModal={handleCloseModal} />
      <ConfirmationDialog
        open={!!item}
        setOpen={setItem}
        title={t('confirmation')}
        content={t('deleteConfirmationQuestion')}
        actionOk={onDelete}
      />
    </>
  );
};

export default MembershipPerkConfigs;
