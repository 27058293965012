import * as Yup from 'yup';
import i18n from '../../../i18n';

const LoyaltyProgramValidation = Yup.object().shape({
  programName: Yup.string()
    .min(2, i18n.t('validation.tooShort'))
    .max(50, i18n.t('validation.tooLong'))
    .required(i18n.t('validation.required')),
  terms: Yup.string(),
  description: Yup.string(),
  type: Yup.number().when('formTab', {
    is: Yup.number().min(1),
    then: Yup.number().required(i18n.t('validation.required')),
  }),
  itemPoint: Yup.number().when(['formTab', 'type'], {
    is: (formTab, type) => formTab >= 1 && Number(type) === 0,
    then: Yup.number()
      .required(i18n.t('validation.required'))
      .min(1, i18n.t('validation.required')),
  }),
  partnerItemId: Yup.string().when(['formTab', 'type'], {
    is: (formTab, type) => formTab >= 1 && Number(type) === 0,
    then: Yup.string().required(i18n.t('validation.required')),
  }),
  amountPoint: Yup.number().when(['formTab', 'type'], {
    is: (formTab, type) => formTab >= 1 && Number(type) === 1,
    then: Yup.number()
      .required(i18n.t('validation.required'))
      .min(0.000001, i18n.t('validation.required')),
  }),
  minimumAmount: Yup.number().when(['formTab', 'type'], {
    is: (formTab, type) => formTab >= 1 && Number(type) === 1,
    then: Yup.number()
      .required(i18n.t('validation.required'))
      .min(0.01, i18n.t('validation.required')),
  }),
  discountType: Yup.number().when('formTab', {
    is: Yup.number().min(2),
    then: Yup.number().required(i18n.t('validation.required')),
  }),
  rewardItemId: Yup.string().when(['formTab', 'rewardType'], {
    is: (formTab, rewardType) => formTab >= 2 && Number(rewardType) === 0,
    then: Yup.string().required(i18n.t('validation.required')),
  }),
  discountAmount: Yup.number().when(['formTab', 'rewardType'], {
    is: (formTab, rewardType) => formTab >= 2 && Number(rewardType) === 1,
    then: Yup.number().required(i18n.t('validation.required')),
  }),
  requiredAmount: Yup.number().when('formTab', {
    is: (formTab) => formTab >= 2,
    then: Yup.number()
      .required(i18n.t('validation.required'))
      .min(0.01, i18n.t('validation.required')),
  }),
  storeCreditAmount: Yup.number().when(['formTab', 'rewardType'], {
    is: (formTab, rewardType) => formTab >= 2 && Number(rewardType) === 2,
    then: Yup.number()
      .required(i18n.t('validation.required'))
      .min(0.01, i18n.t('validation.required')),
  }),
  // storeCreditExpirationValue: Yup.number().when(['formTab', 'rewardType'], {
  //   is: (formTab, rewardType) => formTab >= 2 && Number(rewardType) === 2,
  //   then: Yup.number()
  //     .required(i18n.t('validation.required'))
  //     .min(1, i18n.t('validation.required')),
  // }),
  // storeCreditExpirationType: Yup.number().when(['formTab', 'rewardType'], {
  //   is: (formTab, rewardType) => formTab >= 2 && Number(rewardType) === 2,
  //   then: Yup.number().required(i18n.t('validation.required')),
  // }),
});

export default LoyaltyProgramValidation;
