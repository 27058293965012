/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Box } from '@material-ui/core';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { membership, merchant } from '../../../state';
import { TransactionTable } from '../../custom';

const mods = {
  createdAt: (val) => val && moment(`${val}Z`).format('MMM Do YYYY, HH:mm'),
};

const SharedMembershipConfig = () => {
  const merchantDetails = useSelector((state) =>
    merchant.selectors.selectMerchantDetails(state)
  );
  const [params, setParams] = React.useState({
    limit: 10,
    page: 1,
    businessId: merchantDetails?.id,
  });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  React.useEffect(() => {
    dispatch(membership.actions.getSharedMembershipConfigs(params));
  }, [params]);

  const isLoading = useSelector(membership.selectors.selectIsLoading);
  const { data, total } = useSelector(
    membership.selectors.selectSharedMembershipConfig
  );
  const handleChangePage = (event, page) => {
    setParams({ ...params, page: page + 1 });
    // search();
  };
  const handleChangeRowsPerPage = (event) => {
    setParams({ ...params, limit: event.target.value, page: 1 });
    // search();
  };

  const colsTitles = [
    t('membership.sharedMembershipConfig.merchantName'),
    t('membership.sharedMembershipConfig.discount'),
    t('membership.sharedMembershipConfig.startDate'),
  ];

  const rowKeys = ['secondBusiness.name', 'discount', 'createdAt'];

  const pagination = {
    total,
    pageSize: params.limit,
    page: params.page,
    handleChangePage,
    handleChangeRowsPerPage,
  };

  return (
    <>
      <Box py={2}>
        <TransactionTable
          cols={colsTitles}
          rowKeys={rowKeys}
          data={data}
          mods={mods}
          pagination={pagination}
          isLoading={isLoading}
          noDataMsg={t('noDataMsg')}
        />
      </Box>
    </>
  );
};

export default SharedMembershipConfig;
