/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import {
  Box,
  DialogContent,
  DialogContentText,
  Dialog,
  IconButton,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Close } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { membership, merchant } from '../../../state';
import { TransactionTable } from '../../custom';

const ViewPaymentActivityModal = ({ item, onClose }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);

  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const colsTitles = [
    t('membership.transactions.dateTime'),
    t('membership.transactions.amount'),
    t('membership.transactions.referenceId'),
  ];
  const currency = useSelector(merchant.selectors.selectCurrency);
  const rowKeys = ['createdAt', 'amount', 'referenceId'];

  const mods = {
    createdAt: (val) => moment(`${val}Z`).format('MMM Do YYYY, HH:mm'),
    amount: (val) =>
      t('cardValue', {
        amount: val.toFixed(2),
        formatParams: {
          amount: { currency },
        },
      }),
  };

  const getTransactions = async () => {
    setLoading(true);
    const res = await dispatch(membership.actions.getTransactionList(item.id));
    if (res.payload) {
      setData(res.payload);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (item?.id) {
      getTransactions();
    }
  }, [item]);

  const body = (
    <>
      <Box py={2}>
        <Typography variant="subtitle">
          {t('membership.transactions.paymentActivityForMember')}:{' '}
          {item?.firstName} {item?.lastName}, {t('Id')}: {item?.code}
        </Typography>
      </Box>
      <Box py={2}>
        <TransactionTable
          cols={colsTitles}
          rowKeys={rowKeys}
          data={data || []}
          mods={mods}
          isLoading={loading}
          noDataMsg={t('noDataMsg')}
        />
      </Box>
    </>
  );

  return (
    <Dialog
      open={!!item?.id}
      onClose={onClose}
      fullWidth
      size="sm"
      scroll="paper"
      aria-labelledby="terms and conditions"
      aria-describedby="terms and conditions"
      BackdropProps={{
        timeout: 500,
      }}
    >
      <DialogContent dividers>
        <DialogContentText tabIndex={-1} component="div">
          {body}
        </DialogContentText>
      </DialogContent>
      <IconButton
        aria-label="clear"
        onClick={onClose}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          width: 20,
          height: 20,
        }}
      >
        <Close fontSize="small" />
      </IconButton>
    </Dialog>
  );
};

ViewPaymentActivityModal.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  item: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ViewPaymentActivityModal;
