import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { CircularProgress } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Field, Formik } from 'formik';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { CustomInputField } from '../../custom';
import api from '../../../state/loyalty/api';
import { alert } from '../../../state';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  maxWidth: 'none',
  width: 900,
};

const fieldStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};

const DisplayOperatior = ({ isAddFunds, name }) => (
  <Typography
    style={{
      alignSelf: 'center',
      marginLeft: 10,
      marginRight: 10,
      color: isAddFunds ? 'green' : 'red',
      fontWeight: 'bold',
    }}
  >
    {name}
  </Typography>
);

DisplayOperatior.propTypes = {
  isAddFunds: PropTypes.bool,
  name: PropTypes.string,
};

DisplayOperatior.defaultProps = {
  isAddFunds: true,
  name: '',
};

const AddLoyaltyPointModal = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [isAddFunds, setAddFunds] = React.useState(true);
  const [currentUser, setCurrentUser] = React.useState({});
  const { t } = useTranslation();

  const openAlert = (payload) => dispatch(alert.actions.open(payload));
  const handleClose = () => setOpen(false);

  const validationSchema = () =>
    Yup.object().shape({
      amount: Yup.number().required(t('storeCredit.validates.amountRequired')),
    });

  const initialData = {
    point: currentUser.point,
    amount: '',
    totalPoint: '',
    rewardedTimes: currentUser.rewardedTimes,
  };

  const onShowModal = (row, addFunds) => {
    setOpen(true);
    setAddFunds(addFunds);
    setCurrentUser(row);
  };

  React.useImperativeHandle(ref, () => ({
    showModal: onShowModal,
    closeModal: handleClose,
  }));

  const onSubmit = async ({ amount }, formikHelper) => {
    formikHelper.setSubmitting(true);
    try {
      if (isAddFunds) {
        await api.addLoyaltyPoint({
          amount: Number(amount),
          id: currentUser.id,
        });
        props.afterSubmit();
      }
      handleClose();
      formikHelper.setSubmitting(false);
      openAlert({
        message: `${
          isAddFunds
            ? t('loyalty.messages.successfullyAddedPoints')
            : t('storeCredit.messages.successfullyDeducted')
        }`,
        severity: 'success',
      });
    } catch (error) {
      openAlert({
        message: error?.error?.message || t('loyalty.messages.failAddedPoints'),
        severity: 'error',
      });
      formikHelper.setSubmitting(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography
            id="transition-modal-description"
            style={{}}
            sx={{ mt: 2, mb: 4 }}
          >
            {t('loyalty.addPointTitle', {
              firstName: `${currentUser.firstName} ${currentUser.lastName}`,
              code: currentUser.code,
            })}
          </Typography>
          <Formik
            onSubmit={onSubmit}
            enableReinitialize
            initialValues={initialData}
            validationSchema={validationSchema}
          >
            {({ isSubmitting, handleSubmit, setFieldValue }) => {
              const {
                totalPoint,
                rewardedTimes,
                requiredAmount,
                point,
              } = currentUser;

              const calculateBalance = (e) => {
                if (e.target.value !== '') {
                  setFieldValue(
                    'totalPoint',
                    isAddFunds
                      ? Number(totalPoint) + Number(e.target.value)
                      : Number(totalPoint) - Number(e.target.value)
                  );
                } else {
                  setFieldValue('totalPoint', '');
                }

                setFieldValue(
                  'rewardedTimes',

                  rewardedTimes +
                    Math.ceil(
                      Number(
                        (Number(point) + Number(e.target.value)) /
                          requiredAmount
                      )
                    )
                );
              };
              return (
                <Box sx={fieldStyle}>
                  {/* <p>{JSON.stringify(currentUser)}</p> */}

                  <CustomInputField
                    InputProps={{
                      disabled: true,
                      color: 'black',
                    }}
                    customInput={Field}
                    component={TextField}
                    trim
                    required
                    label={t('loyalty.currentPoint')}
                    autoComplete="off"
                    name="point"
                    variant="outlined"
                  />
                  <DisplayOperatior
                    isAddFunds={isAddFunds}
                    name={isAddFunds ? '+' : '-'}
                  />
                  <CustomInputField
                    InputProps={{
                      color: 'black',
                    }}
                    customInput={Field}
                    component={TextField}
                    onChange={calculateBalance}
                    trim
                    required
                    label={`${
                      isAddFunds
                        ? t('loyalty.addPoint')
                        : t('storeCredit.deductAmount')
                    }`}
                    autoComplete="off"
                    name="amount"
                    variant="outlined"
                  />
                  <DisplayOperatior isAddFunds={isAddFunds} name="=" />
                  <Box style={{ flex: 1 }}>
                    <Field
                      InputProps={{
                        disabled: true,
                        color: 'black',
                      }}
                      InputLabelProps={{ shrink: true }}
                      component={TextField}
                      trim
                      label={t('loyalty.totalPointEarned')}
                      autoComplete="off"
                      name="totalPoint"
                      variant="outlined"
                    />
                  </Box>

                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    type="submit"
                    style={{
                      backgroundColor: isAddFunds ? 'green' : 'red',
                      marginLeft: 20,
                      width: 160,
                    }}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <CircularProgress size={24} />
                    ) : (
                      `${
                        isAddFunds
                          ? t('loyalty.addPoint')
                          : t('storeCredit.deductFunds')
                      }`
                    )}
                  </Button>
                </Box>
              );
            }}
          </Formik>
        </Box>
      </Fade>
    </Modal>
  );
});

AddLoyaltyPointModal.propTypes = {
  afterSubmit: PropTypes.func,
};

AddLoyaltyPointModal.defaultProps = {
  afterSubmit: () => {},
};

export default AddLoyaltyPointModal;
