import React from 'react';
import { Router } from '@reach/router';
import LoyaltyProgramsList from './LoyaltyProgramsList';
import LoyaltyList from './LoyaltyList';
import LoyaltyProgramForm from './LoyaltyProgramForm';
import PrivateRoute from '../../PrivateRoute';

const LoyaltyPrograms = () => {
  return (
    <Router basepath="dashboard/loyalty">
      {/* <LoyaltyProgramsList path="/manage" /> */}
      {/* <LoyaltyProgramForm path="/" /> */}
      <PrivateRoute path="/" component={LoyaltyProgramForm} />
      <PrivateRoute path="/manage" component={LoyaltyProgramsList} />
      <PrivateRoute path="/list" component={LoyaltyList} />
    </Router>
  );
};

export default LoyaltyPrograms;
