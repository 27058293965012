/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import {
  Box,
  Grid,
  Button,
  MenuItem,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import { Select } from 'formik-mui';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Close } from '@material-ui/icons';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';
import { alert, membership, merchant } from '../../../state';
import { CustomInputField } from '../../custom';

const frequencies = [
  'DAILY',
  'WEEKLY',
  'EVERY_TWO_WEEKS',
  'THIRTY_DAYS',
  'SIXTY_DAYS',
  'NINETY_DAYS',
  'MONTHLY',
  'EVERY_TWO_MONTHS',
  'QUARTERLY',
  'EVERY_FOUR_MONTHS',
  'EVERY_SIX_MONTHS',
  'ANNUAL',
  'EVERY_TWO_YEARS',
  'CUSTOM',
];

const AddSubscriptionPlanModal = ({ item, isOpen, closeModal }) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const openAlert = (payload) => dispatch(alert.actions.open(payload));

  const merchantDetails = useSelector(merchant.selectors.selectMerchantDetails);
  const currency = merchantDetails.currency || 'USD';
  const currencySymbol = getSymbolFromCurrency(currency || 'USD');

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('required')),
    period: Yup.string().required(t('required')),
    amount: Yup.number()
      .min(
        0,
        t('minimumIs', {
          value: `${t('cardValue', {
            amount: 0,
            formatParams: {
              amount: { currency },
            },
          })}`,
        })
      )
      .required(t('required')),
    expirationDate: Yup.date()
      .nullable()
      .when('period', {
        is: 'CUSTOM',
        then: Yup.date().required(t('required')),
        // .min(
        //   new Date(new Date().setHours(0, 0, 0, 0)),
        //   'Start Date need to be after today'
        // ),
      }),
  });

  const createMembershipPlan = async (formData, resetForm) => {
    const response = await dispatch(
      membership.actions.createMembershipPlan(formData)
    );
    console.log(response);
    if (response.payload) {
      setTimeout(() => {
        openAlert({
          message: t('membership.mplan.addedSuccessfully'),
          severity: 'success',
        });
        setLoading(false);
        resetForm();
        dispatch(membership.actions.getMembershipPlans());
        closeModal(true);
      }, 3000);
    } else {
      openAlert({
        message:
          response.error?.message || t('membership.addDiscountForm.failCreate'),
        severity: 'error',
      });
      setLoading(false);
    }
  };

  const updatePlan = async (formData, resetForm) => {
    const data = {
      id: item.id,
      ...formData,
    };
    const response = await dispatch(membership.actions.updatePlan(data));
    console.log(response);
    if (response.payload) {
      setTimeout(() => {
        openAlert({
          message: t('membership.mplan.updatedSuccessfully'),
          severity: 'success',
        });
        setLoading(false);
        resetForm();
        dispatch(membership.actions.getMembershipPlans());
        closeModal(true);
      }, 3000);
    } else {
      openAlert({
        message: response.error?.message || t('membership.mplan.failUpdate'),
        severity: 'error',
      });
      setLoading(false);
    }
  };

  const onSubmitPlan = async (formData, resetForm) => {
    if (item) {
      await updatePlan(formData, resetForm);
    } else {
      await createMembershipPlan(formData, resetForm);
    }
  };

  const body = (
    <Box>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={{
          name: item?.name || '',
          period: item?.period || '',
          amount: item?.amount || '',
          expirationDate: item?.expirationDate || '',
          allowedSecondaryMembers: item?.allowedSecondaryMembers || 0,
        }}
        onSubmit={async (values, { resetForm }) => {
          const formData = values;
          setLoading(true);
          try {
            await onSubmitPlan(formData, resetForm);
          } catch (error) {
            console.log(error);
            // setLoading(false);
          }

          return true;
        }}
      >
        {({ values, errors, handleSubmit, setFieldValue }) => {
          useEffect(() => {
            if (values.period && values.period !== 'CUSTOM') {
              setFieldValue('expirationDate', null);
            }
          }, [values.period]);
          return (
            <Form>
              <Box mt={2}>
                <Grid container spacing={5}>
                  <Grid item sm={6}>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      fullWidth
                      required
                      label={t('membership.planName')}
                      name="name"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      fullWidth
                      removeArrows
                      required
                      type="number"
                      label={t('price')}
                      name="amount"
                      startAdornment={currencySymbol}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <Field
                      component={Select}
                      as="select"
                      fullWidth
                      required
                      label={t('frequency')}
                      name="period"
                      variant="outlined"
                      style={{ width: 281 }}
                    >
                      <MenuItem value={undefined}>---</MenuItem>
                      {frequencies.map(
                        (opt) =>
                          opt && (
                            <MenuItem value={opt} key={opt}>
                              {opt}
                            </MenuItem>
                          )
                      )}
                    </Field>
                  </Grid>
                  <Grid item sm={6}>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      fullWidth
                      type="number"
                      removeArrows
                      label={t('membership.mplan.secondaryMembers')}
                      name="allowedSecondaryMembers"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DatePicker
                      inputFormat="YYYY-MM-DD"
                      mask="____-__-__"
                      required
                      minDate={moment().startOf('day')}
                      slotProps={{
                        textField: {
                          readOnly: true,
                          variant: 'outlined',
                          error: Boolean(errors.expirationDate),
                          helperText: errors.expirationDate,
                        },
                      }}
                      disabled={values.period !== 'CUSTOM' || loading}
                      renderInput={(pr) => {
                        return (
                          <Field
                            readOnly
                            inputProps={{ readOnly: true }}
                            component={TextField}
                            fullWidth
                            label={`${t('membership.mplan.expirationDate')}`}
                            name="expirationDate"
                            variant="outlined"
                            {...pr}
                            error={Boolean(errors.expirationDate)}
                            helperText={errors.expirationDate}
                            onKeyDown={(e) => e.preventDefault()}
                          />
                        );
                      }}
                      value={
                        values.expirationDate
                          ? moment(values.expirationDate)
                          : null
                      }
                      onChange={(val) => {
                        const currentTime = moment().format('HH:mm:ss');
                        setFieldValue(
                          'expirationDate',
                          val
                            ? `${val.format('YYYY-MM-DD')} ${currentTime}`
                            : ''
                        );
                      }}
                      name="expirationDate"
                      label={`${t('membership.mplan.expirationDate')}`}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box mt={4}>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Button
                      style={{ marginRight: 12 }}
                      onClick={handleSubmit}
                      // loading={loading}
                      disabled={loading}
                      variant="contained"
                      color="primary"
                    >
                      {t('save')}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );

  return (
    <Dialog
      id={item?.id}
      open={isOpen}
      onClose={closeModal}
      fullWidth
      // maxWidth="lg"
      // style={{
      //   '& .MuiDialog-container': {
      //     '& .MuiPaper-root': {
      //       width: '100%',
      //       maxWidth: '1100px', // Set your width here
      //     },
      //   },
      // }}
      PaperProps={{
        style: {
          maxWidth: 650,
        },
      }}
      disableEnforceFocus
    >
      <DialogTitle>
        {item
          ? t('membership.mplan.editPlan')
          : t('membership.mplan.addNewPlan')}
      </DialogTitle>
      <DialogContent>{body}</DialogContent>
      <IconButton
        aria-label="clear"
        onClick={closeModal}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          width: 20,
          height: 20,
        }}
      >
        <Close fontSize="small" />
      </IconButton>
    </Dialog>
  );
};

AddSubscriptionPlanModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  item: PropTypes.object,
};

AddSubscriptionPlanModal.defaultProps = {
  item: null,
};

export default AddSubscriptionPlanModal;
