import React from 'react';

import { TextField } from 'formik-mui';
import { useTranslation } from 'react-i18next';
import { Field, useFormikContext } from 'formik';
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  Radio,
  Typography,
} from '@material-ui/core';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';
import { RadioGroup } from 'formik-material-ui';

const LoyaltyInitialSetup = () => {
  const {
    values,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();
  const { t } = useTranslation();
  return (
    <>
      <Grid container spacing={2}>
        <Grid container item xs={12} md={7} justifyContent="space-between">
          <Grid item xs={12}>
            <Box mb={2}>
              <Field
                component={TextField}
                fullWidth
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                required
                label={t('loyalty.programName')}
                name="programName"
                variant="outlined"
              />
            </Box>
          </Grid>
          {values === 0 && ( // hidden
            <Grid item xs={4}>
              <Box mb={2}>
                <DatePicker
                  format="MM/DD/YYYY"
                  required={false}
                  renderInput={(params) => {
                    return (
                      <Field
                        component={TextField}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label={t('loyalty.expirationDate')}
                        name="expirationDate"
                        variant="outlined"
                        {...params}
                      />
                    );
                  }}
                  value={
                    values.expirationDate ? moment(values.expirationDate) : null
                  }
                  onChange={(val) => {
                    setFieldValue(
                      'expirationDate',
                      val ? val.format('MM/DD/YYYY') : ''
                    );
                  }}
                  name="expirationDate"
                  label={t('loyalty.expirationDate')}
                  onKeyPress={() => setFieldTouched('expirationDate')}
                />
              </Box>
            </Grid>
          )}
          <Grid container spacing={2} mt={2} alignItems="center">
            <Grid item xs>
              <Box pb={2}>
                <Field
                  component={TextField}
                  fullWidth
                  multiline
                  rows={4}
                  type="text"
                  label={t('loyalty.description')}
                  name="description"
                  variant="outlined"
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={2} alignItems="center">
            <Grid item xs>
              <Field
                component={TextField}
                fullWidth
                multiline
                rows={4}
                type="text"
                label={t('loyalty.termsAndConditions')}
                name="terms"
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <CircularProgress size={24} />
              ) : (
                t('saveAndContinue')
              )}
            </Button>
          </Box>
        </Grid>
        <Grid item md={4}>
          <Grid container item style={{ padding: 9 }}>
            <Field component={RadioGroup} name="type" row label="fdfd">
              <Grid item>
                <FormControlLabel
                  value="true"
                  control={
                    <Radio
                      value={0}
                      color="primary"
                      name="codeType"
                      checked={values.codeType === '0'}
                    />
                  }
                  label={t('QRCode')}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  value="false"
                  control={
                    <Radio
                      value={1}
                      color="primary"
                      name="codeType"
                      checked={values.codeType === '1'}
                    />
                  }
                  label={t('BarCode')}
                />
              </Grid>
            </Field>
          </Grid>
          <Box mt={2}>
            <Grid container item xs={8}>
              <Grid item xs={12}>
                <Typography>
                  <Field
                    component={TextField}
                    fullWidth
                    style={{ width: 220 }}
                    type="text"
                    label={t('loyalty.signOnBonus')}
                    name="signUpBonusPoint"
                    variant="outlined"
                  />
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default LoyaltyInitialSetup;
