import React from 'react';

import { Checkbox, RadioGroup } from 'formik-material-ui';
import { useTranslation } from 'react-i18next';
import { Field, useFormikContext } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  Typography,
} from '@material-ui/core';
import { TextField } from 'formik-mui';
import { useSelector } from 'react-redux';
import getSymbolFromCurrency from 'currency-symbol-map';
import { loyalty, merchant } from '../../../state';
import { CustomInputField } from '../../custom';

const LoyaltyInitialSetup = () => {
  const { values, handleSubmit, isSubmitting } = useFormikContext();
  const { t } = useTranslation();
  const items = useSelector(loyalty.selectors.selectItems);
  const { currency } = useSelector(merchant.selectors.selectMerchantDetails);
  const currencySymbol = getSymbolFromCurrency(currency);
  return (
    <>
      <Box mb={2} alignItems="center">
        <Typography>{t('loyalty.programBasedOn')}:</Typography>
      </Box>
      <Box mb={2}>
        <Field component={RadioGroup} name="type" row>
          <Grid container>
            <Grid
              container
              item
              spacing={2}
              xs={12}
              md={8}
              justifyContent="space-between"
            >
              <Grid item xs={4} style={{ display: 'none' }}>
                <FormControlLabel
                  value="0"
                  control={
                    <Radio
                      color="primary"
                      name="type"
                      checked={Number(values.type) === 0}
                      disabled
                    />
                  }
                  label={t('loyalty.itemPurchasedPerOrder')}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  value="1"
                  control={
                    <Radio
                      color="primary"
                      name="type"
                      checked={Number(values.type) === 1}
                    />
                  }
                  label={t('loyalty.amountSpentPerOrder')}
                />
              </Grid>
            </Grid>
          </Grid>
        </Field>
      </Box>
      <Box>
        <Grid container>
          <Grid
            container
            item
            spacing={2}
            xs={12}
            md={8}
            justifyContent="space-between"
          >
            <Grid item xs={4} style={{ display: 'none' }}>
              <Card elevation={3}>
                <CardContent>
                  <Box mb={3}>
                    <Field
                      component={TextField}
                      fullWidth
                      select
                      type="text"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label={t('loyalty.selectItem')}
                      name="partnerItemId"
                      variant="outlined"
                      disabled={Number(values.type) === 1}
                    >
                      <MenuItem value={undefined}>---</MenuItem>
                      {items.map((opt) => (
                        <MenuItem value={opt.id} key={opt.id}>
                          {opt.name}
                        </MenuItem>
                      ))}
                    </Field>
                  </Box>
                  <Field
                    component={TextField}
                    fullWidth
                    type="text"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label={t('loyalty.pointsEarned')}
                    name="itemPoint"
                    variant="outlined"
                    disabled={Number(values.type) === 1}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card elevation={3}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        startAdornment={currencySymbol}
                        fullWidth
                        type="text"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label={t('loyalty.amount')}
                        name="minimumAmount"
                        variant="outlined"
                        disabled={Number(values.type) === 0}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>
                        <Field
                          type="checkbox"
                          component={Checkbox}
                          color="primary"
                          name="excludeTax"
                        />
                        {t('loyalty.excludeTax')}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        fullWidth
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label={t('loyalty.pointsEarned')}
                        name="amountPoint"
                        variant="outlined"
                        removeArrows
                        disabled={Number(values.type) === 0}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box mt={4}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          type="submit"
          disabled={isSubmitting}
        >
          {isSubmitting ? <CircularProgress size={24} /> : t('saveAndContinue')}
        </Button>
      </Box>
    </>
  );
};

export default LoyaltyInitialSetup;
