import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { CircularProgress } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Field, Formik } from 'formik';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { CustomInputField } from '../../custom';
import api from '../../../state/storeCredit/api';
import { alert } from '../../../state';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  maxWidth: 'none',
  width: 900,
};

const fieldStyle = {
  display: 'flex',
  flexDirection: 'row',
};

const DisplayOperatior = ({ isAddFunds, name }) => (
  <Typography
    style={{
      alignSelf: 'center',
      marginLeft: 10,
      marginRight: 10,
      color: isAddFunds ? 'green' : 'red',
      fontWeight: 'bold',
    }}
  >
    {name}
  </Typography>
);

DisplayOperatior.propTypes = {
  isAddFunds: PropTypes.bool,
  name: PropTypes.string,
};

DisplayOperatior.defaultProps = {
  isAddFunds: true,
  name: '',
};

const AddDeductFundModal = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [isAddFunds, setAddFunds] = React.useState(true);
  const [currentUser, setCurrentUser] = React.useState({});
  const { t } = useTranslation();

  const openAlert = (payload) => dispatch(alert.actions.open(payload));
  const handleClose = () => setOpen(false);

  const validationSchema = (currencySymbol) =>
    Yup.object().shape({
      Amount: Yup.number().required(t('storeCredit.validates.amountRequired')),
      ExpectedBalance: Yup.number()
        .max(
          2000,
          t('storeCredit.validates.expectedBalanceMax', { currencySymbol })
        )
        .min(
          0,
          t('storeCredit.validates.expectedBalanceMin', { currencySymbol })
        ),
    });

  const initialData = {
    CurrentAmount: currentUser.balance,
    Amount: '',
    ExpectedBalance: '',
  };

  const onShowModal = (row, addFunds) => {
    setOpen(true);
    setAddFunds(addFunds);
    setCurrentUser(row);
  };

  React.useImperativeHandle(ref, () => ({
    showModal: onShowModal,
    closeModal: handleClose,
  }));

  const onSubmit = async ({ Amount }, formikHelper) => {
    formikHelper.setSubmitting(true);
    try {
      if (isAddFunds) {
        await api.addFundGiftCard({
          Amount: Number(Amount),
          Code: currentUser.code,
        });

        props.afterSubmit();
      } else {
        await api.deductGiftcard({
          Amount: Number(Amount),
          Code: currentUser.code,
        });
        props.afterSubmit();
      }
      handleClose();
      formikHelper.setSubmitting(false);
      openAlert({
        message: `${
          isAddFunds
            ? t('storeCredit.messages.successfullyAddedFunds')
            : t('storeCredit.messages.successfullyDeducted')
        }`,
        severity: 'success',
      });
    } catch (error) {
      openAlert({
        message: isAddFunds
          ? t('storeCredit.messages.failAddedFunds')
          : t('storeCredit.messages.failDeducted'),
        severity: 'error',
      });
      formikHelper.setSubmitting(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography
            id="transition-modal-description"
            style={{}}
            sx={{ mt: 2, mb: 4 }}
          >
            {isAddFunds ? t('adding') : t('deducting')}{' '}
            {t('storeCredit.addDeductFundTitle', {
              firstName: currentUser.firstName,
              code: currentUser.code,
            })}
          </Typography>
          <Formik
            onSubmit={onSubmit}
            validationSchema={() => validationSchema(props.currencySymbol)}
            enableReinitialize
            initialValues={initialData}
          >
            {({ isSubmitting, handleSubmit, values, setFieldValue }) => {
              const { CurrentAmount } = values;

              const calculateBalance = (e) => {
                if (e.target.value !== '') {
                  setFieldValue(
                    'ExpectedBalance',
                    isAddFunds
                      ? Number(CurrentAmount) + Number(e.target.value)
                      : Number(CurrentAmount) - Number(e.target.value)
                  );
                } else {
                  setFieldValue('ExpectedBalance', '');
                }
              };
              return (
                <Box sx={fieldStyle}>
                  <CustomInputField
                    startAdornment={props.currencySymbol}
                    InputProps={{
                      disabled: true,
                      color: 'black',
                    }}
                    customInput={Field}
                    component={TextField}
                    trim
                    required
                    label={t('storeCredit.currentBalance')}
                    autoComplete="off"
                    name="CurrentAmount"
                    variant="outlined"
                  />
                  <DisplayOperatior
                    isAddFunds={isAddFunds}
                    name={isAddFunds ? '+' : '-'}
                  />
                  <CustomInputField
                    startAdornment={props.currencySymbol}
                    InputProps={{
                      color: 'black',
                    }}
                    customInput={Field}
                    component={TextField}
                    onChange={calculateBalance}
                    trim
                    required
                    label={`${
                      isAddFunds
                        ? t('storeCredit.addAmount')
                        : t('storeCredit.deductAmount')
                    }`}
                    autoComplete="off"
                    name="Amount"
                    variant="outlined"
                  />
                  <DisplayOperatior isAddFunds={isAddFunds} name="=" />
                  <CustomInputField
                    startAdornment={props.currencySymbol}
                    InputProps={{
                      disabled: true,
                      color: 'black',
                    }}
                    customInput={Field}
                    component={TextField}
                    trim
                    required
                    label={t('storeCredit.expectedBalance')}
                    autoComplete="off"
                    name="ExpectedBalance"
                    variant="outlined"
                  />

                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    type="submit"
                    style={{
                      backgroundColor: isAddFunds ? 'green' : 'red',
                      marginLeft: 10,
                      alignSelf: 'baseline',
                    }}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <CircularProgress size={24} />
                    ) : (
                      `${
                        isAddFunds
                          ? t('storeCredit.addFunds')
                          : t('storeCredit.deductFunds')
                      }`
                    )}
                  </Button>
                </Box>
              );
            }}
          </Formik>
        </Box>
      </Fade>
    </Modal>
  );
});

AddDeductFundModal.propTypes = {
  afterSubmit: PropTypes.func,
  currencySymbol: PropTypes.string,
};

AddDeductFundModal.defaultProps = {
  afterSubmit: () => {},
  currencySymbol: '$',
};

export default AddDeductFundModal;
