/* eslint-disable react/forbid-prop-types,react/require-default-props,no-plusplus,no-await-in-loop,no-unused-vars */
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React, { useState } from 'react';
import * as Yup from 'yup';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import CSVReader from 'react-csv-reader';
import { Close, Check } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { alert, coupons, merchant } from '../../../state';
import { TransactionTable } from '../../custom';
import DownloadIcon from '../../../images/Memberships/download.png';
import SingleCouponBox from './SingleCouponBox';

const SendToManyEmail = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const cols = ['First Name', 'Last Name', 'Email', 'Sent'];
  const rowKeys = ['firstName', 'lastName', 'email', 'sent'];

  const { currency } = useSelector((state) =>
    merchant.selectors.selectMerchantDetails(state)
  );
  console.log(currency);
  const [isLoading, setIsLoading] = useState(false);
  const couponsList = useSelector(coupons.selectors.selectCouponList);
  const openAlert = (payload) => dispatch(alert.actions.open(payload));
  const [params, setParams] = React.useState({
    limit: 10,
    page: 1,
  });
  const getCoupons = (search = '') =>
    dispatch(coupons.actions.getCouponList({ ...params, search }));
  React.useEffect(() => {
    getCoupons();
  }, [params]);
  const [data, setData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);

  const parseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => {
      switch (header) {
        case 'First Name':
          return 'firstName';
        case 'Last Name':
          return 'lastName';
        case 'Email':
          return 'email';
        default:
          return header;
      }
    },
  };
  const handleCsvUpload = (csvData) => {
    setData(csvData.map((r, index) => ({ ...r, sent: 'false', index })));
  };
  const downloadCSV = () => {
    fetch('../../../SampleCustomerImportFile.csv').then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'SampleImportFile.csv';
        alink.click();
      });
    });
  };

  const pagination = {
    total: data.length,
    pageSize: params.limit,
    page: params.page,
    handleChangePage: (event, page) => {
      setParams({ ...params, page: page + 1 });
    },
    handleChangeRowsPerPage: (event) => {
      setParams({ ...params, limit: event.target.value, page: 1 });
    },
  };

  const mods = {
    sent: (val) => {
      switch (val) {
        case true:
          return (
            <Check color="secondary" size={14} style={{ color: 'green' }} />
          );
        case 'loading':
          return <CircularProgress color="primary" size={14} />;
        case 'failed':
          return <Close color="error" size={14} />;
        default:
          return '';
      }
    },
  };
  const searchBox = () => (
    <Box mt={2}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} lg="auto">
          <Button variant="contained" color="primary" component="label">
            {t('import')}
            <CSVReader
              inputId="CSVReader"
              inputStyle={{ display: 'none' }}
              onFileLoaded={handleCsvUpload}
              parserOptions={parseOptions}
            />
          </Button>
          <Tooltip title={t('downloadCSV')} placement="top" arrow>
            <Button onClick={downloadCSV} style={{ marginLeft: 10 }}>
              <img
                src={DownloadIcon}
                alt={t('downloadCSV')}
                style={{ width: 30, cursor: 'pointer' }}
              />
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <Box>
      <Formik
        validationSchema={Yup.object().shape({
          code: Yup.string().required(t('validation.required')),
        })}
        initialValues={{
          code: '',
          description: '',
        }}
        onSubmit={async (values, { resetForm, setFieldValue }) => {
          await setIsLoading(true);
          const updatedArray = data;
          for (let i = 0; i < updatedArray.length; i++) {
            const row = updatedArray[i];
            updatedArray[i].sent = 'loading';
            setData(updatedArray);
            setCurrentIndex(i);
            try {
              const resp = await dispatch(
                coupons.actions.sendSingleCoupon({
                  ...values,
                  ...row,
                })
              );
              if (resp.payload) {
                updatedArray[i].sent = true;
                setData(updatedArray);
              } else if (resp.error) {
                updatedArray[i].sent = 'failed';
                setData(updatedArray);
              }
            } catch (error) {
              console.log(error);
            }
          }
          if (!updatedArray.some((r) => r.sent !== true)) {
            openAlert({
              message: t('coupons.couponCodesSentSuccessfully'),
              severity: 'success',
            });
            const { code } = values;
            resetForm();
            setFieldValue('code', code);
          } else {
            openAlert({
              message: t('coupons.couponCodesSendFailure'),
              severity: 'error',
            });
          }
          // setData([]);
          setIsLoading(false);
        }}
      >
        {({ setFieldValue, handleSubmit, values }) => {
          const setCouponCode = async (val) => {
            setFieldValue('code', val.code);
            setFieldValue('description', val.description);
          };
          return (
            <>
              <SingleCouponBox setCouponCode={setCouponCode} />
              <Box
                p={3}
                style={{
                  border: '1px solid #ccc',
                  borderRadius: 10,
                  position: 'relative',
                }}
                mb={5}
              >
                <Typography
                  style={{
                    position: 'absolute',
                    top: -22,
                    left: 30,
                    background: '#fff',
                    padding: 6,
                    fontSize: 18,
                    fontWeight: 'bold',
                  }}
                >
                  {t('coupons.step2EmailCouponToManyCustomers')}
                </Typography>
                <Form>
                  {/* <Box mt={2}> */}
                  {/*  <Grid container spacing={5}> */}
                  {/*    <Grid item md={6}> */}
                  {/*      <Autocomplete */}
                  {/*        options={couponsList?.data} */}
                  {/*        getOptionLabel={(option) => option.code} */}
                  {/*        getOptionSelected={(option, value) => */}
                  {/*          option.code === value.code */}
                  {/*        } */}
                  {/*        onChange={(event, value) => { */}
                  {/*          if (value) { */}
                  {/*            handleInputChange(value, setFieldValue); */}
                  {/*          } */}
                  {/*        }} */}
                  {/*        renderInput={(pr) => ( */}
                  {/*          <Field */}
                  {/*            {...pr} */}
                  {/*            component={TextField} */}
                  {/*            name="code" */}
                  {/*            label="Coupon Code" */}
                  {/*            fullWidth */}
                  {/*            required */}
                  {/*            onChange={(e) => { */}
                  {/*              debouncedSearch(e.target.value); */}
                  {/*            }} */}
                  {/*          /> */}
                  {/*        )} */}
                  {/*      /> */}
                  {/*    </Grid> */}
                  {/*    <Grid item xs={6}> */}
                  {/*      <Field */}
                  {/*        component={TextField} */}
                  {/*        fullWidth */}
                  {/*        multiline */}
                  {/*        type="text" */}
                  {/*        label="Coupon Code/ Campaign Description" */}
                  {/*        name="description" */}
                  {/*        variant="outlined" */}
                  {/*        disabled */}
                  {/*      /> */}
                  {/*    </Grid> */}
                  {/*  </Grid> */}
                  {/* </Box> */}
                  <TransactionTable
                    cols={cols}
                    rowKeys={rowKeys}
                    data={data.slice(
                      (params.page - 1) * params.limit,
                      params.page * params.limit
                    )}
                    pagination={pagination}
                    key={currentIndex}
                    isLoading={false}
                    searchBox={searchBox}
                    mods={mods}
                  />

                  <Box mt={2}>
                    <Grid
                      container
                      spacing={5}
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Grid item>
                        <Button
                          onClick={handleSubmit}
                          loading={isLoading}
                          disabled={isLoading || !data.length || !values.code}
                          variant="contained"
                          color="primary"
                        >
                          SEND
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Form>
              </Box>
            </>
          );
        }}
      </Formik>
    </Box>
  );
};

export default SendToManyEmail;
