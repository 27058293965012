/* eslint-disable react/forbid-prop-types,react/require-default-props,no-plusplus,no-await-in-loop */
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  MenuItem,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { Select, TextField } from 'formik-mui';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import CSVReader from 'react-csv-reader';
import { Close, Check } from '@material-ui/icons';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { alert, coupons } from '../../../state';
import { CustomInputField, TransactionTable } from '../../custom';
import DownloadIcon from '../../../images/Memberships/download.png';

const useStyles = makeStyles(() => ({
  fullwidthChild: {
    '& > div': {
      width: '100%',
      minWidth: '300px',
    },
  },
}));
const GenerateManyCoupon = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const cols = ['First Name', 'Last Name', 'Email', 'Sent'];
  const rowKeys = ['firstName', 'lastName', 'email', 'sent'];

  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const couponPlans = useSelector(coupons.selectors.selectCouponPlans);
  const openAlert = (payload) => dispatch(alert.actions.open(payload));
  React.useEffect(() => {
    dispatch(coupons.actions.getCouponPlanList());
  }, []);
  const [data, setData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);

  const [params, setParams] = React.useState({
    limit: 10,
    page: 1,
  });
  const pagination = {
    total: data.length,
    pageSize: params.limit,
    page: params.page,
    handleChangePage: (event, page) => {
      setParams({ ...params, page: page + 1 });
    },
    handleChangeRowsPerPage: (event) => {
      setParams({ ...params, limit: event.target.value, page: 1 });
    },
  };
  const parseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => {
      switch (header) {
        case 'First Name':
          return 'firstName';
        case 'Last Name':
          return 'lastName';
        case 'Email':
          return 'email';
        default:
          return header;
      }
    },
  };
  const handleCsvUpload = (csvData) => {
    setData(csvData.map((r, index) => ({ ...r, sent: false, index })));
  };
  const downloadCSV = () => {
    fetch('../../../SampleCustomerImportFile.csv').then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'SampleImportFile.csv';
        alink.click();
      });
    });
  };

  const mods = {
    sent: (val) => {
      switch (val) {
        case true:
          return (
            <Check color="secondary" size={14} style={{ color: 'green' }} />
          );
        case 'loading':
          return <CircularProgress color="primary" size={14} />;
        case 'failed':
          return <Close color="error" size={14} />;
        default:
          return '';
      }
    },
  };
  const searchBox = () => (
    <Box mt={2}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} lg="auto">
          <Button variant="contained" color="primary" component="label">
            Import
            <CSVReader
              inputId="CSVReader"
              inputStyle={{ display: 'none' }}
              onFileLoaded={handleCsvUpload}
              parserOptions={parseOptions}
            />
          </Button>
          <Tooltip title="Download CSV" placement="top" arrow>
            <Button onClick={downloadCSV} style={{ marginLeft: 10 }}>
              <img
                src={DownloadIcon}
                alt="Download CSV"
                style={{ width: 30, cursor: 'pointer' }}
              />
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <Box>
      <Formik
        validationSchema={Yup.object().shape({
          couponPlanId: Yup.string().required(t('validation.required')),
          maxRedemptions: Yup.number()
            .required(t('validation.required'))
            .min(0, t('minimumIsZero')),
          expirationDate: Yup.string().required(t('validation.required')),
          description: Yup.string().required(t('validation.required')),
          discountDescription: Yup.string(),
        })}
        initialValues={{
          couponPlanId: '',
          description: '',
          discountDescription: '',
          maxRedemptions: '',
          generateCodeType: '0',
        }}
        onSubmit={async (values) => {
          await setIsLoading(true);
          const updatedArray = data;
          for (let i = 0; i < updatedArray.length; i++) {
            const row = updatedArray[i];
            updatedArray[i].sent = 'loading';
            setData(updatedArray);
            setCurrentIndex(i);
            try {
              const resp = await dispatch(
                coupons.actions.createSingleCoupon({
                  ...values,
                  maxRedemptions: values.maxRedemptions || -1,
                  ...row,
                })
              );
              if (resp.payload) {
                updatedArray[i].sent = true;
                setData(updatedArray);
              } else if (resp.error) {
                updatedArray[i].sent = 'failed';
                setData(updatedArray);
              }
            } catch (error) {
              console.log(error);
            }
          }
          if (!updatedArray.some((r) => r.sent !== true)) {
            openAlert({
              message: t('coupons.couponCodesSentSuccessfully'),
              severity: 'success',
            });
          } else {
            openAlert({
              message: t('coupons.couponCodesSendFailure'),
              severity: 'error',
            });
          }
          // setData([]);
          setIsLoading(false);
        }}
      >
        {({
          setFieldValue,
          handleSubmit,
          setFieldTouched,
          values,
          errors,
          touched,
        }) => {
          return (
            <Form>
              <Box
                p={3}
                style={{
                  border: '1px solid #ccc',
                  borderRadius: 10,
                  position: 'relative',
                }}
                mb={5}
              >
                <Typography
                  style={{
                    position: 'absolute',
                    top: -15,
                    left: 30,
                    background: '#fff',
                    padding: 3,
                  }}
                >
                  {t('coupons.step1SelectDiscount')}
                </Typography>
                <Grid container spacing={5} style={{ marginTop: 10 }}>
                  <Grid item md={5} xs={12} className={classes.fullwidthChild}>
                    <Field
                      component={Select}
                      as="select"
                      autoWidth={false}
                      fullWidth
                      required
                      label={`${t('coupons.discountName')}*`}
                      name="couponPlanId"
                      variant="outlined"
                      onChange={(event, val) => {
                        setFieldValue('couponPlanId', val.props.value);
                        setFieldValue(
                          'discountDescription',
                          couponPlans.find((r) => r.id === val.props.value)
                            ?.discount
                        );
                      }}
                      // style={{ width: 320 }}
                    >
                      <MenuItem value={undefined}>---</MenuItem>
                      {couponPlans.map(
                        (e) =>
                          e && (
                            <MenuItem value={e.id} key={e.id}>
                              {e.name}
                            </MenuItem>
                          )
                      )}
                    </Field>
                  </Grid>
                  <Grid item md={5} xs={12}>
                    <Field
                      component={TextField}
                      fullWidth
                      type="text"
                      label={t('coupons.discountDescription')}
                      name="discountDescription"
                      variant="outlined"
                      disabled
                      InputLabelProps={{
                        shrink: !!values.discountDescription,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Grid container justifyContent="space-between" spacing={4}>
                      <Grid item xs={6}>
                        <DatePicker
                          format="MM/DD/YYYY"
                          required
                          disablePast
                          renderInput={(pr) => {
                            return (
                              <Field
                                component={TextField}
                                fullWidth
                                label={`${t('loyalty.expirationDate')} *`}
                                name="expirationDate"
                                variant="outlined"
                                {...pr}
                                error={
                                  (touched.maxRedemptions ||
                                    touched.expirationDate) &&
                                  Boolean(errors.expirationDate)
                                }
                                helperText={
                                  touched.maxRedemptions &&
                                  errors.expirationDate
                                }
                              />
                            );
                          }}
                          value={
                            values.expirationDate
                              ? moment(values.expirationDate)
                              : null
                          }
                          onChange={(val) => {
                            setFieldValue(
                              'expirationDate',
                              val ? val.format('MM/DD/YYYY') : ''
                            );
                          }}
                          name="expirationDate"
                          label={`${t('loyalty.expirationDate')} *`}
                          onKeyPress={() => setFieldTouched('expirationDate')}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          fullWidth
                          type="number"
                          removeArrows
                          label={t('coupons.maxRedemptions')}
                          name="maxRedemptions"
                          variant="outlined"
                          required
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    <Field
                      component={TextField}
                      fullWidth
                      type="text"
                      label={t('coupons.campaignName')}
                      name="description"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box
                p={3}
                style={{
                  border: '1px solid #ccc',
                  borderRadius: 10,
                  position: 'relative',
                }}
                mb={5}
              >
                <Typography
                  style={{
                    position: 'absolute',
                    top: -22,
                    left: 30,
                    background: '#fff',
                    padding: 6,
                    fontSize: 18,
                    fontWeight: 'bold',
                  }}
                >
                  {t('coupons.step2GenerateCouponSendToCustomers')}
                </Typography>
                <TransactionTable
                  cols={cols}
                  rowKeys={rowKeys}
                  data={data.slice(
                    (params.page - 1) * params.limit,
                    params.page * params.limit
                  )}
                  pagination={pagination}
                  key={currentIndex}
                  isLoading={false}
                  searchBox={searchBox}
                  mods={mods}
                />

                <Box mt={2}>
                  <Grid
                    container
                    spacing={5}
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Grid item>
                      <Button
                        onClick={handleSubmit}
                        loading={isLoading}
                        disabled={isLoading || !data.length}
                        variant="contained"
                        color="primary"
                      >
                        SEND
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default GenerateManyCoupon;
