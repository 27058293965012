import * as React from 'react';
import { TextField } from 'formik-material-ui';
import moment from 'moment';
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Box,
  Grid,
  IconButton,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import DatePicker from '@mui/lab/DatePicker';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { Close } from '@material-ui/icons';
import api from '../../../state/membership/api';
import { alert, membership } from '../../../state';

const fieldStyle = {
  display: 'flex',
  flexDirection: 'row',
};

const EditMemberExpirationDateModal = React.forwardRef(({ onClose, item }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const openAlert = (payload) => dispatch(alert.actions.open(payload));
  const isProduction = process.env.GATSBY_ACTIVE_ENV === `production`;
  const initialData = {
    currentExpirationDate: item?.expirationDate,
    newExpirationDate: null,
  };
  const onSubmit = async (values, formikHelper) => {
    formikHelper.setSubmitting(true);
    try {
      await api.updateMembershipExpirationDate({
        id: item?.id,
        expirationDate: values.newExpirationDate,
      });
      openAlert({
        message: t('membership.messages.successfullyEditMember'),
        severity: 'success',
      });
      dispatch(membership.actions.getMembershipList());
      onClose();
    } catch (error) {
      console.log(error);
      openAlert({
        message:
          error?.message || error || t('membership.messages.failedEditMember'),
        severity: 'error',
      });
      formikHelper.setSubmitting(false);
    }
  };

  const validateExpirationDate = (val) => {
    if (!val) {
      return null;
    }

    if (
      !moment(val, 'YYYY-MM-DD').isValid() ||
      moment(val, 'YYYY-MM-DD').isSameOrBefore(
        moment().add(isProduction ? 0 : -1, 'year'),
        'date'
      )
    ) {
      return t('invalidDate');
    }

    return null;
  };

  return (
    <Dialog open={!!item} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {t('membership.modifyExpirationDate')}: {item?.code}
      </DialogTitle>
      <DialogContent>
        <Box py={2}>
          <Formik
            onSubmit={onSubmit}
            enableReinitialize
            initialValues={initialData}
          >
            {({
              isSubmitting,
              setFieldValue,
              setFieldTouched,
              values,
              errors,
            }) => {
              return (
                <Form>
                  <Box sx={fieldStyle}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Box pb={2}>
                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                            spacing={2}
                          >
                            <Grid item xs={12} md={6}>
                              <DatePicker
                                inputFormat="YYYY-MM-DD"
                                renderInput={(pr) => {
                                  return (
                                    <Field
                                      component={TextField}
                                      fullWidth
                                      label={t(
                                        'membership.editExpirationDateModal.currentDate'
                                      )}
                                      name="currentExpirationDate"
                                      variant="outlined"
                                      {...pr}
                                    />
                                  );
                                }}
                                value={
                                  values.currentExpirationDate
                                    ? moment(values.currentExpirationDate)
                                    : null
                                }
                                name="currentExpirationDate"
                                label={t(
                                  'membership.editExpirationDateModal.currentDate'
                                )}
                                readOnly
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <DatePicker
                                inputFormat="YYYY-MM-DD"
                                mask="____-__-__"
                                required
                                minDate={
                                  isProduction
                                    ? moment()
                                    : moment().add(-1, 'year')
                                }
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                    variant: 'outlined',
                                    error: Boolean(errors.deliverAt),
                                    helperText: errors.deliverAt,
                                  },
                                }}
                                renderInput={(pr) => {
                                  return (
                                    <Field
                                      readOnly
                                      inputProps={{ readOnly: true }}
                                      component={TextField}
                                      fullWidth
                                      label={`${t(
                                        'membership.editExpirationDateModal.newDate'
                                      )} *`}
                                      name="newExpirationDate"
                                      validate={validateExpirationDate}
                                      variant="outlined"
                                      {...pr}
                                      error={Boolean(errors.newExpirationDate)}
                                      helperText={errors.newExpirationDate}
                                      onKeyDown={(e) => e.preventDefault()}
                                    />
                                  );
                                }}
                                value={
                                  values.newExpirationDate
                                    ? moment(values.newExpirationDate)
                                    : null
                                }
                                onChange={(val) => {
                                  const currentTime = moment().format(
                                    'HH:mm:ss'
                                  );
                                  setFieldValue(
                                    'newExpirationDate',
                                    val
                                      ? `${val.format(
                                          'YYYY-MM-DD'
                                        )} ${currentTime}`
                                      : ''
                                  );
                                }}
                                name="newExpirationDate"
                                label={`${t(
                                  'membership.editExpirationDateModal.newDate'
                                )} *`}
                                onKeyPress={() =>
                                  setFieldTouched('newExpirationDate')
                                }
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      flexDirection: 'column',
                    }}
                  >
                    <Button color="primary" variant="contained" type="submit">
                      {isSubmitting ? (
                        <CircularProgress size={24} />
                      ) : (
                        t('saveChanges')
                      )}
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </DialogContent>
      <IconButton
        aria-label="clear"
        onClick={onClose}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          width: 20,
          height: 20,
        }}
      >
        <Close fontSize="small" />
      </IconButton>
    </Dialog>
  );
});

EditMemberExpirationDateModal.propTypes = {
  onClose: PropTypes.func,
  item: PropTypes.objectOf(PropTypes.any),
};

EditMemberExpirationDateModal.defaultProps = {
  onClose: () => {},
  item: null,
};

export default EditMemberExpirationDateModal;
