import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AddCircle, RemoveCircle } from '@material-ui/icons';
import getSymbolFromCurrency from 'currency-symbol-map';
import moment from 'moment';
import { useDebounce } from 'use-debounce';
import { merchant, storeCredit } from '../../../state';
import { TransactionTable } from '../../custom';
import AddDeductFundsModal from './AddDeductFundModal';
import RedemptionsModal from './RedemptionsModal';
import RedemptionsIcon from '../../../images/redemption.svg';
import searchIcon from '../../../images/Memberships/search.png';

const StoreCreditSearchUpdate = () => {
  const modalRef = React.useRef(null);
  const refModalRedemptions = React.useRef(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [noDataMsg, setNoDataMsg] = React.useState(t('storeCredit.noData'));
  const [params, setParams] = React.useState({
    limit: 10,
    page: 1,
    // status: 'ACTIVE',
  });
  const isLoading = useSelector(storeCredit.selectors.selectIsLoading);

  const showAddFunds = (row, isAddFunds) => {
    modalRef.current.showModal(row, isAddFunds);
  };

  const mods = {
    createdAt: (val) => val && moment(`${val}Z`).format('MMM Do YYYY, HH:mm'),
    expirationDate: (val) => val && moment(`${val}`).format('MMM Do YYYY'),
    amount: (val, row) =>
      t('cardValue', {
        amount: val,
        formatParams: {
          amount: { currency: row.currency },
        },
      }),
    balance: (val, row) => (
      <Typography align="right">
        {t('cardValue', {
          amount: val,
          formatParams: {
            amount: { currency: row.currency },
          },
        })}
      </Typography>
    ),
    addFunds: (id, row) => (
      <Typography
        style={{
          color: '#1CBF3C',
          cursor: 'pointer',
          textDecoration: 'underline',
          whiteSpace: 'nowrap',
        }}
        onClick={() => showAddFunds(row, true)}
      >
        <AddCircle />
      </Typography>
    ),
    deductFunds: (id, row) => (
      <Typography
        style={{
          color: 'red',
          cursor: 'pointer',
          textDecoration: 'underline',
          whiteSpace: 'nowrap',
        }}
        onClick={() => showAddFunds(row, false)}
      >
        <RemoveCircle />
      </Typography>
    ),
    id: (v, row) => (
      <Typography
        style={{
          color: 'blue',
          cursor: 'pointer',
          textDecoration: 'underline',
          whiteSpace: 'nowrap',
        }}
        onClick={() => refModalRedemptions.current?.showModal(row)}
      >
        <img src={RedemptionsIcon} alt="" />
      </Typography>
    ),
  };

  const { data, total, totalBalance } = useSelector(
    storeCredit.selectors.selectStoreCreditsList
  );

  const currency = useSelector(merchant.selectors.selectCurrency);
  const currencySymbol = getSymbolFromCurrency(currency);
  const [searchText, setSearchText] = useState('');
  const [value] = useDebounce(params, 700);
  const colsTitles = [
    t('storeCredit.firstName'),
    t('storeCredit.lastName'),
    t('storeCredit.emailAddress'),
    t('storeCredit.storeCredit'),
    t('storeCredit.issuedDate'),
    t('storeCredit.expirationDate'),
    t('storeCredit.status'),
    t('storeCredit.currentBalance'),
    '',
    '',
    '',
  ];

  const rowKeys = [
    'firstName',
    'lastName',
    'email',
    'code',
    'createdAt',
    'expirationDate',
    'status',
    'balance',
    'addFunds',
    'deductFunds',
    'id',
  ];

  const search = () => {
    if (params.search !== searchText) {
      setParams({
        ...params,
        search: searchText,
      });
    }
  };

  const afterSubmit = () => {
    setParams({
      ...params,
      // text from search
    });
  };

  React.useEffect(() => {
    search();
  }, [searchText]);

  React.useEffect(() => {
    if (value?.search === '' || value?.search?.length > 2) {
      dispatch(storeCredit.actions.getStoreCreditsList(params));
    }
  }, [value]);

  React.useEffect(() => {
    if (isLoading) {
      setNoDataMsg(t('storeCredit.noData'));
    }
  }, [isLoading]);

  const onkeyPress = (e) => {
    if (e.keyCode === 13) {
      search();
    }
  };

  const handleChangePage = (event, page) => {
    setParams({ ...params, page: page + 1 });
  };
  const handleChangeRowsPerPage = (event) => {
    setParams({ ...params, limit: event.target.value, page: 1 });
  };

  const pagination = {
    total,
    pageSize: params.limit,
    page: params.page,
    handleChangePage,
    handleChangeRowsPerPage,
  };
  const searchBox = () => (
    <Grid
      container
      direction="row-reverse"
      item
      spacing={2}
      alignItems="flex-end"
      md={12}
    >
      <Grid md={6} xs={12} item>
        <TextField
          placeholder={t('storeCredit.placeholderSearch')}
          variant="outlined"
          fullWidth
          label={t('search')}
          onKeyUp={onkeyPress}
          onChange={(e) => setSearchText(e.target.value.trim())}
          style={{ height: '47px', width: 250 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => search()}
                  style={{ marginRight: -14 }}
                >
                  <img
                    src={searchIcon}
                    alt={t('search')}
                    style={{
                      width: 25,
                      height: 25,
                      paddingTop: 2,
                      cursor: 'pointer',
                    }}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid md={6} xs={12} item>
        <Box display="flex">
          <Typography style={{ fontWeight: 'bold', marginRight: 5 }}>
            {t('storeCredit.totalCurrentBalance')}:{' '}
          </Typography>
          <Typography>{`${currencySymbol}${totalBalance || 0}`}</Typography>
        </Box>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Box>
        <TransactionTable
          cols={colsTitles}
          rowKeys={rowKeys}
          data={data}
          mods={mods}
          isLoading={isLoading}
          noDataMsg={noDataMsg}
          pagination={pagination}
          searchBox={searchBox}
        />
      </Box>
      <AddDeductFundsModal
        currencySymbol={currencySymbol}
        afterSubmit={afterSubmit}
        ref={modalRef}
      />
      <RedemptionsModal ref={refModalRedemptions} />
    </>
  );
};

export default StoreCreditSearchUpdate;
