/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import { Box, Grid, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { coupons, membership } from '../../../state';
import { TransactionTable } from '../../custom';
import SingleCouponBox from './SingleCouponBox';

const rowKeys = [
  'code',
  'description',
  'couponPlan.name',
  'couponPlan.discount',
  'maxRedemptions',
  'expirationDate',
  'createdAt',
];
const membershipStatuses = [
  'Active',
  'Canceled',
  'Pending',
  'Deactivated',
  'Expired',
  '',
];

const SingleCouponList = () => {
  const [params, setParams] = React.useState({
    limit: 10,
    page: 1,
    isSingle: true,
  });
  const [, setIsOpen] = React.useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const colsTitles = [
    t('coupons.couponCode'),
    t('description'),
    t('coupons.discountName'),
    t('coupons.discountDescription'),
    t('coupons.maxRedemptions'),
    t('expirationDate'),
    t('created'),
  ];
  React.useEffect(() => {
    dispatch(coupons.actions.getCouponList(params));
  }, [params]);

  const isLoading = useSelector(coupons.selectors.selectIsLoading);
  const { data: couponList, total } = useSelector(
    coupons.selectors.selectCouponList
  );

  const mods = {
    status: (val) => membershipStatuses[val] || 'Deactivated',
    expirationDate: (val) =>
      val ? moment(`${val}Z`).format('MMM DD YYYY, HH:mm:ss') : '',
    createdAt: (val) =>
      val ? moment(`${val}Z`).format('MMM DD YYYY, HH:mm:ss') : '',
    maxRedemptions: (val) => (val === -1 ? '' : val),
  };
  const handleChangePage = (event, page) => {
    setParams({ ...params, page: page + 1 });
  };
  const handleChangeRowsPerPage = (event) => {
    setParams({ ...params, limit: event.target.value, page: 1 });
  };

  const pagination = {
    total,
    pageSize: params.limit,
    page: params.page,
    handleChangePage,
    handleChangeRowsPerPage,
  };

  useEffect(() => {
    dispatch(coupons.actions.getCouponPlanList());
  }, []);

  const searchBox = () => (
    <Grid item>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsOpen(true)}
            type="submit"
          >
            {t('coupons.generateCouponCode')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  useEffect(() => {
    dispatch(membership.actions.getMembershipPlans());
  }, []);

  return (
    <>
      <SingleCouponBox
        setCouponCode={() => dispatch(coupons.actions.getCouponList(params))}
      />
      <Box py={2}>
        <TransactionTable
          cols={colsTitles}
          rowKeys={rowKeys}
          data={couponList}
          searchBox={searchBox}
          pagination={pagination}
          mods={mods}
          isLoading={isLoading}
          noDataMsg={t('noDataMsg')}
        />
      </Box>
    </>
  );
};

export default SingleCouponList;
