import * as React from 'react';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Box,
  Grid,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { Close } from '@material-ui/icons';
import { CustomInputField } from '../../custom';
import api from '../../../state/membership/api';
import { alert, membership } from '../../../state';

const fieldStyle = {
  display: 'flex',
  flexDirection: 'row',
};

const EditMemberModal = React.forwardRef(({ onClose, item }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const listOfMonths = [
    { name: 'January', value: 1 },
    { name: 'February', value: 2 },
    { name: 'March', value: 3 },
    { name: 'April', value: 4 },
    { name: 'May', value: 5 },
    { name: 'June', value: 6 },
    { name: 'July', value: 7 },
    { name: 'August', value: 8 },
    { name: 'September', value: 9 },
    { name: 'October', value: 10 },
    { name: 'November', value: 11 },
    { name: 'December', value: 12 },
  ];
  const openAlert = (payload) => dispatch(alert.actions.open(payload));
  const membershipConfig = useSelector(
    membership.selectors.selectMembershipConfig
  );
  // const plans = useSelector(coupons.selectors.selectCouponPlans);
  const phoneRegExp = /^\(*\+*[1-9]{0,3}\)*-*[1-9]{0,3}[-. /]*\(*[2-9]\d{2}\)*[-. /]*\d{3}[-. /]*\d{4} *e*x*t*\.* *\d{0,4}$/;

  const validationSchema = () =>
    Yup.object().shape({
      firstName: Yup.string()
        .min(2, t('tooShort'))
        .max(50, t('validation.tooLong'))
        .required(t('validation.required')),
      lastName: Yup.string()
        .min(2, t('validation.tooShort'))
        .max(50, t('validation.tooLong'))
        .required(t('validation.required')),
      email: Yup.string()
        .email('Invalid email')
        .required(t('validation.required')),
      phone: Yup.string()
        .matches(phoneRegExp, t('validation.invalidPhoneNumber'))
        .required(t('validation.required')),
    });

  const initialData = {
    ...item,
  };
  const onSubmit = async (values, formikHelper) => {
    formikHelper.setSubmitting(true);
    try {
      await api.updateMembership({
        ...values,
      });
      openAlert({
        message: t('membership.messages.successfullyEditMember'),
        severity: 'success',
      });
      dispatch(membership.actions.getMembershipList());
      onClose();
    } catch (error) {
      console.log(error.message);
      openAlert({
        message: error?.message || t('membership.messages.failedEditMember'),
        severity: 'error',
      });
      formikHelper.setSubmitting(false);
    }
  };

  return (
    <Dialog open={!!item} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {t('membership.modifyMember')}: {item?.code}
      </DialogTitle>
      <DialogContent>
        <Box py={2}>
          <Formik
            onSubmit={onSubmit}
            enableReinitialize
            initialValues={initialData}
            validationSchema={validationSchema}
          >
            {({ isSubmitting, setFieldValue }) => {
              return (
                <Form>
                  <Box sx={fieldStyle}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Box pb={2}>
                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                            spacing={2}
                          >
                            <Grid item xs={12} md={6}>
                              <CustomInputField
                                customInput={Field}
                                component={TextField}
                                capitalize
                                fullWidth
                                required
                                label={t('firstName')}
                                name="firstName"
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <CustomInputField
                                customInput={Field}
                                component={TextField}
                                capitalize
                                fullWidth
                                required
                                label={t('lastName')}
                                name="lastName"
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>
                        </Box>
                        <Box pb={2}>
                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                            spacing={2}
                          >
                            <Grid item xs={12} md={6}>
                              <CustomInputField
                                customInput={Field}
                                component={TextField}
                                trim
                                fullWidth
                                required
                                label={t('phone')}
                                name="phone"
                                type="tel"
                                removeArrows
                                variant="outlined"
                                maskType="maskType"
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <CustomInputField
                                customInput={Field}
                                component={TextField}
                                trim
                                fullWidth
                                required
                                label={t('email')}
                                name="email"
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        {membershipConfig.showAddressInput && (
                          <>
                            <Box pb={2}>
                              <CustomInputField
                                customInput={Field}
                                component={TextField}
                                fullWidth
                                required
                                label={t('address')}
                                name="address"
                                variant="outlined"
                              />
                            </Box>
                            <Box pb={2}>
                              <Grid container display="row" spacing={2}>
                                <Grid
                                  item
                                  xs={
                                    membershipConfig.captureBirthMonth ? 6 : 5
                                  }
                                >
                                  <Field
                                    component={TextField}
                                    label="City"
                                    name="city"
                                    variant="outlined"
                                    required
                                    fullWidth
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={
                                    membershipConfig.captureBirthMonth ? 6 : 4
                                  }
                                >
                                  <Field
                                    component={TextField}
                                    label="State / Province"
                                    name="state"
                                    variant="outlined"
                                    required
                                    fullWidth
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={
                                    membershipConfig.captureBirthMonth ? 6 : 3
                                  }
                                >
                                  <Field
                                    component={TextField}
                                    label="Zip Code / Pin Code"
                                    name="zipcode"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    InputProps={{
                                      inputProps: {
                                        maxLength: '5',
                                      },
                                    }}
                                  />
                                </Grid>
                                {membershipConfig.captureBirthMonth && (
                                  <Grid item xs={6}>
                                    <Field
                                      component={TextField}
                                      label={t('birthMonth')}
                                      select
                                      name="birthMonth"
                                      variant="outlined"
                                      fullWidth
                                    >
                                      {listOfMonths.map((month) => (
                                        <MenuItem
                                          key={month.value}
                                          value={month.value}
                                          onClick={() =>
                                            setFieldValue(
                                              'birthMonth',
                                              month.value
                                            )
                                          }
                                        >
                                          {month.name}
                                        </MenuItem>
                                      ))}
                                    </Field>
                                  </Grid>
                                )}
                              </Grid>
                            </Box>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      flexDirection: 'column',
                    }}
                  >
                    <Button color="primary" variant="contained" type="submit">
                      {isSubmitting ? (
                        <CircularProgress size={24} />
                      ) : (
                        t('saveChanges')
                      )}
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </DialogContent>
      <IconButton
        aria-label="clear"
        onClick={onClose}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          width: 20,
          height: 20,
        }}
      >
        <Close fontSize="small" />
      </IconButton>
    </Dialog>
  );
});

EditMemberModal.propTypes = {
  onClose: PropTypes.func,
  item: PropTypes.string,
};

EditMemberModal.defaultProps = {
  onClose: () => {},
  item: null,
};

export default EditMemberModal;
