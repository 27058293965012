import * as React from 'react';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Box,
  Grid,
  IconButton,
} from '@material-ui/core';
import moment from 'moment';
import DatePicker from '@mui/lab/DatePicker';
import { useDispatch } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { Close } from '@material-ui/icons';
import { CustomInputField } from '../../custom';
import api from '../../../state/membership/api';
import { alert, membership } from '../../../state';

const fieldStyle = {
  display: 'flex',
  flexDirection: 'row',
};

const PauseMemberModal = React.forwardRef(({ onClose, item }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const openAlert = (payload) => dispatch(alert.actions.open(payload));

  const validationSchema = () =>
    Yup.object().shape({
      startDate: Yup.date()
        .required(t('validation.required'))
        .max(
          new Date(item.expirationDate),
          'Start Date need to be less than the Expiration Date'
        ),
      endDate: Yup.date()
        .required(t('validation.required'))
        .when('startDate', (startDate, schema) => {
          if (startDate) {
            const currentDay = new Date(startDate.getTime() + 86400000);
            return schema.min(currentDay, 'End date must be after start date');
          }
          return schema;
        }),
    });

  const initialData = {
    firstName: item?.firstName,
    lastName: item?.lastName,
    startDate: moment().add(1, 'days').format('YYYY-MM-DD'),
    endDate: '',
  };
  const onSubmit = async (values, formikHelper) => {
    formikHelper.setSubmitting(true);
    try {
      await api.updateMembershipTimePause({
        membershipId: item?.id,
        startDate: moment(values.startDate, 'YYYY-MM-DD')
          .startOf('day')
          .add(1, 'hours')
          .local()
          .format(),
        endDate: moment(values.endDate, 'YYYY-MM-DD')
          .startOf('day')
          .add(1, 'hours')
          .local()
          .format(),
      });
      openAlert({
        message: t('membership.messages.successfullyEditMember'),
        severity: 'success',
      });
      dispatch(membership.actions.getMembershipList());
      onClose();
    } catch (error) {
      openAlert({
        message:
          error?.message || error || t('membership.messages.failedEditMember'),
        severity: 'error',
      });
      formikHelper.setSubmitting(false);
    }
  };

  const validateDate = (val) => {
    if (!val) return null;

    if (
      !moment(val, 'YYYY-MM-DD').isValid() ||
      moment(val, 'YYYY-MM-DD').isBefore(moment(), 'date')
    ) {
      return t('invalidDate');
    }

    return null;
  };

  return (
    <Dialog open={!!item} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {t('membership.pauseMembership.title')}
        {item?.code}
      </DialogTitle>
      <DialogContent>
        <Box py={2}>
          <Formik
            onSubmit={onSubmit}
            enableReinitialize
            initialValues={initialData}
            validationSchema={validationSchema}
          >
            {({
              isSubmitting,
              setFieldValue,
              setFieldTouched,
              values,
              errors,
              touched,
            }) => {
              return (
                <Form>
                  <Box sx={fieldStyle}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Box pb={2}>
                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                            spacing={2}
                          >
                            <Grid item xs={12} md={6}>
                              <CustomInputField
                                customInput={Field}
                                component={TextField}
                                capitalize
                                fullWidth
                                label={t('firstName')}
                                name="firstName"
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                disabled
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <CustomInputField
                                customInput={Field}
                                component={TextField}
                                capitalize
                                fullWidth
                                label={t('lastName')}
                                name="lastName"
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                disabled
                              />
                            </Grid>
                          </Grid>
                        </Box>
                        <Box pb={2}>
                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                            spacing={2}
                          >
                            <Grid item xs={12} md={6}>
                              <DatePicker
                                inputFormat="YYYY-MM-DD"
                                mask="____-__-__"
                                required
                                minDate={moment().add(-1, 'days')}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                    variant: 'outlined',
                                  },
                                }}
                                renderInput={(pr) => {
                                  return (
                                    <Field
                                      readOnly
                                      inputProps={{ readOnly: true }}
                                      component={TextField}
                                      fullWidth
                                      label={`${t(
                                        'membership.pauseMembership.startDate'
                                      )} *`}
                                      name="startDate"
                                      validate={validateDate}
                                      variant="outlined"
                                      {...pr}
                                      error={
                                        touched.startDate &&
                                        Boolean(errors.startDate)
                                      }
                                      helperText={
                                        touched.startDate && errors.startDate
                                      }
                                      onKeyDown={(e) => e.preventDefault()}
                                    />
                                  );
                                }}
                                value={
                                  values.startDate
                                    ? moment(values.startDate)
                                    : null
                                }
                                onChange={(val) => {
                                  setFieldValue(
                                    'startDate',
                                    val ? val.format('YYYY-MM-DD') : ''
                                  );
                                }}
                                name="startDate"
                                label={`${t(
                                  'membership.pauseMembership.startDate'
                                )} *`}
                                onKeyPress={() => setFieldTouched('startDate')}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <DatePicker
                                inputFormat="YYYY-MM-DD"
                                mask="____-__-__"
                                required
                                minDate={moment().add(-1, 'days')}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                    variant: 'outlined',
                                  },
                                }}
                                renderInput={(pr) => {
                                  return (
                                    <Field
                                      readOnly
                                      inputProps={{ readOnly: true }}
                                      component={TextField}
                                      fullWidth
                                      label={`${t(
                                        'membership.pauseMembership.endDate'
                                      )} *`}
                                      name="endDate"
                                      validate={validateDate}
                                      variant="outlined"
                                      {...pr}
                                      error={
                                        touched.endDate &&
                                        Boolean(errors.endDate)
                                      }
                                      helperText={
                                        touched.endDate && errors.endDate
                                      }
                                      onKeyDown={(e) => e.preventDefault()}
                                    />
                                  );
                                }}
                                value={
                                  values.endDate ? moment(values.endDate) : null
                                }
                                onChange={(val) => {
                                  setFieldValue(
                                    'endDate',
                                    val ? val.format('YYYY-MM-DD') : ''
                                  );
                                }}
                                name="endDate"
                                label={`${t(
                                  'membership.pauseMembership.endDate'
                                )} *`}
                                onKeyPress={() => setFieldTouched('endDate')}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      flexDirection: 'column',
                    }}
                  >
                    <Button color="primary" variant="contained" type="submit">
                      {isSubmitting ? (
                        <CircularProgress size={24} />
                      ) : (
                        t('saveChanges')
                      )}
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </DialogContent>
      <IconButton
        aria-label="clear"
        onClick={onClose}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          width: 20,
          height: 20,
        }}
      >
        <Close fontSize="small" />
      </IconButton>
    </Dialog>
  );
});

PauseMemberModal.propTypes = {
  onClose: PropTypes.func,
  item: PropTypes.string,
};

PauseMemberModal.defaultProps = {
  onClose: () => {},
  item: null,
};

export default PauseMemberModal;
