/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Field, Formik } from 'formik';
import PropTypes from 'prop-types';
import { Checkbox } from 'formik-material-ui';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { TextField } from 'formik-mui';
import moment from 'moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import { alert, ticket } from '../../../state';
import SEO from '../../seo';
import { TicketEventRepeatValidation } from './validation';
import api from '../../../state/ticket/api';

const RepeatTicketEventForm = ({ closeModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ticketEvent = useSelector(ticket.selectors.selectTicketEvent);
  const openAlert = (payload) => dispatch(alert.actions.open(payload));

  const createInitialValue = () => {
    const value = {
      eventStartTime: moment(`${ticketEvent.startTime}Z`).toDate(),
      eventEndTime: moment(`${ticketEvent.endTime}Z`).toDate(),
      daysOfWeek: [],
      startDate: null,
      endDate: null,
    };
    return value;
  };

  useEffect(() => {
    dispatch(ticket.actions.getTicketEvents());
  }, []);
  const [dates, setDates] = useState([]);

  const handleSubmit = async () => {
    try {
      // eslint-disable-next-line no-restricted-syntax
      for (const date of dates) {
        // eslint-disable-next-line no-await-in-loop
        await api.duplicateTicketEvent({
          ...ticketEvent,
          startTime: date.startTime,
          endTime: date.endTime,
          id: null,
          status: 0,
          parentEventId: ticketEvent.id,
        });
      }

      dispatch(ticket.actions.setCurrentTicketEvent({}));
      openAlert({
        message: 'Tickets have been duplicated successfully',
        severity: 'success',
      });
      closeModal();
      // Additional logic after setting ticket events
    } catch (error) {
      console.error('Error setting ticket events:', error);
    }
  };

  return (
    <>
      <SEO title={t('tickets.tickets')} />
      <Formik
        key={Math.random}
        enableReinitialize
        validateOnChange={false}
        validateOnBlur
        validationSchema={TicketEventRepeatValidation}
        initialValues={createInitialValue()}
        onSubmit={async (values) => {
          handleSubmit(values);
        }}
      >
        {({
          values,
          setFieldValue,
          validateField,
          touched,
          setFieldTouched,
          errors,
          isSubmitting,
        }) => {
          const daysOfWeek = [
            { value: 1, label: t('monday') },
            { value: 2, label: t('tuesday') },
            { value: 3, label: t('wednesday') },
            { value: 4, label: t('thursday') },
            { value: 5, label: t('friday') },
            { value: 6, label: t('saturday') },
            { value: 0, label: t('sunday') },
          ];

          const calculateWeekdayOccurrences = (
            startDate,
            endDate,
            selectedDaysOfWeek,
            eventStartTime,
            eventEndTime
          ) => {
            const currentDate = moment(startDate);
            const end = moment(endDate);
            let count = 0;
            const validDates = [];

            const startTime = moment(eventStartTime);
            const endTime = moment(eventEndTime);

            while (currentDate <= end) {
              const weekday = currentDate.day();
              const now = moment();
              const startTimeMoment = moment(currentDate).set({
                hour: startTime.hour(),
                minute: startTime.minute(),
              });

              if (
                startTimeMoment.isAfter(now) &&
                (selectedDaysOfWeek.length === 0 ||
                  selectedDaysOfWeek.includes(weekday))
              ) {
                // eslint-disable-next-line no-plusplus
                count++;

                // Calculate endTimeMoment based on startTimeMoment
                const endTimeMoment = moment(currentDate).set({
                  hour: endTime.hour(),
                  minute: endTime.minute(),
                });

                // If endTimeMoment is before startTimeMoment, add 1 day
                if (endTimeMoment.isBefore(startTimeMoment)) {
                  endTimeMoment.add(1, 'day');
                }

                validDates.push({
                  startTime: startTimeMoment,
                  endTime: endTimeMoment,
                });
              }

              currentDate.add(1, 'day');
            }

            setDates(validDates);
            setFieldValue('times', count);
          };

          useEffect(() => {
            calculateWeekdayOccurrences(
              values.startDate,
              values.endDate,
              values.daysOfWeek,
              values.eventStartTime,
              values.eventEndTime
            );
          }, [
            values.daysOfWeek,
            values.startDate,
            values.endDate,
            values.eventStartTime,
            values.eventEndTime,
          ]);
          return (
            <Box>
              <Box
                style={{
                  border: '1px solid #ccc',
                  position: 'relative',
                  width: '100%',
                }}
                p={2}
                pb={4}
              >
                <Grid
                  container
                  item
                  xs={12}
                  spacing={2}
                  style={{ marginTop: 10 }}
                >
                  <Grid item xs={12} md={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        format="hh:mm aa"
                        renderInput={(pr) => {
                          return (
                            <Field
                              readOnly
                              component={TextField}
                              fullWidth
                              label={`${t('startTime')}`}
                              name="eventStartTime"
                              variant="outlined"
                              {...pr}
                              inputProps={{
                                ...pr.inputProps,
                                readOnly: true,
                                placeholder: '',
                              }}
                              error={
                                touched.eventStartTime &&
                                Boolean(errors.eventStartTime)
                              }
                              helperText={
                                touched.eventStartTime && errors.eventStartTime
                              }
                              onKeyDown={(e) => e.preventDefault()}
                            />
                          );
                        }}
                        sx={{ width: '100%' }}
                        slotProps={{
                          field: {
                            readOnly: true,
                            onKeyDown: (e) => e.preventDefault(),
                          },
                          textField: {
                            helperText:
                              touched.eventStartTime &&
                              Boolean(errors.eventStartTime)
                                ? touched.eventStartTime &&
                                  errors.eventStartTime
                                : '',
                            error:
                              touched.eventStartTime &&
                              Boolean(errors.eventStartTime),
                          },
                          layout: {
                            sx: {
                              '.MuiMultiSectionDigitalClockSection-root': {
                                width: 76,
                              },
                              '.MuiMultiSectionDigitalClockSection-item': {
                                width: 68,
                              },
                            },
                          },
                        }}
                        value={values.eventStartTime}
                        onChange={(val) => {
                          // const formattedTime = moment(val).format('HH:mm A');
                          setFieldValue('eventStartTime', val);
                          setTimeout(() => {
                            if (values.eventEndTime) {
                              // validateField('eventEndTime');
                            }
                          });
                          // setFieldTouched('eventStartTime', true);
                        }}
                        onBlur={() => setFieldTouched('eventStartTime', true)}
                        name="eventStartTime"
                        label={`${t('startTime')}`}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        renderInput={(pr) => {
                          return (
                            <Field
                              readOnly
                              component={TextField}
                              fullWidth
                              label={`${t('endTime')}`}
                              name="eventEndTime"
                              variant="outlined"
                              {...pr}
                              inputProps={{
                                ...pr.inputProps,
                                readOnly: true,
                                placeholder: '',
                              }}
                              error={
                                touched.eventEndTime &&
                                Boolean(errors.eventEndTime)
                              }
                              helperText={
                                touched.eventEndTime && errors.eventEndTime
                              }
                              onKeyDown={(e) => e.preventDefault()}
                            />
                          );
                        }}
                        sx={{ width: '100%' }}
                        slotProps={{
                          field: {
                            readOnly: true,
                            onKeyDown: (e) => e.preventDefault(),
                          },
                          textField: {
                            helperText:
                              touched.eventEndTime &&
                              Boolean(errors.eventEndTime)
                                ? touched.eventEndTime && errors.eventEndTime
                                : '',
                            error:
                              touched.eventEndTime &&
                              Boolean(errors.eventEndTime),
                          },
                          layout: {
                            sx: {
                              '.MuiMultiSectionDigitalClockSection-root': {
                                width: 76,
                              },
                              '.MuiMultiSectionDigitalClockSection-item': {
                                width: 68,
                              },
                            },
                          },
                        }}
                        value={values.eventEndTime}
                        onChange={(val) => {
                          setFieldValue('eventEndTime', val);
                          setFieldTouched('eventEndTime', true);
                        }}
                        onBlur={() => setFieldTouched('eventEndTime', true)}
                        name="eventEndTime"
                        label={`${t('endTime')}`}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Box>
              <Box mt={3} />
              <Box
                style={{
                  border: '1px solid #ccc',
                }}
                p={2}
              >
                <Box
                  style={{
                    border: '1px solid #ccc',
                    position: 'relative',
                  }}
                  p={2}
                  mt={2}
                  mb={2}
                >
                  <Typography
                    style={{
                      position: 'absolute',
                      top: -15,
                      left: 15,
                      background: '#fff',
                      padding: 3,
                    }}
                  >
                    {t('loyalty.daysOfTheWeek')}
                  </Typography>
                  <Grid container spacing={1}>
                    {daysOfWeek.map((day) => (
                      <Grid item key={day.value}>
                        <Field
                          type="checkbox"
                          component={Checkbox}
                          name="daysOfWeek"
                          color="primary"
                          value={day.value}
                          parse={(value) => Number(value)}
                          onChange={(event) => {
                            const value = parseInt(event.target.value, 10);
                            const isChecked = event.target.checked;
                            if (isChecked) {
                              setFieldValue('daysOfWeek', [
                                ...values.daysOfWeek,
                                value,
                              ]);
                            } else {
                              setFieldValue(
                                'daysOfWeek',
                                values.daysOfWeek.filter(
                                  (dayValue) => dayValue !== value
                                )
                              );
                            }
                          }}
                        />
                        {day.label}
                      </Grid>
                    ))}
                  </Grid>
                </Box>
                <Box
                  style={{
                    border: '1px solid #ccc',
                    position: 'relative',
                    width: '100%',
                  }}
                  p={2}
                  pb={4}
                  mb={2}
                >
                  <Typography
                    style={{
                      position: 'absolute',
                      top: -15,
                      left: 15,
                      background: '#fff',
                      padding: 3,
                    }}
                  >
                    {t('loyalty.byDates')}:
                  </Typography>
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={2}
                    style={{ marginTop: 10 }}
                  >
                    <Grid item xs={12} md={6}>
                      <DatePicker
                        disablePast
                        inputFormat="YYYY-MM-DD"
                        mask=""
                        renderInput={(pr) => {
                          return (
                            <Field
                              readOnly
                              component={TextField}
                              fullWidth
                              label={`${t('loyalty.startDate')}`}
                              name="startDate"
                              variant="outlined"
                              {...pr}
                              inputProps={{
                                ...pr.inputProps,
                                readOnly: true,
                                placeholder: '',
                              }}
                              error={
                                touched.startDate && Boolean(errors.startDate)
                              }
                              helperText={touched.startDate && errors.startDate}
                              onKeyDown={(e) => e.preventDefault()}
                            />
                          );
                        }}
                        value={
                          values.startDate ? moment(values.startDate) : null
                        }
                        onChange={(val) => {
                          setFieldValue('startDate', val);
                          setTimeout(() => {
                            validateField('startDate');
                            setFieldTouched('startDate', true);
                            if (values.endDate) {
                              validateField('endDate');
                            }
                          });
                        }}
                        onBlur={() => setFieldTouched('startDate', true)}
                        name="startDate"
                        label={`${t('loyalty.startDate')}`}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <DatePicker
                        disableMaskedInput
                        minDateTime={
                          values.startDate ? moment(values.startDate) : null
                        }
                        disablePast
                        inputFormat="YYYY-MM-DD"
                        renderInput={(pr) => {
                          return (
                            <Field
                              readOnly
                              component={TextField}
                              fullWidth
                              label={`${t('loyalty.endDate')}`}
                              name="endDate"
                              variant="outlined"
                              {...pr}
                              inputProps={{
                                ...pr.inputProps,
                                readOnly: true,
                                placeholder: '',
                              }}
                              error={touched.endDate && Boolean(errors.endDate)}
                              helperText={touched.endDate && errors.endDate}
                              onKeyDown={(e) => e.preventDefault()}
                            />
                          );
                        }}
                        value={values.endDate ? moment(values.endDate) : null}
                        onChange={(val) => {
                          setFieldValue('endDate', val);
                          setTimeout(() => {
                            validateField('endDate');
                            setFieldTouched('endDate', true);
                          });
                        }}
                        onBlur={() => setFieldTouched('endDate', true)}
                        name="endDate"
                        label={`${t('loyalty.endDate')}`}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box
                style={{
                  border: '1px solid #ccc',
                  minHeight: 38,
                  width: '100%',
                }}
                p={1}
                mt={3}
              >
                {t('tickets.event.eventWillBeRepeatedXTimes', {
                  times: values.times,
                })}
              </Box>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      flexDirection: 'column',
                    }}
                    mt={4}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <CircularProgress size={24} />
                      ) : (
                        t('save')
                      )}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          );
        }}
      </Formik>
    </>
  );
};
RepeatTicketEventForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default RepeatTicketEventForm;
