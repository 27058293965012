import React from 'react';
import {
  Typography,
  Box,
  Grid,
  Divider,
  Tabs,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { TabContext, TabPanel } from '@material-ui/lab';
import SwipeableViews from 'react-swipeable-views';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import StyledTab from '../../custom/StyledTab';
import { alert, membership, merchant } from '../../../state';
import MembershipConfig from './MembershipConfig';
import CheckInList from './CheckInList';
import RegisterConfig from './RegisterConfig';
import MembershipList from './MembershipList';
import UrlCheckIn from './UrlForCheckIn';
import MembershipDiscountsAndPerks from './MembershipDiscountsAndPerks';
import AutomaticStoreCreditsList from './AutomaticStoreCreditsList';
import MembershipImages from './MembershipImages';
import SponsorConfig from './SponsorConfig';
import SharedMembershipConfig from './SharedMembershipConfig';
import IssueCouponsConfig from './IssueCouponsConfig';
import DefinePlans from './DefinePlans';
import MembershipLoyaltyConfig from './MembershipLoyaltyConfig';
import Perks from './Perks';
import { PartnerEnum } from '../../../utils/constants';

const MembershipSetup = () => {
  const { t } = useTranslation();
  const membershipConfig = useSelector(
    membership.selectors.selectMembershipConfig
  );
  const dispatch = useDispatch();
  const {
    startingNumber,
    isConfigByTier,
    imageUrl,
    description,
    terms,
    customQuestion,
    plans,
    questions,
    allowSelectPlan,
    isDigital,
    numberType,
    showHeader,
    captureBirthMonth,
    showAddressInput,
    hasLoyalty,
    loyaltyProgramId,
    useTax,
    tax,
    hasSponsor,
    sponsorText,
    sponsorImage,
    autoRenewal,
    trialDays,
    hasTrial,
    enableOneTimeCost,
    oneTimeCost,
    oneTimeCostType,
    enableRecurringCost,
    recurringCost,
    recurringCostType,
  } = membershipConfig;
  const [formTab, setTab] = React.useState(0);
  const openAlert = (payload) => dispatch(alert.actions.open(payload));
  const { enableStoreCredit } = useSelector(
    merchant.selectors.selectMerchantDetails
  );
  const { enableCoupon, enableLoyaltyCard, partner = '' } = useSelector(
    merchant.selectors.selectMerchantDetails
  );

  React.useEffect(() => {
    dispatch(membership.actions.getMerchantMembershipConfig());
    dispatch(membership.actions.getMembershipPlans());
  }, []);

  return (
    <div>
      <Box pb={2}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs="auto">
            <Typography variant="h5">{t('membershipCardDetails')}</Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Formik
        enableReinitialize
        validateOnChange={false}
        validateOnBlur
        validationSchema={() => {
          return Yup.object().shape({
            tax: Yup.number()
              .min(0)
              .when('useTax', {
                is: true,
                then: Yup.number()
                  .min(0.1, t('required'))
                  .required(t('required')),
              }),
            trialDays: Yup.number()
              .min(0)
              .when('hasTrial', {
                is: true,
                then: Yup.number()
                  .min(1, t('required'))
                  .required(t('required')),
              }),
            sponsorText: Yup.string()
              .nullable()
              .when(['hasSponsor'], {
                is: (val) => formTab === 8 && val === 1,
                then: (schema) =>
                  schema
                    .required(t('validates.required'))
                    .max(50, t('membership.validates.sponsorTextMax')),
              }),
            sponsorImage: Yup.string()
              .nullable()
              .when(['hasSponsor'], {
                is: (val) => formTab === 8 && val === 1,
                then: (schema) => schema.required(t('validates.required')),
              }),
            oneTimeCost: Yup.number()
              .min(0)
              .when('enableOneTimeCost', {
                is: true,
                then: Yup.number()
                  .min(0.1, t('required'))
                  .required(t('required')),
              }),
            recurringCost: Yup.number()
              .min(0)
              .when('enableRecurringCost', {
                is: true,
                then: Yup.number()
                  .min(0.1, t('required'))
                  .required(t('required')),
              }),
          });
        }}
        initialValues={{
          startingNumber: startingNumber || '10000001',
          isConfigByTier,
          imageUrl,
          description,
          terms,
          customQuestion,
          questions,
          allowSelectPlan,
          plans: plans && plans[0] ? plans : [{}],
          isDigital,
          showHeader,
          captureBirthMonth,
          showAddressInput,
          numberType: numberType || 0,
          hasLoyalty,
          loyaltyProgramId,
          useTax,
          tax: tax || 0,
          hasTrial,
          trialDays: trialDays || 0,
          hasSponsor: hasSponsor === 1 || hasSponsor === true ? 1 : 0,
          sponsorText,
          sponsorImage,
          autoRenewal,
          enableOneTimeCost: enableOneTimeCost || false,
          oneTimeCost,
          oneTimeCostType: oneTimeCostType || 0,
          enableRecurringCost: enableRecurringCost || false,
          recurringCost,
          recurringCostType: recurringCostType || 0,
        }}
        onSubmit={async (values) => {
          const res = await dispatch(
            membership.actions.setMerchantMembershipConfig({
              ...values,
              ...(formTab === 8
                ? {
                    hasSponsor: values.hasSponsor,
                    sponsorText: values.sponsorText,
                    sponsorImage: values.sponsorImage,
                  }
                : {
                    hasSponsor,
                    sponsorText,
                    sponsorImage,
                  }),
              tax: values.tax || 0,
              trialDays: values.trialDays || 0,
            })
          );
          if (res.payload) {
            openAlert({
              message: t('membership.messages.updateSuccess'),
              severity: 'success',
            });
            dispatch(membership.actions.getMembershipPlans());
            dispatch(membership.actions.getMerchantMembershipConfig());
          }
          if (res.error) {
            openAlert({
              message: res.error.message || t('updateGiftCardFailed'),
              severity: 'error',
            });
          }
        }}
      >
        {({ handleSubmit, isSubmitting }) => {
          return (
            <Box py={4}>
              <Tabs
                value={formTab}
                onChange={(event, newValue) => {
                  setTab(newValue);
                }}
                TabIndicatorProps={{ style: { background: '#0969da' } }}
                aria-label="Select settings tab"
              >
                <StyledTab
                  disableRipple
                  id="initialSetup"
                  aria-controls="values"
                  label={t('membership.tabs.initialSetup')}
                />
                <StyledTab
                  disableRipple
                  id="initialSetup"
                  aria-controls="values"
                  label={t('membership.tabs.definePlans')}
                />
                <StyledTab
                  disableRipple
                  id="setupDiscounts"
                  aria-controls="values"
                  label={t('membership.tabs.setupDiscountsAndPerks')}
                />
                <StyledTab
                  disableRipple
                  id="images"
                  aria-controls="values"
                  label={t('membership.tabs.images')}
                />
                <StyledTab
                  disableRipple
                  id="registerConfig"
                  aria-controls="values"
                  label={t('membership.tabs.signUpForm')}
                />
                <StyledTab
                  disableRipple
                  id="membershipsList"
                  aria-controls="values"
                  label={t('membership.tabs.members')}
                />
                <StyledTab
                  disableRipple
                  id="checkinData"
                  aria-controls="images"
                  label={t('membership.tabs.checkInData')}
                />
                <StyledTab
                  disableRipple
                  id="urlforcheckin"
                  aria-controls="images"
                  label={t('membership.tabs.perks')}
                />
                <StyledTab
                  disableRipple
                  id="urlforcheckin"
                  aria-controls="images"
                  label={t('membership.tabs.urlLink')}
                />
                <StyledTab
                  disableRipple
                  id="issueCoupons"
                  aria-controls="values"
                  label={t('membership.tabs.issueCoupons')}
                  style={{
                    display:
                      enableCoupon && partner === PartnerEnum.Square
                        ? 'block'
                        : 'none',
                  }}
                />
                <StyledTab
                  disableRipple
                  id="issueLoyalty"
                  aria-controls="values"
                  label={t('membership.tabs.issueLoyalty')}
                  style={{
                    display:
                      enableLoyaltyCard && partner === PartnerEnum.Square
                        ? 'block'
                        : 'none',
                  }}
                />
                <StyledTab
                  disableRipple
                  id="misc"
                  aria-controls="values"
                  label={t('membership.tabs.misc')}
                  style={{ display: 'none' }}
                />
                <StyledTab
                  disableRipple
                  id="programMerchants"
                  aria-controls="values"
                  label={t('membership.tabs.programMerchants')}
                  style={{
                    display: membershipConfig.plans?.some(
                      (p) => p.sharingStatus === 1
                    )
                      ? 'block'
                      : 'none',
                  }}
                />
                <StyledTab
                  disableRipple
                  id="registerConfig"
                  aria-controls="values"
                  label={t('membership.tabs.setupAutomaticStoreCredits')}
                  disabled={!enableStoreCredit}
                  style={{
                    display: enableStoreCredit === 'hidden' ? 'block' : 'none',
                  }}
                />
              </Tabs>
              <Divider />
              <TabContext value={formTab.toString()}>
                <SwipeableViews index={formTab} onChangeIndex={setTab}>
                  <TabPanel value={formTab.toString()} index={0}>
                    <>
                      <MembershipConfig />
                      <Grid item xs={12} md={3}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSubmit}
                          type="submit"
                          fullWidth
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? (
                            <CircularProgress size={24} />
                          ) : (
                            t('saveChanges')
                          )}
                        </Button>
                      </Grid>
                    </>
                  </TabPanel>
                  {formTab === 1 ? (
                    <TabPanel value={formTab.toString()} index={1}>
                      <DefinePlans />
                    </TabPanel>
                  ) : (
                    <div />
                  )}
                  {formTab === 2 ? (
                    <TabPanel
                      value={formTab.toString()}
                      index={3}
                      style={{ padding: '10px 0' }}
                    >
                      <MembershipDiscountsAndPerks />
                    </TabPanel>
                  ) : (
                    <div />
                  )}
                  {formTab === 3 ? (
                    <TabPanel value={formTab.toString()} index={4}>
                      <MembershipImages />
                    </TabPanel>
                  ) : (
                    <div />
                  )}
                  {formTab === 4 ? (
                    <TabPanel value={formTab.toString()} index={5}>
                      <RegisterConfig />
                    </TabPanel>
                  ) : (
                    <div />
                  )}
                  {formTab === 5 ? (
                    <TabPanel value={formTab.toString()} index={6}>
                      <MembershipList />
                    </TabPanel>
                  ) : (
                    <div />
                  )}
                  {formTab === 6 ? (
                    <TabPanel value={formTab.toString()} index={7}>
                      <CheckInList />
                    </TabPanel>
                  ) : (
                    <div />
                  )}
                  {formTab === 7 ? (
                    <TabPanel value={formTab.toString()} index={8}>
                      <Perks />
                    </TabPanel>
                  ) : (
                    <div />
                  )}
                  {formTab === 8 ? (
                    <TabPanel value={formTab.toString()} index={9}>
                      <UrlCheckIn />
                    </TabPanel>
                  ) : (
                    <div />
                  )}
                  {formTab === 9 ? (
                    <TabPanel value={formTab.toString()} index={10}>
                      <IssueCouponsConfig />
                    </TabPanel>
                  ) : (
                    <div />
                  )}
                  {formTab === 10 ? (
                    <TabPanel value={formTab.toString()} index={11}>
                      <MembershipLoyaltyConfig />
                    </TabPanel>
                  ) : (
                    <div />
                  )}
                  {formTab === 11 ? ( // hidden
                    <TabPanel value={formTab.toString()} index={12}>
                      <SponsorConfig />
                    </TabPanel>
                  ) : (
                    <div />
                  )}
                  {formTab === 12 ? (
                    <TabPanel value={formTab.toString()} index={13}>
                      <SharedMembershipConfig />
                    </TabPanel>
                  ) : (
                    <div />
                  )}
                  {formTab === 13 ? (
                    <TabPanel value={formTab.toString()} index={2}>
                      <AutomaticStoreCreditsList />
                    </TabPanel>
                  ) : (
                    <div />
                  )}
                </SwipeableViews>
              </TabContext>
            </Box>
          );
        }}
      </Formik>
    </div>
  );
};

export default MembershipSetup;
