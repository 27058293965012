/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import {
  Box,
  Grid,
  Button,
  MenuItem,
  Card,
  CardContent,
  Typography,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { TextField, Select } from 'formik-mui';
import Modal from '@material-ui/core/Modal';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, RadioGroup } from 'formik-material-ui';
import * as Yup from 'yup';
import getSymbolFromCurrency from 'currency-symbol-map';
import SEO from '../../seo';
import { alert, membership, merchant, transactions } from '../../../state';
import { CustomInputField } from '../../custom';
import { FREQUENCY } from '../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    boxShadow: theme.shadows[5],
    right: 'auto',
    minWidth: '700px',
    height: '600px',
    bottom: 'auto',
    marginRight: '-50%',
    background: 'transparent',
    backgroundColor: '#fff',
    border: '1px solid #000',
    padding: theme.spacing(4, 4),
    overflow: 'scroll',
    // transform: 'translate(-50%, -50%)',
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const initValues = {
  planId: '',
  storeCreditConfigId: '',
  birthMonthEnabled: true,
  onPayment: {
    id: '',
    enabled: true,
    amount: '',
    daysToExpire: '',
  },
  birthMonth: {
    id: '',
    enabled: 1,
    amount: '',
    daysToExpire: '',
  },
  recurring: {
    id: '',
    frequency: '',
    amount: '',
    daysToExpire: 7,
  },
};

const AutomaticStoreCreditsForm = ({
  planOptions,
  isOpen,
  closeModal,
  storeCreditConfig,
  itemSelected,
}) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();

  const openAlert = (payload) => dispatch(alert.actions.open(payload));

  const [initialValues, setInitialValues] = React.useState(initValues);
  const [selectedFrequency, setSelectedFrequency] = React.useState({});
  const isSending = useSelector(transactions.selectors.selectIsSending);
  const [modalStyle] = React.useState(getModalStyle);
  const { currency } = useSelector(merchant.selectors.selectMerchantDetails);
  const currencySymbol = getSymbolFromCurrency(currency);

  useEffect(() => {
    if (itemSelected.storeCreditConfigId) {
      setInitialValues(itemSelected);
      setSelectedFrequency(
        FREQUENCY.find((f) => f.value === itemSelected.recurring.frequency)
      );
    } else {
      setInitialValues(initValues);
      setSelectedFrequency();
    }
  }, [itemSelected]);

  useEffect(() => {
    dispatch(membership.actions.getMembershipPlans());
  }, []);

  const validationSchema = Yup.object().shape({
    planId: Yup.string().required(t('membership.validates.planIdRequired')),
    onPayment: Yup.object().shape({
      amount: Yup.number()
        .max(2000, t('membership.amountLimit', { limit: '2000$' }))
        .required(t('membership.amountRequired')),
      daysToExpire: Yup.number().required(t('membership.expiresInRequired')),
    }),
    birthMonth: Yup.object().shape({
      amount: Yup.number()
        .max(2000, t('membership.amountLimit', { limit: `2000${currency}` }))
        .required(t('membership.amountRequired')),
      daysToExpire: Yup.number().required(t('membership.expiresInRequired')),
    }),
    recurring: Yup.object().shape({
      frequency: Yup.string().required(t('membership.selectFrequency')),
      amount: Yup.number()
        .max(2000, t('membership.amountLimit', { limit: `2000${currency}` }))
        .required(t('membership.amountRequired')),
      // daysToExpire: Yup.number().required(
      //   'Expires in # days is a required field'
      // ),
    }),
  });

  const diffDay = (d1, d2) => {
    const date1 = new Date(d1);
    const date2 = new Date(d2);
    // To calculate the time difference of two dates
    const differenceInTime = date2.getTime() - date1.getTime();
    // To calculate the no. of days between two dates
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    return Math.round(differenceInDays);
  };

  const getDayDiffQuarterly = () => {
    const today = new Date();
    const currentYear = today.getFullYear();

    const quarterly1 = new Date(currentYear, 2, 30);
    const quarterly2 = new Date(currentYear, 5, 30);
    const quarterly3 = new Date(currentYear, 8, 30);
    const quarterly4 = new Date(currentYear, 11, 31);
    if (today <= quarterly1) {
      const firstDate = new Date(currentYear, 3, 1);
      const seconDate = new Date(currentYear, 6, 1);
      return diffDay(firstDate, seconDate);
    }

    if (today <= quarterly2) {
      const firstDate = new Date(currentYear, 6, 1);
      const seconDate = new Date(currentYear, 9, 1);
      return diffDay(firstDate, seconDate);
    }

    if (today <= quarterly3) {
      const firstDate = new Date(currentYear, 9, 1);
      const seconDate = new Date(currentYear, 11, 1);
      return diffDay(firstDate, seconDate);
    }

    if (today <= quarterly4) {
      const firstDate = new Date(currentYear + 1, 0, 1);
      const seconDate = new Date(currentYear + 1, 3, 1);
      return diffDay(firstDate, seconDate);
    }
    return 0;
  };

  const calculateDayDiff = (type) => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 1;
    const currentDay = today.getDate();

    let days = 0;
    switch (type) {
      case 'Weekly':
        days = 7;
        break;
      case 'TwiceAMonth':
        if (currentDay < 15) {
          days = 15;
        } else {
          const lastDayOfMonth = new Date(currentYear, currentMonth, 0);
          const twiceDayOfMonth = new Date(currentYear, today.getMonth(), 15);
          days = diffDay(twiceDayOfMonth, lastDayOfMonth);
        }
        break;
      case 'Monthly': {
        const firstDayOfNextMonth2 = new Date(currentYear, currentMonth + 1, 1);
        const firstDayOfNextMonth = new Date(currentYear, currentMonth, 1);
        days = diffDay(firstDayOfNextMonth, firstDayOfNextMonth2);
        break;
      }
      case 'Quarterly':
        days = getDayDiffQuarterly();
        break;
      default: {
        // Annually
        const dayOfNextYear = new Date(
          currentYear + 1,
          today.getMonth(),
          currentDay + 1
        );
        days = diffDay(today, dayOfNextYear);
      }
    }
    return days;
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <SEO title={t('automaticStoreCredit.storeCredits')} />
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={async (values, { resetForm, setErrors }) => {
          const formData = values;

          formData.storeCreditConfigId = storeCreditConfig.id;
          formData.birthMonth.enabled = values.birthMonthEnabled;

          try {
            let response = '';
            if (itemSelected.recurring && itemSelected.recurring.id) {
              response = await dispatch(
                membership.actions.updateAutomaticStoreCredit(formData)
              );
            } else {
              response = await dispatch(
                membership.actions.setAutomaticStoreCredit(formData)
              );
            }
            console.log(response.payload);

            if (response.payload) {
              let message = t(
                'membership.membership.storeCreditPlanSavedSuccessfully'
              );
              if (itemSelected.recurring && itemSelected.recurring.id) {
                message = t('membership.storeCreditPlanSavedSuccessfully');
              }
              openAlert({
                message,
                severity: 'success',
              });
              resetForm();
              dispatch(membership.actions.getMembershipPlanStoreCreditList());
              closeModal(true);
            } else if (response.error.message) {
              setErrors({ planId: t('membership.planCreated') });
            }
            setLoading(false);
          } catch (error) {
            // setLoading(false);
          }

          return true;
        }}
      >
        {({ isSubmitting, values, setFieldValue, handleSubmit }) => {
          const handleOnchangeRadio = (value) => {
            setFieldValue('onPayment.enabled', value);
          };

          const handleSelectPlan = (event, val) => {
            setFieldValue('planId', val.props.value);
          };

          const handleFrequency = (val) => {
            setSelectedFrequency(
              FREQUENCY.find((f) => f.value === val.props.value)
            );
            const dayDiff = calculateDayDiff(val.props.value);
            setFieldValue('recurring.daysToExpire', dayDiff);
            setFieldValue('recurring.enabled', !!val.props.value);
          };

          return (
            <Form>
              <Box py={2}>
                <Field
                  component={Select}
                  as="select"
                  autoWidth={false}
                  fullWidth
                  required
                  label={t('automaticStoreCredit.selectPlan')}
                  name="planId"
                  variant="outlined"
                  onChange={(event, val) => handleSelectPlan(event, val)}
                  style={{ minWidth: '200px' }}
                  placeholder={t('automaticStoreCredit.selectPlan')}
                >
                  <MenuItem value={undefined}>---</MenuItem>
                  {planOptions.map(
                    (opt) =>
                      opt && (
                        <MenuItem
                          value={opt.id}
                          key={opt.partnerSubscriptionPlanId}
                        >
                          {opt.name}
                        </MenuItem>
                      )
                  )}
                </Field>
              </Box>
              <Box py={2}>
                <Card variant="outlined" style={{ display: 'inline-flex' }}>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid container item md={6} xs={12}>
                        <Field component={RadioGroup} name="isPhysical">
                          <Grid container item md={8} xs={8}>
                            {t('membership.issueStoreCreditQuestion')}
                          </Grid>

                          <Grid container item md={4} xs={4}>
                            <Grid item xs={6} md={6}>
                              <Box display="flex" alignItems="center">
                                <Box ml={1}>
                                  <FormControlLabel
                                    value="0"
                                    control={
                                      <Radio
                                        color="primary"
                                        disabled={isSubmitting}
                                      />
                                    }
                                    checked={values.onPayment.enabled === true}
                                    label="Yes"
                                    disabled={isSubmitting}
                                    onChange={() => handleOnchangeRadio(true)}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} md={6}>
                              <Box display="flex" alignItems="center" ml={2}>
                                <Box mr={1}>
                                  <FormControlLabel
                                    value="1"
                                    control={
                                      <Radio
                                        color="primary"
                                        disabled={isSubmitting}
                                      />
                                    }
                                    checked={values.onPayment.enabled === false}
                                    onChange={() => handleOnchangeRadio(false)}
                                    label="No"
                                    disabled={isSubmitting}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </Field>
                      </Grid>
                      <Grid item xs={3}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          trimLeadingZeros
                          value={values.onPayment.amount}
                          startAdornment={currencySymbol}
                          required
                          type="number"
                          removeArrows
                          label={t('membership.amountLimitDescription')}
                          name="onPayment.amount"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          value={values.onPayment.daysToExpire}
                          trimLeadingZeros
                          type="number"
                          removeArrows
                          label={t('membership.expiresInDays')}
                          name="onPayment.daysToExpire"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
              <Box py={2}>
                <Card variant="outlined">
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <Typography>
                          <Field
                            type="checkbox"
                            component={Checkbox}
                            name="birthMonthEnabled"
                            color="primary"
                          />
                          {t('automaticStoreCredit.birthMonth')}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          trimLeadingZeros
                          value={values.birthMonth.amount}
                          startAdornment={currencySymbol}
                          required
                          type="number"
                          removeArrows
                          label={t('membership.amountLimitDescription')}
                          name="birthMonth.amount"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          trimLeadingZeros
                          type="number"
                          removeArrows
                          value={values.birthMonth.daysToExpire}
                          label={t('membership.expiresInDays')}
                          name="birthMonth.daysToExpire"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
              <Box py={2}>
                <Card variant="outlined">
                  <CardContent>
                    <Grid container spacing={3} md={12}>
                      <Grid item md={4} xs={4}>
                        <CustomInputField
                          customInput={Field}
                          component={Select}
                          as="select"
                          autoWidth={false}
                          fullWidth
                          required
                          label={t('automaticStoreCredit.selectFrequency')}
                          name="recurring.frequency"
                          variant="outlined"
                          onChange={(event, val) => handleFrequency(val)}
                          style={{ minWidth: '200px' }}
                          placeholder="adad"
                        >
                          <MenuItem value={undefined}>---</MenuItem>
                          {FREQUENCY.map((opt) => (
                            <MenuItem value={opt.value} key={opt.value}>
                              {opt.label}
                            </MenuItem>
                          ))}
                        </CustomInputField>
                      </Grid>
                      <Grid item xs={4}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          trimLeadingZeros
                          startAdornment={currencySymbol}
                          required
                          type="number"
                          removeArrows
                          value={values.recurring.amount}
                          label={t('membership.amountLimitDescription')}
                          name="recurring.amount"
                          variant="outlined"
                        />
                      </Grid>

                      {/* <Grid item xs={4} md={4}> */}
                      {/*  <CustomInputField */}
                      {/*    customInput={Field} */}
                      {/*    component={TextField} */}
                      {/*    trimLeadingZeros */}
                      {/*    fullWidth */}
                      {/*    type="number" */}
                      {/*    removeArrows */}
                      {/*    value={values.recurring.daysToExpire} */}
                      {/*    label="Expires in # days" */}
                      {/*    name="recurring.daysToExpire" */}
                      {/*    variant="outlined" */}
                      {/*    disabled */}
                      {/*  /> */}
                      {/* </Grid> */}
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
              {selectedFrequency ? selectedFrequency.note : ''}
              <Box py={2}>
                <Grid container justifyContent="flex-end">
                  <Button
                    style={{ marginRight: 20 }}
                    onClick={handleSubmit}
                    loading={loading}
                    disabled={loading}
                    variant="contained"
                    color="primary"
                  >
                    {t('save')}
                  </Button>
                  <Button
                    disabled={isSending}
                    onClick={closeModal}
                    variant="contained"
                    color="primary"
                  >
                    {t('close')}
                  </Button>
                </Grid>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </div>
  );

  return (
    <Modal open={isOpen} onClose={closeModal}>
      {body}
    </Modal>
  );
};

AutomaticStoreCreditsForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  itemSelected: PropTypes.object.isRequired,
  planOptions: PropTypes.array.isRequired,
  storeCreditConfig: PropTypes.object.isRequired,
};

export default AutomaticStoreCreditsForm;
