/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import { Divider, Tabs } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TabContext, TabPanel } from '@material-ui/lab';
import SwipeableViews from 'react-swipeable-views';
import { membership } from '../../../state';
import StyledTab from '../../custom/StyledTab';
import MembershipDiscounts from './MembershipDiscounts';
import MembershipPerkConfigs from './MembershipPerkConfigs';

const MembershipDiscountsAndPerks = () => {
  const [formTab, setTab] = React.useState(0);
  const [params] = React.useState({ limit: 10, page: 1 });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(membership.actions.getMembershipDiscountsList());
  }, [params]);

  const membershipPlans = useSelector(membership.selectors.selectPlans);

  useEffect(() => {}, [membershipPlans]);

  return (
    <>
      <Tabs
        value={formTab}
        onChange={(event, newValue) => {
          setTab(newValue);
        }}
        TabIndicatorProps={{ style: { background: '#0969da' } }}
        aria-label="Select settings tab"
        style={{ padding: '0 205px' }}
      >
        <StyledTab
          disableRipple
          id="config-discounts"
          aria-controls="values"
          label={t('membership.tabs.discounts')}
        />
        <StyledTab
          disableRipple
          id="config-perks"
          aria-controls="values"
          label={t('membership.tabs.perkConfigs')}
        />
      </Tabs>
      <Divider />
      <TabContext value={formTab.toString()}>
        <SwipeableViews index={formTab} onChangeIndex={setTab}>
          <TabPanel value={formTab.toString()} index={0}>
            <MembershipDiscounts />
          </TabPanel>
          <TabPanel value={formTab.toString()} index={1}>
            <MembershipPerkConfigs />
          </TabPanel>
        </SwipeableViews>
      </TabContext>
    </>
  );
};

export default MembershipDiscountsAndPerks;
