/* eslint-disable react/prop-types */
import * as React from 'react';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Box,
  Grid,
  IconButton,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Field, Form, Formik } from 'formik';

import { useTranslation } from 'react-i18next';
import { Close } from '@material-ui/icons';
import { CustomInputField } from '../../../custom';
import api from '../../../../state/ticket/api';
import { alert, ticket } from '../../../../state';
import i18n from '../../../../i18n';

// eslint-disable-next-line react/prop-types
const AddTicketTypeModal = ({ onClose, item }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const openAlert = (payload) => dispatch(alert.actions.open(payload));
  const hexColorRegex = /^#([0-9A-Fa-f]{3}){1,2}$/;

  const validationSchema = () =>
    Yup.object().shape({
      name: Yup.string().required(t('validation.required')),
      backgroundColor: Yup.string().matches(
        hexColorRegex,
        i18n.t('brandColorsSetting.invalidHexColorFormat')
      ),
      description: Yup.string(),
    });

  const onSubmit = async (values, formikHelper) => {
    formikHelper.setSubmitting(true);
    try {
      await api.postTicketType({
        ...values,
      });
      openAlert({
        message: t('tickets.messages.ticketTypeAddedSuccess'),
        severity: 'success',
      });
      onClose();
      dispatch(ticket.actions.getTicketTypes());
    } catch (error) {
      console.log(error.message);
      openAlert({
        message:
          error?.message || error || t('tickets.messages.failedAddVenue'),
        severity: 'error',
      });
      formikHelper.setSubmitting(false);
    }
  };

  return (
    <Dialog open={!!item} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        {item?.id ? t('tickets.ticketType.edit') : t('tickets.ticketType.add')}
      </DialogTitle>
      <DialogContent>
        <Box py={2}>
          <Formik
            onSubmit={onSubmit}
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={{
              name: item?.name,
              description: item?.description,
              backgroundColor: item?.backgroundColor || '#fff',
              id: item?.id,
            }}
          >
            {({ isSubmitting }) => {
              return (
                <Form>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          capitalize
                          fullWidth
                          required
                          label={t('tickets.ticketType.ticketType')}
                          name="name"
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Box pb={2}>
                          <CustomInputField
                            customInput={Field}
                            component={TextField}
                            fullWidth
                            label={t('description')}
                            name="description"
                            variant="outlined"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box pb={2}>
                          <CustomInputField
                            customInput={Field}
                            component={TextField}
                            fullWidth
                            label={t('brandColorsSetting.backgroundColor')}
                            name="backgroundColor"
                            variant="outlined"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      flexDirection: 'column',
                    }}
                  >
                    <Button color="primary" variant="contained" type="submit">
                      {isSubmitting ? (
                        <CircularProgress size={24} />
                      ) : (
                        t('saveChanges')
                      )}
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </DialogContent>
      <IconButton
        aria-label="clear"
        onClick={onClose}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          width: 20,
          height: 20,
        }}
      >
        <Close fontSize="small" />
      </IconButton>
    </Dialog>
  );
};

export default AddTicketTypeModal;
