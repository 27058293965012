import * as React from 'react';
import { TextField } from 'formik-material-ui';
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Box,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { Close } from '@material-ui/icons';
import { useState } from 'react';
import { CustomInputField } from '../../custom';
import api from '../../../state/membership/api';
import { alert, membership } from '../../../state';

const fieldStyle = {
  display: 'flex',
  flexDirection: 'row',
};

const CancelMemberModal = React.forwardRef(({ onClose, item }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const openAlert = (payload) => dispatch(alert.actions.open(payload));

  const initialData = {
    ...item,
  };
  const cancelMembership = async () => {
    try {
      setLoading(true);
      await api.cancelMembership(item.id);
      openAlert({
        message: t('membership.messages.successfullyScheduledCancelled'),
        severity: 'success',
      });
      setLoading(false);
      dispatch(membership.actions.getMembershipList());
      onClose();
    } catch (error) {
      openAlert({
        message: error?.message || t('membership.messages.failedEditMember'),
        severity: 'error',
      });
    }
  };

  const deactivateMembership = async () => {
    try {
      setLoading(true);
      await api.deactivateMembership(item.id);
      openAlert({
        message: t('membership.messages.successfullyDeactivated'),
        severity: 'success',
      });
      setLoading(false);
      dispatch(membership.actions.getMembershipList());
      onClose();
    } catch (error) {
      openAlert({
        message: error?.message || t('membership.messages.failedEditMember'),
        severity: 'error',
      });
    }
  };

  return (
    <Dialog open={!!item} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {t('membership.cancelMembership')} #: {item?.code}
      </DialogTitle>
      <DialogContent>
        <Box py={2}>
          <Formik
            onSubmit={() => {}}
            enableReinitialize
            initialValues={initialData}
          >
            {() => {
              return (
                <Form>
                  <Box sx={fieldStyle}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Box pb={2}>
                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                            spacing={2}
                          >
                            <Grid item xs={12} md={6}>
                              <CustomInputField
                                customInput={Field}
                                component={TextField}
                                capitalize
                                fullWidth
                                required
                                disabled
                                label={t('firstName')}
                                name="firstName"
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <CustomInputField
                                customInput={Field}
                                component={TextField}
                                capitalize
                                fullWidth
                                required
                                disabled
                                label={t('lastName')}
                                name="lastName"
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>
                        </Box>
                        <Box pb={2}>
                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                            spacing={2}
                          >
                            <Grid item xs={12} md={6}>
                              <CustomInputField
                                customInput={Field}
                                component={TextField}
                                trim
                                fullWidth
                                required
                                disabled
                                label={t('phone')}
                                name="phone"
                                type="tel"
                                removeArrows
                                variant="outlined"
                                maskType="maskType"
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <CustomInputField
                                customInput={Field}
                                component={TextField}
                                trim
                                fullWidth
                                required
                                disabled
                                label={t('email')}
                                name="email"
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Grid container alignItems="space-between" spacing={2}>
                    <Grid item xs={6}>
                      <Button
                        color="primary"
                        variant="contained"
                        type="button"
                        fullWidth
                        onClick={cancelMembership}
                      >
                        {loading ? (
                          <CircularProgress size={24} />
                        ) : (
                          t('membership.scheduledCancel')
                        )}
                      </Button>
                      <Typography variant="span">
                        ({t('membership.cancellationNote')})
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        color="primary"
                        variant="contained"
                        type="button"
                        fullWidth
                        onClick={deactivateMembership}
                      >
                        {loading ? (
                          <CircularProgress size={24} />
                        ) : (
                          t('membership.immediateCancel')
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </DialogContent>
      <IconButton
        aria-label="clear"
        onClick={onClose}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          width: 20,
          height: 20,
        }}
      >
        <Close fontSize="small" />
      </IconButton>
    </Dialog>
  );
});

CancelMemberModal.propTypes = {
  onClose: PropTypes.func,
  item: PropTypes.string,
};

CancelMemberModal.defaultProps = {
  onClose: () => {},
  item: null,
};

export default CancelMemberModal;
