/* eslint-disable no-nested-ternary,react/no-unused-prop-types */
import React from 'react';
import { Divider, Box, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import SEO from '../../seo';
import TicketEventForm from './TicketEventForm';

const TicketEventCreate = () => {
  const { t } = useTranslation();
  return (
    <>
      <SEO title={t('tickets.createEvent')} />
      <Box pb={2}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Typography variant="h5">
              {t('tickets.event.createEvent')}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <TicketEventForm />
    </>
  );
};
TicketEventCreate.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isCreating: PropTypes.bool.isRequired,
};

export default TicketEventCreate;
