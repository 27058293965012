/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import { Box, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SEO from '../../seo';
import {
  membership,
  transactions,
  storeCredit,
  merchant,
} from '../../../state';
import { TransactionTable } from '../../custom';
import AutomaticStoreCreditsForm from './AutomaticStoreCreditsForm';
import { FREQUENCY_NAME } from '../../../utils/constants';

const rowKeys = [
  'name',
  'onPaymentEnabled',
  'onPaymentAmount',
  'onPaymentDaysToExpire',
  'onBirthMonthEnabled',
  'onBirthMonthAmount',
  'onBirthMonthDaysToExpire',
  'recurringFrequency',
  'recurringAmount',
  // 'recurringDaysToExpire',
  'id',
];

const TRIGGER_TYPE = {
  OnPayment: 0,
  BirthMonth: 1,
  Recurring: 2,
};

const AutomaticStoreCreditsList = () => {
  const [params] = React.useState({ limit: 10, page: 1 });
  const [itemSelected, setItemSelected] = React.useState({});
  const [storeCreditsList, setStoreCreditsList] = React.useState([]);
  const [planList, setPlanList] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const dispatch = useDispatch();
  React.useEffect(() => {
    // dispatch(membership.actions.getMembershipList());
    dispatch(membership.actions.getMembershipPlanStoreCreditList());
    dispatch(storeCredit.actions.getStoreCreditConfig());
    // dispatch(membership.actions.getMembershipPlans());
  }, [params]);
  const { t } = useTranslation();
  const colsTitles = [
    t('membership.plan'),
    t('membership.onMembershipFeePayment'),
    t('giftCards.amount'),
    t('membership.expiresInDays'),
    t('birthMonth'),
    t('giftCards.amount'),
    t('membership.expiresInDays'),
    t('membership.recurring'),
    t('giftCards.amount'),
    // 'Expires in Days',
    '',
  ];
  const isLoading = useSelector(membership.selectors.selectIsLoading);
  const isSending = useSelector(transactions.selectors.selectIsSending);
  const membershipPlans = useSelector(membership.selectors.selectPlans);
  const membershipPlanStoreCredits = useSelector(
    membership.selectors.selectStoreCreditsList
  );
  const storeCreditConfig = useSelector(
    storeCredit.selectors.selectStoreCreditConfig
  );
  const { currency } = useSelector(merchant.selectors.selectMerchantDetails);

  useEffect(() => {
    console.log(membershipPlanStoreCredits, 222);
    if (membershipPlanStoreCredits.length) {
      const items = membershipPlanStoreCredits.map((s) => {
        const item = {};
        item.name = s.name;
        item.planId = s.id;
        s.membershipPlanStoreCredits?.forEach((m) => {
          if (m.triggerType === TRIGGER_TYPE.OnPayment) {
            item.onPayment = m;
            item.storeCreditConfigId = storeCreditConfig;
          }
          if (m.triggerType === TRIGGER_TYPE.Recurring) {
            item.recurring = { ...m };
            item.recurring.frequency = FREQUENCY_NAME[m.frequency];
          }

          if (m.triggerType === TRIGGER_TYPE.BirthMonth) {
            item.birthMonth = { ...m };
            item.birthMonthEnabled = item.birthMonth.enabled;
          }
        });
        return item;
      });
      setStoreCreditsList(items);
    }
  }, [membershipPlanStoreCredits]);

  useEffect(() => {
    if (membershipPlanStoreCredits.length && membershipPlans.length) {
      const items = membershipPlans.map((p) =>
        membershipPlanStoreCredits.find((s) => s.id === p.id)
      );
      setPlanList(items);
    }
  }, [membershipPlans, membershipPlanStoreCredits]);

  const handleOpenModal = (edit, item = {}) => {
    setIsEdit(edit);
    setItemSelected(item);
    setIsOpen(true);
  };
  const mods = {
    onPaymentEnabled: (id, row) => (row.onPayment.enabled ? 'Yes' : 'No'),
    onPaymentAmount: (id, row) =>
      t('cardValue', {
        amount: row.onPayment.amount,
        formatParams: {
          amount: { currency },
        },
      }),
    onPaymentDaysToExpire: (id, row) => `${row.onPayment.daysToExpire}`,
    recurringFrequency: (id, row) => row.recurring.frequency,
    recurringAmount: (id, row) =>
      t('cardValue', {
        amount: row.recurring.amount,
        formatParams: {
          amount: { currency },
        },
      }),
    recurringDaysToExpire: (id, row) => `${row.recurring.daysToExpire}`,
    onBirthMonthEnabled: (id, row) => (row.birthMonth.enabled ? 'Yes' : 'No'),
    onBirthMonthAmount: (id, row) =>
      t('cardValue', {
        amount: row.birthMonth.amount,
        formatParams: {
          amount: { currency },
        },
      }),
    onBirthMonthDaysToExpire: (id, row) => `${row.birthMonth.daysToExpire}`,
    id: (id, row) => (
      <Button onClick={() => handleOpenModal(true, row)}>Modify</Button>
    ),
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <SEO title={t('automaticStoreCredit.storeCredits')} />
      <AutomaticStoreCreditsForm
        planOptions={isEdit ? planList : membershipPlans}
        isOpen={isOpen}
        storeCreditConfig={storeCreditConfig}
        closeModal={handleCloseModal}
        itemSelected={itemSelected}
      />
      <Button
        disabled={isSending}
        onClick={() => handleOpenModal(false)}
        variant="contained"
        color="primary"
      >
        {t('automaticStoreCredit.create')}
      </Button>
      <Box py={2}>
        <TransactionTable
          cols={colsTitles}
          rowKeys={rowKeys}
          data={storeCreditsList}
          mods={mods}
          isLoading={isLoading}
          noDataMsg={t('noDataMsg')}
        />
      </Box>
    </>
  );
};

export default AutomaticStoreCreditsList;
