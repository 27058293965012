import React from 'react';
import { Box, Divider, Grid, Tabs, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TabContext, TabPanel } from '@material-ui/lab';
import SwipeableViews from 'react-swipeable-views';
import StyledTab from '../../custom/StyledTab';
import VenuesList from './VenuesList';
import TicketTypesList from './TicketTypesList';
import TicketMisc from './TicketMisc';

const TicketingSetup = () => {
  const { t } = useTranslation();
  const [formTab, setTab] = React.useState(0);

  return (
    <>
      <Box pb={2}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Typography variant="h5">{t('tickets.setup')}</Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box pb={2}>
        <Tabs
          value={formTab}
          onChange={(event, newValue) => {
            setTab(newValue);
          }}
          TabIndicatorProps={{ style: { background: '#0969da' } }}
          aria-label="Select settings tab"
        >
          <StyledTab
            disableRipple
            id="venues"
            aria-controls="values"
            label={t('tickets.venues')}
          />
          <StyledTab
            disableRipple
            id="ticketTypes"
            aria-controls="values"
            label={t('tickets.ticketTypes')}
          />
          <StyledTab
            disableRipple
            id="misc"
            aria-controls="values"
            label={t('tickets.misc')}
          />
        </Tabs>
        <Divider />
        <TabContext value={formTab.toString()}>
          <SwipeableViews index={formTab} onChangeIndex={setTab}>
            <TabPanel value={formTab.toString()} index={0}>
              <VenuesList />
            </TabPanel>
            <TabPanel value={formTab.toString()} index={1}>
              <TicketTypesList />
            </TabPanel>
            <TabPanel value={formTab.toString()} index={1}>
              <TicketMisc />
            </TabPanel>
          </SwipeableViews>
        </TabContext>
      </Box>
    </>
  );
};

export default TicketingSetup;
