/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Button,
  Tooltip,
  IconButton,
  TextField,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { TextField as FormikTextField } from 'formik-material-ui';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useDebounce } from 'use-debounce';
import { Field } from 'formik';
import { alert, membership, transactions } from '../../../state';
import { TransactionTable, DownloadCSV } from '../../custom';
import BlinkText from '../../custom/BlinkText';
import CouponListModal from './CouponListModal';
import DownloadIcon from '../../../images/Memberships/download.png';
import EditMemberModal from './EditMemberModal';
import PauseMemberModal from './PauseMemberModal';
import ResumeMemberModal from './ResumeMemberModal';
import CancelMemberModal from './CancelMemberModal';
import ViewPaymentActivityModal from './ViewPaymentActivityModal';
import MembershipEditMenu from './MembershipEditMenu';
import EditMemberExpirationDateModal from './EditMemberExpirationDateModal';
import ReactiveExpiredMembershipModal from './ReactiveExpiredMembershipModal';
import EditMemberPlanModal from './EditMemberPlanModal';
import CPRIcon from '../../../images/Memberships/cpr.png';
import Eye from '../../../images/Memberships/eye.png';
import PlayIcon from '../../../images/Memberships/play-button.png';
import MembershipResendMenu from './MembershipResendMenu';
import AddMemberModal from './AddMemberModal';
import addMember from '../../../images/Memberships/add-user.png';
import searchIcon from '../../../images/Memberships/search.png';
import MembershipBulk from './MembershipBulk';
import BulkModal from './Components/BulkModal';
import AddSecondaryMemberModal from './AddSecondaryMemberModal';

const membershipStatuses = [
  'Active',
  'Canceled',
  'Pending',
  'Deactivated',
  'Expired',
  'Paused',
  '',
];

const autoRenew = [
  {
    label: 'Yes',
    value: 'true',
  },
  {
    label: 'No',
    value: 'false',
  },
  {
    label: 'Clear',
    value: '',
  },
];

const useStyles = makeStyles(() => ({
  fullwidthChild: {
    '& > div': {
      width: '100%',
      minWidth: '300px',
    },
  },
}));
const MembershipList = () => {
  const [params, setParams] = React.useState({
    limit: 10,
    page: 1,
    isAutoRenewal: '',
  });
  const [value] = useDebounce(params, 700);
  const [keyword, setKeyword] = React.useState('');
  const [isShowAddMember, setIsShowAddMember] = useState(false);
  const [bulkType, setBulkType] = useState('');
  const dispatch = useDispatch();
  const classes = useStyles();

  React.useEffect(() => {
    dispatch(membership.actions.getMembershipList(params));
  }, []);

  React.useEffect(() => {
    if (value?.keyword === '' || value.keyword?.length > 2) {
      const paramSearch = { ...params };
      dispatch(membership.actions.getMembershipList(paramSearch));
    }
  }, [value]);

  const { t } = useTranslation();

  const colsTitles = [
    t('membership.membershipList.code'),
    t('membership.membershipList.firstName'),
    t('membership.membershipList.lastName'),
    t('membership.membershipList.plan'),
    t('membership.membershipList.email'),
    t('membership.membershipList.phone'),
    t('membership.membershipList.expirationDate'),
    t('membership.membershipConfig.autoRenewal'),
    t('membership.membershipList.status'),
    '',
  ];

  const rowKeys = [
    'code',
    'firstName',
    'lastName',
    'plan.name',
    'email',
    'phone',
    'expirationDate',
    'autoRenewal',
    'status',
    'id',
  ];

  const [activeRowEmail, setActiveRowEmail] = useState(true);
  const [showCoupons, setShowCoupons] = useState(null);
  const [secondaryMembers, setSecondaryMembers] = useState(null);
  const [item, setItem] = useState(null);
  const [cancelItem, setCancelItem] = useState(null);
  const [editExpirationItem, setEditExpirationItem] = useState(null);
  const [pauseMembershipItem, setPauseMembershipItem] = useState(null);
  const [resumeMembershipItem, setResumeMembershipItem] = useState(null);
  const [viewPaymentActivity, setViewPaymentActivity] = useState(null);
  const [editPlanItem, setEditPlanItem] = useState(null);
  const [editExpirationMembership, setEditExpirationMembership] = useState(
    null
  );

  const isLoading = useSelector(membership.selectors.selectIsLoading);
  const isSending = useSelector(transactions.selectors.selectIsSending);
  const { data: membershipList, total } = useSelector(
    membership.selectors.selectMembershipList
  );
  const csv = useSelector(membership.selectors.selectCsv);
  const refLink = React.useRef();

  const handleDownload = () => {
    dispatch(membership.actions.downloadMembershipCSV())
      .then(() => {
        refLink.current.link.click();
      })
      .then(() =>
        dispatch(
          alert.actions.open({
            message: t('csvDownloadSuccess'),
            severity: 'success',
          })
        )
      );
  };

  const search = async () => {
    await setParams({
      ...params,
      keyword,
      isAutoRenewal: '',
    });
  };

  const openAddNewMember = () => {
    setIsShowAddMember(true);
  };

  React.useEffect(() => {
    search();
  }, [keyword]);

  const mods = {
    status: (val) => membershipStatuses[val] || 'Deactivated',
    expirationDate: (val) => val && moment(`${val}Z`).format('MMM Do YYYY'),
    autoRenewal: (val) => (
      <Checkbox color="primary" name="isPhysical" checked={val === true} />
    ),
    id: (id, row) => (
      <>
        <Grid
          container
          alignItems="flex-start"
          direction="row"
          style={{ minWidth: 140 }}
        >
          {row.status === 0 && (
            <>
              <Grid item>
                <Tooltip
                  title={t('membership.membershipList.resendEmail')}
                  placement="top"
                  arrow
                >
                  <div>
                    <MembershipResendMenu
                      membership={row}
                      setActiveRowEmail={setActiveRowEmail}
                    />
                  </div>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title={t('edit')} placement="top" arrow>
                  <div>
                    <MembershipEditMenu
                      membership={row}
                      onEditProfile={() => setItem(row)}
                      onEditExpirationDate={() => setEditExpirationItem(row)}
                      onPauseMembership={() => setPauseMembershipItem(row)}
                      onViewPaymentActivity={() => setViewPaymentActivity(row)}
                      onEditPlan={() => setEditPlanItem(row)}
                      cancelModal={() => setCancelItem(row)}
                      openCoupons={() => setShowCoupons(row)}
                      openSecondaryMembers={() => setSecondaryMembers(row)}
                    />
                  </div>
                </Tooltip>
              </Grid>
            </>
          )}
          {row.status === 4 && (
            <Grid item>
              <Tooltip title={t('membership.reactive')} placement="top" arrow>
                <IconButton onClick={() => setEditExpirationMembership(row)}>
                  <img
                    src={CPRIcon}
                    alt={t('membership.reactivate')}
                    style={{
                      width: 20,
                      height: 21,
                      paddingTop: 2,
                      cursor: 'pointer',
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
          {row.status === 5 && (
            <Grid item>
              <Tooltip title={t('membership.restart')} placement="top" arrow>
                <IconButton onClick={() => setResumeMembershipItem(row)}>
                  <img
                    src={PlayIcon}
                    alt={t('membership.restart')}
                    style={{
                      width: 20,
                      height: 21,
                      paddingTop: 2,
                      cursor: 'pointer',
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
          {row.status !== 0 && (
            <Grid item>
              <Tooltip title={t('view')} placement="top" arrow>
                <IconButton onClick={() => setViewPaymentActivity(row)}>
                  <img
                    src={Eye}
                    style={{
                      width: 20,
                      height: 21,
                      paddingTop: 2,
                      cursor: 'pointer',
                    }}
                    alt={t('view')}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </>
    ),
  };
  const handleChangePage = (event, page) => {
    setParams({ ...params, page: page + 1 });
  };
  const handleChangeRowsPerPage = (event) => {
    setParams({ ...params, limit: event.target.value, page: 1 });
  };

  const pagination = {
    total,
    pageSize: params.limit,
    page: params.page,
    handleChangePage,
    handleChangeRowsPerPage,
  };

  const searchBox = () => (
    <Grid container alignItems="flex-end">
      <Box
        style={{
          border: '1px solid #E0E0E0',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingLeft: 14,
          paddingTop: 6,
          paddingBottom: 4,
        }}
      >
        <Grid item className={classes.fullwidthChild}>
          <TextField
            fullWidth
            label={t('search')}
            name="keyword"
            variant="outlined"
            value={keyword}
            onChange={(val) => {
              setKeyword(val.target.value);
            }}
            style={{ height: '47px', minWidth: 230 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => search()}
                    style={{ marginRight: -14 }}
                  >
                    <img
                      src={searchIcon}
                      alt={t('search')}
                      style={{
                        width: 25,
                        height: 25,
                        paddingTop: 2,
                        cursor: 'pointer',
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid
          item
          style={{
            height: 60,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 50,
            paddingRight: 10,
          }}
        >
          <Typography variant="button" display="block">
            OR
          </Typography>
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Field
                onChange={(event) => {
                  setParams({
                    ...params,
                    keyword: '',
                    isAutoRenewal: event.target.value,
                  });
                  setKeyword('');
                }}
                style={{ height: '47px', minWidth: '130px' }}
                component={FormikTextField}
                fullWidth
                select
                name="isAutoRenewal"
                variant="outlined"
                label={t('membership.membershipConfig.autoRenewal')}
                value={params.isAutoRenewal}
              >
                <MenuItem value={undefined}>---</MenuItem>
                {autoRenew.map((renew) => (
                  <MenuItem value={renew.value} key={renew.value}>
                    {renew.label}
                  </MenuItem>
                ))}
              </Field>
            }
          />
        </Grid>
      </Box>
      <Box
        style={{
          height: 32,
        }}
      >
        <Grid
          item
          style={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <DownloadCSV
            handleDownload={handleDownload}
            refLink={refLink}
            data={csv}
            filename="membership-list"
          >
            <Tooltip
              title={t('membership.membershipList.downloadCSV')}
              placement="top"
              arrow
            >
              <Button onClick={handleDownload}>
                <img
                  src={DownloadIcon}
                  alt={t('membership.membershipList.downloadCSV')}
                  style={{ width: 25, cursor: 'pointer' }}
                />
              </Button>
            </Tooltip>
          </DownloadCSV>
          <MembershipBulk setBulkType={setBulkType} />
          <Tooltip
            title={t('membership.membershipList.addNewMemberShip')}
            placement="top"
            arrow
          >
            <Button onClick={() => openAddNewMember()}>
              <img
                src={addMember}
                alt={t('membership.membershipList.addNewMemberShip')}
                style={{
                  width: 25,
                  height: 25,
                  paddingTop: 2,
                  cursor: 'pointer',
                }}
              />
            </Button>
          </Tooltip>
        </Grid>
      </Box>
    </Grid>
  );

  useEffect(() => {
    dispatch(membership.actions.getMembershipPlans());
  }, []);

  return (
    <>
      <Box>
        {isSending ? (
          <BlinkText text={t('resendingEmail', { email: activeRowEmail })} />
        ) : (
          <TransactionTable
            cols={colsTitles}
            rowKeys={rowKeys}
            data={membershipList}
            searchBox={searchBox}
            pagination={pagination}
            mods={mods}
            isLoading={isLoading}
            noDataMsg={t('noDataMsg')}
          />
        )}
      </Box>
      <CouponListModal
        item={showCoupons}
        onClose={() => setShowCoupons(null)}
      />
      <EditMemberModal item={item} onClose={() => setItem(null)} />
      <PauseMemberModal
        item={pauseMembershipItem}
        onClose={() => setPauseMembershipItem(null)}
      />
      <ResumeMemberModal
        item={resumeMembershipItem}
        onClose={() => setResumeMembershipItem(null)}
      />
      <EditMemberExpirationDateModal
        item={editExpirationItem}
        onClose={() => {
          setEditExpirationItem(null);
          dispatch(membership.actions.getMembershipList(params));
        }}
      />
      <EditMemberPlanModal
        item={editPlanItem}
        onClose={() => setEditPlanItem(null)}
      />
      <CancelMemberModal
        item={cancelItem}
        onClose={() => {
          setCancelItem(null);
          dispatch(membership.actions.getMembershipList(params));
        }}
      />
      <ReactiveExpiredMembershipModal
        item={editExpirationMembership}
        onClose={() => {
          setEditExpirationMembership(null);
          dispatch(membership.actions.getMembershipList(params));
        }}
      />
      <ViewPaymentActivityModal
        item={viewPaymentActivity}
        onClose={() => setViewPaymentActivity(null)}
      />
      <AddSecondaryMemberModal
        item={secondaryMembers}
        onClose={() => {
          setSecondaryMembers(null);
          dispatch(membership.actions.getMembershipList(params));
        }}
      />
      <AddMemberModal
        isOpen={isShowAddMember}
        closeModal={() => {
          setIsShowAddMember(false);
          params.isAutoRenewal =
            params.isAutoRenewal === 'noFilter' ? '' : params.isAutoRenewal;
          dispatch(membership.actions.getMembershipList(params));
        }}
      />
      {bulkType && (
        <BulkModal
          isOpen={bulkType}
          params={params}
          closeModal={() => {
            setBulkType('');
            params.isAutoRenewal =
              params.isAutoRenewal === 'noFilter' ? '' : params.isAutoRenewal;
            dispatch(membership.actions.getMembershipList(params));
          }}
        />
      )}
    </>
  );
};

export default MembershipList;
