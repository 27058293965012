/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import { Box, Grid, Button, IconButton } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { ticket } from '../../../state';
import { TransactionTable } from '../../custom';
import Editing from '../../../images/Memberships/editing.png';
import AddTicketTypeModal from './Modals/AddTicketTypeModal';

const TicketTypesList = () => {
  const [params, setParams] = React.useState({ limit: 10, page: 1 });
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const colsTitles = [
    t('tickets.ticketType.ticketType'),
    t('description'),
    t('brandColorsSetting.backgroundColor'),
    '',
  ];

  const rowKeys = ['name', 'description', 'backgroundColor', 'id'];
  // const openAlert = (payload) => dispatch(alert.actions.open(payload));
  const [modalItem, setModalItem] = React.useState(false);

  const mods = {
    id: (val, item) => (
      <Tooltip title={t('edit')} placement="top" arrow>
        <IconButton
          aria-haspopup="true"
          onClick={() => setModalItem(item)}
          cursor="pointer"
        >
          <img src={Editing} alt="" style={{ width: 20 }} />
        </IconButton>
      </Tooltip>
    ),
  };

  React.useEffect(() => {
    dispatch(ticket.actions.getTicketTypes(params));
  }, [params]);

  const isLoading = useSelector(ticket.selectors.selectIsLoading);
  const { data: ticketList, total } = useSelector(
    ticket.selectors.selectTicketTypes
  );

  const handleChangePage = (event, page) => {
    setParams({ ...params, page: page + 1 });
  };
  const handleChangeRowsPerPage = (event) => {
    setParams({ ...params, limit: event.target.value, page: 1 });
  };

  const pagination = {
    total,
    pageSize: params.limit,
    page: params.page,
    handleChangePage,
    handleChangeRowsPerPage,
  };

  const searchBox = () => (
    <Grid item>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setModalItem({})}
      >
        {t('tickets.ticketType.add')}
      </Button>
    </Grid>
  );

  const sortedTicketList = useMemo(() => {
    if (ticketList.length > 0) {
      return [...ticketList].sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    }
    return [];
  }, [ticketList]);

  return (
    <>
      <Box py={2}>
        <Grid container xs={8}>
          <TransactionTable
            cols={colsTitles}
            rowKeys={rowKeys}
            data={sortedTicketList}
            mods={mods}
            isLoading={isLoading}
            pagination={pagination}
            searchBox={searchBox}
            noDataMsg={t('noDataMsg')}
          />
        </Grid>
      </Box>

      <AddTicketTypeModal
        item={modalItem}
        onClose={() => setModalItem(false)}
      />
    </>
  );
};
export default TicketTypesList;
