/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { merchant } from '../../../state';
import { CustomImgCard } from '../../custom';

const LoyaltyReview = ({ loyalty }) => {
  const merchantDetails = useSelector(merchant.selectors.selectMerchantDetails);
  const { currency } = merchantDetails;
  const { t } = useTranslation();

  const getStatus = () => {
    if (loyalty.status === 0) return 'New';
    if (loyalty.status === 2) return 'Deactivated';
    if (
      loyalty.expirationDate &&
      moment().isAfter(moment(`${loyalty.expirationDate}Z`))
    ) {
      return 'Expired';
    }
    return 'Active';
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Typography>{t('image')} </Typography>
        </Grid>
        <Grid item xs={12} md={5}>
          <CustomImgCard
            name={t('image')}
            width="100%"
            imageUrl={
              loyalty.imageUrl ||
              merchantDetails.logoUrl ||
              '/images/defaultGiftCard.jpg'
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Typography>{t('loyalty.programName')} </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          {loyalty.programName}
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Typography>{t('loyalty.codeType')} </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          {loyalty.codeType === 0
            ? t('QRCode')
            : loyalty.codeType === 1
            ? t('BarCode')
            : ''}
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Typography>{t('loyalty.description')} </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          {loyalty.description}
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Typography>{t('loyalty.termsAndConditions')} </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          {loyalty.terms}
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Typography>{t('loyalty.programBasedOn')} </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          {Number(loyalty.type) === 0
            ? t('loyalty.itemPurchasedPerOrder')
            : t('loyalty.amountSpentPerOrder')}
        </Grid>
      </Grid>
      {Number(loyalty.type) === 0 && loyalty.partnerItemId && (
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Typography>{t('loyalty.selectedItem')} </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            {loyalty.partnerItemName}
          </Grid>
        </Grid>
      )}
      {Number(loyalty.type) === 1 ? (
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Typography>{t('loyalty.amount')} </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            {t('cardValue', {
              amount: loyalty.minimumAmount,
              formatParams: {
                amount: { currency },
              },
            })}
          </Grid>
        </Grid>
      ) : null}
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Typography>{t('loyalty.pointsEarned')} </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          {loyalty.point}
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Typography>{t('loyalty.rewardType')} </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {Number(loyalty.rewardType) === 0
            ? t('loyalty.freeItem')
            : Number(loyalty.rewardType) === 1
            ? t('loyalty.discountAmount')
            : t('loyalty.storeCredit')}
        </Grid>
      </Grid>
      {Number(loyalty.rewardType) === 0 && loyalty.rewardItemId ? (
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Typography>{t('loyalty.rewardItem')} </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            {loyalty.rewardItemName}
          </Grid>
        </Grid>
      ) : null}
      {Number(loyalty.rewardType) === 1 ? (
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Typography>{t('loyalty.discountAmount')} </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            {t('cardValue', {
              amount: loyalty.discountAmount,
              formatParams: {
                amount: { currency },
              },
            })}
          </Grid>
        </Grid>
      ) : null}
      {Number(loyalty.rewardType) === 2 ? (
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Typography>{t('loyalty.storeCreditAmount')} </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            {t('cardValue', {
              amount: loyalty.storeCreditAmount,
              formatParams: {
                amount: { currency },
              },
            })}
            {loyalty.storeCreditExpirationValue &&
            loyalty.storeCreditExpirationType ? (
              <>
                <Typography
                  variant="body1"
                  component="span"
                  style={{
                    display: 'inline-block',
                    marginLeft: 20,
                  }}
                >
                  ({t('loyalty.storeCreditExpiration')}{' '}
                  {loyalty.storeCreditExpirationValue}{' '}
                  {loyalty.storeCreditExpirationType === 1
                    ? t('loyalty.days')
                    : loyalty.storeCreditExpirationType === 2
                    ? t('loyalty.months')
                    : loyalty.storeCreditExpirationType === 3
                    ? t('loyalty.year')
                    : ''}
                  )
                </Typography>
              </>
            ) : null}
          </Grid>
        </Grid>
      ) : null}
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Typography>{t('loyalty.requiredAmount')} </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          {loyalty.requiredAmount}
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Typography>{t('loyalty.status')} </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          {getStatus(loyalty)}
        </Grid>
      </Grid>
      {loyalty.signUpBonusPoint > 0 ? (
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Typography>{t('loyalty.signOnBonus')} </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            {loyalty.signUpBonusPoint} points
          </Grid>
        </Grid>
      ) : null}
      {loyalty.promotions?.description ? (
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Typography>{t('loyalty.promotions')} </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            {loyalty.promotions?.description}
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};
LoyaltyReview.propTypes = {
  loyalty: PropTypes.shape({
    programName: PropTypes.string,
    expirationDate: PropTypes.string,
    codeType: PropTypes.number,
    description: PropTypes.string,
    rewardType: PropTypes.number,
    type: PropTypes.number,
    minimumAmount: PropTypes.number,
    terms: PropTypes.string,
    rewardItemId: PropTypes.string,
    discountAmount: PropTypes.string,
    requiredAmount: PropTypes.string,
    storeCreditAmount: PropTypes.string,
    partnerItemId: PropTypes.string,
    partnerItemName: PropTypes.string,
    rewardItemName: PropTypes.string,
    point: PropTypes.number,
    status: PropTypes.number,
    imageUrl: PropTypes.string,
    storeCreditExpirationType: PropTypes.number,
    storeCreditExpirationValue: PropTypes.number,
    signUpBonusPoint: PropTypes.number,
    promotions: PropTypes.shape({
      description: PropTypes.string,
    }),
  }).isRequired,
};
export default LoyaltyReview;
