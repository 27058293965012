/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Divider, Box, Tabs } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TabContext, TabPanel } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Formik } from 'formik';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import { alert, merchant, ticket } from '../../../state';
import SEO from '../../seo';
import StyledTab from '../../custom/StyledTab';
import { TicketEventValidation } from './validation';
import EventInformationForm from './EventInformationForm';
import { PartnerEnum } from '../../../utils/constants';
import TicketPricingList from './TicketPricingList';
import TicketMembershipDiscounts from './TicketMembershipDiscounts';
import TicketPublish from './TicketPublish';
import TicketEventUploadImage from './TicketEventUploadImage';
import TicketAdditionalCosts from './TicketAdditionalCosts';

const TicketEventForm = ({ closeModal, isCreating = true }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { partner = '' } = useSelector(
    merchant.selectors.selectMerchantDetails
  );
  const ticketEvent = useSelector(ticket.selectors.selectTicketEvent);
  const [formTab, setTab] = useState(0);
  const {
    name,
    description,
    ticketTypes,
    terms,
    imageUrl,
    maxNumber,
    fees,
    feesHelperText,
    codeType,
    qrPrefix,
    useSingleEmail = 0,
    title,
    tagLine,
    startTime,
    endTime,
    ticketVenueId,
    tax,
    ticketFees,
    useDiscountAllMembership,
    discountType,
    discountAll,
  } = ticketEvent;

  const openAlert = (payload) => dispatch(alert.actions.open(payload));

  const createInitialValue = () => {
    const value = {
      ...ticketEvent,
      name: name || '',
      description: description || '',
      ticketTypes: ticketTypes || [],
      maxNumber,
      terms,
      imageUrl,
      hasFees: ticketFees > 0 ? '1' : '0',
      codeType: codeType ? codeType.toString() : '0',
      ticketFees: 0,
      feesHelperText: feesHelperText || '',
      isSquareCompatibleScanner: qrPrefix ? '1' : '0',
      useSingleEmail:
        useSingleEmail === true || useSingleEmail === '1' ? '1' : '0',
      title: title || '',
      tagLine: tagLine || '',
      startTime: startTime || '',
      endTime: endTime || '',
      ticketVenueId: ticketVenueId || '',
      formTab,
      useDiscountAllMembership:
        useDiscountAllMembership === true ? 'true' : 'false',
      discountType: discountType || 0,
      discountAll: discountAll || 0,
      serviceFee: 'false',
      taxService: 'false',
      fees: fees || 0,
      tax: tax || 0,
    };
    return value;
  };

  useEffect(() => {
    dispatch(ticket.actions.getTicketEvents());
  }, []);

  const handleSubmit = async (values) => {
    try {
      const ticketType = values.ticketTypes
        .filter((type) => type.name || type.amount)
        .map((type) => ({
          ...type,
          name: type.name || '',
          amount: Number(type.amount || 0),
        }));
      const isSquareCompatibleScanner =
        partner !== PartnerEnum.Square
          ? 0
          : values.isSquareCompatibleScanner === true ||
            values.isSquareCompatibleScanner === '1';
      const useEmail =
        values.useSingleEmail === true || values.useSingleEmail === '1';

      const modifyData = {
        ...values,
        ticketType,
        isSquareCompatibleScanner,
        useSingleEmail: useEmail,
      };
      const res = await dispatch(ticket.actions.setTicketEvent(modifyData));
      if (res.payload) {
        openAlert({
          message: t('tickets.event.updateEventSuccess'),
          severity: 'success',
        });
        if (formTab < 5) {
          setTab(formTab + 1);
        }
        if (formTab === 5) {
          dispatch(ticket.actions.setCurrentTicketEvent({}));
          if (!isCreating) {
            closeModal();
          } else {
            navigate('/dashboard/ticketing/events');
          }
        }
      }
      if (res.error) {
        openAlert({
          message: t('updateGiftCardFailed'),
          severity: 'error',
        });
      }
    } catch (error) {
      openAlert({
        message:
          error?.message || error || t('membership.messages.failedEditMember'), // !TODO: change message
        severity: 'error',
      });
    }
  };

  return (
    <>
      <SEO title={t('tickets.tickets')} />
      <Formik
        key={Math.random}
        enableReinitialize
        validateOnChange={false}
        validateOnBlur
        validationSchema={TicketEventValidation}
        initialValues={createInitialValue()}
        onSubmit={async (values) => {
          handleSubmit(values);
        }}
      >
        {() => {
          return (
            <Box pb={4}>
              <Tabs
                value={formTab}
                onChange={(event, newValue) => {
                  setTab(newValue);
                }}
                TabIndicatorProps={{ style: { background: '#0969da' } }}
                aria-label="Select settings tab"
              >
                <StyledTab
                  disableRipple
                  id="eventInformation"
                  aria-controls="values"
                  label={t('tickets.event.eventInformation')}
                />
                <StyledTab
                  disableRipple
                  id="ticketUploadImage"
                  aria-controls="team_description"
                  label={t('tickets.event.uploadImage')}
                />
                <StyledTab
                  disableRipple
                  id="ticketPricing"
                  aria-controls="team_description"
                  label={t('tickets.event.ticketPricing')}
                />
                <StyledTab
                  disableRipple
                  id="ticketAdditionalCost"
                  aria-controls="team_ticketAdditionalCost"
                  label={t('tickets.event.ticketAdditionalCost')}
                />
                <StyledTab
                  disableRipple
                  id="membershipDiscount"
                  aria-controls="membership_discount"
                  label={t('tickets.event.membershipDiscounts')}
                />
                <StyledTab
                  disableRipple
                  id="reviewPublish"
                  aria-controls="reviewPublish"
                  label={t('tickets.event.reviewPublish')}
                />
              </Tabs>
              <Divider />
              <TabContext value={formTab.toString()}>
                <SwipeableViews index={formTab} onChangeIndex={setTab}>
                  <TabPanel value={formTab.toString()} index={0}>
                    <EventInformationForm />
                  </TabPanel>
                  <TabPanel value={formTab.toString()} index={1}>
                    {formTab === 1 && <TicketEventUploadImage />}
                  </TabPanel>
                  <TabPanel value={formTab.toString()} index={2}>
                    {formTab === 2 && <TicketPricingList />}
                  </TabPanel>
                  <TabPanel value={formTab.toString()} index={2}>
                    {formTab === 3 && <TicketAdditionalCosts />}
                  </TabPanel>
                  <TabPanel value={formTab.toString()} index={3}>
                    {formTab === 4 && <TicketMembershipDiscounts />}
                  </TabPanel>
                  <TabPanel value={formTab.toString()} index={3}>
                    {formTab === 5 && <TicketPublish />}
                  </TabPanel>
                </SwipeableViews>
              </TabContext>
            </Box>
          );
        }}
      </Formik>
    </>
  );
};
TicketEventForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isCreating: PropTypes.bool.isRequired,
};

export default TicketEventForm;
