/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import {
  Box,
  Grid,
  Button,
  IconButton,
  makeStyles,
  TextField,
  Radio,
  Typography,
  Divider,
  CircularProgress,
  InputAdornment,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import moment from 'moment/moment';
import { useDebounce } from 'use-debounce';
import { alert, ticket } from '../../../state';
import { DownloadCSV, TransactionTable } from '../../custom';
import Editing from '../../../images/Memberships/editing.png';
import DownloadIcon from '../../../images/Memberships/download.png';
import Resend from '../../../images/Memberships/resend.png';
import EditTicketModal from './Modals/EditTicketModal';
import searchIcon from '../../../images/Memberships/search.png';
import FreeTicket from '../../../images/Memberships/free-ticket.png';

const useStyles = makeStyles(() => ({
  fullwidthChild: {
    '& > div': {
      width: '100%',
      minWidth: '300px',
    },
  },
}));

const EventStatus = {
  Published: 1,
  Done: 2,
};

const TicketStatus = {
  Reserved: 0,
  Active: 1,
  CheckedIn: 2,
  Expired: 3,
};

const TicketList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const [keyword, setKeyword] = React.useState('');
  const [eventStatusValue, setEventStatusValue] = React.useState(
    EventStatus.Published
  );
  const [params, setParams] = React.useState({
    limit: 10,
    page: 1,
    status: eventStatusValue,
  });
  const [debounce] = useDebounce(params, 700);

  React.useEffect(() => {
    dispatch(ticket.actions.getTickets(params));
  }, []);

  const [editItem, setEditItem] = useState(null);

  const [activeRowEmail, setActiveRowEmail] = useState(null);
  const resendEmail = async (id) => {
    setActiveRowEmail(id);
    const res = await dispatch(ticket.actions.resendEmail(id));
    if (!res.error) {
      dispatch(
        alert.actions.open({
          message: t('dashboards.emailSent'),
          severity: 'success',
        })
      );
    }
    setActiveRowEmail(null);
  };

  const colsTitles = [
    t('tickets.eventDateTime'),
    t('tickets.eventName'),
    `${t('tickets.order')} #`,
    `${t('tickets.ticket.ticket')} #`,
    t('tickets.ticketType.ticketType'),
    t('firstName'),
    t('lastName'),
    t('email'),
    t('status'),
    t('tickets.event.checkInDateTime'),
    '',
  ];

  const rowKeys = [
    'startTime',
    'eventName',
    'orderNumber',
    'ticketNumber',
    'ticketType',
    'firstName',
    'lastName',
    'email',
    'status',
    'checkInAt',
    'id',
  ];

  const mods = {
    startTime: (value, item) =>
      value && `${moment(`${item.startTime}Z`).format('MMM Do YYYY, HH:mm')}`,
    checkInAt: (value) =>
      value && moment(`${value}Z`).format('MMM Do YYYY, HH:mm'),
    status: (value) => {
      switch (value) {
        case TicketStatus.Reserved:
          return 'Reserved';
        case TicketStatus.Active:
          return 'Active';
        case TicketStatus.CheckedIn:
          return 'CheckedIn';
        case TicketStatus.Expired:
          return 'Expired';
        default:
          return '';
      }
    },
    id: (id, row) => (
      <>
        {activeRowEmail !== row.id ? (
          <Tooltip
            title={t('membership.membershipList.resendEmail')}
            placement="top"
            arrow
          >
            <IconButton
              aria-haspopup="true"
              onClick={() => resendEmail(id)}
              cursor="pointer"
              disabled={activeRowEmail}
            >
              <img src={Resend} alt="" style={{ width: 20 }} />
            </IconButton>
          </Tooltip>
        ) : (
          <CircularProgress color="primary" size={14} />
        )}
        <Tooltip title={t('edit')} placement="top" arrow>
          <IconButton
            aria-haspopup="true"
            onClick={() => setEditItem(row)}
            cursor="pointer"
          >
            <img src={Editing} alt="" style={{ width: 20 }} />
          </IconButton>
        </Tooltip>
      </>
    ),
  };

  const isLoading = useSelector(ticket.selectors.selectIsLoading);
  const { data: tickets, total } = useSelector(ticket.selectors.selectTickets);

  const handleChangePage = (event, page) => {
    setParams({ ...params, page: page + 1, status: eventStatusValue });
  };
  const handleChangeRowsPerPage = (event) => {
    setParams({
      ...params,
      limit: event.target.value,
      page: 1,
      status: eventStatusValue,
    });
  };

  const handleChangeRadio = (event) => {
    setEventStatusValue(Number(event.target.value));
    setParams({
      ...params,
      status: Number(event.target.value),
    });
  };

  const pagination = {
    total,
    pageSize: params.limit,
    page: params.page,
    handleChangePage,
    handleChangeRowsPerPage,
  };

  const csv = useSelector(ticket.selectors.selectCsv);
  const refLink = React.useRef();

  const handleDownload = () => {
    dispatch(ticket.actions.downloadTicketsCSV())
      .then(() => {
        refLink.current.link.click();
      })
      .then(() =>
        dispatch(
          alert.actions.open({
            message: t('csvDownloadSuccess'),
            severity: 'success',
          })
        )
      );
  };

  const search = () => {
    setParams({
      ...params,
      keyword,
    });
  };

  const searchBox = () => (
    <Grid item>
      <Grid>
        <Box>
          <Box
            style={{
              display: 'flex',
              textAlign: 'center',
              alignItems: 'center',
            }}
          >
            <Radio
              value={EventStatus.Published}
              name="eventStatus"
              color="primary"
              checked={eventStatusValue === EventStatus.Published}
              onChange={handleChangeRadio}
            />
            <Typography className={classes.mr10}>
              {t('tickets.statusPublish')}
            </Typography>
            <Radio
              value={EventStatus.Done}
              name="eventStatus"
              color="primary"
              checked={eventStatusValue === EventStatus.Done}
              onChange={handleChangeRadio}
            />
            <Typography className={classes.mr10}>
              {t('tickets.statusDone')}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid container spacing={2} alignItems="center">
        <Grid item className={classes.fullwidthChild}>
          <TextField
            fullWidth
            label={t('tickets.ticket.fName')}
            name="keyword"
            variant="outlined"
            onChange={(val) => {
              setKeyword(val.target.value);
            }}
            style={{ height: '47px', minWidth: 400 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => search()}
                    style={{ marginRight: -14 }}
                  >
                    <img
                      src={searchIcon}
                      alt={t('search')}
                      style={{
                        width: 25,
                        height: 25,
                        paddingTop: 2,
                        cursor: 'pointer',
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item style={{ marginBottom: -15, padding: 0 }}>
          <DownloadCSV
            handleDownload={handleDownload}
            refLink={refLink}
            data={csv}
            filename="ticket-list"
          >
            <Tooltip
              title={t('membership.membershipList.downloadCSV')}
              placement="top"
              arrow
            >
              <Button onClick={handleDownload}>
                <img
                  src={DownloadIcon}
                  alt={t('membership.membershipList.downloadCSV')}
                  style={{ width: 30, cursor: 'pointer' }}
                />
              </Button>
            </Tooltip>
          </DownloadCSV>
        </Grid>
        <Grid item style={{ marginBottom: -12, padding: 0 }}>
          <Button>
            <img
              src={FreeTicket}
              alt={t('tickets.ticket.freeTicket')}
              style={{ width: 38, cursor: 'pointer' }}
            />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  React.useEffect(() => {
    search();
  }, [keyword]);

  React.useEffect(() => {
    if (debounce?.keyword === '' || debounce?.keyword?.length > 2) {
      dispatch(ticket.actions.getTickets(params));
    }
  }, [debounce]);

  return (
    <>
      <Box pb={2}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Typography variant="h5">{t('tickets.tickets')}</Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box py={2}>
        <TransactionTable
          cols={colsTitles}
          rowKeys={rowKeys}
          data={tickets}
          mods={mods}
          isLoading={isLoading}
          pagination={pagination}
          searchBox={searchBox}
          noDataMsg={t('noDataMsg')}
        />
      </Box>

      <EditTicketModal
        item={editItem}
        onClose={() => {
          setEditItem(null);
          dispatch(ticket.actions.getTickets(params));
        }}
      />
    </>
  );
};
export default TicketList;
