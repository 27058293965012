/* eslint-disable react/prop-types */
import * as React from 'react';
import { TextField as FormikTextField, TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Box,
  Grid,
  IconButton,
  MenuItem,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';

import { useTranslation } from 'react-i18next';
import { Close } from '@material-ui/icons';
import getSymbolFromCurrency from 'currency-symbol-map';
import { CustomInputField } from '../../../custom';
import api from '../../../../state/ticket/api';
import { alert, merchant, ticket } from '../../../../state';

// eslint-disable-next-line react/prop-types
const AddPricingModal = ({ onClose, item, params }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const openAlert = (payload) => dispatch(alert.actions.open(payload));
  const validationSchema = () =>
    Yup.object().shape(
      {
        ticketTypeId: Yup.string().required(t('validation.required')),
        price: Yup.number().required(t('validation.required')),
        totalQuantity: Yup.number().required(t('validation.required')),
        minPerOrder: Yup.number().required(t('validation.required')),
        maxPerOrder: Yup.number().required(t('validation.required')),
        groupDiscount: Yup.number()
          .nullable()
          .when('groupDiscountQuantity', {
            is: (groupDiscountQuantity) => groupDiscountQuantity,
            then: Yup.number().required(t('validation.required')).max(100),
            otherwise: Yup.number().nullable(),
          }),
        groupDiscountQuantity: Yup.number()
          .nullable()
          .when('groupDiscount', {
            is: (groupDiscount) => groupDiscount,
            then: Yup.number().required(t('validation.required')),
            otherwise: Yup.number().nullable(),
          }),
      },
      ['groupDiscount', 'groupDiscountQuantity']
    );

  const onSubmit = async (values, formikHelper) => {
    formikHelper.setSubmitting(true);
    try {
      await api.postTicketPricing({
        ...item,
        ...values,
        id: item?.id,
      });
      openAlert({
        message: t('tickets.messages.pricingAddedSuccess'),
        severity: 'success',
      });
      onClose();
      dispatch(ticket.actions.getTicketPricing(params));
    } catch (error) {
      openAlert({
        message:
          error?.message || error || t('tickets.messages.failedAddPricing'),
        severity: 'error',
      });
      formikHelper.setSubmitting(false);
    }
  };
  React.useEffect(() => {
    dispatch(ticket.actions.getTicketTypes({ limit: 9999 }));
  }, []);
  const ticketTypes =
    useSelector(ticket.selectors.selectTicketTypes)?.data || [];
  const activeTicketEvent = useSelector(ticket.selectors.selectTicketEvent);
  const { currency } = useSelector(merchant.selectors.selectMerchantDetails);
  const currencySymbol = getSymbolFromCurrency(currency || 'USD');

  return (
    <Dialog open={!!item} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {item?.id ? t('tickets.pricing.edit') : t('tickets.pricing.add')}
      </DialogTitle>
      <DialogContent>
        <Box py={2}>
          <Formik
            onSubmit={onSubmit}
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={{
              ticketTypeId: item?.ticketTypeId,
              price: item?.price,
              totalQuantity: item?.totalQuantity,
              minPerOrder: item?.minPerOrder,
              maxPerOrder: item?.maxPerOrder,
              description: item?.description,
              ticketEventId: item?.ticketEventId || activeTicketEvent?.id,
              groupDiscountQuantity: item?.groupDiscountQuantity,
              groupDiscount: item?.groupDiscount,
            }}
          >
            {({ isSubmitting, values, setFieldValue }) => {
              return (
                <Form>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={FormikTextField}
                          fullWidth
                          select
                          value={values.ticketTypeId}
                          name="ticketTypeId"
                          variant="outlined"
                          label={t('tickets.ticketType.ticketType')}
                          onChange={(e) => {
                            setFieldValue('ticketTypeId', e.target?.value);
                            setFieldValue(
                              'description',
                              ticketTypes.find(
                                (type) => type.id === e.target?.value
                              )?.description || ''
                            );
                          }}
                        >
                          {ticketTypes
                            ?.slice() // Create a shallow copy of the array
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .filter(
                              (type) =>
                                !type.events ||
                                !type.events.includes(
                                  item?.ticketEventId || activeTicketEvent?.id
                                )
                            )
                            .map(({ id, name }) => (
                              <MenuItem
                                key={id}
                                value={id}
                                selected={values.ticketEventId === id}
                              >
                                {name}
                              </MenuItem>
                            ))}
                        </Field>
                      </Grid>

                      <Grid item xs={12}>
                        <Box pb={2}>
                          <CustomInputField
                            customInput={Field}
                            component={TextField}
                            fullWidth
                            InputProps={{
                              shrink: true,
                            }}
                            label={t('tickets.pricing.ticketDescription')}
                            name="description"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: Boolean(values.description),
                            }}
                          />
                        </Box>
                        <Box pb={2}>
                          <Grid container display="row" spacing={2}>
                            <Grid item xs={6}>
                              <CustomInputField
                                customInput={Field}
                                component={TextField}
                                label={t('tickets.pricing.ticketPrice')}
                                name="price"
                                type="number"
                                removeArrows
                                variant="outlined"
                                required
                                fullWidth
                                startAdornment={currencySymbol}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <CustomInputField
                                customInput={Field}
                                component={TextField}
                                label={t('tickets.pricing.totalQuantity')}
                                name="totalQuantity"
                                type="number"
                                removeArrows
                                variant="outlined"
                                required
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <CustomInputField
                                customInput={Field}
                                component={TextField}
                                label={t(
                                  'tickets.pricing.minimumTicketPerOrder'
                                )}
                                name="minPerOrder"
                                type="number"
                                removeArrows
                                variant="outlined"
                                required
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <CustomInputField
                                customInput={Field}
                                component={TextField}
                                label={t(
                                  'tickets.pricing.maximumTicketPerOrder'
                                )}
                                name="maxPerOrder"
                                type="number"
                                removeArrows
                                variant="outlined"
                                required
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <CustomInputField
                                customInput={Field}
                                component={TextField}
                                label={t(
                                  'tickets.pricing.quantityForGroupDiscount'
                                )}
                                name="groupDiscountQuantity"
                                type="number"
                                removeArrows
                                variant="outlined"
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <CustomInputField
                                customInput={Field}
                                component={TextField}
                                label={t('tickets.pricing.groupDiscount')}
                                name="groupDiscount"
                                type="number"
                                removeArrows
                                variant="outlined"
                                fullWidth
                                extraEndAdornments="%"
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      flexDirection: 'column',
                    }}
                  >
                    <Button color="primary" variant="contained" type="submit">
                      {isSubmitting ? (
                        <CircularProgress size={24} />
                      ) : (
                        t('saveChanges')
                      )}
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </DialogContent>
      <IconButton
        aria-label="clear"
        onClick={onClose}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          width: 20,
          height: 20,
        }}
      >
        <Close fontSize="small" />
      </IconButton>
    </Dialog>
  );
};

export default AddPricingModal;
