import React, { useEffect, useRef } from 'react';
import {
  Box,
  FormControlLabel,
  Grid,
  Typography,
  Checkbox,
  TextField,
  Tooltip,
  Button,
  IconButton,
} from '@material-ui/core';
import { Field, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import getSymbolFromCurrency from 'currency-symbol-map';
import PropTypes from 'prop-types';
import Editing from '../../../images/Memberships/editing.png';
import { CustomInputField, TransactionTable } from '../../custom';
import { membership, merchant, ticket } from '../../../state';
import AddTicketDiscountModal from './Modals/AddTicketDiscountModal';

const TicketMembershipDiscounts = ({ isEdit = false }) => {
  const { t } = useTranslation();
  const {
    values,
    handleSubmit,
    setFieldValue,
    isSubmitting,
  } = useFormikContext();
  const dispatch = useDispatch();
  const amountRef = useRef(null);
  const [itemSelected, setItemSelected] = React.useState({});
  const [planDiscountAll, setPlanDiscountAll] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const discountsList = useSelector(ticket.selectors.selectEventsDiscountsList);
  const ticketEvent = useSelector(ticket.selectors.selectTicketEvent);

  const membershipPlans = useSelector(membership.selectors.selectPlans);
  const { currency } = useSelector(merchant.selectors.selectMerchantDetails);
  const currencySymbol = getSymbolFromCurrency(currency || 'USD');

  useEffect(() => {
    if (values.useDiscountAllMembership === 'false' || !isEdit) {
      return;
    }
    const newDiscountsList = membershipPlans.map((item) => {
      return {
        ...item,
        membershipPlanId: item.id,
        discount: values.discountAll,
        discountType: 0,
      };
    });
    setPlanDiscountAll(newDiscountsList);
  }, [values, discountsList]);

  const colsTitles = [
    t('membership.discounts.plan'),
    t('membership.discounts.discount'),
    t('tickets.discounts.edit'),
  ];

  const rowKeys = ['membershipPlanId', 'discount', 'id'];
  const handleOpenModal = (edit, item = {}) => {
    setItemSelected(item);
    setIsOpen(true);
  };

  const mods = {
    membershipPlanId: (item) =>
      membershipPlans.find((p) => p.id === item)?.name,
    discount: (_, row) =>
      row.discountType === 0
        ? `${row.discount} %`
        : `${row.discount} ${currencySymbol}`,
    id: (_, row) => (
      <Tooltip title={t('edit')} placement="top" arrow>
        <IconButton
          aria-haspopup="true"
          onClick={() => handleOpenModal(true, row)}
          cursor="pointer"
        >
          <img src={Editing} alt="" style={{ width: 20 }} />
        </IconButton>
      </Tooltip>
    ),
  };

  useEffect(() => {
    dispatch(ticket.actions.getEventsDiscountsList(ticketEvent.id));
  }, []);

  const transactionTable = () => {
    return (
      <TransactionTable
        cols={colsTitles}
        rowKeys={rowKeys}
        data={
          values.useDiscountAllMembership === 'false' || !isEdit
            ? discountsList
            : planDiscountAll
        }
        mods={mods}
        isLoading={false}
        noDataMsg={t('noDataMsg')}
      />
    );
  };

  if (isEdit) {
    return transactionTable();
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Box mb={2}>
            <Typography style={{ fontWeight: 'bold' }}>
              {t('tickets.discounts.memberDiscount')}:{' '}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid item>
            <FormControlLabel
              value="true"
              checked={values.useDiscountAllMembership === 'true'}
              control={
                <Checkbox
                  color="primary"
                  name="useDiscountAllMembership"
                  onClick={() =>
                    setFieldValue(
                      'useDiscountAllMembership',
                      values.useDiscountAllMembership === 'true'
                        ? 'false'
                        : 'true'
                    )
                  }
                />
              }
              label="All Plans"
              name="useDiscountAllMembership"
            />
            <Box item style={{ position: 'relative' }} width={320}>
              <CustomInputField
                extraEndAdornments
                customInput={Field}
                component={TextField}
                fullWidth
                type="number"
                removeArrows
                name="discountAll"
                id="discount-all"
                variant="outlined"
                required
                label={t('membership.addDiscountForm.discount')}
                inputRef={amountRef}
                style={{ width: 320 }}
                disabled={values.useDiscountAllMembership === 'false'}
                value={values.discountAll}
                InputProps={{
                  style: {
                    paddingRight: 150,
                    minWidth: 320,
                    width: '100%',
                  },
                }}
              />
              <ToggleButtonGroup
                exclusive
                id="roleType"
                value={values.discountType}
                size="small"
                style={{ position: 'absolute', right: 10, top: 6 }}
              >
                <ToggleButton
                  value={0}
                  style={{ padding: '5px 25px' }}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    setFieldValue('discountType', 0);
                    if (document.activeElement !== amountRef.current)
                      amountRef.current.focus();
                  }}
                >
                  %
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={12} md={8}>
          <Grid item xs={12} md={12}>
            {ticketEvent?.id && (
              <Button
                onClick={() => handleOpenModal(false)}
                variant="contained"
                color="primary"
              >
                Select Plan
              </Button>
            )}

            <Box mb={2}>{transactionTable()}</Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Button
              disabled={isSubmitting}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              {t('saveAndContinue')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <AddTicketDiscountModal
        isOpen={isOpen}
        closeModal={() => setIsOpen(false)}
        ticketEvent={ticketEvent}
        itemSelected={itemSelected}
      />
    </Box>
  );
};

TicketMembershipDiscounts.propTypes = {
  isEdit: PropTypes.func.isRequired,
};

export default TicketMembershipDiscounts;
