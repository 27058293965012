/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import {
  Box,
  DialogContent,
  DialogContentText,
  Dialog,
  IconButton,
  Typography,
  DialogTitle,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Close } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { TransactionTable } from '../../custom';
import AddSecondaryMemberForm from './AddSecondaryMemberForm';
import api from '../../../state/membership/api';

const ViewPaymentActivityModal = ({ item, onClose }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);

  const [data, setData] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const colsTitles = [
    t('membership.membershipList.code'),
    t('membership.membershipList.firstName'),
    t('membership.membershipList.lastName'),
    t('membership.membershipList.phone'),
    t('membership.membershipList.email'),
    t('membership.membershipList.status'),
  ];

  const rowKeys = ['code', 'firstName', 'lastName', 'phone', 'email', 'status'];
  const membershipStatuses = [
    'Active',
    'Canceled',
    'Pending',
    'Deactivated',
    'Expired',
    'Paused',
    '',
  ];

  const [params, setParams] = React.useState({
    limit: 10,
    page: 1,
  });

  const handleChangePage = (event, page) => {
    setParams({ ...params, page: page + 1 });
  };
  const handleChangeRowsPerPage = (event) => {
    setParams({ ...params, limit: event.target.value, page: 1 });
  };

  const pagination = {
    total,
    pageSize: params.limit,
    page: params.page,
    handleChangePage,
    handleChangeRowsPerPage,
  };

  const mods = {
    createdAt: (val) => moment(`${val}Z`).format('MMM Do YYYY, HH:mm'),
    status: (val) => membershipStatuses[val] || 'Deactivated',
  };

  const getTransactions = async () => {
    setLoading(true);
    const res = await api.getMembershipList({
      ...params,
      primaryMembershipId: item.id,
    });
    console.log(res);
    if (res) {
      setData(res.data);
      setTotal(res.total);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (item?.id) {
      getTransactions();
    }
  }, [item, params]);

  const body = (
    <>
      <Box>
        <AddSecondaryMemberForm
          primaryMembership={item}
          onComplete={() => getTransactions()}
          secondaryMembershipCount={data.filter((r) => r.status === 0).length}
        />
      </Box>
      <Box py={2}>
        <TransactionTable
          cols={colsTitles}
          rowKeys={rowKeys}
          data={data || []}
          mods={mods}
          isLoading={loading}
          noDataMsg={t('noDataMsg')}
          pagination={pagination}
        />
      </Box>
    </>
  );

  return (
    <Dialog
      open={!!item?.id}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      scroll="paper"
      aria-labelledby="terms and conditions"
      aria-describedby="terms and conditions"
      BackdropProps={{
        timeout: 500,
      }}
    >
      <DialogTitle>
        <Typography variant="subtitle1">
          {t('membership.secondaryMembers.addSecondaryMembersFor')}{' '}
          <b>
            {item?.firstName} {item?.lastName} (#{item?.code})
          </b>
        </Typography>
        <Typography variant="subtitle1">
          {t('membership.plan')}: <b>{item?.plan.name}</b>
        </Typography>
        <Typography variant="subtitle1">
          {t('membership.secondaryMembers.numberAllowed')}:{' '}
          <b>{item?.plan.allowedSecondaryMembers}</b>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText tabIndex={-1} component="div">
          {body}
        </DialogContentText>
      </DialogContent>
      <IconButton
        aria-label="clear"
        onClick={onClose}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          width: 20,
          height: 20,
        }}
      >
        <Close fontSize="small" />
      </IconButton>
    </Dialog>
  );
};

ViewPaymentActivityModal.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  item: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ViewPaymentActivityModal;
