/* eslint-disable eqeqeq */

import React, { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  Grid,
  Divider,
  Tabs,
  Button,
  CircularProgress,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Field, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { TabContext, TabPanel } from '@material-ui/lab';
import { RadioGroup, TextField } from 'formik-material-ui';
import StyledTab from '../../custom/StyledTab';
import { alert, storeCredit, merchant } from '../../../state';
import CardSection from '../GiftCardDetails/CardSection';
import StoreCreditsList from './StoreCreditsList';
import StoreCreditsIssue from './StoreCreditsIssue';
import StoreCreditIssueCard from './StoreCreditIssueCard';
import StoreCreditSearchUpdate from './StoreCreditSearchUpdate';
import StoreCreditsImport from './StoreCreditsImport';
import PhysicalStoreCreditsImport from './PhysicalStoreCreditsImport';
import { PartnerEnum } from '../../../utils/constants';

const StoreCreditsConfig = () => {
  const { t } = useTranslation();
  const storeCreditConfig = useSelector(
    storeCredit.selectors.selectStoreCreditConfig
  );
  const isLoading = useSelector(storeCredit.selectors.selectIsLoading);
  const imgIsLoading = useSelector(storeCredit.selectors.selectImgIsLoading);
  const dispatch = useDispatch();
  const setGiftCardImage = (payload) =>
    dispatch(merchant.actions.setGiftCardImage(payload));
  const {
    imageUrl,
    enableTrigger,
    terms,
    plans,
    codeType,
    qrPrefix,
  } = storeCreditConfig;

  const [formTab, setTab] = useState(0);

  const openAlert = (payload) => dispatch(alert.actions.open(payload));

  const merchantDetails = useSelector(merchant.selectors.selectMerchantDetails);
  const { partner = '' } = merchantDetails;

  useEffect(() => {
    dispatch(storeCredit.actions.getStoreCreditConfig());
    dispatch(storeCredit.actions.getStoreCreditPlans());
  }, []);

  return (
    <div>
      <Box pb={2}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Typography variant="h5">
              {t('storeCredit.storeCredits')}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Formik
        enableReinitialize
        validateOnChange={false}
        validateOnBlur
        validator={() => ({})}
        initialValues={{
          enableTrigger,
          imageUrl,
          terms,
          codeType,
          plans: plans && plans[0] ? plans : [{}],
          isSquareCompatibleScanner: qrPrefix ? '1' : '0',
        }}
        onSubmit={async (values) => {
          const payload = {
            ...values,
            plans: values.plans[0].id
              ? [
                  {
                    ...values.plans[0],
                    amount: Number(values.plans[0].amount),
                    originalAmount: Number(values.plans[0].originalAmount),
                  },
                ]
              : [],
            isSquareCompatibleScanner:
              partner === PartnerEnum.Square &&
              (values.isSquareCompatibleScanner === true ||
                values.isSquareCompatibleScanner == '1')
                ? 1
                : 0,
          };
          const res = await dispatch(
            storeCredit.actions.setStoreCreditConfig(payload)
          );
          if (res.payload) {
            if (formTab === 2 && values.enableTrigger && values.plans[0].id) {
              dispatch(
                storeCredit.actions.getStoreCreditsList({ status: 'NEW' })
              );
            } else {
              openAlert({
                message: t('storeCredit.messages.updateSuccess'),
                severity: 'success',
              });
            }
          }
          if (res.error) {
            openAlert({
              message: t('updateGiftCardFailed'),
              severity: 'error',
            });
          }
        }}
      >
        {({ handleSubmit, isSubmitting, values }) => {
          return (
            <Box py={4}>
              <Tabs
                value={formTab}
                onChange={(event, newValue) => {
                  setTab(newValue);
                }}
                TabIndicatorProps={{ style: { background: '#0969da' } }}
                aria-label="Select settings tab"
              >
                <StyledTab
                  disableRipple
                  id="initialSetup"
                  aria-controls="values"
                  label={t('storeCredit.initialSetup')}
                />

                <StyledTab
                  disableRipple
                  id="issueSc"
                  aria-controls="values"
                  label={t('storeCredit.issueStoreCredit')}
                />
                <StyledTab
                  disableRipple
                  id="searchUpdate"
                  aria-controls="values"
                  label={t('storeCredit.searchUpdate')}
                />
                <StyledTab
                  disableRipple
                  id="import"
                  aria-controls="values"
                  label={t('storeCredit.bulkIssueDigitalCards')}
                />
                <StyledTab
                  disableRipple
                  id="physical"
                  aria-controls="values"
                  label={t('storeCredit.bulkIssuePhysicalCards')}
                  style={{ display: 'none' }}
                />
                {merchantDetails.partner === 'Hidden' && (
                  <StyledTab
                    disableRipple
                    id="list"
                    aria-controls="values"
                    label={t('storeCredit.bulkClearBalances')}
                  />
                )}
                {merchantDetails.partner === 'Hidden' && (
                  <StyledTab
                    disableRipple
                    id="issue"
                    aria-controls="values"
                    label={t('storeCredit.squareSubscriptionPlans')}
                  />
                )}
              </Tabs>
              <Divider />
              <TabContext value={formTab.toString()}>
                <SwipeableViews index={formTab} onChangeIndex={setTab}>
                  <TabPanel value={formTab.toString()} index={0}>
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                          <Box mb={2}>
                            <Typography style={{ fontWeight: 'bold' }}>
                              {t('storeCredit.note')}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Box mb={2}>
                            <CardSection
                              isLoading={isLoading}
                              imgIsLoading={imgIsLoading}
                              merchantDetails={storeCreditConfig}
                              merchantCreatives={storeCreditConfig}
                              setGiftCardImage={setGiftCardImage}
                              type="storeCredit"
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Grid container item>
                            <Grid item xs={4}>
                              <Typography
                                variant="subtitle1"
                                style={{ padding: 7, marginRight: 5 }}
                              >
                                {t('storeCredit.storeCreditType')}:
                              </Typography>
                            </Grid>
                            <Field
                              component={RadioGroup}
                              name="type"
                              row
                              label="fdfd"
                            >
                              <Grid item>
                                <FormControlLabel
                                  value="true"
                                  control={
                                    <Radio
                                      value={0}
                                      color="primary"
                                      name="codeType"
                                      checked={values.codeType == 0}
                                    />
                                  }
                                  label={t('QRCode')}
                                />
                              </Grid>
                              <Grid item>
                                <FormControlLabel
                                  value="false"
                                  control={
                                    <Radio
                                      value={1}
                                      color="primary"
                                      name="codeType"
                                      checked={values.codeType == 1}
                                    />
                                  }
                                  label={t('BarCode')}
                                />
                              </Grid>
                            </Field>
                          </Grid>
                          {partner === PartnerEnum.Square && (
                            <Grid container item>
                              <Grid item xs={4}>
                                <Typography
                                  variant="subtitle1"
                                  style={{ padding: 7, marginRight: 5 }}
                                >
                                  {t('storeCredit.squareCompatibleScanner')}:
                                </Typography>
                              </Grid>
                              <Field
                                component={RadioGroup}
                                name="isSquareCompatibleScanner"
                                row
                              >
                                <Grid item>
                                  <FormControlLabel
                                    value="true"
                                    control={
                                      <Radio
                                        value={1}
                                        color="primary"
                                        name="isSquareCompatibleScanner"
                                        checked={
                                          values.isSquareCompatibleScanner ==
                                          '1'
                                        }
                                      />
                                    }
                                    label={t('yes')}
                                  />
                                </Grid>
                                <Grid item>
                                  <FormControlLabel
                                    value="false"
                                    control={
                                      <Radio
                                        value={0}
                                        color="primary"
                                        name="isSquareCompatibleScanner"
                                        checked={
                                          values.isSquareCompatibleScanner ===
                                          '0'
                                        }
                                      />
                                    }
                                    label={t('no')}
                                  />
                                </Grid>
                              </Field>
                            </Grid>
                          )}
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Grid item xs={12} md={6}>
                            <Box mb={2}>
                              <Field
                                component={TextField}
                                fullWidth
                                multiline
                                rows={4}
                                type="text"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                label={t('storeCredit.termsAndConditions')}
                                name="terms"
                                variant="outlined"
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSubmit}
                          type="submit"
                          fullWidth
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? (
                            <CircularProgress size={24} />
                          ) : (
                            t('saveChanges')
                          )}
                        </Button>
                      </Grid>
                    </>
                  </TabPanel>
                  <TabPanel value={formTab.toString()} index={1}>
                    {formTab === 1 && <StoreCreditIssueCard />}
                  </TabPanel>
                  <TabPanel value={formTab.toString()} index={2}>
                    {formTab === 2 && <StoreCreditSearchUpdate />}
                  </TabPanel>
                  <TabPanel value={formTab.toString()} index={3}>
                    {formTab === 3 && <StoreCreditsImport />}
                  </TabPanel>
                  <TabPanel value={formTab.toString()} index={3}>
                    {formTab === 4 && <PhysicalStoreCreditsImport />}
                  </TabPanel>
                  <TabPanel value={formTab.toString()} index={4}>
                    {formTab === 5 && <StoreCreditsList />}
                  </TabPanel>
                  <TabPanel value={formTab.toString()} index={5}>
                    {formTab === 6 && <StoreCreditsIssue />}
                  </TabPanel>
                </SwipeableViews>
              </TabContext>
            </Box>
          );
        }}
      </Formik>
    </div>
  );
};

export default StoreCreditsConfig;
