import React from 'react';

import { TextField, Select } from 'formik-mui';
import { useTranslation } from 'react-i18next';
import { Field, useFormikContext } from 'formik';
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  Typography,
  Card,
  CardContent,
} from '@material-ui/core';
import { RadioGroup } from 'formik-material-ui';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import getSymbolFromCurrency from 'currency-symbol-map';
import { loyalty, merchant } from '../../../state';
import { CustomInputField } from '../../custom';

// eslint-disable-next-line no-unused-vars
const LoyaltyInitialSetup = ({ isCreating }) => {
  const { values, handleSubmit, isSubmitting } = useFormikContext();
  const { t } = useTranslation();
  const items = useSelector(loyalty.selectors.selectItems);
  const { currency } = useSelector(merchant.selectors.selectMerchantDetails);
  const currencySymbol = getSymbolFromCurrency(currency);
  const storeCreditExpirationPlans = [
    {
      id: 1,
      name: t('loyalty.days'),
    },
    {
      id: 2,
      name: t('loyalty.months'),
    },
    {
      id: 3,
      name: t('loyalty.year'),
    },
  ];
  const [selectedItem, setSelectedItem] = React.useState(
    values.storeCreditExpirationType || 2
  );

  return (
    <>
      <Grid container>
        <Grid
          container
          item
          spacing={2}
          xs={12}
          justifyContent="space-between"
          style={{ maxWidth: 1200 }}
        >
          <Grid item xs={12}>
            <Box mt={2}>
              <Typography>{t('loyalty.requiredAmount')}*:</Typography>
              <Box mt={2}>
                <CustomInputField
                  customInput={Field}
                  component={TextField}
                  type="number"
                  removeArrows
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="requiredAmount"
                  variant="outlined"
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Card
              elevation={3}
              style={{
                position: 'relative',
                overflow: 'visible',
                marginTop: 20,
              }}
            >
              <CardContent>
                <Box mb={2}>
                  <Typography
                    style={{
                      position: 'absolute',
                      top: -15,
                      left: 30,
                      background: '#fff',
                    }}
                  >
                    {t('loyalty.rewardType')}*(select one):
                  </Typography>
                  <Field component={RadioGroup} name="rewardType">
                    {/* <FormControlLabel */}
                    {/*  value="0" */}
                    {/*  control={ */}
                    {/*    <Radio */}
                    {/*      color="primary" */}
                    {/*      name="rewardType" */}
                    {/*      checked={Number(values.rewardType) === 0} */}
                    {/*      disabled */}
                    {/*    /> */}
                    {/*  } */}
                    {/*  label={t('loyalty.freeItem')} */}
                    {/* /> */}
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={3}>
                        <FormControlLabel
                          value="1"
                          control={
                            <Radio
                              color="primary"
                              name="rewardType"
                              disabled
                              checked={Number(values.rewardType) === 1}
                            />
                          }
                          label={t('loyalty.discountAmount')}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        {Number(values.rewardType) === 0 ? (
                          <Field
                            component={TextField}
                            select
                            type="text"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label={t('loyalty.selectItem')}
                            name="rewardItemId"
                            variant="outlined"
                            disabled
                          >
                            <MenuItem value={undefined}>---</MenuItem>
                            {items.map((opt) => (
                              <MenuItem value={opt.id} key={opt.id}>
                                {opt.name}
                              </MenuItem>
                            ))}
                          </Field>
                        ) : (
                          <CustomInputField
                            customInput={Field}
                            component={TextField}
                            startAdornment={currencySymbol}
                            fullWidth
                            type="number"
                            removeArrows
                            label={t('loyalty.discountAmount')}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled={Number(values.rewardType) !== 1}
                            name="discountAmount"
                            variant="outlined"
                          />
                        )}
                      </Grid>
                    </Grid>
                    <Box mt={2}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={3}>
                          <FormControlLabel
                            value="2"
                            control={
                              <Radio
                                color="primary"
                                name="rewardType"
                                checked={Number(values.rewardType) === 2}
                              />
                            }
                            label={t('loyalty.storeCredit')}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <CustomInputField
                            customInput={Field}
                            component={TextField}
                            startAdornment={currencySymbol}
                            fullWidth
                            type="number"
                            removeArrows
                            label="Amount"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name="storeCreditAmount"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          container
                          item
                          md={6}
                          spacing={2}
                          alignItems="center"
                        >
                          <Grid item>
                            <Typography variant="subtitle1">
                              {t('loyalty.storeCreditExpiration')}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <CustomInputField
                              customInput={Field}
                              component={TextField}
                              fullWidth
                              required
                              type="number"
                              removeArrows
                              name="storeCreditExpirationValue"
                              variant="outlined"
                              disabled={Number(values.rewardType) !== 2}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Field
                              component={Select}
                              as="select"
                              fullWidth
                              required
                              name="storeCreditExpirationType"
                              value={selectedItem}
                              key={selectedItem}
                              variant="outlined"
                              disabled={Number(values.rewardType) !== 2}
                              onChange={(event, val) => {
                                setSelectedItem(
                                  storeCreditExpirationPlans.find(
                                    (p) => p.id === val.props.value
                                  )?.id
                                );
                              }}
                              style={{
                                width: 100,
                              }}
                            >
                              {storeCreditExpirationPlans.map(
                                (opt) =>
                                  opt && (
                                    <MenuItem value={opt.id} key={opt.id}>
                                      {opt.name}
                                    </MenuItem>
                                  )
                              )}
                            </Field>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Field>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          type="submit"
          disabled={isSubmitting}
        >
          {isSubmitting ? <CircularProgress size={24} /> : t('saveAndContinue')}
        </Button>
      </Box>
    </>
  );
};

LoyaltyInitialSetup.propTypes = {
  isCreating: PropTypes.bool.isRequired,
};

export default LoyaltyInitialSetup;
