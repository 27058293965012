import React from 'react';
import { Router, useLocation } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';

import {
  Dashboard as DashboardRoute,
  BusinessProfile,
  Reports,
  GiftCardConfigs,
  Login,
  Connect,
  ConnectSquareLocation,
  NotFound,
} from '../components/routes';
import PrivateRoute from '../components/PrivateRoute';
import { SidebarHeader, Content } from '../components/layouts';
import { auth, merchant } from '../state';
import { PreLoader } from '../components/custom';
import StoreCredits from '../components/routes/OtherServices/StoreCredits';
import Coupons from '../components/routes/OtherServices/Coupons';
import Memberships from '../components/routes/OtherServices/Memberships';
import Loyalty from '../components/routes/OtherServices/Loyalty';
import Ticketing from '../components/routes/OtherServices/Ticketing';

const Dashboard = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const location = useLocation();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) =>
    auth.selectors.selectIsLoggedIn(state)
  );
  const merchantDetails = useSelector((state) =>
    merchant.selectors.selectMerchantDetails(state)
  );
  const isConnected = merchantDetails.merchantConnected;
  const { partner } = merchantDetails;
  const hasLocationId = !!merchantDetails.locationId;
  const isDashboardRoute = location.pathname !== '/dashboard/login';

  React.useEffect(() => {
    if (!isLoggedIn) {
      const response = dispatch(auth.actions.refreshToken());
      response.then(async ({ payload }) => {
        if (payload) {
          await dispatch(merchant.actions.getMerchantDetails(payload));
        }
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return <PreLoader />;
  }

  const isValidated = () => {
    if (partner === 'Square') {
      return isLoggedIn && isConnected && hasLocationId;
    }
    return isLoggedIn && isConnected;
  };

  // console.log('hasLocationId', hasLocationId);
  // console.log('isConnected', isConnected);
  // console.log('isLoggedIn', isLoggedIn);
  // console.log('all good', isValidated());
  // console.log('----------------------------');
  return (
    <>
      {isDashboardRoute && isValidated() ? <SidebarHeader /> : null}
      <Content
        withSidebar={isDashboardRoute && isValidated()}
        md={12}
        component="main"
      >
        <Router basepath="/dashboard">
          <PrivateRoute path="/connect" component={Connect} />
          <PrivateRoute path="/location" component={ConnectSquareLocation} />
          <PrivateRoute path="/" component={DashboardRoute} />
          <PrivateRoute path="/statistics/*" component={DashboardRoute} />
          <PrivateRoute path="/business-profile" component={BusinessProfile} />
          <PrivateRoute path="/gift-cards/*" component={GiftCardConfigs} />
          <PrivateRoute path="/reports" component={Reports} />
          <PrivateRoute path="/store-credits" component={StoreCredits} />
          <PrivateRoute path="/memberships" component={Memberships} />
          <PrivateRoute path="/loyalty/*" component={Loyalty} />
          <PrivateRoute path="/ticketing/*" component={Ticketing} />
          <PrivateRoute path="/coupons" component={Coupons} />
          <PrivateRoute path="/reports" component={Reports} />

          <Login path="/login" />
          <NotFound default path="/404" />
        </Router>
      </Content>
    </>
  );
};

export default Dashboard;
