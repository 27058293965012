/* eslint-disable react/destructuring-assignment */
import { Box, Button, Grid } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { Tooltip } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { coupons, merchant } from '../../../state';
import { TransactionTable } from '../../custom';
import ConfirmationDialog from '../GiftCardDetails/ConfirmDialog';
import AddDefineCouponForm from './AddDefineCouponForm';

const rowKeys = ['createdAt', 'name', 'discount'];

const CouponPlans = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const colsTitles = [
    t('coupons.dateCreated'),
    t('coupons.discountName'),
    t('coupons.discountDescription'),
  ];

  const [params, setParams] = React.useState({
    limit: 10,
    page: 1,
    update: new Date().getTime(),
  });
  const [categories, setCategories] = React.useState([]);
  // const [items, setItems] = React.useState([]);
  const [itemSelected, setItemSelected] = React.useState({});
  const [isOpen, setIsOpen] = React.useState(false);
  const [_isLoading, setIsLoading] = React.useState(true);
  const [isConfirm, setIsConfirm] = React.useState(false);

  const isLoading1 = useSelector(coupons.selectors.selectIsLoading);
  const plans = useSelector(coupons.selectors.selectCouponPlans);
  const total = useSelector(coupons.selectors.selectCouponPlansTotal);

  const init = async ({ update, ...pr }) => {
    setIsLoading(true);
    if (!categories.length) {
      const resp = await dispatch(merchant.actions.getCategoriesList());
      if (!resp.errors && resp.payload) setCategories(resp.payload);
    }
    // if (!items.length) {
    //   const resp = await dispatch(loyalty.actions.getItems());
    //   if (!resp.errors && resp.payload) setItems(resp.payload);
    // }
    dispatch(coupons.actions.getCouponPlanList(pr));
    setIsLoading(false);
  };

  React.useEffect(() => {
    init(params);
  }, [params]);
  const handleOpenModal = (edit, item = {}) => {
    setItemSelected(item);
    setIsOpen(true);
  };
  const mods = {
    id: (id, row) => (
      <Grid container alignItems="center" direction="row">
        <Tooltip title={t('edit')} placement="top" arrow>
          <Edit onClick={() => handleOpenModal(true, row)} cursor="pointer" />
        </Tooltip>
        <Tooltip title={t('delete')} placement="top" arrow>
          <Delete onClick={() => setIsConfirm(id)} cursor="pointer" />
        </Tooltip>
      </Grid>
    ),
    createdAt: (val) => val && moment(`${val}Z`).format('MMM Do YYYY, HH:mm'),
    targetScopeId: (id) => categories.find((e) => e.id === id)?.name,
    // partnerPricingRuleId: (id) => items.find((e) => e.id === id)?.name,
  };

  const onReload = () => setParams({ ...params, update: new Date().getTime() });

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const onDelete = async () => {
    await dispatch(coupons.actions.deleteCouponPlan(isConfirm));
    onReload();
  };

  const pagination = {
    total,
    pageSize: params.limit,
    page: params.page,
    handleChangePage: (event, page) => {
      setParams({ ...params, page: page + 1 });
    },
    handleChangeRowsPerPage: (event) => {
      setParams({ ...params, limit: event.target.value, page: 1 });
    },
  };

  const isLoading = _isLoading || isLoading1;

  return (
    <>
      <AddDefineCouponForm
        isOpen={isOpen}
        closeModal={handleCloseModal}
        categories={categories}
        // items={items}
        selected={itemSelected}
        onReload={onReload}
      />

      <ConfirmationDialog
        open={isConfirm}
        setOpen={setIsConfirm}
        title={t('confirmation')}
        content={t('deleteConfirmationQuestion')}
        actionOk={onDelete}
      />
      <Box>
        <TransactionTable
          cols={colsTitles}
          rowKeys={rowKeys}
          data={plans}
          mods={mods}
          isLoading={isLoading}
          pagination={pagination}
          searchBox={() => (
            <Button
              disabled={isLoading}
              onClick={() => handleOpenModal(false)}
              variant="contained"
              color="primary"
            >
              {t('coupons.defineNewDiscount')}
            </Button>
          )}
          noDataMsg={t('noDataMsg')}
        />
      </Box>
    </>
  );
};

export default CouponPlans;
