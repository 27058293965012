/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { Box, Grid, Button, Tooltip, IconButton } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { membership, merchant } from '../../../state';
import { TransactionTable } from '../../custom';
import AddSubscriptionPlanModal from './AddSubscriptionPlanModal';
import MembershipPlanEditMenu from './MembershipPlanEditMenu';
import CancelMemberPlanModal from './DeactiveMemberPlanModal';
import CPRIcon from '../../../images/Memberships/cpr.png';

const membershipPlanStatuses = ['InActive', 'Active', 'Disabled'];

const MembershipList = () => {
  const [params, setParams] = React.useState({ limit: 10, page: 1 });
  const dispatch = useDispatch();
  const currency = useSelector(merchant.selectors.selectCurrency);
  React.useEffect(() => {
    dispatch(membership.actions.getMembershipList(params));
  }, [params]);
  const { t } = useTranslation();

  const colsTitles = [
    t('membership.planName'),
    t('price'),
    t('frequency'),
    t('dateCreated'),
    t('dateUpdated'),
    t('status'),
    '',
  ];

  const rowKeys = [
    'name',
    'amount',
    'period',
    'createdAt',
    'lastUpdatedAt',
    'status',
    'id',
  ];

  const [showAddPlan, setShowAddPlan] = useState(false);
  const [item, setItem] = useState(null);
  const [cancelItem, setDisableItem] = useState(null);
  const isLoading = useSelector(membership.selectors.selectIsLoading);
  // const plans = useSelector(membership.selectors.selectPlans);
  const selectAllPlans = useSelector(membership.selectors.selectAllPlans);

  const mods = {
    status: (val) => membershipPlanStatuses[val] || 'Deactivated',
    createdAt: (val) => val && moment(`${val}Z`).format('MMM Do, YYYY'),
    lastUpdatedAt: (val) => val && moment(`${val}Z`).format('MMM Do, YYYY'),
    period: (val, row) =>
      `${val}${
        val === 'CUSTOM' && row.expirationDate
          ? ` \n(${moment(`${row.expirationDate}Z`).format('MMM Do, YYYY')})`
          : ''
      }`,
    amount: (val) =>
      `${t('cardValue', {
        amount: val.toFixed(2),
        formatParams: {
          amount: { currency },
        },
      })}`,
    id: (id, row) => (
      <>
        <Grid container alignItems="flex-start" direction="row">
          {row.status === 1 && (
            <Grid item>
              <Tooltip title={t('edit')} placement="top" arrow>
                <div>
                  <MembershipPlanEditMenu
                    membershipPlan={row}
                    onEditPlan={() => {
                      setShowAddPlan(true);
                      setItem(row);
                    }}
                    onDisablePlan={() => setDisableItem({ ...row, status: 2 })}
                  />
                </div>
              </Tooltip>
            </Grid>
          )}
          {row.status === 2 && (
            <Grid item>
              <Tooltip title={t('membership.reactivate')} placement="top" arrow>
                <IconButton
                  onClick={() => setDisableItem({ ...row, status: 1 })}
                >
                  <img
                    src={CPRIcon}
                    alt={t('membership.reactivate')}
                    style={{
                      width: 20,
                      height: 21,
                      paddingTop: 2,
                      cursor: 'pointer',
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </>
    ),
  };
  const handleChangePage = (event, page) => {
    setParams({ ...params, page: page + 1 });
  };
  const handleChangeRowsPerPage = (event) => {
    setParams({ ...params, limit: event.target.value, page: 1 });
  };

  const pagination = {
    total: selectAllPlans.length,
    pageSize: params.limit,
    page: params.page,
    handleChangePage,
    handleChangeRowsPerPage,
  };

  const searchBox = () => (
    <Grid item>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setShowAddPlan(true);
              setItem(null);
            }}
            type="submit"
          >
            {t('membership.addPlan')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  useEffect(() => {
    dispatch(membership.actions.getMembershipPlans());
  }, []);

  return (
    <>
      <Box py={2}>
        <TransactionTable
          cols={colsTitles}
          rowKeys={rowKeys}
          data={selectAllPlans}
          searchBox={searchBox}
          pagination={pagination}
          mods={mods}
          isLoading={isLoading}
          noDataMsg={t('noDataMsg')}
        />
      </Box>
      <AddSubscriptionPlanModal
        item={item}
        isOpen={showAddPlan}
        closeModal={() => {
          setShowAddPlan(false);
          setItem(null);
        }}
      />
      <CancelMemberPlanModal
        item={cancelItem}
        onClose={() => setDisableItem(null)}
      />
    </>
  );
};

export default MembershipList;
