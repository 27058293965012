import React, { useState } from 'react';
import { TextField } from 'formik-mui';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, useFormikContext } from 'formik';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
} from '@material-ui/core';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField as FormikTextField } from 'formik-material-ui';
import { CustomInputField } from '../../custom';
import { ticket } from '../../../state';
import CkEditor from '../../custom/LazyCkEditor';

const EventInformationForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    values,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
    validateField,
  } = useFormikContext();
  const { data: venues } = useSelector(ticket.selectors.selectTicketVenues);
  const [startTimeValue] = useState(
    values.startTime ? new Date(`${values.startTime}Z`) : null
  );
  const [endTimeValue] = useState(
    values.endTime ? new Date(`${values.endTime}Z`) : null
  );
  React.useEffect(() => {
    dispatch(ticket.actions.getTicketVenues({ limit: 9999 }));
  }, []);

  return (
    <Grid container spacing={2} xs={12} md={12} lg={8}>
      <Grid item xs={12} md={6}>
        <Box pb={2}>
          <CustomInputField
            customInput={Field}
            component={TextField}
            capitalize
            fullWidth
            required
            label={t('tickets.event.eventTitle')}
            name="title"
            variant="outlined"
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box pb={2}>
          <CustomInputField
            customInput={Field}
            component={TextField}
            capitalize
            fullWidth
            label={t('tickets.event.eventTagLine')}
            name="tagLine"
            variant="outlined"
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            required
            disablePast
            format="MMM dd yyyy, hh:mm a"
            sx={{ width: '100%' }}
            slotProps={{
              field: {
                readOnly: true,
                onKeyDown: (e) => e.preventDefault(),
              },
              textField: {
                helperText:
                  touched.startTime && Boolean(errors.startTime)
                    ? touched.startTime && errors.startTime
                    : '',
                error: touched.startTime && Boolean(errors.startTime),
              },
              layout: {
                sx: {
                  '.MuiMultiSectionDigitalClockSection-root': {
                    width: 76,
                  },
                  '.MuiMultiSectionDigitalClockSection-item': {
                    width: 68,
                  },
                },
              },
            }}
            value={startTimeValue}
            onChange={(val) => {
              setFieldValue('startTime', val);
              setTimeout(() => {
                validateField('startTime');
                if (values.endTime) {
                  validateField('endTime');
                }
              });
              setFieldTouched('startTime', true);
            }}
            name="startTime"
            label={`${t('tickets.event.startTime')} *`}
            timeSteps={{ minutes: 1 }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} md={6}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            required
            minDateTime={values.startTime ? new Date(values.startTime) : null}
            disablePast
            format="MMM dd yyyy, hh:mm a"
            sx={{ width: '100%' }}
            slotProps={{
              field: {
                readOnly: true,
                onKeyDown: (e) => e.preventDefault(),
              },
              textField: {
                helperText:
                  touched.endTime && Boolean(errors.endTime)
                    ? touched.endTime && errors.endTime
                    : '',
                error: touched.endTime && Boolean(errors.endTime),
              },
              layout: {
                sx: {
                  '.MuiMultiSectionDigitalClockSection-root': {
                    width: 76,
                  },
                  '.MuiMultiSectionDigitalClockSection-item': {
                    width: 68,
                  },
                },
              },
            }}
            value={endTimeValue}
            onChange={(val) => {
              setFieldValue('endTime', val);
              setTimeout(() => validateField('endTime'));
              setFieldTouched('endTime', true);
            }}
            name="endTime"
            label={`${t('tickets.event.endTime')} *`}
            timeSteps={{ minutes: 1 }}
          />
        </LocalizationProvider>
      </Grid>

      <Grid item xs={12} md={6}>
        <Box pb={2}>
          <Field
            component={FormikTextField}
            fullWidth
            select
            value={values.ticketVenueId}
            label={`${t('tickets.venue.venue')}`}
            name="ticketVenueId"
            variant="outlined"
          >
            <MenuItem value={undefined}>Venue</MenuItem>
            {venues.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Field>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box pb={2}>
          <CkEditor name="description" />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            flexDirection: 'column',
          }}
        >
          <Button
            color="primary"
            variant="contained"
            type="submit"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <CircularProgress size={24} />
            ) : (
              t('saveAndContinue')
            )}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default EventInformationForm;
