/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useRef } from 'react';
import {
  Box,
  Grid,
  Button,
  MenuItem,
  IconButton,
  Typography,
} from '@material-ui/core';
import { TextField, Select } from 'formik-mui';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  ToggleButton,
} from '@mui/material';
import { ToggleButtonGroup } from '@mui/lab';
import { Close } from '@material-ui/icons';
import getSymbolFromCurrency from 'currency-symbol-map';
import { alert, membership, merchant, ticket } from '../../../../state';
import { CustomInputField } from '../../../custom';

const AddTicketDiscountModal = ({
  isOpen,
  closeModal,
  ticketEvent,
  itemSelected,
}) => {
  const membershipPlans = useSelector(membership.selectors.selectPlans);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const openAlert = (payload) => dispatch(alert.actions.open(payload));

  const { currency } = useSelector(merchant.selectors.selectMerchantDetails);
  const currencySymbol = getSymbolFromCurrency(currency || 'USD');
  const amountRef = useRef(null);

  const [loading, setLoading] = React.useState(false);
  const [membershipPlan, setMembershipPlan] = React.useState({});

  useEffect(() => {
    dispatch(membership.actions.getMembershipPlans());
    if (itemSelected.membershipPlanId) {
      setMembershipPlan(
        membershipPlans.find((p) => p.id === itemSelected.membershipPlanId) ||
          {}
      );
    }
  }, [itemSelected]);

  const validationSchema = Yup.object().shape({
    membershipPlanId: Yup.string().required(
      t('membership.validates.planRequired')
    ),
    amount: Yup.number()
      .min(0.00001, t('membership.validates.amountMin'))
      .required(t('validation.required'))
      .when('discountType', {
        is: (discountType) => discountType === 0,
        then: Yup.number()
          .min(0.00001, t('membership.validates.amountMin'))
          .max(100, t('membership.validates.amountMax')),
      }),
  });

  const addDiscountToMembership = async (formData, resetForm) => {
    const ticketEventId = ticketEvent?.id;
    let response = null;
    const data = {
      discount: formData.amount,
      discountType: formData.discountType,
    };

    if (itemSelected?.id) {
      data.id = itemSelected.id;
      response = await dispatch(ticket.actions.editEventsDiscounts(data));
    } else {
      data.ticketEventId = ticketEventId;
      data.membershipPlanId = formData.membershipPlanId;
      response = await dispatch(ticket.actions.setEventsDiscounts(data));
    }

    if (response.payload) {
      openAlert({
        message: t('membership.addDiscountForm.addedSuccessfully'),
        severity: 'success',
      });
      resetForm();
      dispatch(ticket.actions.getEventsDiscountsList(ticketEventId));
      closeModal(true);
    } else if (response.error) {
      openAlert({
        message:
          response.error.message || t('membership.addDiscountForm.failCreate'),

        severity: 'error',
      });
    }
    setLoading(false);
  };

  const body = (
    <Box>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          membershipPlanId: itemSelected.membershipPlanId || '',
          amount: itemSelected.discount || '',
          discountType: itemSelected.discountType || 0,
        }}
        onSubmit={async (values, { resetForm }) => {
          const formData = values;
          addDiscountToMembership(formData, resetForm);
        }}
      >
        {({ values, setFieldValue, handleSubmit }) => {
          const handleSelectPlan = (event, val) => {
            setFieldValue('membershipPlanId', val.props.value);
            setMembershipPlan(
              membershipPlans.find((p) => p.id === val.props.value) || {}
            );
          };

          return (
            <Form>
              <Box mt={2}>
                <Grid container spacing={5}>
                  <Grid item>
                    <Field
                      component={Select}
                      as="select"
                      autoWidth={false}
                      fullWidth
                      required
                      label={t('automaticStoreCredit.selectPlan')}
                      name="membershipPlanId"
                      value={values.membershipPlanId}
                      variant="outlined"
                      onChange={(event, val) => handleSelectPlan(event, val)}
                      style={{ width: 320 }}
                      placeholder={t('automaticStoreCredit.selectPlan')}
                    >
                      <MenuItem value={undefined}>---</MenuItem>
                      {membershipPlans.map(
                        (opt) =>
                          opt && (
                            <MenuItem
                              value={opt.id}
                              key={opt.partnerSubscriptionPlanId}
                            >
                              {opt.name}
                            </MenuItem>
                          )
                      )}
                    </Field>
                  </Grid>
                  <Grid item style={{ position: 'relative' }}>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      value={values.amount}
                      fullWidth
                      type="number"
                      removeArrows
                      name="amount"
                      variant="outlined"
                      required
                      label={t('membership.addDiscountForm.discount')}
                      inputRef={amountRef}
                      style={{ width: 320 }}
                      InputProps={{
                        style: {
                          paddingRight: 150,
                          minWidth: 320,
                          width: '100%',
                        },
                      }}
                    />
                    <ToggleButtonGroup
                      exclusive
                      id="roleType"
                      value={values.discountType}
                      size="small"
                      style={{ position: 'absolute', right: 30, top: 30 }}
                    >
                      <ToggleButton
                        value={0}
                        style={{ padding: '5px 25px' }}
                        onMouseDown={(e) => {
                          e.preventDefault();
                          setFieldValue('discountType', 0);
                          if (document.activeElement !== amountRef.current)
                            amountRef.current.focus();
                        }}
                      >
                        %
                      </ToggleButton>
                      <ToggleButton
                        value={1}
                        style={{ padding: '5px 25px' }}
                        onMouseDown={(e) => {
                          e.preventDefault();
                          setFieldValue('discountType', 1);
                          if (document.activeElement !== amountRef.current)
                            amountRef.current.focus();
                        }}
                      >
                        {currencySymbol}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
              </Box>
              <Box mt={4}>
                <Grid container justifyContent="space-between">
                  <Grid item xs={7}>
                    <Box p={1}>
                      <Typography variant="body2">
                        {membershipPlan?.id && values.amount > 0
                          ? `${membershipPlan.name} members will get ${
                              values.discountType === 0
                                ? `${values.amount}%`
                                : t('cardValue', {
                                    amount: values.amount,
                                    formatParams: {
                                      amount: { currency: currency || 'USD' },
                                    },
                                  })
                            }`
                          : ''}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Button
                      style={{ marginRight: 12 }}
                      onClick={handleSubmit}
                      loading={loading}
                      disabled={loading}
                      variant="contained"
                      color="primary"
                    >
                      {t('save')}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );

  return (
    <Dialog open={isOpen} onClose={closeModal} maxWidth={320}>
      <DialogTitle>{t('membership.discounts.setupDiscounts')}</DialogTitle>
      <DialogContent>{body}</DialogContent>
      <IconButton
        aria-label="clear"
        onClick={closeModal}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          width: 20,
          height: 20,
        }}
      >
        <Close fontSize="small" />
      </IconButton>
    </Dialog>
  );
};

AddTicketDiscountModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  ticketEvent: PropTypes.object.isRequired,
  itemSelected: PropTypes.object.isRequired,
};

export default AddTicketDiscountModal;
