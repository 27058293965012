/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import {
  Box,
  DialogContent,
  DialogContentText,
  Dialog,
  IconButton,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Close } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { coupons } from '../../../state';
import { TransactionTable } from '../../custom';

const CouponListModal = ({ item, onClose }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [params, setParams] = React.useState({ limit: 10, page: 1 });

  const dispatch = useDispatch();
  const [data, setData] = React.useState({ data: [], total: 0 });
  const colsTitles = [
    t('membership.issueCouponsConfig.issueDate'),
    t('membership.issueCouponsConfig.couponCode'),
    t('membership.issueCouponsConfig.expirationDate'),
  ];

  const rowKeys = ['createdAt', 'code', 'expirationDate'];

  const mods = {
    createdAt: (val) => moment(`${val}Z`).format('MMM Do YYYY, HH:mm'),
    expirationDate: (val) =>
      val ? moment(`${val}Z`).format('MMM Do YYYY, HH:mm') : '',
  };

  const getCoupons = async () => {
    if (params.membershipId) {
      setLoading(true);
      const res = await dispatch(coupons.actions.getCouponList(params));
      if (res.payload) {
        setData(res.payload);
      }
      setLoading(false);
    }
  };

  const handleChangePage = async (event, page) => {
    const p = { ...params, page: page + 1 };
    setParams(p);
  };
  const handleChangeRowsPerPage = async (event) => {
    const p = { ...params, limit: event.target.value, page: 1 };
    setParams(p);
  };
  const pagination = {
    total: data?.total || 0,
    pageSize: params.limit,
    page: params.page,
    handleChangePage,
    handleChangeRowsPerPage,
  };

  useEffect(() => {
    getCoupons();
  }, [params]);
  useEffect(() => {
    setParams({ ...params, membershipId: item?.id });
  }, [item]);
  const body = (
    <>
      <Box py={2}>
        <Typography variant="subtitle">
          {t('member')}: {item?.firstName} {item?.lastName}
        </Typography>
        <br />
        <Typography variant="subtitle">
          {t('Id')}: {item?.code}
        </Typography>
      </Box>
      <Box py={2}>
        <TransactionTable
          cols={colsTitles}
          rowKeys={rowKeys}
          data={data?.data || []}
          mods={mods}
          pagination={pagination}
          isLoading={loading}
          noDataMsg={t('noDataMsg')}
        />
      </Box>
    </>
  );

  return (
    <Dialog
      open={!!item?.id}
      onClose={onClose}
      fullWidth
      size="sm"
      scroll="paper"
      aria-labelledby="terms and conditions"
      aria-describedby="terms and conditions"
      BackdropProps={{
        timeout: 500,
      }}
    >
      <DialogContent dividers>
        <DialogContentText tabIndex={-1} component="div">
          {body}
        </DialogContentText>
      </DialogContent>
      <IconButton
        aria-label="clear"
        onClick={onClose}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          width: 20,
          height: 20,
        }}
      >
        <Close fontSize="small" />
      </IconButton>
    </Dialog>
  );
};

CouponListModal.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  item: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CouponListModal;
