import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { Field, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { alert, ticket } from '../../../state';
import { CustomInputField } from '../../custom';

const TicketMisc = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ticketConfig = useSelector(ticket.selectors.selectTicketConfig);
  const validationSchema = () =>
    Yup.object().shape({
      timeToPurchase: Yup.number()
        .required(t('validation.required'))
        .min(0, t('minimumIsZero'))
        .max(30, t('maximumIsThirty')),
    });
  React.useEffect(() => {
    dispatch(ticket.actions.getTicketConfig());
  }, []);
  const openAlert = (payload) => dispatch(alert.actions.open(payload));
  return (
    <Formik
      initialValues={{
        timeToPurchase: ticketConfig.timeToPurchase || 30,
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        const res = await dispatch(
          ticket.actions.setTicketConfig({
            ...ticketConfig,
            ...values,
          })
        );

        if (res.payload) {
          openAlert({
            message: t('accountDetailUpdated'),
            severity: 'success',
          });
        }
        if (res.error) {
          openAlert({
            message: t('somethingWentWrong'),
            severity: 'error',
          });
        }
        await dispatch(ticket.actions.getTicketConfig());
        actions.setSubmitting(false);
      }}
      is
    >
      {({ isSubmitting, handleSubmit }) => (
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          xs={8}
          md={4}
        >
          <Grid item xs={12} md={6}>
            <Typography>
              {t('tickets.timeToPurchase')}
              <br />({t('tickets.inMinutes')})
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomInputField
              customInput={Field}
              component={TextField}
              capitalize
              fullWidth
              type="number"
              removeArrows
              name="timeToPurchase"
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <Button
              onClick={handleSubmit}
              loading={isSubmitting}
              disabled={isSubmitting}
              variant="contained"
              color="primary"
            >
              {t('save')}
            </Button>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};

export default TicketMisc;
