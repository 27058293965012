import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  Button,
  Box,
  DialogContent,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TransactionTable } from '../../custom';

const CsvResultDialog = (props) => {
  const { close, data } = props;
  const { t } = useTranslation();
  const colsTitles = [t('row'), t('email'), t('status'), t('message')];

  const rowKeys = ['row', 'email', 'status', 'message'];
  const coloredText = (val, row) => (
    <Typography color={row.status === 'Failed' ? 'secondary' : 'textPrimary'}>
      {val}
    </Typography>
  );
  const mods = {
    status: coloredText,
    message: coloredText,
  };
  return (
    <Dialog open={!!data.length} onClose={close} maxWidth="lg" fullWidth>
      <DialogContent>
        <TransactionTable
          cols={colsTitles}
          rowKeys={rowKeys}
          data={data}
          mods={mods}
        />
        <Box pt={2} pb={2} justifyContent="center" alignItems="center">
          <Button
            component="button"
            variant="contained"
            color="primary"
            onClick={close}
          >
            {t('ok')}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
CsvResultDialog.propTypes = {
  close: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      row: PropTypes.number,
      email: PropTypes.string,
      status: PropTypes.string,
      message: PropTypes.string,
    })
  ).isRequired,
};

export default CsvResultDialog;
