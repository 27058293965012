/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import {
  ListItemIcon,
  MenuItem,
  Menu,
  ListItemText,
  Box,
  Typography,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FileCopy } from '@material-ui/icons';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'gatsby';

import { useTranslation } from 'react-i18next';
import { merchant, alert } from '../../../state';
import EventsIcon from '../../../images/Tickets/placard.png';
import CalendarIcon from '../../../images/Tickets/schedule.png';

const LandingPageMenu = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const merchantDetails = useSelector((state) =>
    merchant.selectors.selectMerchantDetails(state)
  );
  const pintunaAppUrl =
    process.env.GATSBY_APP_PORTAL_URL || 'https://app.pintuna.com/';
  const calendarUrl = `${pintunaAppUrl}concert-series/${merchantDetails.id}/calendar`;
  const eventsUrl = `${pintunaAppUrl}concert-series/${merchantDetails.id}`;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleCopy = () => {
    const payload = {
      message: t('copiedToClipboard'),
      severity: 'success',
    };
    dispatch(alert.actions.open(payload));
  };

  const useStyles = makeStyles(() => ({
    linkLandingPage: {
      fontSize: '16px',
      textDecoration: 'underline',
      marginRight: 25,
      color: '#105ef0',
      cursor: 'pointer',
    },
  }));
  const classes = useStyles();

  return (
    <>
      <Box
        aria-controls={open ? `landing-page-button` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClickMenu}
        className={classes.linkLandingPage}
      >
        <Typography variant="subtitle1">{t('tickets.landingPages')}</Typography>
      </Box>
      <Menu
        id="event-lading-page-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': `landing-page-button`,
          style: { paddingTop: 0, paddingBottom: 0 },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem>
          <ListItemIcon>
            <img
              src={CalendarIcon}
              alt="calendar-icon"
              style={{ width: 25, height: 25 }}
            />
          </ListItemIcon>
          <ListItemText className={classes.linkLandingPage}>
            <Link to={calendarUrl} target="_blank">
              Calendar View
            </Link>
          </ListItemText>
          <Tooltip title="Copy Link" placement="top" arrow>
            <CopyToClipboard onCopy={handleCopy} text={calendarUrl}>
              <FileCopy cursor="pointer" />
            </CopyToClipboard>
          </Tooltip>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <img
              src={EventsIcon}
              alt="events-icon"
              style={{ width: 25, height: 25 }}
            />
          </ListItemIcon>
          <ListItemText className={classes.linkLandingPage}>
            <Link to={eventsUrl} target="_blank">
              Event View
            </Link>
          </ListItemText>
          <Tooltip title="Copy Link" placement="top" arrow>
            <CopyToClipboard onCopy={handleCopy} text={eventsUrl}>
              <FileCopy cursor="pointer" />
            </CopyToClipboard>
          </Tooltip>
        </MenuItem>
      </Menu>
    </>
  );
};

export default LandingPageMenu;
