/* eslint-disable react/destructuring-assignment,no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@material-ui/core';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import QRCodeDownload from '../../../images/qr-code.png';

const QRCodeDownloadButton = ({ id, value, fileName, format }) => {
  const downloadQR = () => {
    const canvas = document.getElementById(id);
    const pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    const downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = `${fileName}.${format}`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const { t } = useTranslation();

  return (
    <>
      <QRCode
        id={id}
        value={value}
        size={300}
        level="H"
        includeMargin
        style={{ display: 'none' }}
      />
      <Tooltip title={t('loyalty.downloadLinkQrCode')} placement="top" arrow>
        <IconButton
          style={{ padding: 0, width: 30 }}
          onClick={() => downloadQR()}
          cursor="pointer"
        >
          <img src={QRCodeDownload} alt="" style={{ width: 23 }} />
        </IconButton>
      </Tooltip>
    </>
  );
};

QRCodeDownloadButton.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  format: PropTypes.string.isRequired,
};

export default QRCodeDownloadButton;
