import * as Yup from 'yup';
import i18n from 'i18next';

const ticketTypeSchema = Yup.lazy((values) =>
  Yup.object().shape({
    name: values.amount
      ? Yup.string().trim().required(i18n.t('validation.required'))
      : Yup.string().trim(),
    amount: values.name
      ? Yup.number()
          .required('Amount is required')
          .positive('Amount must be positive')
      : Yup.number(),
  })
);
export const discountSchema = Yup.object().shape({
  discountAll: Yup.number()
    .min(0.00001, i18n.t('membership.validates.amountMin'))
    .required(i18n.t('validation.required'))
    .when('discountType', {
      is: (discountType) => discountType === 0,
      then: Yup.number()
        .min(0.00001, i18n.t('membership.validates.amountMin'))
        .max(100, i18n.t('membership.validates.amountMax')),
    }),
});

const TicketValidation = () =>
  Yup.object().shape({
    name: Yup.string()
      .min(2, i18n.t('validation.tooShort'))
      .max(50, i18n.t('validation.tooLong'))
      .required(i18n.t('validation.required')),
    maxNumber: Yup.number().min(0, 'Must be positive').nullable(),
    ticketTypes: Yup.array()
      .of(ticketTypeSchema)
      .min(1, i18n.t('atLeastOneTicketTypeRequired'))
      .test(
        'not-all-blank',
        'At least one ticket type must be provided',
        (value) =>
          value.some((ticketType) =>
            Object.values(ticketType).some(
              (prop) => prop !== undefined && prop !== ''
            )
          )
      ),
    fees: Yup.number().when('hasFees', {
      is: (hasFees) => hasFees === true,
      then: Yup.number()
        .required(i18n.t('validation.required'))
        .min(0.0001, i18n.t('validation.required')),
    }),
    feesHelperText: Yup.string(),
  });

export const TicketEventValidation = Yup.object().shape({
  title: Yup.string().required(i18n.t('validation.required')),
  tagLine: Yup.string().optional(),
  startTime: Yup.string().required(i18n.t('validation.required')),
  endTime: Yup.date()
    .required('End date is required')
    .when('startTime', (startDate, schema) => {
      return startDate
        ? schema.min(startDate, 'End date must be later than start date')
        : schema;
    }),
  ticketVenueId: Yup.string().required(i18n.t('validation.required')),
  description: Yup.string(),
  ticketFees: Yup.number().when(['formTab', 'hasFees'], {
    is: (formTab, hasFees) => formTab >= 1 && hasFees === '1',
    then: Yup.number()
      .required(i18n.t('validation.required'))
      .min(0.0001, i18n.t('validation.required')),
  }),
  feesHelperText: Yup.string().optional(),
  discountAll: Yup.number().when(
    ['discountType', 'formTab', 'useDiscountAllMembership'],
    {
      is: (discountType, formTab, useDiscountAllMembership) =>
        discountType === 0 &&
        formTab >= 4 &&
        useDiscountAllMembership === 'true',
      then: Yup.number()
        .min(0.00001, i18n.t('membership.validates.amountMin'))
        .max(100, i18n.t('membership.validates.amountMax'))
        .required(i18n.t('validation.required')),
    }
  ),
  fees: Yup.number().when(['formTab', 'serviceFee'], {
    is: (formTab, serviceFee) => formTab >= 4 && serviceFee === 'true',
    then: Yup.number()
      .min(0.00001, i18n.t('tickets.messages.amountFeeMin'))
      .max(100, i18n.t('tickets.messages.amountFeeMax'))
      .required(i18n.t('validation.required')),
  }),
  tax: Yup.number().when(['formTab', 'taxService'], {
    is: (formTab, taxService) => formTab >= 4 && taxService === 'true',
    then: Yup.number()
      .min(0.00001, i18n.t('tickets.messages.amountTaxMin'))
      .max(100, i18n.t('tickets.messages.amountTaxMax'))
      .required(i18n.t('validation.required')),
  }),
});

export const TicketEventRepeatValidation = Yup.object().shape({
  startDate: Yup.date().nullable().required(i18n.t('validation.required')),
  endDate: Yup.date()
    .nullable()
    .required('End date is required')
    .when('startTime', (startDate, schema) => {
      return startDate
        ? schema.min(startDate, 'End date must be later than start date')
        : schema;
    }),
  times: Yup.number().max(50, 'Max number of repeat times is 50'),
  startTime: Yup.date().nullable().required(i18n.t('validation.required')),
  endTime: Yup.date().nullable().required(i18n.t('validation.required')),
});

export default TicketValidation;
