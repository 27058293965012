/* eslint-disable react/forbid-prop-types,react/require-default-props */
import { Box, Button, Grid, IconButton, MenuItem } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { ToggleButtonGroup } from '@mui/lab';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  ToggleButton,
} from '@mui/material';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Field, Form, Formik } from 'formik';
import { Select, TextField } from 'formik-mui';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { alert, coupons, loyalty, merchant } from '../../../state';
import { CustomInputField } from '../../custom';
import { TARGET_TYPE } from '../../../utils/constants';

const AddDefineCouponForm = ({
  isOpen,
  closeModal,
  categories,
  selected,
  onReload,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const refDiscount = useRef(null);
  const refForm = useRef(null);

  const openAlert = (payload) => dispatch(alert.actions.open(payload));

  const { currency } = useSelector(merchant.selectors.selectMerchantDetails);
  const isLoadingLoyalty = useSelector(loyalty.selectors.selectIsLoading);
  const currencySymbol = getSymbolFromCurrency(currency);

  const [items, setItems] = React.useState([]);
  const [isLoading1, setLoading] = React.useState(false);
  const [discountItem, setDiscountItem] = React.useState('Whole Purchase');
  const getItems = async () => {
    const res = await dispatch(loyalty.actions.getItems());
    if (res.payload) {
      setItems(res.payload);
    }
  };
  useEffect(() => {
    getItems();
  }, []);

  const onSelectedCategory = async (event, val) => {
    refForm.current?.setFieldValue('targetScopeId', val.props.value);
    refForm.current?.setFieldValue(
      'targetType',
      val.props.value ? TARGET_TYPE.Category : TARGET_TYPE.WholePurchase
    );
    refForm.current?.setFieldValue('itemId', '');
    setDiscountItem(categories.find((p) => p.id === val.props.value)?.name);
    const resp = await dispatch(
      loyalty.actions.getItemsByCategory(val.props.value)
    );
    setItems(resp.payload);
  };

  const onSelectedItem = (event, val) => {
    refForm.current?.setFieldValue('targetScopeId', val.props.value);
    refForm.current?.setFieldValue(
      'targetType',
      val.props.value ? TARGET_TYPE.Item : TARGET_TYPE.Category
    );
    // refForm.current?.setFieldValue('categoryId', '');
    setDiscountItem(
      items?.find((p) => p.firstVariantId === val.props.value)?.name
    );
  };

  const isLoading = isLoading1 || isLoadingLoyalty;

  const body = (
    <Box>
      <Formik
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .required(t('validation.required'))
            .min(2, t('validation.tooShort'))
            .max(50, t('validation.tooLong')),
          amount: Yup.number()
            .required(t('validation.required'))
            .min(0, 'Required'), // .max(50, i18n.t('validation.tooLong')),
          targetScopeId: Yup.string(),
        })}
        initialValues={{
          name: '',
          discount: '',
          amount: '',
          discountType: 0,
          targetScopeId: '',
          categoryId: '',
          itemId: '',
          targetType: TARGET_TYPE.WholePurchase,
          description: '',
          ...selected,
        }}
        onSubmit={async (values, { resetForm }) => {
          const formData = values;
          setLoading(true);
          try {
            formData.discount = `${
              values.amount + (values.discountType === 0 ? '%' : currencySymbol)
            } off ${discountItem || 'Whole Purchase'}`;
            // eslint-disable-next-line no-nested-ternary
            formData.targetType = values.itemId
              ? TARGET_TYPE.Item
              : values.categoryId
              ? TARGET_TYPE.Category
              : TARGET_TYPE.WholePurchase;
            formData.targetScopeId = values.itemId || values.categoryId;

            const resp = await dispatch(
              coupons.actions.createCouponPlan({
                ...formData,
                ...(selected && { id: selected.id }),
              })
            );

            // eslint-disable-next-line no-nested-ternary

            if (resp.payload) {
              openAlert({
                message: t('coupons.couponCreatedSuccessfully'),
                severity: 'success',
              });
              resetForm();
              if (onReload) onReload();
              closeModal(true);
            } else if (resp.error) {
              openAlert({
                message: resp.error.message || t('coupons.couponSaveError'),
                severity: 'error',
              });
            }
          } catch (error) {
            console.error(error);
          } finally {
            setLoading(false);
          }
          return true;
        }}
      >
        {(form) => {
          const { values, setFieldValue, handleSubmit } = form;
          refForm.current = form;
          return (
            <Form>
              <Box mt={2}>
                <Grid container spacing={5}>
                  <Grid item>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      fullWidth
                      type="text"
                      removeArrows
                      name="name"
                      variant="outlined"
                      required
                      label={t('coupons.name')}
                      style={{ width: 320 }}
                      InputProps={{
                        style: {
                          paddingRight: 150,
                          minWidth: 320,
                          width: '100%',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item style={{ position: 'relative' }}>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      fullWidth
                      type="number"
                      removeArrows
                      name="amount"
                      variant="outlined"
                      required
                      label={t('coupons.discount')}
                      inputRef={refDiscount}
                      style={{ width: 320 }}
                      InputProps={{
                        style: {
                          paddingRight: 150,
                          minWidth: 320,
                          width: '100%',
                        },
                      }}
                    />
                    <ToggleButtonGroup
                      exclusive
                      value={values.discountType}
                      size="small"
                      style={{ position: 'absolute', right: 30, top: 30 }}
                    >
                      <ToggleButton
                        value={0}
                        style={{ padding: '5px 25px' }}
                        onMouseDown={(e) => {
                          e.preventDefault();
                          setFieldValue('discountType', 0);
                          if (document.activeElement !== refDiscount.current)
                            refDiscount.current.focus();
                        }}
                      >
                        %
                      </ToggleButton>
                      <ToggleButton
                        value={1}
                        style={{ padding: '5px 25px' }}
                        onMouseDown={(e) => {
                          e.preventDefault();
                          setFieldValue('discountType', 1);
                          if (document.activeElement !== refDiscount.current)
                            refDiscount.current.focus();
                        }}
                      >
                        {currencySymbol}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                  <Grid item>
                    <Field
                      component={Select}
                      as="select"
                      autoWidth={false}
                      fullWidth
                      required
                      label={t('coupons.category')}
                      name="categoryId"
                      variant="outlined"
                      onChange={onSelectedCategory}
                      style={{ width: 320 }}
                      placeholder="Category"
                    >
                      <MenuItem value={undefined}>---</MenuItem>
                      {categories.map(
                        (e) =>
                          e && (
                            <MenuItem value={e.id} key={e.id}>
                              {e.name}
                            </MenuItem>
                          )
                      )}
                    </Field>
                  </Grid>
                  <Grid item>
                    <Field
                      component={Select}
                      as="select"
                      autoWidth={false}
                      fullWidth
                      required
                      label={t('coupons.item')}
                      name="itemId"
                      variant="outlined"
                      onChange={onSelectedItem}
                      style={{ width: 320 }}
                      placeholder="Item"
                      disabled={isLoadingLoyalty}
                    >
                      <MenuItem value={undefined}>---</MenuItem>
                      {items?.map(
                        (e) =>
                          e && (
                            <MenuItem
                              value={e.firstVariantId}
                              key={e.firstVariantId}
                            >
                              {e.name}
                            </MenuItem>
                          )
                      )}
                    </Field>
                  </Grid>
                </Grid>
              </Box>
              <Box my={3}>
                <Grid container justifyContent="space-between">
                  <Grid item xs={7}>
                    <Box
                      style={{ border: '1px solid #ccc', minHeight: 38 }}
                      p={1}
                    >
                      {values.amount > 0
                        ? `Members will get ${
                            values.discountType === 0
                              ? `${values.amount}%`
                              : t('cardValue', {
                                  amount: values.amount,
                                  formatParams: {
                                    amount: { currency },
                                  },
                                })
                          } off ${discountItem || 'Whole Purchase'}`
                        : ''}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box mt={2}>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Button
                      style={{ marginRight: 12 }}
                      onClick={handleSubmit}
                      loading={isLoading}
                      disabled={isLoading}
                      variant="contained"
                      color="primary"
                    >
                      {t('save')}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );

  return (
    <Dialog open={isOpen} onClose={closeModal} fullWidth maxWidth="md">
      <DialogTitle>{t('coupons.createNewDiscount')}</DialogTitle>
      <DialogContent>{body}</DialogContent>
      <IconButton
        aria-label="clear"
        onClick={closeModal}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          width: 20,
          height: 20,
        }}
      >
        <Close fontSize="small" />
      </IconButton>
    </Dialog>
  );
};

AddDefineCouponForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  categories: PropTypes.array.isRequired,
  // items: PropTypes.array.isRequired,
  selected: PropTypes.object,
  onReload: PropTypes.func,
};

export default AddDefineCouponForm;
