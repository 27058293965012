import React from 'react';
import { Box, Grid, Typography, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton, Stack } from '@mui/material';
import moment from 'moment';
import { useFormikContext } from 'formik';
import TicketPricingList from './TicketPricingList';
import { alert, ticket } from '../../../state';
import TicketMembershipDiscounts from './TicketMembershipDiscounts';
import ConfirmationDialog from '../GiftCardDetails/ConfirmDialog';

const TicketPublish = () => {
  const { t } = useTranslation();
  const {
    values,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useFormikContext();
  const dispatch = useDispatch();
  const activeTicketEvent = useSelector(ticket.selectors.selectTicketEvent);
  const [params] = React.useState({
    limit: 10,
    page: 1,
    ticketEventId: activeTicketEvent?.id,
  });
  const { data: venues } = useSelector(ticket.selectors.selectTicketVenues);
  const openAlert = (payload) => dispatch(alert.actions.open(payload));

  React.useEffect(() => {
    if (activeTicketEvent?.id) {
      dispatch(ticket.actions.getTicketPricing(params));
    }
  }, [params]);
  const { data: ticketList } = useSelector(
    ticket.selectors.selectTicketPricing
  );
  const [isConfirm, setIsConfirm] = React.useState(false);

  const getVenues = () => {
    const venuesData = venues.filter(
      (item) => item.id === values.ticketVenueId
    )[0];
    return !venuesData?.name
      ? ''
      : `${venuesData?.name}, ${venuesData?.address}, ${venuesData?.city}, ${venuesData?.state}, ${venuesData?.zipcode}`;
  };

  const publishSubmit = (status) => {
    if (ticketList.length === 0) {
      openAlert({
        message: t('tickets.publish.ticketPricingRequired'),
        severity: 'error',
      });
      return;
    }
    if (!values.imageUrl) {
      openAlert({
        message: t('tickets.publish.ticketImageRequired'),
        severity: 'error',
      });
      return;
    }
    setIsConfirm(false);
    setFieldValue('status', status);
    handleSubmit();
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Typography variant="subtitle1">
            {t('tickets.event.eventTitle')}: {values.title}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {t('tickets.publish.eventTagLine')}: {values.tagLine}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {t('tickets.publish.eventStartDate')}:{' '}
            {moment(`${values.startTime}`).format('MMM DD YYYY, HH:mm:ss')}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {t('tickets.publish.eventEndDate')}:{' '}
            {moment(`${values.endTime}`).format('MMM DD YYYY, HH:mm:ss')}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {t('tickets.publish.venueName')}: {getVenues()}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {t('tickets.publish.eventDescription')}:
          </Typography>
          <Box>
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: values.description }}
            />
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box>
            {!values.imageUrl ? (
              <Skeleton variant="rect" style={{ width: 560, height: 350 }} />
            ) : (
              <img
                width={560}
                src={values.imageUrl}
                alt={t('tickets.publish.ticketImage')}
              />
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Typography variant="subtitle1" gutterBottom>
            {t('tickets.publish.ticketPricing')}:
          </Typography>
          <Box>
            <TicketPricingList isEdit={false} />
          </Box>
        </Grid>
        <Grid item md={12}>
          <Typography variant="subtitle1" gutterBottom>
            {t('tickets.publish.additionalCost')}: Tax = {values.tax}%, Service
            Fees = {values.fees}%
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {t('tickets.publish.memberDiscount')}:{' '}
            {values.useDiscountAllMembership === 'true'
              ? `Discount All Plans: ${Number(values.discountAll).toFixed(2)} %`
              : 'Specific Plans'}
          </Typography>
          <Box>
            <TicketMembershipDiscounts isEdit />
          </Box>
        </Grid>
        <Grid item md={12}>
          <Stack spacing={2} direction="row">
            <Button
              disabled={false}
              variant="contained"
              color="primary"
              mr={10}
            >
              {t('tickets.publish.preview')}
            </Button>
            <Button
              disabled={isSubmitting}
              variant="contained"
              onClick={() => setIsConfirm(true)}
              style={{ backgroundColor: '#4caf50', color: '#fff' }}
            >
              {values.status !== 'Published'
                ? t('tickets.publish.publish')
                : t('tickets.publish.unPublish')}
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <ConfirmationDialog
        open={isConfirm}
        setOpen={setIsConfirm}
        title={t('confirmation')}
        content={
          values.status === 'Published'
            ? t('unPublishConfirmation')
            : t('publishConfirmation')
        }
        actionOk={() => publishSubmit(values.status === 'Published' ? 0 : 1)}
      />
    </Box>
  );
};

export default TicketPublish;
