/* eslint-disable react/forbid-prop-types */
import React from 'react';
import {
  Box,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { membership, alert } from '../../../state';
import { CustomInputField } from '../../custom';
import CountrySelect from '../../custom/PhoneInput';
import i18n from '../../../i18n';

const AddSecondaryMemberForm = ({
  primaryMembership = null,
  onComplete,
  secondaryMembershipCount,
}) => {
  const membershipConfig = useSelector(
    membership.selectors.selectMembershipConfig
  );
  const [loading, setLoading] = React.useState(false);
  const [phone, setPhone] = React.useState('');
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const openAlert = (payload) => dispatch(alert.actions.open(payload));

  const phoneRegExp = /^\(*\+*[1-9]{0,3}\)*-*[1-9]{0,3}[-. /]*\(*[2-9]\d{2}\)*[-. /]*\d{3}[-. /]*\d{4} *e*x*t*\.* *\d{0,4}$/;
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, i18n.t('validation.tooShort'))
      .max(50, i18n.t('validation.tooLong'))
      .required(i18n.t('validation.required')),
    lastName: Yup.string()
      .min(2, i18n.t('validation.tooShort'))
      .max(50, i18n.t('validation.tooLong'))
      .required(i18n.t('validation.required')),
    email: Yup.string()
      .email(i18n.t('invalidEmail'))
      .required(i18n.t('validation.required')),
    localPhone: Yup.string()
      .matches(/^\d+$/, i18n.t('validation.invalidNumber'))
      .matches(phoneRegExp, t('validation.invalidPhoneNumber'))
      .required(i18n.t('validation.required')),
    countryCode: Yup.string().required(i18n.t('validation.required')),
    memberCard: Yup.string().when('isPhysical', {
      is: (isPhysical) => isPhysical === 2,
      then: Yup.string().required(i18n.t('required')),
    }),
  });

  const handlePhoneChange = (value) => {
    setPhone(value);
  };

  const createMemberShip = async (formData, resetForm) => {
    const body = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phone,
      primaryMembershipId: primaryMembership?.id,
      code: formData.memberCard,
    };
    const response = await dispatch(
      membership.actions.addSecondaryMemberShip(body)
    );
    if (response.payload) {
      setTimeout(() => {
        openAlert({
          message: t('membership.secondaryMembers.addedSuccessfully'),
          severity: 'success',
        });
        setLoading(false);
        resetForm();
        onComplete();
      }, 2000);
    } else {
      openAlert({
        message:
          response.error?.message ||
          response.error ||
          t('membership.membershipList.failCreate'),
        severity: 'error',
      });
      setLoading(false);
    }
  };

  const onSubmitMemberShip = async (formData, resetForm) => {
    await createMemberShip(formData, resetForm);
  };

  const body = (
    <Box>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={{
          isPhysical: membershipConfig.numberType,
          code: '',
          memberCard: '',
          countryCode: 'US',
          email: '',
          firstName: '',
          lastName: '',
          localPhone: '',
          primaryMembershipId: primaryMembership?.id,
        }}
        onSubmit={async (values, { resetForm }) => {
          const formData = values;
          setLoading(true);
          try {
            await onSubmitMemberShip(formData, resetForm);
          } catch (error) {
            console.log(error);
          }
          return true;
        }}
      >
        {({ values, handleSubmit }) => {
          return (
            <Form>
              <Grid container spacing={2} md={10}>
                {values.isPhysical === 2 && (
                  <>
                    <Grid item sm={6}>
                      <FormControlLabel
                        value="true"
                        control={
                          <Checkbox
                            color="primary"
                            name="isPhysical"
                            checked={values.isPhysical === 2}
                            disabled
                          />
                        }
                        label={t('membership.membershipConfig.physical')}
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        fullWidth
                        disabled={values.isPhysical !== 2}
                        required
                        label={`${t(
                          'membership.membershipList.enterMember'
                        )} #`}
                        name="memberCard"
                        variant="outlined"
                      />
                    </Grid>
                  </>
                )}
                <Grid item sm={6}>
                  <CustomInputField
                    customInput={Field}
                    component={TextField}
                    fullWidth
                    required
                    label={t('membership.membershipList.firstName')}
                    name="firstName"
                    variant="outlined"
                  />
                </Grid>
                <Grid item sm={6}>
                  <CustomInputField
                    customInput={Field}
                    component={TextField}
                    fullWidth
                    required
                    label={t('membership.membershipList.lastName')}
                    name="lastName"
                    variant="outlined"
                  />
                </Grid>
                <Grid item sm={6}>
                  <CountrySelect
                    value={values.countryCode}
                    onChange={handlePhoneChange}
                  />
                </Grid>
                <Grid item sm={6}>
                  <CustomInputField
                    customInput={Field}
                    component={TextField}
                    trim
                    fullWidth
                    required
                    type="email"
                    label={t('email')}
                    name="email"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Button
                    style={{ marginRight: 12 }}
                    onClick={handleSubmit}
                    disabled={
                      loading ||
                      primaryMembership?.plan?.allowedSecondaryMembers <=
                        secondaryMembershipCount
                    }
                    variant="contained"
                    color="primary"
                  >
                    {t('add')}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );

  return body;
};

AddSecondaryMemberForm.propTypes = {
  primaryMembershipId: PropTypes.object,
  onComplete: PropTypes.func.isRequired,
};

AddSecondaryMemberForm.defaultProps = {
  primaryMembershipId: null,
};
export default AddSecondaryMemberForm;
