import React, { lazy, Suspense } from 'react';
import { CircularProgress } from '@material-ui/core';

const LazyCKEditorFormik = lazy(() => import('./CkEditor'));

const LazyCkEditor = (props) => (
  <Suspense fallback={<CircularProgress />}>
    <LazyCKEditorFormik {...props} />
  </Suspense>
);

export default LazyCkEditor;
