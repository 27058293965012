import React from 'react';
import {
  Box,
  Grid,
  Button,
  CircularProgress,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import DatePicker from '@mui/lab/DatePicker';
import { Field, Formik, Form } from 'formik';
import { Checkbox, TextField, RadioGroup } from 'formik-material-ui';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import getSymbolFromCurrency from 'currency-symbol-map';

import api from '../../../state/storeCredit/api';
import SEO from '../../seo';
import { CustomInputField } from '../../custom';
import { alert, merchant } from '../../../state';

export default () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const validationSchema = (currencySymbol) =>
    Yup.object().shape({
      firstName: Yup.string().when('isPhysical', {
        is: '0',
        then: Yup.string().required(t('storeCredit.validates.required')),
      }),
      lastName: Yup.string().when('isPhysical', {
        is: '0',
        then: Yup.string().required(t('storeCredit.validates.required')),
      }),
      email: Yup.string().when('isPhysical', {
        is: '0',
        then: Yup.string()
          .required(t('storeCredit.validates.required'))
          .email(t('storeCredit.validates.email')),
      }),
      amount: Yup.number()
        .required(t('storeCredit.validates.required'))
        .min(0, t('storeCredit.validates.amountMin', { currencySymbol }))
        .max(2000, t('storeCredit.validates.amountMax', { currencySymbol })),
      code: Yup.string().when('isPhysical', {
        is: '1',
        then: Yup.string().required(t('storeCredit.validates.required')),
      }),
    });

  const validateExpirationDate = (val) => {
    if (!val) {
      // Return null if the value is blank
      return null;
    }

    if (
      !moment(val, 'YYYY-MM-DD').isValid() ||
      moment(val, 'YYYY-MM-DD').isBefore(moment(), 'date')
    ) {
      return t('invalidDate');
    }

    return null;
  };

  const currency = useSelector(merchant.selectors.selectCurrency);
  const currencySymbol = getSymbolFromCurrency(currency);
  const defaultForm = {
    expirationDate: '',
    firstName: '',
    lastName: '',
    sendConfirmationEmail: true,
    email: '',
    amount: '',
    code: '',
  };
  const openAlert = (payload) => dispatch(alert.actions.open(payload));

  const onSubmit = async (values, formikHelper) => {
    formikHelper.setSubmitting(true);
    try {
      await api.issueStoreCredit({
        ...values,
        isPhysical: values.isPhysical === '1',
        expirationDate:
          values.expirationDate &&
          moment(values.expirationDate, 'YYYY-MM-DD').isValid()
            ? moment(values.expirationDate, 'YYYY-MM-DD').format('MM/DD/YYYY')
            : null,
      });
      openAlert({
        message: t('storeCredit.messages.issueCardUpdateSuccess'),
        severity: 'success',
      });
      formikHelper.setSubmitting(false);
      formikHelper.setValues({
        isPhysical: values.isPhysical,
        ...defaultForm,
      });
    } catch (error) {
      openAlert({
        message: error.errors || error.toString() || t('updateGiftCardFailed'),
        severity: 'error',
      });
      formikHelper.setSubmitting(false);
    }
  };

  return (
    <>
      <SEO title={t('storeCredit.detailIssueECard')} />
      <Formik
        validationSchema={() => validationSchema(currencySymbol)}
        onSubmit={onSubmit}
        initialValues={{
          isPhysical: '0',
          ...defaultForm,
        }}
      >
        {({
          isSubmitting,
          values,
          setFieldValue,
          handleSubmit,
          setFieldTouched,
          errors,
          touched,
        }) => {
          const { isPhysical } = values;

          const clearCardValue = () => {
            setFieldValue('code', '');
          };
          const clearNameValue = () => {
            setFieldValue('firstName', '');
            setFieldValue('lastName', '');
            setFieldValue('email', '');
          };
          return (
            <Form>
              <Grid container spacing={2}>
                <Grid mb={2} container md={3} xs={12}>
                  <Field component={RadioGroup} name="isPhysical">
                    <Grid container md={12} xs={12}>
                      <Grid item xs={6} md={6}>
                        <Box display="flex" alignItems="center">
                          <Box ml={1}>
                            <FormControlLabel
                              value="0"
                              control={
                                <Radio
                                  color="primary"
                                  disabled={isSubmitting}
                                />
                              }
                              label={t('storeCredit.digital')}
                              disabled={isSubmitting}
                              onChange={clearCardValue}
                            />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <Box display="flex" alignItems="center" ml={2}>
                          <Box mr={1}>
                            <FormControlLabel
                              value="1"
                              control={
                                <Radio
                                  color="primary"
                                  disabled={isSubmitting}
                                />
                              }
                              onChange={clearNameValue}
                              label={t('storeCredit.physical')}
                              disabled={isSubmitting}
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Field>
                </Grid>

                <Grid item md={3} xs={12}>
                  <CustomInputField
                    customInput={Field}
                    component={TextField}
                    trim
                    fullWidth
                    InputProps={{
                      disabled: isPhysical !== '1',
                    }}
                    required
                    label={t('storeCredit.enterScanCard')}
                    autoComplete="off"
                    name="code"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <Box display="flex" alignItems="center" ml={2}>
                    <Box mr={1}>
                      <FormControlLabel
                        label={t('storeCredit.sendConfirmationEmail')}
                        control={
                          <Field
                            component={Checkbox}
                            color="primary"
                            type="checkbox"
                            name="sendConfirmationEmail"
                            // required
                            checked={values.sendConfirmationEmail}
                            value={values.sendConfirmationEmail}
                            onKeyPress={() =>
                              setFieldTouched('sendConfirmationEmail')
                            }
                          />
                        }
                      />
                      {errors.sendConfirmationEmail &&
                        touched.sendConfirmationEmail && (
                          <div style={{ color: '#f44336', fontSize: 12 }}>
                            {errors.sendConfirmationEmail}
                          </div>
                        )}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Box mb={1} />
              <Grid container spacing={2}>
                <Grid item xs={6} md={3}>
                  <Box mt={2}>
                    <CustomInputField
                      startAdornment={currencySymbol}
                      customInput={Field}
                      component={TextField}
                      trim
                      fullWidth
                      required
                      type="number"
                      label={t('storeCredit.inputAmount')}
                      autoComplete="off"
                      name="amount"
                      variant="outlined"
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Box mt={2} mb={1}>
                    <DatePicker
                      inputFormat="YYYY-MM-DD"
                      format="YYYY-MM-DD"
                      mask="____-__-__"
                      defaultValue={null}
                      disablePast
                      required={false}
                      renderInput={(params) => {
                        return (
                          <Field
                            component={TextField}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label={t('loyalty.expirationDate')}
                            name="expirationDate"
                            validate={validateExpirationDate}
                            variant="outlined"
                            {...params}
                            error={
                              (params.error &&
                                params.inputProps?.value !== '') ||
                              errors.expirationDate
                            }
                          />
                        );
                      }}
                      value={
                        values.expirationDate
                          ? moment(values.expirationDate, 'YYYY-MM-DD')
                          : null
                      }
                      onChange={(val) => {
                        setFieldValue(
                          'expirationDate',
                          val ? val.format('YYYY-MM-DD') : ''
                        );
                        setFieldTouched('expirationDate');
                      }}
                      name="expirationDate"
                      label={t('loyalty.expirationDate')}
                      onKeyPress={() => setFieldTouched('expirationDate')}
                    />
                  </Box>
                </Grid>
              </Grid>
              <p>{values.expirationDate}</p>
              <Grid container spacing={2}>
                <Grid item xs={6} md={3}>
                  <Field
                    component={TextField}
                    trim
                    fullWidth
                    required
                    label={t('firstName')}
                    autoComplete="off"
                    name="firstName"
                    variant="outlined"
                    disabled={isPhysical !== '0'}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <Field
                    component={TextField}
                    trim
                    fullWidth
                    required
                    label={t('lastName')}
                    autoComplete="off"
                    name="lastName"
                    disabled={isPhysical !== '0'}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={12} md={6}>
                  <CustomInputField
                    customInput={Field}
                    component={TextField}
                    trim
                    fullWidth
                    required
                    label={t('email')}
                    autoComplete="off"
                    name="email"
                    disabled={isPhysical !== '0'}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <CircularProgress size={24} />
                    ) : (
                      t('storeCredit.btnIssueStoreCredit')
                    )}
                  </Button>
                </Box>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
