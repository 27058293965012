import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  Radio,
  Typography,
} from '@material-ui/core';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { RadioGroup } from 'formik-material-ui';
import { coupons, merchant } from '../../../state';
import { PartnerEnum } from '../../../utils/constants';
import CardSection from '../GiftCardDetails/CardSection';

const CouponConfig = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { values, handleSubmit, isSubmitting } = useFormikContext();

  const couponConfig = useSelector(coupons.selectors.selectCouponConfig);
  const isLoading = useSelector(coupons.selectors.selectIsLoading);
  const imgIsLoading = useSelector(coupons.selectors.selectImgIsLoading);
  const setImageCouponConfig = (payload) =>
    dispatch(coupons.actions.setImageCouponConfig(payload));

  const merchantDetails = useSelector(merchant.selectors.selectMerchantDetails);
  const { partner = '' } = merchantDetails;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box mb={2}>
            <CardSection
              isLoading={isLoading}
              imgIsLoading={imgIsLoading}
              merchantDetails={couponConfig}
              merchantCreatives={couponConfig}
              setGiftCardImage={setImageCouponConfig}
              type="storeCredit"
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container item>
            <Grid item xs={4}>
              <Typography
                variant="subtitle1"
                style={{ padding: 7, marginRight: 5 }}
              >
                {' '}
                {t('coupons.couponType')}:{' '}
              </Typography>
            </Grid>
            <Field component={RadioGroup} name="type" row label="fdfd">
              <Grid item>
                <FormControlLabel
                  value="true"
                  control={
                    <Radio
                      value={0}
                      color="primary"
                      name="codeType"
                      checked={values.codeType === '0'}
                    />
                  }
                  label="QR Code"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  value="false"
                  control={
                    <Radio
                      value={1}
                      color="primary"
                      name="codeType"
                      checked={values.codeType === '1'}
                    />
                  }
                  label="Bar Code"
                />
              </Grid>
            </Field>
          </Grid>
          {partner === PartnerEnum.Square &&
          partner === PartnerEnum.Clover && ( // hidden
              <Grid container item>
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    style={{ padding: 7, marginRight: 5 }}
                  >
                    {' '}
                    {t('coupons.squareCompatibleScanner')}:{' '}
                  </Typography>
                </Grid>
                <Field
                  component={RadioGroup}
                  name="isSquareCompatibleScanner"
                  row
                >
                  <Grid item>
                    <FormControlLabel
                      value="true"
                      control={
                        <Radio
                          value={1}
                          color="primary"
                          name="isSquareCompatibleScanner"
                          checked={values.isSquareCompatibleScanner === '1'}
                        />
                      }
                      label={t('yes')}
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      value="false"
                      control={
                        <Radio
                          value={0}
                          color="primary"
                          name="isSquareCompatibleScanner"
                          checked={values.isSquareCompatibleScanner === '0'}
                        />
                      }
                      label={t('no')}
                    />
                  </Grid>
                </Field>
              </Grid>
            )}
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid item xs={12} md={6}>
            <Box mb={2}>
              <Field
                component={TextField}
                fullWidth
                multiline
                rows={4}
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                label={t('termsAndConditions')}
                name="terms"
                variant="outlined"
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          type="submit"
          fullWidth
          disabled={isSubmitting}
        >
          {isSubmitting ? <CircularProgress size={24} /> : t('saveChanges')}
        </Button>
      </Grid>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getMerchantDetails: (payload) =>
    dispatch(merchant.actions.getMerchantDetails(payload)),
});

export default connect(null, mapDispatchToProps)(CouponConfig);
