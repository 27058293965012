/* eslint-disable react/forbid-prop-types,react/require-default-props */
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { RadioGroup } from 'formik-material-ui';
import { Select, TextField } from 'formik-mui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { alert, coupons } from '../../../state';
import { CustomInputField } from '../../custom';

const useStyles = makeStyles(() => ({
  fullwidthChild: {
    '& > div': {
      width: '100%',
      minWidth: '300px',
    },
  },
}));
const SingleCouponBox = ({ setCouponCode }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const couponPlans = useSelector(coupons.selectors.selectCouponPlans);
  const openAlert = (payload) => dispatch(alert.actions.open(payload));
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(coupons.actions.getCouponPlanList());
  }, []);
  return (
    <Box
      p={3}
      style={{
        border: '1px solid #ccc',
        borderRadius: 10,
        position: 'relative',
      }}
      mb={5}
    >
      <Typography
        style={{
          position: 'absolute',
          top: -22,
          left: 30,
          background: '#fff',
          padding: 6,
          fontSize: 18,
          fontWeight: 'bold',
        }}
      >
        {t('coupons.step1GenerateCouponCode')}
      </Typography>
      <Formik
        validationSchema={Yup.object().shape({
          couponPlanId: Yup.string().required(t('validation.required')),
          maxRedemptions: Yup.number()
            .required(t('validation.required'))
            .min(0, t('minimumIsZero')),
          expirationDate: Yup.string().required(t('validation.required')),
          discountDescription: Yup.string(),
          description: Yup.string().required(t('validation.required')),
          code: Yup.string().when('generateCodeType', {
            is: (val) => val === '1' || val === 1,
            then: Yup.string()
              .required(t('validation.required'))
              .min(6, t('coupons.uniqueCouponCode', { digits: 6 }))
              .max(6, t('coupons.uniqueCouponCode', { digits: 6 })),
          }),
        })}
        initialValues={{
          couponPlanId: '',
          description: '',
          maxRedemptions: '',
          generateCodeType: '0',
        }}
        onSubmit={async (values, { setFieldValue }) => {
          setIsLoading(true);
          try {
            const resp = await dispatch(
              coupons.actions.createSingleCoupon({
                ...values,
                maxRedemptions:
                  values.maxRedemptions === '' ? '-1' : values.maxRedemptions,
              })
            );
            if (resp.payload) {
              openAlert({
                message: t('coupons.couponCodeGeneratedSuccessfully'),
                severity: 'success',
              });
              setFieldValue('code', resp.payload?.code);
              if (setCouponCode) setCouponCode(resp.payload);
            } else if (resp.error) {
              openAlert({
                message:
                  resp.error.message || t('coupons.couponCodeGenerationFailed'),
                severity: 'error',
              });
            }
          } catch (error) {
            console.log(error);
          } finally {
            setIsLoading(false);
          }
          return true;
        }}
      >
        {({
          values,
          setFieldValue,
          handleSubmit,
          setFieldTouched,
          errors,
          touched,
        }) => {
          return (
            <Form>
              <Box mt={2}>
                <Grid container alignItems="center">
                  <Field component={RadioGroup} name="type" row>
                    <Grid item>
                      <FormControlLabel
                        value="true"
                        control={
                          <Radio
                            value={0}
                            color="primary"
                            name="generateCodeType"
                            checked={values.generateCodeType === '0'}
                            onChange={() => {
                              setFieldValue('code', '');
                            }}
                          />
                        }
                        label={t('coupons.automatic')}
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        value="false"
                        control={
                          <Radio
                            value={1}
                            color="primary"
                            name="generateCodeType"
                            checked={values.generateCodeType === '1'}
                          />
                        }
                        label={t('coupons.manual')}
                      />
                    </Grid>
                  </Field>
                </Grid>
              </Box>
              <Box mt={2}>
                <Grid container spacing={4}>
                  <Grid item className={classes.fullwidthChild} md={5}>
                    <Field
                      component={Select}
                      as="select"
                      autoWidth={false}
                      fullWidth
                      required
                      label={t('coupons.discountName')}
                      name="couponPlanId"
                      variant="outlined"
                      onChange={(event, val) => {
                        setFieldValue('couponPlanId', val.props.value);
                        setFieldValue(
                          'discountDescription',
                          couponPlans.find((r) => r.id === val.props.value)
                            ?.discount
                        );
                      }}
                    >
                      <MenuItem value={undefined}>---</MenuItem>
                      {couponPlans.map(
                        (e) =>
                          e && (
                            <MenuItem value={e.id} key={e.id}>
                              {e.name}
                            </MenuItem>
                          )
                      )}
                    </Field>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Field
                      component={TextField}
                      fullWidth
                      type="text"
                      label={t('coupons.discountDescription')}
                      name="discountDescription"
                      variant="outlined"
                      disabled
                      InputLabelProps={{
                        shrink: !!values.discountDescription,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Grid container justifyContent="space-between" spacing={4}>
                      <Grid item xs={6}>
                        <DatePicker
                          format="MM/DD/YYYY"
                          required
                          disablePast
                          renderInput={(params) => {
                            return (
                              <Field
                                component={TextField}
                                fullWidth
                                label={`${t('loyalty.expirationDate')} *`}
                                name="expirationDate"
                                variant="outlined"
                                {...params}
                                error={Boolean(errors.expirationDate)}
                                helperText={errors.expirationDate}
                              />
                            );
                          }}
                          value={
                            values.expirationDate
                              ? moment(values.expirationDate)
                              : null
                          }
                          onChange={(val) => {
                            setFieldValue(
                              'expirationDate',
                              val ? val.format('MM/DD/YYYY') : ''
                            );
                          }}
                          name="expirationDate"
                          label={`${t('loyalty.expirationDate')} *`}
                          onKeyPress={() => setFieldTouched('expirationDate')}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          fullWidth
                          type="number"
                          removeArrows
                          name="maxRedemptions"
                          variant="outlined"
                          required
                          label={t('coupons.maxRedemptions')}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Field
                      component={TextField}
                      fullWidth
                      required
                      type="text"
                      label={t('coupons.campaignName')}
                      name="description"
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item md={3} xs={12}>
                    <Field
                      component={TextField}
                      fullWidth
                      type="text"
                      required
                      label={t('coupons.enterCouponCode')}
                      name="code"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: !!values.description,
                      }}
                      error={touched.description && Boolean(errors.code)}
                      helperText={touched.description && errors.code}
                      disabled={values.generateCodeType === '0'}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box mt={2}>
                <Grid
                  container
                  spacing={5}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid item>
                    <Button
                      onClick={handleSubmit}
                      loading={isLoading}
                      disabled={isLoading}
                      variant="contained"
                      color="primary"
                    >
                      {t('coupons.generateCouponCodeUppercase')}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

SingleCouponBox.propTypes = {
  setCouponCode: PropTypes.func.isRequired,
};

export default SingleCouponBox;
