/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import {
  Box,
  Grid,
  Button,
  MenuItem,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { TextField, TextField as FormikTextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Close } from '@material-ui/icons';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';

import { membership, alert } from '../../../state';
import { CustomInputField } from '../../custom';
import CountrySelect from '../../custom/PhoneInput';
import i18n from '../../../i18n';

const AddMemberModal = ({ item, isOpen, closeModal }) => {
  const plans =
    useSelector(membership.selectors.selectPlans).filter(
      (plan) => plan.status === 1
    ) || [];
  const membershipConfig = useSelector(
    membership.selectors.selectMembershipConfig
  );
  const [loading, setLoading] = React.useState(false);
  const [phone, setPhone] = React.useState('');
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(membership.actions.getMembershipPlans());
  }, []);

  const openAlert = (payload) => dispatch(alert.actions.open(payload));

  const phoneRegExp = /^\(*\+*[1-9]{0,3}\)*-*[1-9]{0,3}[-. /]*\(*[2-9]\d{2}\)*[-. /]*\d{3}[-. /]*\d{4} *e*x*t*\.* *\d{0,4}$/;
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, i18n.t('validation.tooShort'))
      .max(50, i18n.t('validation.tooLong'))
      .required(i18n.t('validation.required')),
    lastName: Yup.string()
      .min(2, i18n.t('validation.tooShort'))
      .max(50, i18n.t('validation.tooLong'))
      .required(i18n.t('validation.required')),
    email: Yup.string()
      .email(i18n.t('invalidEmail'))
      .required(i18n.t('validation.required')),
    localPhone: Yup.string()
      .matches(/^\d+$/, i18n.t('validation.invalidNumber'))
      .matches(phoneRegExp, t('validation.invalidPhoneNumber'))
      .required(i18n.t('validation.required')),
    memberCard: Yup.string().when('isPhysical', {
      is: (isPhysical) => isPhysical === 2,
      then: Yup.string().required(i18n.t('required')),
      // // eslint-disable-next-line func-names
      // .test(async function (value) {
      //   const error = await debouncedValidateMembershipCode(value, this);
      //   return error;
      // }),
    }),
    expirationDate: Yup.string().required(i18n.t('validation.required')),
    planId: Yup.string().required(i18n.t('validation.required')),
    countryCode: Yup.string().required(i18n.t('validation.required')),
  });

  const validateExpirationDate = (val) => {
    if (!val) {
      return null;
    }
    if (
      !moment(val, 'YYYY-MM-DD').isValid() ||
      moment(val, 'YYYY-MM-DD').isSameOrBefore(moment(), 'date')
    ) {
      return t('invalidDate');
    }
    return null;
  };

  const handlePhoneChange = (value) => {
    setPhone(value);
  };

  const createMemberShip = async (formData, resetForm) => {
    const body = {
      planId: formData.planId,
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      expirationDate: moment(formData.expirationDate).format('YYYY-MM-DD'),
      phone,
      code: formData.isPhysical === 2 ? formData.memberCard : '',
    };
    const response = await dispatch(membership.actions.createMemberShip(body));
    if (response.payload) {
      setTimeout(() => {
        openAlert({
          message: t('membership.membershipList.addedSuccessfully'),
          severity: 'success',
        });
        setLoading(false);
        resetForm();
      }, 2000);
    } else {
      openAlert({
        message:
          response.error?.message ||
          response.error ||
          t('membership.membershipList.failCreate'),
        severity: 'error',
      });
      setLoading(false);
    }
  };

  const onSubmitMemberShip = async (formData, resetForm) => {
    await createMemberShip(formData, resetForm);
  };

  const body = (
    <Box>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={{
          isPhysical: membershipConfig.numberType,
          planId: '',
          countryCode: 'US',
          email: '',
          firstName: '',
          lastName: '',
          memberCard: '',
          localPhone: '',
          expirationDate: '',
        }}
        onSubmit={async (values, { resetForm }) => {
          const formData = values;
          setLoading(true);
          try {
            await onSubmitMemberShip(formData, resetForm);
          } catch (error) {
            console.log(error);
          }
          return true;
        }}
      >
        {({ values, errors, handleSubmit, setFieldValue, touched }) => {
          return (
            <Form>
              <Box mt={2}>
                <Grid container spacing={2}>
                  <Grid item sm={6}>
                    <FormControlLabel
                      value="true"
                      control={
                        <Checkbox
                          color="primary"
                          name="isPhysical"
                          checked={values.isPhysical === 2}
                          disabled
                        />
                      }
                      label={t('membership.membershipConfig.physical')}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      fullWidth
                      disabled={values.isPhysical !== 2}
                      required
                      label={`${t('membership.membershipList.enterMember')} #`}
                      name="memberCard"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      fullWidth
                      required
                      label={t('membership.membershipList.firstName')}
                      name="firstName"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      fullWidth
                      required
                      label={t('membership.membershipList.lastName')}
                      name="lastName"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <CountrySelect
                      value={values.countryCode}
                      onChange={handlePhoneChange}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      trim
                      fullWidth
                      required
                      type="email"
                      label={t('email')}
                      name="email"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      component={FormikTextField}
                      fullWidth
                      select
                      name="planId"
                      variant="outlined"
                      label={t('membership.images.selectPlan')}
                    >
                      <MenuItem value={undefined}>---</MenuItem>
                      {plans.map(
                        (opt) =>
                          opt && (
                            <MenuItem value={opt.id} key={opt.id}>
                              {opt.name}
                            </MenuItem>
                          )
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DatePicker
                      inputFormat="YYYY-MM-DD"
                      mask="____-__-__"
                      required
                      minDate={moment()}
                      slotProps={{
                        textField: {
                          readOnly: true,
                          variant: 'outlined',
                          error: Boolean(errors.expirationDate),
                          helperText: errors.expirationDate,
                        },
                      }}
                      renderInput={(pr) => {
                        return (
                          <Field
                            readOnly
                            inputProps={{ readOnly: true }}
                            component={TextField}
                            fullWidth
                            label={`${t(
                              'membership.membershipList.memberShipExpirationDate'
                            )}`}
                            name="expirationDate"
                            validate={validateExpirationDate}
                            variant="outlined"
                            {...pr}
                            error={
                              touched.expirationDate &&
                              Boolean(errors.expirationDate)
                            }
                            helperText={
                              touched.expirationDate && errors.expirationDate
                            }
                            onKeyDown={(e) => e.preventDefault()}
                          />
                        );
                      }}
                      value={
                        values.expirationDate
                          ? moment(values.expirationDate)
                          : null
                      }
                      onChange={(val) => {
                        const currentTime = moment().format('HH:mm:ss');
                        setFieldValue(
                          'expirationDate',
                          val
                            ? `${val.format('YYYY-MM-DD')} ${currentTime}`
                            : ''
                        );
                      }}
                      name="expirationDate"
                      label={`${t(
                        'membership.membershipList.memberShipExpirationDate'
                      )}`}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box mt={4}>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Button
                      style={{ marginRight: 12 }}
                      onClick={handleSubmit}
                      disabled={loading}
                      variant="contained"
                      color="primary"
                    >
                      {t('save')}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );

  return (
    <Dialog
      id={item?.id}
      open={isOpen}
      onClose={closeModal}
      fullWidth
      PaperProps={{
        style: {
          maxWidth: 650,
        },
      }}
      disableEnforceFocus
    >
      <DialogTitle>
        {t('membership.membershipList.addNewMemberTitle')}
      </DialogTitle>
      <DialogContent>{body}</DialogContent>
      <IconButton
        aria-label="clear"
        onClick={closeModal}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          width: 20,
          height: 20,
        }}
      >
        <Close fontSize="small" />
      </IconButton>
    </Dialog>
  );
};

AddMemberModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  item: PropTypes.object,
};

AddMemberModal.defaultProps = {
  item: null,
};

export default AddMemberModal;
