import React from 'react';
import { Typography, Box, Grid, Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { loyalty } from '../../../state';
import DefineLoyaltyProgramForm from './DefineLoyaltyProgramForm';

const LoyaltyForm = () => {
  const { t } = useTranslation();
  const loyaltyProgram = useSelector(loyalty.selectors.selectLoyaltyProgram);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(loyalty.actions.getNewLoyaltyProgram());
  }, []);

  return (
    <div>
      <Box pb={2}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs="auto">
            <Typography variant="h5">
              {t('loyalty.loyaltyCreateProgram')}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box pt={2}>
        <DefineLoyaltyProgramForm
          loyaltyProgramData={loyaltyProgram}
          isCreating
        />
      </Box>
    </div>
  );
};

export default LoyaltyForm;
