/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';

import { TextField, Switch, Select } from 'formik-mui';
import { useTranslation } from 'react-i18next';
import { Field, useFormikContext } from 'formik';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { makeStyles } from '@material-ui/core/styles';
import getSymbolFromCurrency from 'currency-symbol-map';
import { CheckBox, CheckBoxOutlineBlank, FileCopy } from '@material-ui/icons';
import { Checkbox as FormikCheckbox } from 'formik-material-ui';
import { CustomInputField, TransactionTable } from '../../custom';
import { alert, membership } from '../../../state';
import QRCodeDownloadButton from '../LoyaltyPrograms/QRCodeDownloadButton';

const PintunaAppUrl =
  process.env.GATSBY_APP_PORTAL_URL || 'https://app.pintuna.com/';
const useStyles = makeStyles(() => ({
  fullwidthChild: {
    '& > div': {
      width: '100%',
    },
  },
}));
const RegisterConfig = () => {
  const classes = useStyles();
  const {
    values,
    setFieldValue,
    handleSubmit,
    isSubmitting,
  } = useFormikContext();

  const { t } = useTranslation();
  const membershipConfig = useSelector(
    membership.selectors.selectMembershipConfig
  );
  // const isLoading = useSelector(membership.selectors.selectIsLoading);
  const plans = useSelector(membership.selectors.selectPlans);
  const [plansOption, setPlansOption] = useState([]);
  const dispatch = useDispatch();

  const [currencySymbol] = useState(
    getSymbolFromCurrency((plans && plans[0] && plans[0].currency) || 'USD')
  );
  const onPlanChange = (val) => {
    let i = 0;
    const planOptions = plans.map((p) => {
      if (p.partnerSubscriptionPlanId === val.props.value) {
        return { ...p, isDisplayed: 1, order: 0 };
      }
      i += 1;
      return { ...p, isDisplayed: 0, order: i };
    });
    setPlansOption(planOptions);
    setFieldValue(
      'plans',
      planOptions.sort((a, b) => (a.order > b.order ? 1 : -1))
    );
  };
  const handleCopy = () => {
    const payload = {
      message: t('copiedToClipboard'),
      severity: 'success',
    };
    dispatch(alert.actions.open(payload));
  };

  // useEffect(() => {
  //   if (isConfigByTier) {
  //     dispatch(membership.actions.getMembershipPlans());
  //   }
  // }, [isConfigByTier]);

  useEffect(() => {
    setFieldValue('plans', plans);
    setPlansOption(plans);
  }, [plans]);

  const updateDisplay = (i) => {
    const newPlanList = plansOption.map((p) => {
      if (p.id === i.id) {
        return { ...p, isDisplayed: !i.isDisplayed };
      }
      return p;
    });
    setPlansOption(newPlanList);
    setFieldValue('plans', newPlanList);
  };

  const updateDigital = (i) => {
    const newPlanList = plansOption.map((p) => {
      if (p.id === i.id) {
        return { ...p, isDigital: !i.isDigital };
      }
      return p;
    });
    setPlansOption(newPlanList);
    setFieldValue('plans', newPlanList);
  };

  const cols = [
    t('membership.planName'),
    t('membership.registerConfig.status'),
    t('membership.registerConfig.issueMembershipCard'),
    t('membership.registerConfig.display'),
    t('membership.registerConfig.signupFormURL'),
  ];
  const rowKeys = ['name', 'status', 'isDigital', 'isDisplayed', 'id'];

  const mods = {
    status: (id, row) => (
      <Typography variant="body2">
        {row.status ? t('active') : t('inActive')}
      </Typography>
    ),
    isDisplayed: (id, row) =>
      row.isDisplayed ? (
        <CheckBox
          style={{
            cursor: 'pointer',
          }}
          onClick={() => updateDisplay(row)}
        />
      ) : (
        <CheckBoxOutlineBlank
          style={{
            cursor: 'pointer',
          }}
          onClick={() => updateDisplay(row)}
        />
      ),
    isDigital: (id, row) =>
      row.isDigital ? (
        <CheckBox
          style={{
            cursor: 'pointer',
          }}
          onClick={() => updateDigital(row)}
        />
      ) : (
        <CheckBoxOutlineBlank
          style={{
            cursor: 'pointer',
          }}
          onClick={() => updateDigital(row)}
        />
      ),
    id: (id, row) => (
      <>
        <Tooltip title="Copy Link" placement="top" arrow>
          <CopyToClipboard
            text={`${PintunaAppUrl}membership/${id}/register`}
            onCopy={handleCopy}
          >
            <FileCopy cursor="pointer" />
          </CopyToClipboard>
        </Tooltip>
        <span style={{ marginLeft: 5, verticalAlign: 'top' }}>
          <Tooltip
            title={t('loyalty.downloadLinkQrCode')}
            placement="top"
            arrow
          >
            <QRCodeDownloadButton
              id={`${id}/register`}
              value={`${PintunaAppUrl}membership/${id}/register`}
              fileName={`${row.name} - Sign Up Form URL QR Code`}
              format="png"
            />
          </Tooltip>
        </span>
      </>
    ),
  };

  return (
    <>
      <Box mb={2} alignItems="center">
        <Grid container spacing={2} mt={2} alignItems="center">
          <Grid item xs={8}>
            <CustomInputField
              customInput={Field}
              component={TextField}
              fullWidth
              disabled
              type="string"
              removeArrows
              InputLabelProps={{
                shrink: true,
              }}
              label={`${t('membership.registerUrl')}`}
              name="link"
              variant="outlined"
              value={`${PintunaAppUrl}membership/${membershipConfig.businessId}/register`}
            />
          </Grid>
          <Grid item xs="auto">
            <CopyToClipboard
              text={`${PintunaAppUrl}membership/${membershipConfig.businessId}/register`}
              onCopy={handleCopy}
            >
              <Button variant="contained" color="primary">
                {t('membership.registerConfig.copyLink')}
              </Button>
            </CopyToClipboard>
            <span style={{ marginLeft: 5 }}>
              <QRCodeDownloadButton
                id={`${membershipConfig.id}`}
                value={`${PintunaAppUrl}membership/${membershipConfig.businessId}/register`}
                fileName={`${membershipConfig.businessName} - Sign Up Form URL QR Code`}
                format="png"
              />
            </span>
          </Grid>
        </Grid>
      </Box>
      <Box mb={2} alignItems="center">
        <Grid container xs={12} spacing={1}>
          <Grid item xs="auto">
            <Box
              sx={{ width: 250, display: 'inline-flex', alignItems: 'center' }}
            >
              <Typography> {t('membership.registerConfig.qa')}</Typography>
              <Field
                component={Switch}
                type="checkbox"
                name="allowSelectPlan"
                color="primary"
              />
            </Box>
          </Grid>
          <Grid container item xs={12} md={8} spacing={2}>
            {!values.allowSelectPlan && (
              <>
                <Grid item className={classes.fullwidthChild}>
                  <Field
                    component={Select}
                    as="select"
                    autoWidth={false}
                    label={t('membership.planNameToIssue')}
                    name="plans[0].partnerSubscriptionPlanId"
                    variant="outlined"
                    onChange={(event, val) => onPlanChange(val)}
                    style={{ height: '56px', width: 300 }}
                  >
                    <MenuItem value={undefined}>---</MenuItem>
                    {plans
                      .filter((p) => p.status)
                      .map((opt) => (
                        <MenuItem
                          value={opt.partnerSubscriptionPlanId}
                          key={opt.partnerSubscriptionPlanId}
                        >
                          {opt.name}
                        </MenuItem>
                      ))}
                  </Field>
                </Grid>
                <Grid item xs={4} md={3}>
                  <CustomInputField
                    customInput={Field}
                    component={TextField}
                    fullWidth
                    disabled
                    startAdornment={currencySymbol}
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label={t('membership.amount')}
                    value={
                      values.plans &&
                      values.plans.filter(
                        (p) => p.status && p.isDisplayed
                      )[0] &&
                      values.plans.filter((p) => p.status && p.isDisplayed)[0]
                        .amount
                    }
                    name="plans[0].amount"
                    variant="outlined"
                  />
                </Grid>
                <Grid item>
                  <Box textAlign="center">
                    <Typography>
                      {t('membership.registerConfig.issueMembershipCard')}
                    </Typography>
                    <Field
                      type="checkbox"
                      component={FormikCheckbox}
                      color="primary"
                      name="plans[0].isDigital"
                      // onClick={(e, val) => console.log(e, val, values.plans[0])}
                    />
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Box>

      {values.allowSelectPlan && (
        <Grid item md={6} xs={12} style={{ marginBottom: 20 }}>
          {plansOption && (
            <TransactionTable
              cols={cols}
              rowKeys={rowKeys}
              data={plansOption}
              mods={mods}
              noDataMsg={t('noData')}
              isMinHeight={false}
              size="small"
            />
          )}
        </Grid>
      )}
      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          type="submit"
          disabled={isSubmitting}
        >
          {isSubmitting ? <CircularProgress size={24} /> : t('save')}
        </Button>
      </Box>
    </>
  );
};

export default RegisterConfig;
