import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { membership } from '../../../state';
import { CustomImgCard, CustomInputField } from '../../custom';

const useStyles = makeStyles(() => ({
  mr10: {
    marginRight: 20,
  },
  mb10: {
    marginBottom: 10,
  },
  mt10: {
    marginTop: 10,
  },
}));
const SponsorConfig = () => {
  const {
    values,
    setFieldValue,
    setFieldError,
    handleSubmit,
    isSubmitting,
    errors,
  } = useFormikContext();

  const { t } = useTranslation();
  const classes = useStyles();

  const refFileInput = useRef();
  const dispatch = useDispatch();

  const [hasSponsor, setHasSponsor] = useState(values.hasSponsor || 0);
  const [imgIsLoading, setImgIsLoading] = useState(false);
  const [imgPreview, setImgPreview] = useState(values.sponsorImage || '');

  const handleChangeRadioSponsor = (event) => {
    setHasSponsor(Number(event.target.value));
    setFieldValue('hasSponsor', Number(event.target.value));
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    const formData = new FormData();
    formData.append('file', file);
    reader.readAsDataURL(file);
    if (file.size > 500 * 1024) {
      refFileInput.current.value = '';
      setFieldError('sponsorImage', t('fileCannotExceedKB', { size: 500 }));
    } else {
      reader.onloadend = async () => {
        setImgIsLoading(true);
        refFileInput.current.value = '';
        const res = await dispatch(
          membership.actions.setSponsorImage(formData)
        );
        if (res && res.error) {
          refFileInput.current.value = '';
          setFieldError('sponsorImage', res.error.message);
          setImgPreview('');
        }
        if (res && res.payload) {
          setFieldError('sponsorImage', '');
          setImgPreview(reader.result);
          setFieldValue('sponsorImage', res.payload.imageUrl);
        }
        setImgIsLoading(false);
      };
    }
  };
  const handleClear = () => {
    refFileInput.current.value = '';
    setFieldError('sponsorImage', undefined);
    setFieldValue('sponsorImage', '');
    setImgPreview('');
    // setFieldTouched('sponsorImage', false);
    setImgIsLoading(false);
  };

  const isLoading = isSubmitting || imgIsLoading;

  return (
    <>
      <Grid item xs={12} md={6}>
        <Box
          style={{
            display: 'flex',
            textAlign: 'center',
            alignItems: 'center',
          }}
        >
          <Typography className={classes.mr10}>
            {t('membership.sponsorConfig.confirm')}
          </Typography>
          <FormControlLabel
            value={1}
            control={
              <Radio
                checked={hasSponsor === 1}
                onChange={handleChangeRadioSponsor}
                value={1}
                name="hasSponsor"
                color="primary"
                label
              />
            }
            label={t('yes')}
          />
          <FormControlLabel
            value={0}
            control={
              <Radio
                checked={hasSponsor === 0}
                onChange={handleChangeRadioSponsor}
                value={0}
                name="hasSponsor"
                color="primary"
              />
            }
            label={t('no')}
          />
        </Box>
        <Card variant="outlined" style={{ padding: 20 }}>
          <Typography className={[classes.mr10, classes.mb10]}>
            {t('membership.sponsorConfig.sponsorText')}
          </Typography>
          <CustomInputField
            customInput={Field}
            component={TextField}
            required
            type="text"
            fullWidth
            name="sponsorText"
            variant="outlined"
          />

          <Typography className={[classes.mr10, classes.mb10, classes.mt10]}>
            {t('membership.sponsorConfig.image')}
          </Typography>
          <CustomImgCard
            isLoading={imgIsLoading}
            square
            imageUrl={imgPreview}
            styleMedia={{
              paddingTop: 0,
              height: 30,
            }}
          />
          <Box>
            <Typography
              variant="caption"
              color={
                /* touched['sponsorImage'] && */ errors.sponsorImage
                  ? 'error'
                  : 'textPrimary'
              }
            >
              {
                /* touched['sponsorImage'] && */ errors.sponsorImage
                  ? errors.sponsorImage
                  : t('imageError')
              }
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Button
              variant="contained"
              color="primary"
              component="label"
              disabled={isLoading}
            >
              {t('membership.sponsorConfig.uploadImage')}
              <input
                id="file"
                type="file"
                ref={refFileInput}
                hidden
                accept="image/*"
                name="sponsorImage"
                onChange={handleImageUpload}
              />
            </Button>
            <IconButton
              aria-label="clear"
              onClick={handleClear}
              style={{ float: 'left', padding: 3 }}
              disabled={isLoading}
            >
              {imgIsLoading ? (
                <CircularProgress size={20} />
              ) : (
                <Close fontSize="small" />
              )}
            </IconButton>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} md={3} className={classes.mt10}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          type="submit"
          fullWidth
          disabled={isLoading}
        >
          {isSubmitting ? <CircularProgress size={24} /> : t('saveChanges')}
        </Button>
      </Grid>
    </>
  );
};

export default SponsorConfig;
