import React, { useRef } from 'react';
import {
  Box,
  FormControlLabel,
  Grid,
  Typography,
  Checkbox,
  TextField,
  Button,
} from '@material-ui/core';
import { Field, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { CustomInputField } from '../../custom';

const TicketAdditionalCosts = () => {
  const { t } = useTranslation();
  const {
    values,
    setFieldValue,
    isSubmitting,
    handleSubmit,
  } = useFormikContext();
  const amountRef = useRef(null);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Box mb={2}>
            <Typography style={{ fontWeight: 'bold' }}>
              {t('tickets.event.ticketAdditionalCost')}:{' '}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid item style={{ display: 'flex', marginBottom: 16 }}>
            <FormControlLabel
              value="true"
              checked={values.serviceFee === 'true' || values.fees > 0}
              style={{ width: 150 }}
              control={
                <Checkbox
                  color="primary"
                  name="serviceFee"
                  onClick={() =>
                    setFieldValue(
                      'serviceFee',
                      values.serviceFee === 'true' ? 'false' : 'true'
                    )
                  }
                />
              }
              label={t('tickets.serviceFee')}
              name="serviceFee"
            />
            <Box item style={{ position: 'relative' }} width={320}>
              <CustomInputField
                extraEndAdornments
                customInput={Field}
                component={TextField}
                fullWidth
                type="number"
                removeArrows
                name="fees"
                value={values.fees}
                variant="outlined"
                required
                label={t('tickets.serviceFee')}
                inputRef={amountRef}
                style={{ width: 320 }}
                disabled={values.serviceFee === 'false' && !values.fees}
                InputProps={{
                  style: {
                    paddingRight: 150,
                    minWidth: 320,
                    width: '100%',
                  },
                }}
              />
              <ToggleButtonGroup
                exclusive
                disabled
                id="roleType"
                value={values.serviceFee}
                size="small"
                style={{ position: 'absolute', right: 10, top: 6 }}
              >
                <ToggleButton
                  disabled
                  value={0}
                  style={{ padding: '5px 25px' }}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    setFieldValue('fees', 0);
                    if (document.activeElement !== amountRef.current)
                      amountRef.current.focus();
                  }}
                >
                  %
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Grid>
          <Grid item style={{ display: 'flex', marginBottom: 16 }}>
            <FormControlLabel
              value="true"
              checked={values.taxService === 'true' || values.tax > 0}
              style={{ width: 150 }}
              control={
                <Checkbox
                  color="primary"
                  name="taxService"
                  onClick={() =>
                    setFieldValue(
                      'taxService',
                      values.taxService === 'true' ? 'false' : 'true'
                    )
                  }
                />
              }
              label="Tax"
              name="taxService"
            />
            <Box item style={{ position: 'relative' }} width={320}>
              <CustomInputField
                extraEndAdornments
                customInput={Field}
                component={TextField}
                fullWidth
                type="number"
                removeArrows
                name="tax"
                value={values.tax}
                variant="outlined"
                required
                label={t('Tax')}
                inputRef={amountRef}
                style={{ width: 320 }}
                disabled={values.taxService === 'false' && !values.tax}
                InputProps={{
                  style: {
                    paddingRight: 150,
                    minWidth: 320,
                    width: '100%',
                  },
                }}
              />
              <ToggleButtonGroup
                disabled
                exclusive
                id="roleType"
                value={values.discountType}
                size="small"
                style={{ position: 'absolute', right: 10, top: 6 }}
              >
                <ToggleButton
                  disabled
                  value={0}
                  style={{ padding: '5px 25px' }}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    setFieldValue('discountType', 0);
                    if (document.activeElement !== amountRef.current)
                      amountRef.current.focus();
                  }}
                >
                  %
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Grid>
          <Grid item style={{ display: 'flex' }}>
            <Button
              disabled={isSubmitting}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              {t('saveAndContinue')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TicketAdditionalCosts;
