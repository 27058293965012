/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import CSVReader from 'react-csv-reader';
import SEO from '../../seo';
import { alert, merchant, storeCredit } from '../../../state';
import { TransactionTable } from '../../custom';
import ConfirmationDialog from '../GiftCardDetails/ConfirmDialog';

const useStyles = makeStyles(() => ({
  fullwidthChild: {
    '& > div': {
      width: '100%',
    },
  },
  '@keyframes blinker': {
    from: { opacity: 1 },
    to: { opacity: 0.5 },
  },
  blink: {
    animationName: '$blinker',
    animationDuration: '1s',
    animationTimingFunction: 'ease',
    animationIterationCount: 'infinite',
  },
  generateButton: {
    backgroundColor: '#284C0D !important',
    color: '#fff !important',
    minWidth: '190px',
  },
}));
const StoreCreditsImport = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const colsTitles = [
    t('storeCredit.storeCreditsImport.physicalCard'),
    t('firstName'),
    t('lastName'),
    t('email'),
    t('cardValue'),
    t('expiration'),
  ];

  const rowKeys = [
    'Physical Card #',
    'First Name',
    'Last Name',
    'Email',
    'Amount',
    'Expiration',
  ];

  const [noDataMsg, setNoDataMsg] = React.useState(t('storeCredit.noData'));
  const dispatch = useDispatch();
  const openAlert = (payload) => dispatch(alert.actions.open(payload));

  const isLoading = useSelector(storeCredit.selectors.selectIsLoading);
  const currency = useSelector(merchant.selectors.selectCurrency);
  const storeCreditConfig = useSelector(
    storeCredit.selectors.selectStoreCreditConfig
  );
  const syncing = useSelector(storeCredit.selectors.selectSyncing);
  const mods = {
    Amount: (val) =>
      val &&
      t('cardValue', {
        amount: val,
        formatParams: {
          amount: { currency: currency || 'USD' },
        },
      }),
  };
  useEffect(() => {
    if (isLoading) {
      setNoDataMsg(t('storeCredit.noData'));
    }
  }, [isLoading]);
  const [results, setResults] = useState([{}, {}, {}, {}, {}]);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isImported, setImported] = useState(false);
  const handleCsvUpload = (data) => {
    setResults(data);
  };
  const parseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => header.trim(),
  };

  const downloadCSV = () => {
    fetch('../../../SamplePhysicalStoreCreditImportFile.csv').then(
      (response) => {
        response.blob().then((blob) => {
          const fileURL = window.URL.createObjectURL(blob);
          const alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = 'SampleStoreCreditImportFile.csv';
          alink.click();
        });
      }
    );
  };

  return (
    <>
      <SEO title={t('membership.detailedCheckInList')} />
      {storeCreditConfig.businessId && (
        <>
          <Box mt={2}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} lg="auto">
                <Button variant="contained" color="primary" component="label">
                  {t('storeCredit.storeCreditsImport.importFromCSVFile')}
                  <CSVReader
                    inputId="CSVReader"
                    inputStyle={{ display: 'none' }}
                    onFileLoaded={handleCsvUpload}
                    parserOptions={parseOptions}
                  />
                </Button>
              </Grid>
              <Grid item>
                <Typography
                  variant="a"
                  color="textSecondary"
                  onClick={downloadCSV}
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    display: 'flex',
                    verticalAlign: 'center',
                  }}
                >
                  {t('storeCredit.storeCreditsImport.sampleCSV')}
                </Typography>
              </Grid>
            </Grid>
            <Grid item style={{ marginLeft: 'auto' }}>
              <Box ml={1}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.generateButton}
                  onClick={() => setIsConfirm(true)}
                  disabled={isLoading || syncing || !results.length}
                >
                  {syncing ? (
                    <CircularProgress size={24} />
                  ) : (
                    t('storeCredit.storeCreditsImport.btnGenerate')
                  )}
                </Button>
              </Box>
            </Grid>
          </Box>
          <Box mt={2}>
            <Grid container alignItems="center">
              {isLoading ? (
                <Skeleton width="50%" />
              ) : (
                <>
                  <Grid item>
                    <Box>
                      <Typography variant="h6">
                        {t('storeCredit.storeCreditsImport.totalAmount')}:{' '}
                        {t('cardValue', {
                          amount: results.length
                            ? results.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    (b.Amount
                                      ? b.Amount.toString()
                                      : '0'
                                    ).match(/(\d+)/)[1]
                                  )
                                );
                              }, 0)
                            : 0,
                          formatParams: {
                            amount: { currency },
                          },
                        })}
                      </Typography>
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </>
      )}
      <Box py={2}>
        {syncing ? (
          <Box py={10} alignItems="center">
            <Typography variant="h4" align="center" className={classes.blink}>
              {t('storeCredit.storeCreditsImport.pleaseWait')}
            </Typography>
          </Box>
        ) : (
          <TransactionTable
            cols={colsTitles}
            rowKeys={rowKeys}
            data={results}
            mods={mods}
            isLoading={isLoading}
            noDataMsg={noDataMsg}
          />
        )}
      </Box>
      <ConfirmationDialog
        open={isImported}
        setOpen={setImported}
        title=""
        isConfirmation={false}
        content={t('storeCredit.storeCreditsImport.storeSuccessfully')}
        actionOk={() => setImported(false)}
      />
      <ConfirmationDialog
        open={isConfirm}
        setOpen={setIsConfirm}
        title={t('confirmation')}
        content={t('storeCredit.storeCreditsImport.confirmGenerate')}
        actionOk={async () => {
          setIsConfirm(false);
          const response = await dispatch(
            storeCredit.actions.importStoreCredits({
              businessId: storeCreditConfig.businessId,
              data: results.map((r) => ({
                code: r['Physical Card #'] || 1,
                firstName: r['First Name'],
                lastName: r['Last Name'],
                amount: Number(
                  (r.Amount ? r.Amount.toString() : '0').match(/(\d+)/)[1] ||
                    '0'
                ),
                email: r.Email,
                expirationDate: r.Expiration,
              })),
            })
          );
          if (response.error) {
            openAlert({
              message:
                (response.error && response.error.message) ||
                t('weCannotProcessPaymentsPleaseTryLater'),
              severity: 'error',
            });
          } else if (response.payload) {
            openAlert({
              message: `Generated ${
                results.filter((r) => r.Amount).length
              } store credits for total value of ${t('cardValue', {
                amount: results.length
                  ? results.reduce((a, b) => {
                      return (
                        Number(a) +
                        Number(
                          (b.Amount ? b.Amount.toString() : '0').match(
                            /(\d+)/
                          )[1]
                        )
                      );
                    }, 0)
                  : 0,
                formatParams: {
                  amount: { currency },
                },
              })}`,
              severity: 'error',
            });
            setImported(true);
            setResults([{}, {}, {}, {}, {}]);
          }
        }}
      />
    </>
  );
};

export default StoreCreditsImport;
