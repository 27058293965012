import * as React from 'react';
import { TextField } from 'formik-material-ui';
import moment from 'moment';
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Box,
  Grid,
  IconButton,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import DatePicker from '@mui/lab/DatePicker';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { Close } from '@material-ui/icons';
import api from '../../../state/membership/api';
import { alert, membership } from '../../../state';

const fieldStyle = {
  display: 'flex',
  flexDirection: 'row',
};

const ReactiveExpiredMembershipModal = React.forwardRef(({ onClose, item }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const openAlert = (payload) => dispatch(alert.actions.open(payload));

  const initialData = {
    newExpirationDate: null,
  };
  const onSubmit = async (values, formikHelper) => {
    formikHelper.setSubmitting(true);
    try {
      await api.reactiveMembership({
        id: item?.id,
        expirationDate: values.newExpirationDate,
      });
      openAlert({
        message: t('membership.messages.successfullyEditMember'),
        severity: 'success',
      });
      dispatch(membership.actions.getMembershipList());
      onClose();
    } catch (error) {
      openAlert({
        message:
          error?.message || error || t('membership.messages.failedEditMember'),
        severity: 'error',
      });
      formikHelper.setSubmitting(false);
    }
  };

  const validateExpirationDate = (val) => {
    if (!val) {
      return null;
    }

    if (
      !moment(val, 'YYYY-MM-DD').isValid() ||
      moment(val, 'YYYY-MM-DD').isBefore(moment(), 'date')
    ) {
      return t('invalidDate');
    }

    return null;
  };

  return (
    <Dialog open={!!item} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {t('membership.reactiveTitle', {
          code: item?.code,
          fname: item?.firstName,
          lname: item?.lastName,
        })}
      </DialogTitle>
      <DialogContent>
        <Box py={2}>
          <Formik
            onSubmit={onSubmit}
            enableReinitialize
            initialValues={initialData}
          >
            {({
              isSubmitting,
              setFieldValue,
              setFieldTouched,
              values,
              errors,
            }) => {
              return (
                <Form>
                  <Box sx={fieldStyle}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Box pb={2}>
                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                            spacing={2}
                          >
                            <Grid item xs={12} md={6}>
                              <DatePicker
                                inputFormat="YYYY-MM-DD"
                                mask="____-__-__"
                                required
                                renderInput={(pr) => {
                                  return (
                                    <Field
                                      readOnly
                                      inputProps={{ readOnly: true }}
                                      component={TextField}
                                      fullWidth
                                      label={`${t(
                                        'membership.editExpirationDateModal.newDate'
                                      )} *`}
                                      name="newExpirationDate"
                                      validate={validateExpirationDate}
                                      variant="outlined"
                                      {...pr}
                                      error={Boolean(errors.newExpirationDate)}
                                      helperText={errors.newExpirationDate}
                                      onKeyDown={(e) => e.preventDefault()}
                                    />
                                  );
                                }}
                                value={
                                  values.newExpirationDate
                                    ? moment(values.newExpirationDate)
                                    : null
                                }
                                onChange={(val) => {
                                  const currentTime = moment().format(
                                    'HH:mm:ss'
                                  );
                                  setFieldValue(
                                    'newExpirationDate',
                                    val
                                      ? `${val.format(
                                          'YYYY-MM-DD'
                                        )} ${currentTime}`
                                      : ''
                                  );
                                }}
                                name="newExpirationDate"
                                label={`${t(
                                  'membership.editExpirationDateModal.newDate'
                                )} *`}
                                onKeyPress={() =>
                                  setFieldTouched('newExpirationDate')
                                }
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              style={{ paddingTop: 13 }}
                            >
                              <Box
                                style={{
                                  display: 'flex',
                                  alignItems: 'flex-start',
                                  flexDirection: 'column',
                                }}
                              >
                                <Button
                                  color="primary"
                                  variant="contained"
                                  type="submit"
                                >
                                  {isSubmitting ? (
                                    <CircularProgress size={24} />
                                  ) : (
                                    t('save')
                                  )}
                                </Button>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </DialogContent>
      <IconButton
        aria-label="clear"
        onClick={onClose}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          width: 20,
          height: 20,
        }}
      >
        <Close fontSize="small" />
      </IconButton>
    </Dialog>
  );
});

ReactiveExpiredMembershipModal.propTypes = {
  onClose: PropTypes.func,
  item: PropTypes.objectOf(PropTypes.any),
};

ReactiveExpiredMembershipModal.defaultProps = {
  onClose: () => {},
  item: null,
};

export default ReactiveExpiredMembershipModal;
