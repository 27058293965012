import { Box, Divider, Grid, Tabs, Typography } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import { Formik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { alert, coupons, merchant } from '../../../state';
import { PartnerEnum } from '../../../utils/constants';
import StyledTab from '../../custom/StyledTab';
import CouponConfig from './CouponConfig';
import CouponPlans from './CouponPlans';
import CouponList from './CouponList';
import SingleCoupon from './SingleCoupon';
import SendCoupon from './SendCoupon';

const CouponSetup = () => {
  const dispatch = useDispatch();
  const openAlert = (payload) => dispatch(alert.actions.open(payload));
  const { t } = useTranslation();

  const [formTab, setTab] = React.useState(0);

  const couponConfig = useSelector(coupons.selectors.selectCouponConfig);
  const {
    codeType,
    description,
    imageUrl,
    terms,
    qrPrefix = '',
    isSquareCompatibleScanner,
  } = couponConfig;

  const merchantDetails = useSelector(merchant.selectors.selectMerchantDetails);
  const { partner = '' } = merchantDetails;

  // const { enableCoupon } = useSelector(
  //   merchant.selectors.selectMerchantDetails
  // );

  React.useEffect(() => {
    dispatch(coupons.actions.getCouponConfig());
  }, []);

  return (
    <div>
      <Box pb={2}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs="auto">
            <Typography variant="h5">Coupons</Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Formik
        enableReinitialize
        validateOnChange={false}
        validateOnBlur
        validationSchema={() => {
          return Yup.object().shape({});
        }}
        initialValues={{
          codeType: codeType ? codeType.toString() : '0',
          description,
          imageUrl,
          terms,
          isSquareCompatibleScanner:
            isSquareCompatibleScanner === true ||
            isSquareCompatibleScanner === '1'
              ? '1'
              : '0',
          isSquareCompatibleScanner1: qrPrefix ? '1' : '0',
        }}
        onSubmit={async (values) => {
          const res = await dispatch(
            coupons.actions.setCouponConfig({
              codeType: values.codeType,
              description: values.description,
              imageUrl: values.imageUrl,
              terms: values.terms,
              isSquareCompatibleScanner:
                partner === PartnerEnum.Square &&
                (values.isSquareCompatibleScanner === true ||
                  values.isSquareCompatibleScanner === '1')
                  ? 0 // always not use
                  : 0,
            })
          );
          if (res.payload) {
            openAlert({
              message: t('coupons.couponUpdateSuccessEmoji'),
              severity: 'success',
            });
            dispatch(coupons.actions.getCouponConfig());
          }
          if (res.error) {
            openAlert({
              message: res.error.message || t('updateGiftCardFailed'),
              severity: 'error',
            });
          }
        }}
      >
        {() => {
          return (
            <Box py={4}>
              <Tabs
                value={formTab}
                onChange={(event, newValue) => {
                  setTab(newValue);
                }}
                TabIndicatorProps={{ style: { background: '#0969da' } }}
                aria-label=""
              >
                <StyledTab
                  disableRipple
                  id="initialSetup"
                  aria-controls="values"
                  label={t('coupons.initialSetup')}
                />
                <StyledTab
                  disableRipple
                  id="define"
                  aria-controls="values"
                  label={t('coupons.defineDiscount')}
                  disabled={!couponConfig.id}
                />
                <StyledTab
                  disableRipple
                  id="single"
                  aria-controls="values"
                  label={t('coupons.singleCoupon')}
                  disabled={!couponConfig.id}
                  style={{ display: 'none' }}
                />
                <StyledTab
                  disableRipple
                  id="sendCoupon"
                  aria-controls="values"
                  label={t('coupons.sendCouponsEmailCampaign')}
                  disabled={!couponConfig.id}
                />
                <StyledTab
                  disableRipple
                  id="registerConfig"
                  aria-controls="values"
                  label={t('coupons.issuedCoupons')}
                  disabled={!couponConfig.id}
                />
              </Tabs>
              <Divider />
              <TabContext value={formTab.toString()}>
                <SwipeableViews index={formTab} onChangeIndex={setTab}>
                  <TabPanel value={formTab.toString()} index={0}>
                    <CouponConfig />
                  </TabPanel>
                  {formTab === 1 ? (
                    <TabPanel value={formTab.toString()} index={1}>
                      <CouponPlans />
                    </TabPanel>
                  ) : (
                    <div />
                  )}
                  {formTab === 2 ? (
                    <TabPanel value={formTab.toString()} index={2}>
                      <SingleCoupon />
                    </TabPanel>
                  ) : (
                    <div />
                  )}
                  {formTab === 3 ? (
                    <TabPanel value={formTab.toString()} index={2}>
                      <SendCoupon />
                    </TabPanel>
                  ) : (
                    <div />
                  )}
                  {formTab === 4 ? (
                    <TabPanel value={formTab.toString()} index={2}>
                      <CouponList />
                    </TabPanel>
                  ) : (
                    <div />
                  )}
                </SwipeableViews>
              </TabContext>
            </Box>
          );
        }}
      </Formik>
    </div>
  );
};

export default CouponSetup;
