/* eslint-disable react/destructuring-assignment,no-param-reassign */
import React, { useState } from 'react';
import {
  Box,
  Grid,
  Button,
  Typography,
  Divider,
  Paper,
  Dialog,
  DialogContent,
  DialogActions,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { FileCopyTwoTone, Close } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { navigate } from '@reach/router';
import SEO from '../../seo';
import { alert, loyalty, merchant } from '../../../state';
import { TransactionTable } from '../../custom';
import QRCodeDownloadButton from './QRCodeDownloadButton';
import LoyaltyReview from './LoyaltyReview';
import DefineLoyaltyProgramForm from './DefineLoyaltyProgramForm';
import CopyLink from '../../../images/icons8-copy-link-50.png';
import defaultGiftCard from '../../../images/defaultGiftCard.jpg';
import Editing from '../../../images/Memberships/editing.png';

const rowKeys = [
  'programName',
  'rewardItemId',
  'requiredAmount',
  'status',
  'imageUrl',
  'id',
  'createdAt',
  'edit',
];
const LoyaltyProgramList = () => {
  const PintunaAppUrl =
    process.env.GATSBY_APP_PORTAL_URL || 'https://app.pintuna.com/';
  const [params, setParams] = React.useState({ limit: 10, page: 1 });
  const [loyaltyModal, setLoyaltyModal] = useState(false);
  const editLoyaltyModal = useSelector(loyalty.selectors.editLoyaltyModal);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const colsTitles = [
    t('loyalty.programName'),
    t('loyalty.reward'),
    t('loyalty.#OfPoints'),
    t('status'),
    t('image'),
    t('signUpFormLink'),
    t('redemptionFormLink'),
    '',
  ];
  const { currency } = useSelector(merchant.selectors.selectMerchantDetails);
  const merchantDetails = useSelector((state) =>
    merchant.selectors.selectMerchantDetails(state)
  );
  const handleCopy = () => {
    const payload = {
      message: t('copiedToClipboard'),
      severity: 'success',
    };
    dispatch(alert.actions.open(payload));
  };
  const mods = {
    programName: (val, row) => (
      <Typography
        variant="body2"
        style={{
          textDecoration: 'underline',
          cursor: 'pointer',
          color: '#0969da',
        }}
        onClick={() => {
          if (row.status === 0) {
            navigate('/dashboard/loyalty');
          } else {
            setLoyaltyModal(row);
          }
        }}
      >
        {val}
      </Typography>
    ),
    expirationDate: (val) =>
      val ? moment(`${val}Z`).format('MMM DD YYYY') : '',
    type: (val, row) =>
      row.type === 0
        ? t('loyalty.itemPurchasedPerOrder')
        : t('loyalty.amountSpentPerOrder'),
    rewardItemId: (val, row) => {
      switch (row.rewardType) {
        case 0:
          return row.rewardItemName;
        case 1:
          return t('cardValue', {
            amount: row.discountAmount,
            formatParams: { amount: { currency } },
          });
        default:
          // case store credit
          return t('cardValue', {
            amount: row.storeCreditAmount,
            formatParams: { amount: { currency } },
          });
      }
    },
    status: (val, row) => {
      if (val === 0) return 'New';
      if (val === 2) return 'Deactivated';
      if (
        row.expirationDate &&
        moment().isAfter(moment(`${row.expirationDate}Z`))
      ) {
        return 'Expired';
      }
      return 'Active';
    },
    imageUrl: (val, row) => {
      return (
        <img
          src={val || merchantDetails.logoUrl || defaultGiftCard}
          style={{ width: 50 }}
          alt={row.name}
        />
      );
    },
    id: (val, row) => {
      return row.status === 1 && row.hidden !== true ? (
        <>
          <Tooltip title={t('loyalty.copyLink')} placement="top" arrow>
            <IconButton style={{ padding: 0, width: 30 }} cursor="pointer">
              <CopyToClipboard
                text={`${PintunaAppUrl}loyalty/${val}/register`}
                onCopy={handleCopy}
              >
                <FileCopyTwoTone color="primary" cursor="pointer" />
              </CopyToClipboard>
            </IconButton>
          </Tooltip>
          <Tooltip
            title={t('loyalty.downloadLinkQrCode')}
            placement="top"
            arrow
          >
            <QRCodeDownloadButton
              id={`${val}/register`}
              value={`${PintunaAppUrl}loyalty/${val}/register`}
              fileName={`${row.programName} - Sign Up Form URL QR Code`}
              format="png"
            />
          </Tooltip>
        </>
      ) : null;
    },
    createdAt: (val, row) => {
      return row.status === 1 ? (
        <>
          <Tooltip title={t('loyalty.copyLink')} placement="top" arrow>
            <IconButton style={{ padding: 0, width: 30 }} cursor="pointer">
              <CopyToClipboard
                text={`${PintunaAppUrl}loyalty/${row.id}/redeem`}
                onCopy={handleCopy}
              >
                <img
                  src={CopyLink}
                  alt=""
                  style={{ cursor: 'pointer', width: 20 }}
                />
              </CopyToClipboard>
            </IconButton>
          </Tooltip>
          <Tooltip
            title={t('loyalty.downloadLinkQrCode')}
            placement="top"
            arrow
          >
            <QRCodeDownloadButton
              id={`${row.id}/redeem`}
              value={`${PintunaAppUrl}loyalty/${row.id}/redeem`}
              fileName={`${row.programName} - Redemption Form URL QR Code`}
              format="png"
            />
          </Tooltip>
        </>
      ) : null;
    },
    edit: (val, row) => {
      return row.status === 1 ? (
        <Tooltip title={t('loyalty.editProgram')} placement="top" arrow>
          <IconButton
            onClick={() => dispatch(loyalty.actions.setEditProgram(row))}
            cursor="pointer"
            style={{ width: 30, height: 30 }}
          >
            <img src={Editing} alt="" style={{ width: 18 }} />
          </IconButton>
        </Tooltip>
      ) : null;
    },
  };
  const getProgramList = () => {
    dispatch(loyalty.actions.getProgramList(params));
  };
  React.useEffect(getProgramList, [params]);

  const isLoading = useSelector(loyalty.selectors.selectIsLoading);
  const { data: loyaltyProgramList, total } = useSelector(
    loyalty.selectors.selectProgramList
  );

  const isDisabled = (currentLoyalty) => {
    if (currentLoyalty.status === 0) return true;
    return !!(
      currentLoyalty.expirationDate &&
      moment().isAfter(moment(`${currentLoyalty.expirationDate}Z`))
    );
  };
  const openAlert = (payload) => dispatch(alert.actions.open(payload));

  const changeActivate = async (currentLoyalty) => {
    const payload = {
      ...currentLoyalty,
      status: currentLoyalty.status === 1 ? 2 : 1,
    };
    const res = await dispatch(loyalty.actions.saveLoyaltyProgram(payload));
    if (res.payload) {
      openAlert({
        message: t('loyalty.loyaltyProgramUpdatedSuccess'),
        severity: 'success',
      });
      dispatch(loyalty.actions.getProgramList(params)).then(() =>
        setLoyaltyModal(false)
      );
    }
    if (res.error) {
      openAlert({
        message: t('updateGiftCardFailed'),
        severity: 'error',
      });
    }
  };

  const handleChangePage = (event, page) => {
    setParams({ ...params, page: page + 1 });
  };
  const handleChangeRowsPerPage = (event) => {
    setParams({ ...params, limit: event.target.value, page: 1 });
  };
  const pagination = {
    total,
    pageSize: params.limit,
    page: params.page,
    handleChangePage,
    handleChangeRowsPerPage,
  };

  return (
    <>
      <SEO title={t('loyalty.loyaltyProgramList')} />
      <Box pb={2}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs="auto">
            <Typography variant="h5">
              {t('loyalty.loyalty')} - {t('loyalty.manageProgram')}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box py={2}>
        <TransactionTable
          cols={colsTitles}
          rowKeys={rowKeys}
          data={loyaltyProgramList}
          mods={mods}
          isLoading={isLoading}
          pagination={pagination}
          // searchBox={searchBox}
          noDataMsg={t('noDataMsg')}
        />
      </Box>
      <Dialog
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={!!loyaltyModal}
        fullWidth
        maxWidth="sm"
        onClose={() => setLoyaltyModal(false)}
      >
        <DialogContent>
          <Paper>
            <Box p={3}>
              {loyaltyModal && <LoyaltyReview loyalty={loyaltyModal} />}
            </Box>
          </Paper>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-around' }}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => changeActivate(loyaltyModal)}
            disabled={isDisabled(loyaltyModal)}
          >
            {loyaltyModal.status === 1 ? 'Deactivate' : 'Activate'}
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => setLoyaltyModal(false)}
          >
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={!!editLoyaltyModal?.id}
        fullWidth
        maxWidth="lg"
        onClose={() => dispatch(loyalty.actions.setEditProgram(null))}
      >
        <DialogContent>
          <Box p={2}>
            {editLoyaltyModal?.id && (
              <DefineLoyaltyProgramForm
                loyaltyProgramData={editLoyaltyModal}
                isCreating={false}
                onReload={getProgramList}
                closeModal={() =>
                  dispatch(loyalty.actions.setEditProgram(null))
                }
              />
            )}
          </Box>
        </DialogContent>
        <IconButton
          aria-label="clear"
          onClick={() => dispatch(loyalty.actions.setEditProgram(null))}
          style={{
            position: 'absolute',
            top: 10,
            right: 10,
            width: 20,
            height: 20,
          }}
        >
          <Close fontSize="small" />
        </IconButton>
      </Dialog>
    </>
  );
};

export default LoyaltyProgramList;
