/* eslint-disable react/forbid-prop-types,react/require-default-props */
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { RadioGroup } from 'formik-material-ui';
import { Select, TextField } from 'formik-mui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { alert, coupons, membership, merchant } from '../../../state';
import { CustomInputField } from '../../custom';

const useStyles = makeStyles(() => ({
  fullwidthChild: {
    '& > div': {
      width: '100%',
      minWidth: '300px',
    },
  },
}));
const AddMembershipCouponPlan = ({
  isOpen,
  closeModal,
  selected,
  onReload,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { currency } = useSelector((state) =>
    merchant.selectors.selectMerchantDetails(state)
  );
  console.log(currency);
  const [isLoading, setIsLoading] = useState(false);
  const memberShipPlans = useSelector(membership.selectors.selectPlans);
  const couponPlans = useSelector(coupons.selectors.selectCouponPlans);
  const openAlert = (payload) => dispatch(alert.actions.open(payload));
  useEffect(() => {}, []);

  const body = (
    <Box>
      <Formik
        validationSchema={Yup.object().shape({
          membershipPlanId: Yup.string().required(t('validation.required')),
          couponPlanId: Yup.string().required(t('validation.required')),
          maxRedemption: Yup.number().min(0, t('minimumIsZero')),
        })}
        initialValues={{
          membershipPlanId: '',
          couponPlanId: '',
          description: '',
          maxRedemption: '',
          renewalType: '0',
          ...selected,
        }}
        onSubmit={async (values, { resetForm }) => {
          setIsLoading(true);
          try {
            const resp = await dispatch(
              membership.actions.setMembershipCoupon({
                ...values,
                renewalType: !values.renewalType ? '0' : values.renewalType,
              })
            );
            if (resp.payload) {
              openAlert({
                message: t('membership.couponPlan.createdSuccess'),
                severity: 'success',
              });
              resetForm();
              if (onReload) onReload();
              closeModal(true);
            } else if (resp.error) {
              openAlert({
                message:
                  resp.error.message || t('membership.couponPlan.createdFail'),
                severity: 'error',
              });
            }
          } catch (error) {
            console.log(error);
          } finally {
            setIsLoading(false);
          }
          return true;
        }}
      >
        {({ values, setFieldValue, handleSubmit }) => {
          return (
            <Form>
              <Box mt={2}>
                <Grid container spacing={5}>
                  <Grid item md={6} className={classes.fullwidthChild}>
                    <Field
                      component={Select}
                      as="select"
                      autoWidth={false}
                      fullWidth
                      required
                      label={t('membership.planName')}
                      name="membershipPlanId"
                      variant="outlined"
                      onChange={(event, val) => {
                        setFieldValue('membershipPlanId', val.props.value);
                      }}
                      // style={{ width: 320 }}
                      placeholder={t('membership.planName')}
                    >
                      <MenuItem value={undefined}>---</MenuItem>
                      {memberShipPlans.map(
                        (e) =>
                          e && (
                            <MenuItem value={e.id} key={e.id}>
                              {e.name}
                            </MenuItem>
                          )
                      )}
                    </Field>
                  </Grid>
                  <Grid item className={classes.fullwidthChild} md={6}>
                    <Field
                      component={Select}
                      as="select"
                      autoWidth={false}
                      fullWidth
                      required
                      label={t('membership.issueCouponsConfig.couponName')}
                      name="couponPlanId"
                      variant="outlined"
                      onChange={(event, val) => {
                        setFieldValue('couponPlanId', val.props.value);
                      }}
                      // style={{ width: 320 }}
                      placeholder={t(
                        'membership.issueCouponsConfig.couponName'
                      )}
                    >
                      <MenuItem value={undefined}>---</MenuItem>
                      {couponPlans.map(
                        (e) =>
                          e && (
                            <MenuItem value={e.id} key={e.id}>
                              {e.name}
                            </MenuItem>
                          )
                      )}
                    </Field>
                  </Grid>
                </Grid>
              </Box>
              <Box mt={2}>
                <Grid container>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      fullWidth
                      multiline
                      rows={4}
                      type="text"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label={t('membership.issueCouponsConfig.description')}
                      name="description"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box mt={2}>
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      style={{ padding: 7, marginRight: 5 }}
                    >
                      {t('membership.couponPlan.maxRedemptions')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      fullWidth
                      type="number"
                      removeArrows
                      name="maxRedemption"
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      variant="subtitle1"
                      style={{ padding: 7, marginRight: 5 }}
                      color="textSecondary"
                    >
                      {t('membership.couponPlan.note')}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box mt={2}>
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      style={{ padding: 7, marginRight: 5 }}
                    >
                      {t('membership.couponPlan.onMembershipRenewal')}
                    </Typography>
                  </Grid>
                  <Field component={RadioGroup} name="type" row>
                    <Grid item>
                      <FormControlLabel
                        value="true"
                        control={
                          <Radio
                            value={0}
                            color="primary"
                            name="renewalType"
                            checked={values.renewalType === '0'}
                          />
                        }
                        label={t(
                          'membership.issueCouponsConfig.issueNewCoupon'
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        value="false"
                        control={
                          <Radio
                            value={1}
                            color="primary"
                            name="renewalType"
                            checked={values.renewalType === '1'}
                          />
                        }
                        label={t(
                          'membership.issueCouponsConfig.continueExistingCoupon'
                        )}
                        disabled
                      />
                    </Grid>
                  </Field>
                </Grid>
              </Box>
              <Box mt={2}>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Button
                      onClick={handleSubmit}
                      loading={isLoading}
                      disabled={isLoading}
                      variant="contained"
                      color="primary"
                    >
                      {t('save')}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
  return (
    <Dialog open={isOpen} onClose={closeModal} fullWidth maxWidth="md">
      <DialogTitle>Associate a Coupon to a Plan</DialogTitle>
      <DialogContent>{body}</DialogContent>
      <IconButton
        aria-label="clear"
        onClick={closeModal}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          width: 20,
          height: 20,
        }}
      >
        <Close fontSize="small" />
      </IconButton>
    </Dialog>
  );
};

AddMembershipCouponPlan.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  selected: PropTypes.object,
  onReload: PropTypes.func,
};

export default AddMembershipCouponPlan;
