import { Box } from '@material-ui/core';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import moment from 'moment';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { merchant, loyalty } from '../../../state';
import { TransactionTable } from '../../custom';

const LoyaltyTransactionsModal = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const rowKeys = [
    'partnerOrder.partner',
    'partnerOrder.merchantId',
    'createdAt',
    'partnerOrder.orderId',
    'amount',
    'pointsEarned',
    'pointsRedeemed',
  ];
  const colsTitles = [
    t('posSystem'),
    t('merchantId'),
    t('date'),
    t('orderId'),
    t('loyalty.transactionAmount'),
    t('loyalty.pointsEarned'),
    t('loyalty.pointsRedeemed'),
  ];

  const { total = 0, data = [], isLoading = true } = useSelector(
    loyalty.selectors.selectLoyaltyTransactions
  );
  const [isOpen, setIsOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(undefined);
  const [params, setParams] = React.useState({
    limit: 10,
    page: 1,
    update: new Date().getTime(),
  });

  const init = async ({ update, ...p }) => {
    dispatch(
      loyalty.actions.getLoyaltyTransactions({
        ...p,
        loyaltyId: selected.id,
      })
    );
  };

  const onClose = () => setIsOpen(false);

  const onShowModal = (row) => {
    setSelected(row);
    setIsOpen(true);
  };

  React.useEffect(() => {
    if (isOpen && selected !== undefined) init(params);
  }, [params, isOpen, selected]);

  React.useImperativeHandle(ref, () => ({
    showModal: onShowModal,
    closeModal: onClose,
  }));
  const currency = useSelector(merchant.selectors.selectCurrency);

  const mods = {
    createdAt: (value) =>
      value && moment(`${value}Z`).format('MMM Do YYYY, HH:mm'),
    amount: (val) =>
      t('cardValue', {
        amount: val,
        formatParams: {
          amount: { currency },
        },
      }),
    'partnerOrder.orderId': (val, row) => {
      if (val) {
        return val;
      }
      if (row.type === 0) {
        return t('loyalty.addedBySeller');
      }
      if (row.type === 3) {
        return t('loyalty.signOnBonus');
      }
      return '';
    },
  };

  const pagination = {
    total,
    pageSize: params.limit,
    page: params.page,
    handleChangePage: (event, page) => {
      setParams({ ...params, page: page + 1 });
    },
    handleChangeRowsPerPage: (event) => {
      setParams({ ...params, limit: event.target.value, page: 1 });
    },
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      PaperProps={{
        style: {
          maxWidth: 1000,
        },
      }}
    >
      <DialogTitle>{`Loyalty #: ${selected?.code || ''}`}</DialogTitle>
      <DialogContent>
        <Box py={2}>
          <TransactionTable
            cols={colsTitles}
            rowKeys={rowKeys}
            data={data}
            mods={mods}
            isLoading={isLoading}
            pagination={pagination}
            noDataMsg={t('noDataMsg')}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
});

export default LoyaltyTransactionsModal;
