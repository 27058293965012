/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Button,
  Tooltip,
  TextField,
  CircularProgress,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useDebounce } from 'use-debounce';
import { alert, coupons, membership } from '../../../state';
import { TransactionTable, DownloadCSV } from '../../custom';
import CsvIcon from '../../../images/Memberships/csv.png';
import CsvIconDisabled from '../../../images/Memberships/csv-disabled.png';
import Resend from '../../../images/Memberships/resend.png';
import searchIcon from '../../../images/Memberships/search.png';

const rowKeys = [
  'createdAt',
  'firstName',
  'lastName',
  'email',
  'code',
  'maxRedemptions',
  'expirationDate',
  'couponPlan.name',
  'discount',
  'description',
  'id',
  // 'membershipCouponPlan.membershipPlan.name',
];
const membershipStatuses = [
  'Active',
  'Canceled',
  'Pending',
  'Deactivated',
  'Expired',
  '',
];

const useStyles = makeStyles(() => ({
  fullwidthChild: {
    '& > div': {
      width: '100%',
      minWidth: '300px',
    },
  },
}));
const CouponList = () => {
  const [params, setParams] = React.useState({
    limit: 10,
    page: 1,
    search: '',
  });
  const [value] = useDebounce(params, 700);
  const [isDownloading, setIsDownloading] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const search = () => {
    dispatch(coupons.actions.getCouponList(params));
  };
  useEffect(() => {
    search();
  }, []);

  useEffect(() => {
    if (value?.search === '' || value?.search?.length > 2) {
      dispatch(coupons.actions.getCouponList(params));
    }
  }, [value]);

  const { t } = useTranslation();

  const colsTitles = [
    t('coupons.issueDate'),
    t('coupons.firstName'),
    t('coupons.lastName'),
    t('coupons.email'),
    t('coupons.couponCode'),
    t('coupons.maxRedemptions'),
    t('coupons.expirationDate'),
    t('coupons.discountName'),
    t('coupons.discountDescription'),
    t('coupons.campaignName'),
    '',
    // t('coupons.membershipPlan'),
  ];

  const isLoading = useSelector(coupons.selectors.selectIsLoading);
  const { data: couponList, total } = useSelector(
    coupons.selectors.selectCouponList
  );
  const csv = useSelector(coupons.selectors.selectCsv);
  const refLink = React.useRef();
  const handleDownload = () => {
    setIsDownloading(true);
    if (isDownloading) {
      return;
    }
    dispatch(coupons.actions.downloadCouponCSV(params))
      .then(() => {
        refLink.current.link.click();
      })
      .then(() => {
        setIsDownloading(false);
        dispatch(
          alert.actions.open({
            message: t('csvDownloadSuccess'),
            severity: 'success',
          })
        );
      });
  };

  const [activeRowEmail, setActiveRowEmail] = useState(null);

  const resendEmail = async (id) => {
    setActiveRowEmail(id);
    const res = await dispatch(coupons.actions.resendEmail(id));
    if (!res.error) {
      dispatch(
        alert.actions.open({
          message: 'Email Sent',
          severity: 'success',
        })
      );
    }
    setActiveRowEmail(null);
  };

  const mods = {
    status: (val) => membershipStatuses[val] || 'Deactivated',
    expirationDate: (val) =>
      val ? moment(`${val}Z`).format('MMM DD YYYY, HH:mm:ss') : '',
    createdAt: (val) =>
      val ? moment(`${val}Z`).format('MMM DD YYYY, HH:mm:ss') : '',
    maxRedemptions: (val) => (val === -1 ? '' : val),
    id: (id, row) =>
      activeRowEmail !== row.id ? (
        <Tooltip
          title={t('membership.membershipList.resendEmail')}
          placement="top"
          arrow
        >
          <IconButton
            onClick={() => resendEmail(id, row.email)}
            cursor="pointer"
            disabled={activeRowEmail}
          >
            <img
              src={Resend}
              alt={t('membership.membershipList.resendEmail')}
              style={{ width: 20 }}
            />
          </IconButton>
        </Tooltip>
      ) : (
        <CircularProgress color="primary" size={14} />
      ),
  };
  const handleChangePage = (event, page) => {
    setParams({ ...params, page: page + 1 });
  };
  const handleChangeRowsPerPage = (event) => {
    setParams({ ...params, limit: event.target.value, page: 1 });
  };

  const pagination = {
    total,
    pageSize: params.limit,
    page: params.page,
    handleChangePage,
    handleChangeRowsPerPage,
  };

  const searchBox = () => (
    <Grid item>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item className={classes.fullwidthChild} xs={12} md={9}>
          <TextField
            autoWidth={false}
            fullWidth
            label={t('coupons.fName')}
            name="planId"
            variant="outlined"
            onChange={(e) => {
              setParams({ ...params, search: e.target.value });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => search()}
                    style={{ marginRight: -14 }}
                  >
                    <img
                      src={searchIcon}
                      alt={t('search')}
                      style={{
                        width: 25,
                        height: 25,
                        paddingTop: 2,
                        cursor: 'pointer',
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid
          item
          style={{
            marginBottom: -5,
          }}
        >
          <DownloadCSV
            handleDownload={handleDownload}
            refLink={refLink}
            data={csv}
            filename="coupon-list"
          >
            <Tooltip
              title={isDownloading ? 'CSV is Downloading' : 'Export CSV'}
              placement="top"
              arrow
            >
              <Button onClick={handleDownload}>
                <img
                  src={isDownloading ? CsvIconDisabled : CsvIcon}
                  alt="Export CSV"
                  style={{ width: 30, cursor: 'pointer' }}
                />
              </Button>
            </Tooltip>
          </DownloadCSV>
        </Grid>
      </Grid>
    </Grid>
  );

  useEffect(() => {
    dispatch(membership.actions.getMembershipPlans());
  }, []);

  return (
    <Box>
      <TransactionTable
        cols={colsTitles}
        rowKeys={rowKeys}
        data={couponList}
        searchBox={searchBox}
        pagination={pagination}
        mods={mods}
        isLoading={isLoading}
        noDataMsg={t('noDataMsg')}
      />
    </Box>
  );
};

export default CouponList;
