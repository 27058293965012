/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  Box,
  Grid,
  Button,
  IconButton,
  makeStyles,
  TextField,
  Radio,
  Typography,
  Divider,
  InputAdornment,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import moment from 'moment/moment';
import { useDebounce } from 'use-debounce';
import { alert, ticket, merchant } from '../../../state';
import { DownloadCSV, TransactionTable } from '../../custom';
import Eye from '../../../images/Memberships/eye.png';
import DownloadIcon from '../../../images/Memberships/download.png';
import OrderPreviewModal from './Modals/OrderPreviewModal';
import searchIcon from '../../../images/Memberships/search.png';

const useStyles = makeStyles(() => ({
  fullwidthChild: {
    '& > div': {
      width: '100%',
      minWidth: '300px',
    },
  },
}));

const EventStatus = {
  Published: 1,
  Done: 2,
};

const OrderStatus = {
  Draft: 0,
  Done: 1,
};

const OrderList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const [keyword, setKeyword] = React.useState('');
  const [eventStatusValue, setEventStatusValue] = React.useState(
    EventStatus.Published
  );
  const [params, setParams] = React.useState({
    limit: 10,
    page: 1,
    status: eventStatusValue,
    keyword: '',
  });
  const [debounce] = useDebounce(params, 700);
  const currency = useSelector(merchant.selectors.selectCurrency);

  React.useEffect(() => {
    dispatch(ticket.actions.getOrders(params));
  }, []);

  const colsTitles = [
    t('tickets.eventDateTime'),
    t('tickets.eventName'),
    `${t('tickets.order')} #`,
    t('tickets.orderDate'),
    t('tickets.amount'),
    t('firstName'),
    t('lastName'),
    t('email'),
    t('status'),
    '',
  ];

  const rowKeys = [
    'startTime',
    'eventName',
    'orderNumber',
    'createdAt',
    'amount',
    'firstName',
    'lastName',
    'email',
    'status',
    'id',
  ];

  const [modalItem, setModalItem] = React.useState(false);

  const mods = {
    startTime: (value, item) =>
      value && `${moment(`${item.startTime}Z`).format('MMM Do YYYY, HH:mm')}`,
    createdAt: (value) =>
      value && moment(`${value}Z`).format('MMM Do YYYY, HH:mm'),
    amount: (value) =>
      t('cardValue', {
        amount: value,
        formatParams: {
          amount: { currency },
        },
      }),
    status: (value) => {
      switch (value) {
        case OrderStatus.Draft:
          return 'Draft';
        case OrderStatus.Done:
          return 'Done';
        default:
          return '';
      }
    },
    id: (val, item) => (
      <Tooltip title={t('edit')} placement="top" arrow>
        <IconButton
          aria-haspopup="true"
          onClick={() => {
            setModalItem(item);
          }}
          cursor="pointer"
        >
          <img src={Eye} alt="" style={{ width: 20 }} />
        </IconButton>
      </Tooltip>
    ),
  };

  const isLoading = useSelector(ticket.selectors.selectIsLoading);
  const { data: orders, total } = useSelector(ticket.selectors.selectOrders);

  const handleChangePage = (event, page) => {
    setParams({ ...params, page: page + 1, status: eventStatusValue });
  };
  const handleChangeRowsPerPage = (event) => {
    setParams({
      ...params,
      limit: event.target.value,
      page: 1,
      status: eventStatusValue,
    });
  };

  const handleChangeRadio = (event) => {
    setEventStatusValue(Number(event.target.value));
    setParams({
      ...params,
      status: Number(event.target.value),
    });
  };

  const pagination = {
    total,
    pageSize: params.limit,
    page: params.page,
    handleChangePage,
    handleChangeRowsPerPage,
  };

  const csv = useSelector(ticket.selectors.selectCsv);
  const refLink = React.useRef();

  const handleDownload = () => {
    dispatch(ticket.actions.downloadOrdersCSV())
      .then(() => {
        refLink.current.link.click();
      })
      .then(() =>
        dispatch(
          alert.actions.open({
            message: t('csvDownloadSuccess'),
            severity: 'success',
          })
        )
      );
  };

  const search = () => {
    setParams({
      ...params,
      keyword,
    });
  };

  const searchBox = () => (
    <Grid item>
      <Grid>
        <Box>
          <Box
            style={{
              display: 'flex',
              textAlign: 'center',
              alignItems: 'center',
            }}
          >
            <Radio
              value={EventStatus.Published}
              name="eventStatus"
              color="primary"
              checked={eventStatusValue === EventStatus.Published}
              onChange={handleChangeRadio}
            />
            <Typography className={classes.mr10}>
              {t('tickets.statusPublish')}
            </Typography>
            <Radio
              value={EventStatus.Done}
              name="eventStatus"
              color="primary"
              checked={eventStatusValue === EventStatus.Done}
              onChange={handleChangeRadio}
            />
            <Typography className={classes.mr10}>
              {t('tickets.statusDone')}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item className={classes.fullwidthChild}>
          <TextField
            fullWidth
            label={t('tickets.orderList.fName')}
            name="keyword"
            variant="outlined"
            onChange={(val) => {
              setKeyword(val.target.value);
            }}
            style={{ height: '47px', minWidth: 400 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => search()}
                    style={{ marginRight: -14 }}
                  >
                    <img
                      src={searchIcon}
                      alt={t('search')}
                      style={{
                        width: 25,
                        height: 25,
                        paddingTop: 2,
                        cursor: 'pointer',
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item style={{ marginBottom: -6 }}>
          <DownloadCSV
            handleDownload={handleDownload}
            refLink={refLink}
            data={csv}
            filename="order-list"
          >
            <Tooltip
              title={t('membership.membershipList.downloadCSV')}
              placement="top"
              arrow
            >
              <Button onClick={handleDownload}>
                <img
                  src={DownloadIcon}
                  alt={t('membership.membershipList.downloadCSV')}
                  style={{ width: 30, cursor: 'pointer' }}
                />
              </Button>
            </Tooltip>
          </DownloadCSV>
        </Grid>
      </Grid>
    </Grid>
  );

  React.useEffect(() => {
    search();
  }, [keyword]);

  React.useEffect(() => {
    if (debounce?.keyword === '' || debounce?.keyword?.length > 2) {
      dispatch(ticket.actions.getOrders(params));
    }
  }, [debounce]);

  return (
    <>
      <Box pb={2}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Typography variant="h5">{t('tickets.ticketOrders')}</Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box py={2}>
        <TransactionTable
          cols={colsTitles}
          rowKeys={rowKeys}
          data={orders}
          mods={mods}
          isLoading={isLoading}
          pagination={pagination}
          searchBox={searchBox}
          noDataMsg={t('noDataMsg')}
        />
      </Box>

      <OrderPreviewModal item={modalItem} onClose={() => setModalItem(false)} />
    </>
  );
};
export default OrderList;
