/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { Box, Grid, Button, TextField } from '@material-ui/core';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DateRangePicker from '@mui/lab/DateRangePicker';
import { alert, membership } from '../../../state';
import { TransactionTable, DownloadCSV } from '../../custom';

const mods = {
  checkInTime: (val) => moment(`${val}Z`).format('MMM Do YYYY, HH:mm'),
};

const CheckInList = () => {
  const [params, setParams] = React.useState({ limit: 10, page: 1 });
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(membership.actions.getCheckInList(params));
  }, [params]);
  const { t } = useTranslation();

  const colsTitles = [
    t('membership.checkInList.code'),
    t('membership.checkInList.firstName'),
    t('membership.checkInList.lastName'),
    t('membership.checkInList.checkInDateTime'),
    t('membership.checkInList.email'),
    t('membership.checkInList.phone'),
  ];

  const rowKeys = [
    'code',
    'firstName',
    'lastName',
    'checkInTime',
    'email',
    'phone',
  ];

  const isLoading = useSelector(membership.selectors.selectIsLoading);
  const { data: checkInList, total } = useSelector(
    membership.selectors.selectCheckInList
  );
  const csv = useSelector(membership.selectors.selectCsv);
  const refLink = React.useRef();
  const [value, setValue] = useState([null, null]);

  const handleDownload = () => {
    dispatch(membership.actions.downloadCheckInCSV())
      .then(() => {
        refLink.current.link.click();
      })
      .then(() =>
        dispatch(
          alert.actions.open({
            message: t('csvDownloadSuccess'),
            severity: 'success',
          })
        )
      );
  };

  const search = () => {
    setParams({
      ...params,
      startDate: value[0] ? value[0].format('MM/DD/YYYY') : null,
      endDate: value[1] ? value[1].format('MM/DD/YYYY') : null,
    });
  };

  const handleChangePage = (event, page) => {
    setParams({ ...params, page: page + 1 });
    // search();
  };
  const handleChangeRowsPerPage = (event) => {
    setParams({ ...params, limit: event.target.value, page: 1 });
    // search();
  };

  const pagination = {
    total,
    pageSize: params.limit,
    page: params.page,
    handleChangePage,
    handleChangeRowsPerPage,
  };

  const searchBox = () => (
    <Grid item>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item>
          <Box mx={1}>
            <DateRangePicker
              startText={t('membership.checkInList.startDate')}
              endText={t('membership.checkInList.endDate')}
              value={value}
              onChange={(newValue) => {
                setValue(newValue);
              }}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> </Box>
                  <TextField {...endProps} />
                </>
              )}
            />
          </Box>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={search}
            type="submit"
          >
            {t('search')}
          </Button>
        </Grid>
        <Grid item>
          <DownloadCSV
            handleDownload={handleDownload}
            refLink={refLink}
            data={csv}
            filename="check-in-list"
          />
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Box py={2}>
        <TransactionTable
          cols={colsTitles}
          rowKeys={rowKeys}
          data={checkInList}
          mods={mods}
          searchBox={searchBox}
          pagination={pagination}
          isLoading={isLoading}
          noDataMsg={t('noDataMsg')}
        />
      </Box>
    </>
  );
};

export default CheckInList;
