/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  InputAdornment,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import moment from 'moment/moment';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Delete, FileCopyTwoTone } from '@material-ui/icons';
import { useDebounce } from 'use-debounce';

import { alert, merchant, ticket } from '../../../state';
import { TransactionTable } from '../../custom';
import Editing from '../../../images/Memberships/editing.png';
import Reminder from '../../../images/Tickets/reminder.png';
import EditTicketEventModal from './Modals/EditTicketEventModal';
import RepeatTicketEventModal from './Modals/RepeatTicketEventModal';
import ConfirmationDialog from '../GiftCardDetails/ConfirmDialog';
import LandingPageMenu from './LandingPageMenu';
import searchIcon from '../../../images/Memberships/search.png';

const TicketEventsList = () => {
  const PintunaAppUrl =
    process.env.GATSBY_APP_PORTAL_URL || 'https://app.pintuna.com/';
  const merchantDetails = useSelector((state) =>
    merchant.selectors.selectMerchantDetails(state)
  );
  const [params, setParams] = React.useState({
    limit: 10,
    page: 1,
    keyword: '',
    showPastEvents: false,
  });
  const isLoading = useSelector(ticket.selectors.selectIsLoading);
  const { data: events, total } = useSelector(
    ticket.selectors.selectTicketEvents
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const colsTitles = [
    t('tickets.event.startDateTime'),
    t('tickets.event.endDateTime'),
    t('tickets.event.title'),
    t('tickets.event.tagLine'),
    t('status'),
    t('tickets.event.eventUrl'),
    t('tickets.event.ticketUrl'),
    t('tickets.event.checkInUrl'),
    '',
  ];

  const rowKeys = [
    'startTime',
    'endTime',
    'title',
    'tagLine',
    'status',
    'url',
    'ticketUrl',
    'checkInUrl',
    'id',
  ];
  const [modalItem, setModalItem] = React.useState(false);
  const [value] = useDebounce(params, 700);

  const getTicketEvents = () => {
    dispatch(ticket.actions.getTicketEvents(params));
  };

  React.useEffect(() => {
    if (value?.keyword === '' || value?.keyword?.length > 2) {
      getTicketEvents();
    }
  }, [value]);
  const handleCopy = () => {
    const payload = {
      message: t('copiedToClipboard'),
      severity: 'success',
    };
    dispatch(alert.actions.open(payload));
  };

  const openAlert = (payload) => dispatch(alert.actions.open(payload));
  const [id, setId] = useState('');

  const deleteTicketEvent = async () => {
    const response = await dispatch(ticket.actions.deleteTicketEvent(id));
    setId('');
    if (response.payload) {
      openAlert({
        message: t('tickets.event.deletedSuccessfully'),
        severity: 'success',
      });
      getTicketEvents();
    }
  };

  const mods = {
    startTime: (val) => val && moment(`${val}Z`).format('MMM Do YYYY, hh:mm A'),
    endTime: (val) => val && moment(`${val}Z`).format('MMM Do YYYY, hh:mm A'),
    status: (val) => (
      <Typography
        variant="button"
        style={{ color: val === 'Published' ? '#4caf50' : '' }}
      >
        {val}
      </Typography>
    ),
    url: (val, item) => (
      <Tooltip title={t('loyalty.copyLink')} placement="top" arrow>
        <IconButton style={{ padding: 0, width: 30 }} cursor="pointer">
          <CopyToClipboard
            text={`${PintunaAppUrl}events/detail/?event_id=${item?.id}`}
            onCopy={handleCopy}
          >
            <FileCopyTwoTone color="primary" cursor="pointer" />
          </CopyToClipboard>
        </IconButton>
      </Tooltip>
    ),
    ticketUrl: (val, item) => (
      <Tooltip title={t('loyalty.copyLink')} placement="top" arrow>
        <IconButton style={{ padding: 0, width: 30 }} cursor="pointer">
          <CopyToClipboard
            text={`${PintunaAppUrl}tickets/events/${item?.id}`}
            onCopy={handleCopy}
          >
            <FileCopyTwoTone color="primary" cursor="pointer" />
          </CopyToClipboard>
        </IconButton>
      </Tooltip>
    ),
    checkInUrl: (val, item) => (
      <Tooltip title={t('loyalty.copyLink')} placement="top" arrow>
        <IconButton style={{ padding: 0, width: 30 }} cursor="pointer">
          <CopyToClipboard
            text={`${PintunaAppUrl}tickets/${merchantDetails?.id}/events/${item?.id}/check-in`}
            onCopy={handleCopy}
          >
            <FileCopyTwoTone color="primary" cursor="pointer" />
          </CopyToClipboard>
        </IconButton>
      </Tooltip>
    ),
    id: (val, item) => (
      <Grid
        container
        alignItems="flex-start"
        direction="row"
        style={{ width: 140 }}
      >
        <Grid item>
          <Tooltip title={t('edit')} placement="top" arrow>
            <IconButton
              aria-haspopup="true"
              onClick={() => {
                setModalItem('edit');
                dispatch(ticket.actions.setCurrentTicketEvent(item));
              }}
              cursor="pointer"
            >
              <img src={Editing} alt="" style={{ width: 20 }} />
            </IconButton>
          </Tooltip>
        </Grid>
        {item.parentEventId === null && (
          <Grid item>
            <Tooltip
              title={t('tickets.event.repeatEvent')}
              placement="top"
              arrow
            >
              <IconButton
                aria-haspopup="true"
                onClick={() => {
                  setModalItem('repeat');
                  dispatch(ticket.actions.setCurrentTicketEvent(item));
                }}
                cursor="pointer"
              >
                <img src={Reminder} alt="" style={{ width: 20 }} />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
        {item.status === 'Draft' && (
          <Tooltip title={t('delete')} placement="top" arrow>
            <IconButton onClick={() => setId(item.id)} cursor="pointer">
              <Delete />
            </IconButton>
          </Tooltip>
        )}
      </Grid>
    ),
  };

  const handleChangePage = (event, page) => {
    setParams({ ...params, page: page + 1 });
  };
  const handleChangeRowsPerPage = (event) => {
    setParams({ ...params, limit: event.target.value, page: 1 });
  };

  const pagination = {
    total,
    pageSize: params.limit,
    page: params.page,
    handleChangePage,
    handleChangeRowsPerPage,
  };

  const searchBox = () => (
    <Grid item xs={12} md={12}>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item xs={6} md={5}>
          <TextField
            value={params.keyword}
            fullWidth
            placeholder={t('tickets.event.searchPlaceholder')}
            type="string"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            name="keyword"
            onChange={(val) => {
              setParams({ ...params, page: 1, keyword: val.target.value });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => getTicketEvents()}
                    style={{ marginRight: -14 }}
                  >
                    <img
                      src={searchIcon}
                      alt={t('search')}
                      style={{
                        width: 25,
                        height: 25,
                        paddingTop: 2,
                        cursor: 'pointer',
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item style={{ marginBottom: -11 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={params.showPastEvents}
                onChange={(e) =>
                  setParams({ ...params, showPastEvents: e.target.checked })
                }
                name="showPastEvents"
                color="primary"
              />
            }
            label={t('tickets.event.showPastEvents')}
            sx={{ whiteSpace: 'nowrap' }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
  return (
    <>
      <Box pb={2}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Typography variant="h5">{t('tickets.manageEvents')}</Typography>
          </Grid>
          <Grid item>
            <Box display="flex" alignItems="center">
              <LandingPageMenu />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box py={2}>
        <TransactionTable
          cols={colsTitles}
          rowKeys={rowKeys}
          data={events}
          mods={mods}
          isLoading={isLoading}
          pagination={pagination}
          searchBox={searchBox}
          noDataMsg={t('noDataMsg')}
        />
      </Box>
      <EditTicketEventModal
        isOpen={modalItem === 'edit'}
        closeModal={() => {
          setModalItem(false);
          getTicketEvents();
          dispatch(ticket.actions.setCurrentTicketEvent({}));
        }}
        onReload={() => getTicketEvents()}
      />
      <RepeatTicketEventModal
        isOpen={modalItem === 'repeat'}
        closeModal={() => {
          setModalItem(false);
          getTicketEvents();
          dispatch(ticket.actions.setCurrentTicketEvent({}));
        }}
        onReload={() => getTicketEvents()}
      />
      <ConfirmationDialog
        open={id}
        setOpen={setId}
        title={t('confirmation')}
        content={t('deleteConfirmationQuestion')}
        actionOk={() => deleteTicketEvent()}
      />
    </>
  );
};
export default TicketEventsList;
