/* eslint-disable react/destructuring-assignment,no-plusplus,no-await-in-loop */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import CSVReader from 'react-csv-reader';
import { Close, Check } from '@material-ui/icons';
import moment from 'moment';
import { alert, merchant, storeCredit } from '../../../state';
import { TransactionTable } from '../../custom';
import ConfirmationDialog from '../GiftCardDetails/ConfirmDialog';

const useStyles = makeStyles(() => ({
  fullwidthChild: {
    '& > div': {
      width: '100%',
    },
  },
  '@keyframes blinker': {
    from: { opacity: 1 },
    to: { opacity: 0.5 },
  },
  blink: {
    animationName: '$blinker',
    animationDuration: '1s',
    animationTimingFunction: 'ease',
    animationIterationCount: 'infinite',
  },
  generateButton: {
    backgroundColor: '#284C0D !important',
    color: '#fff !important',
    minWidth: '190px',
  },
}));
const StoreCreditsImport = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [noDataMsg, setNoDataMsg] = React.useState(t('storeCredit.noData'));
  const dispatch = useDispatch();
  const openAlert = (payload) => dispatch(alert.actions.open(payload));

  const isLoading = useSelector(storeCredit.selectors.selectIsLoading);
  const currency = useSelector(merchant.selectors.selectCurrency);
  const storeCreditConfig = useSelector(
    storeCredit.selectors.selectStoreCreditConfig
  );
  const syncing = useSelector(storeCredit.selectors.selectSyncing);
  const colsTitles = [
    t('firstName'),
    t('lastName'),
    t('email'),
    t('storeCredit.amount'),
    t('expiration'),
    t('sent'),
  ];
  const rowKeys = [
    'First Name',
    'Last Name',
    'Email',
    'Amount',
    'Expiration',
    'Sent',
  ];

  const mods = {
    Amount: (val) =>
      val &&
      t('cardValue', {
        amount: val,
        formatParams: {
          amount: { currency: currency || 'USD' },
        },
      }),
    Sent: (val) => {
      switch (val) {
        case true:
          return (
            <Check color="secondary" size={14} style={{ color: 'green' }} />
          );
        case 'loading':
          return <CircularProgress color="primary" size={14} />;
        case 'failed':
          return <Close color="error" size={14} />;
        default:
          return '';
      }
    },
  };
  useEffect(() => {
    if (isLoading) {
      setNoDataMsg(t('storeCredit.noData'));
    }
  }, [isLoading]);
  const [results, setResults] = useState([{}, {}, {}, {}, {}]);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isImported, setImported] = useState(false);
  const handleCsvUpload = (data) => {
    setResults(data);
  };
  const parseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => header.trim(),
  };

  const downloadCSV = () => {
    fetch('../../../SampleStoreCreditImportFile.csv').then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'SampleStoreCreditImportFile.csv';
        alink.click();
      });
    });
  };

  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });

  const pagination = {
    total: results.length,
    pageSize: params.limit,
    page: params.page,
    handleChangePage: (event, page) => {
      setParams({ ...params, page: page + 1 });
    },
    handleChangeRowsPerPage: (event) => {
      setParams({ ...params, limit: event.target.value, page: 1 });
    },
  };

  return (
    <>
      {storeCreditConfig.businessId && (
        <>
          <Box mt={2}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} lg="auto">
                <Button variant="contained" color="primary" component="label">
                  {t('storeCredit.storeCreditsImport.importFromCSVFile')}
                  <CSVReader
                    inputId="CSVReader"
                    inputStyle={{ display: 'none' }}
                    onFileLoaded={handleCsvUpload}
                    parserOptions={parseOptions}
                  />
                </Button>
              </Grid>
              <Grid item>
                <Typography
                  variant="a"
                  color="textSecondary"
                  onClick={downloadCSV}
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    display: 'flex',
                    verticalAlign: 'center',
                  }}
                >
                  {t('storeCredit.storeCreditsImport.sampleCSV')}
                </Typography>
              </Grid>
              <Grid item style={{ marginLeft: 'auto' }}>
                <Box ml={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.generateButton}
                    onClick={() => setIsConfirm(true)}
                    disabled={
                      isLoading ||
                      syncing ||
                      !results.length ||
                      !results[0].Email
                    }
                  >
                    {syncing ? (
                      <CircularProgress size={24} />
                    ) : (
                      t('storeCredit.storeCreditsImport.btnGenerate')
                    )}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      <Box py={2}>
        <TransactionTable
          cols={colsTitles}
          rowKeys={rowKeys}
          data={results.slice(
            (params.page - 1) * params.limit,
            params.page * params.limit
          )}
          mods={mods}
          isLoading={isLoading}
          noDataMsg={noDataMsg}
          pagination={pagination}
          searchBox={() => (
            <Grid item>
              <Box>
                <Typography variant="h6">
                  {t('storeCredit.storeCreditsImport.totalAmount')}:{' '}
                  {t('cardValue', {
                    amount: results.length
                      ? results.reduce((a, b) => {
                          return (
                            Number(a) +
                            Number(
                              (b.Amount ? b.Amount.toString() : '0').match(
                                /(\d+)/
                              )[1]
                            )
                          );
                        }, 0)
                      : 0,
                    formatParams: {
                      amount: { currency },
                    },
                  })}
                </Typography>
              </Box>
            </Grid>
          )}
        />
      </Box>
      <ConfirmationDialog
        open={isImported}
        setOpen={setImported}
        title=""
        isConfirmation={false}
        content={t('storeCredit.storeCreditsImport.storeSuccessfully')}
        actionOk={() => setImported(false)}
      />
      <ConfirmationDialog
        open={isConfirm}
        setOpen={setIsConfirm}
        title={t('confirmation')}
        content={t('storeCredit.storeCreditsImport.confirmGenerate')}
        actionOk={async () => {
          setIsConfirm(false);
          const updatedArray = results;
          for (let i = 0; i < updatedArray.length; i++) {
            const row = updatedArray[i];
            updatedArray[i].Sent = 'loading';
            setResults(updatedArray);
            try {
              const resp = await dispatch(
                storeCredit.actions.importStoreCredits({
                  businessId: storeCreditConfig.businessId,
                  data: [
                    {
                      firstName: row['First Name'],
                      lastName: row['Last Name'],
                      amount: Number(
                        (row.Amount ? row.Amount.toString() : '0').match(
                          /(\d+)/
                        )[1] || '0'
                      ),
                      email: row.Email,
                      expirationDate:
                        row.Expiration &&
                        moment(row.Expiration, 'YYYY-MM-DD').isValid()
                          ? moment(row.Expiration, 'YYYY-MM-DD').format(
                              'MM/DD/YYYY'
                            )
                          : null,
                    },
                  ],
                })
              );
              if (resp.error) {
                updatedArray[i].Sent = 'failed';
                setResults(updatedArray);
                openAlert({
                  message:
                    resp.error?.message ||
                    t('weCannotProcessPaymentsPleaseTryLater'),
                  severity: 'error',
                });
              } else if (resp.payload) {
                updatedArray[i].Sent = true;
                setResults(updatedArray);
              }
            } catch (error) {
              console.log(error);
            }
          }
          if (!updatedArray.some((r) => r.Sent !== true)) {
            openAlert({
              message: `Generated ${
                results.filter((r) => r.Amount).length
              } store credits for total value of ${t('cardValue', {
                amount: results.length
                  ? results.reduce((a, b) => {
                      return (
                        Number(a) +
                        Number(
                          (b.Amount ? b.Amount.toString() : '0').match(
                            /(\d+)/
                          )[1]
                        )
                      );
                    }, 0)
                  : 0,
                formatParams: {
                  amount: { currency },
                },
              })}`,
              severity: 'succeed',
            });
            setImported(true);
            setResults([{}, {}, {}, {}, {}]);
          } else {
            openAlert({
              message: t('weCannotProcessPaymentsPleaseTryLater'),
              severity: 'error',
            });
          }
        }}
      />
    </>
  );
};

export default StoreCreditsImport;
