import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import CardSection from '../GiftCardDetails/CardSection';
import { merchant } from '../../../state';

const LoyaltyUploadUmage = () => {
  const { values, handleSubmit, isSubmitting } = useFormikContext();
  const { t } = useTranslation();
  const isLoading = false;
  const imgIsLoading = false;
  const dispatch = useDispatch();
  const setGiftCardImage = (payload) =>
    dispatch(merchant.actions.setGiftCardImage(payload));

  return (
    <>
      <Grid container>
        <Grid
          container
          item
          spacing={2}
          xs={12}
          md={8}
          justifyContent="space-between"
        >
          <Grid item xs={6}>
            <Typography>{t('loyalty.uploadDescription')}</Typography>
          </Grid>
          <Grid item xs={6}>
            <CardSection
              isLoading={isLoading}
              imgIsLoading={imgIsLoading}
              merchantDetails={values}
              merchantCreatives={values}
              setGiftCardImage={setGiftCardImage}
              type="loyalty"
            />
          </Grid>
        </Grid>
      </Grid>
      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          type="submit"
          disabled={isSubmitting}
        >
          {isSubmitting ? <CircularProgress size={24} /> : t('saveAndContinue')}
        </Button>
      </Box>
    </>
  );
};

export default LoyaltyUploadUmage;
