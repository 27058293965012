/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import {
  Box,
  Grid,
  Button,
  Typography,
  Divider,
  TextField,
  Tooltip,
  IconButton,
  CircularProgress,
  InputAdornment,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { AddCircle } from '@material-ui/icons';
import { useDebounce } from 'use-debounce';
import SEO from '../../seo';
import { alert, loyalty } from '../../../state';
import { DownloadCSV, TransactionTable } from '../../custom';
import CsvResultDialog from './CsvResultDialog';
import AddLoyaltyPointModal from './AddLoyaltyPointModal';
import LoyaltyTransactionsModal from './LoyaltyTransactionsModal';
import ExportImage from '../../../images/export.png';
import Resend from '../../../images/Memberships/resend.png';
import searchIcon from '../../../images/Memberships/search.png';

const rowKeys = [
  'programName',
  'firstName',
  'lastName',
  'email',
  'phone',
  'birthMonth',
  'point',
  'totalPoint',
  'rewardedTimes',
  'redeemedTimes',
  'lastEarned',
  'lastReward',
  'lastRedeemed',
  'createdAt',
  'addPoints',
];

const LoyaltyList = () => {
  const [params, setParams] = React.useState({ limit: 10, page: 1 });
  const [keyword, setKeyword] = React.useState('');
  const [value] = useDebounce(params, 700);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [activeRow, setActiveRow] = useState(null);
  const refLoyaltyTransactionsModal = React.useRef(null);

  const colsTitles = [
    t('loyalty.loyaltyProgramName'),
    t('loyalty.firstName'),
    t('loyalty.lastName'),
    t('loyalty.emailAddress'),
    t('loyalty.mobileNumber'),
    t('loyalty.birthdayMonth'),
    t('loyalty.currentPoints'),
    t('loyalty.totalPointsEarned'),
    t('loyalty.totalRewardsEarned'),
    t('loyalty.totalRewardsRedeemed'),
    t('loyalty.lastPointsEarned'),
    t('loyalty.lastRewardEarned'),
    t('loyalty.lastRewardRedeemed'),
    t('loyalty.signUpDate'),
    '',
  ];

  const dateFormat = (val) =>
    val ? moment(`${val}Z`).format('MMM DD YYYY, HH:mm:ss') : '';
  const csv = useSelector(loyalty.selectors.selectCsv);
  const refLink = React.useRef();
  const modalRef = React.useRef(null);

  const showAddFunds = (row, isAddFunds) => {
    modalRef.current.showModal(row, isAddFunds);
  };
  const handleDownload = () => {
    dispatch(loyalty.actions.downloadLoyaltyCSV())
      .then(() => {
        refLink.current.link.click();
      })
      .then(() =>
        dispatch(
          alert.actions.open({
            message: t('csvDownloadSuccess'),
            severity: 'success',
          })
        )
      );
  };

  const resendEmail = async (id) => {
    setActiveRow(id);
    const res = await dispatch(loyalty.actions.resendEmail(id));
    if (!res.error) {
      dispatch(
        alert.actions.open({
          message: t('dashboards.emailSent'),
          severity: 'success',
        })
      );
    }
    setActiveRow(null);
  };

  const mods = {
    lastEarned: dateFormat,
    lastReward: dateFormat,
    createdAt: dateFormat,
    lastRedeemed: dateFormat,
    birthMonth: (val) =>
      Number(val)
        ? moment()
            .month(Number(val) - 1)
            .format('MMM')
        : '',
    addPoints: (id, row) => (
      <Grid container alignItems="center" direction="row" wrap="nowrap">
        <Grid item>
          <Typography
            style={{
              color: '#1CBF3C',
              cursor: 'pointer',
              textDecoration: 'underline',
              whiteSpace: 'nowrap',
              marginRight: 10,
            }}
            onClick={() => showAddFunds(row, true)}
          >
            <AddCircle />
          </Typography>
        </Grid>
        <Grid item>
          {activeRow !== row.id ? (
            <Tooltip
              title={t('membership.membershipList.resendEmail')}
              placement="top"
              arrow
            >
              <IconButton
                onClick={() => resendEmail(row.id)}
                cursor="pointer"
                disabled={activeRow}
              >
                <img
                  src={Resend}
                  alt={t('membership.membershipList.resendEmail')}
                  style={{ width: 20 }}
                />
              </IconButton>
            </Tooltip>
          ) : (
            <CircularProgress color="primary" size={14} />
          )}
        </Grid>
      </Grid>
    ),
    point: (v, row) => (
      <Typography
        style={{
          color: 'blue',
          cursor: 'pointer',
          textDecoration: 'underline',
          whiteSpace: 'nowrap',
        }}
        onClick={() => refLoyaltyTransactionsModal.current?.showModal(row)}
      >
        {v}
      </Typography>
    ),
  };

  React.useEffect(() => {
    dispatch(loyalty.actions.getLoyaltyList(params));
  }, []);

  React.useEffect(() => {
    if (value?.keyword === '' || value?.keyword?.length > 2) {
      dispatch(loyalty.actions.getLoyaltyList(params));
    }
  }, [value]);

  const isLoading = useSelector(loyalty.selectors.selectIsLoading);
  const { data: loyaltyList, total } = useSelector(
    loyalty.selectors.selectLoyaltyList
  );

  const [results, setResults] = useState([]);
  const search = () => {
    setParams({ ...params, page: 1, keyword });
  };

  React.useEffect(() => {
    search();
  }, [keyword]);

  const handleChangePage = (event, page) => {
    setParams({ ...params, page: page + 1 });
  };
  const handleChangeRowsPerPage = (event) => {
    setParams({ ...params, limit: event.target.value, page: 1 });
  };
  const pagination = {
    total,
    pageSize: params.limit,
    page: params.page,
    handleChangePage,
    handleChangeRowsPerPage,
  };

  const searchBox = () => (
    <Grid container item justifyContent="space-between" alignItems="flex-end">
      <Grid item>
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item xs={10}>
            <TextField
              value={keyword}
              fullWidth
              placeholder={t('loyalty.placeholderSearch')}
              type="string"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="keyword"
              onChange={(val) => {
                setKeyword(val.target.value);
              }}
              style={{ minWidth: 300 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => search()}
                      style={{ marginRight: -14 }}
                    >
                      <img
                        src={searchIcon}
                        alt={t('search')}
                        style={{
                          width: 25,
                          height: 25,
                          paddingTop: 2,
                          cursor: 'pointer',
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item alignItems="flex-end">
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <DownloadCSV
              handleDownload={handleDownload}
              refLink={refLink}
              data={csv}
              filename="loyalty-list"
              text="Export"
            >
              <Tooltip title="Export CSV" placement="top" arrow>
                <Button onClick={handleDownload}>
                  <img
                    src={ExportImage}
                    alt="Export CSV"
                    style={{ width: 30, cursor: 'pointer' }}
                  />
                </Button>
              </Tooltip>
            </DownloadCSV>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <>
      <SEO title={t('loyalty.loyaltyList')} />
      <Box pb={2}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs="auto">
            <Typography variant="h5">
              {t('loyalty.loyalty')} - {t('loyalty.customerList')}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider />

      <Box py={2}>
        <TransactionTable
          cols={colsTitles}
          rowKeys={rowKeys}
          data={loyaltyList}
          mods={mods}
          isLoading={isLoading}
          searchBox={searchBox}
          pagination={pagination}
          noDataMsg={t('noDataMsg')}
        />
      </Box>

      <CsvResultDialog data={results} close={() => setResults([])} />
      <AddLoyaltyPointModal
        ref={modalRef}
        afterSubmit={() => dispatch(loyalty.actions.getLoyaltyList(params))}
      />
      <LoyaltyTransactionsModal ref={refLoyaltyTransactionsModal} />
    </>
  );
};

export default LoyaltyList;
