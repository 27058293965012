import React, { useEffect, useState } from 'react';

import { TextField } from 'formik-mui';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, useFormikContext } from 'formik';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Radio,
  Typography,
} from '@material-ui/core';

import { Checkbox } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import getSymbolFromCurrency from 'currency-symbol-map';
import { CustomInputField, TransactionTable } from '../../custom';
import CardSection from '../GiftCardDetails/CardSection';
import { merchant, membership, loyalty } from '../../../state';

const useStyles = makeStyles(() => ({
  textOr: {
    marginRight: 10,
    fontWeight: '700',
  },
  mr10: {
    marginRight: 20,
  },
}));

const MembershipConfig = () => {
  const { values, setFieldValue } = useFormikContext();
  const {
    isConfigByTier,
    hasLoyalty,
    enableOneTimeCost,
    enableRecurringCost,
    oneTimeCostType,
    recurringCostType,
  } = values;
  const classes = useStyles();
  const { t } = useTranslation();
  const membershipConfig = useSelector(
    membership.selectors.selectMembershipConfig
  );

  const [valueNumberType, setValueNumberType] = React.useState(0);
  const [codeType, setCodeType] = React.useState(0);

  const isLoading = useSelector(membership.selectors.selectIsLoading);
  const imgIsLoading = useSelector(merchant.selectors.selectImgIsLoading);
  const plans = useSelector(membership.selectors.selectPlans);
  const [key, setKey] = useState(Math.random());
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();
  const setGiftCardImage = (payload) =>
    dispatch(merchant.actions.setGiftCardImage(payload));
  const currency = useSelector(merchant.selectors.selectCurrency);
  const currencySymbol = getSymbolFromCurrency(currency);

  // const { data: loyaltyProgramList } = useSelector(
  //   loyalty.selectors.selectProgramList
  // );

  const handleChangeRadio = (event) => {
    setValueNumberType(Number(event.target.value));
    setFieldValue('numberType', Number(event.target.value));
  };
  const handleChangeCodeType = (event) => {
    setCodeType(Number(event.target.value));
    setFieldValue('codeType', Number(event.target.value));
  };

  const [plansOption, setPlansOption] = useState([]);
  const cols = [
    t('membership.planName'),
    t('membership.membershipConfig.status'),
    t('membership.membershipConfig.planOffersDiscount'),
  ];
  const rowKeys = ['name', 'status', 'offerDiscount'];

  const updateOfferDiscount = (i) => {
    const newPlanList = plansOption.map((p) => {
      if (p.id === i.id) {
        return { ...p, offerDiscount: !i.offerDiscount };
      }
      return p;
    });
    setPlansOption(newPlanList);
    setFieldValue('plans', newPlanList);
  };

  const mods = {
    name: (val) => val,
    status: (id, row) => (
      <Typography variant="body2">
        {row.status ? 'Active' : 'InActive'}
      </Typography>
    ),
    offerDiscount: (id, row) =>
      row.offerDiscount ? (
        <CheckBox
          style={{
            cursor: 'pointer',
          }}
          onClick={() => updateOfferDiscount(row)}
        />
      ) : (
        <CheckBoxOutlineBlank
          style={{
            cursor: 'pointer',
          }}
          onClick={() => updateOfferDiscount(row)}
        />
      ),
  };

  useEffect(() => {
    setValueNumberType(membershipConfig.numberType);
    setCodeType(membershipConfig.codeType);
  }, [membershipConfig]);

  useEffect(() => {
    if (isConfigByTier) {
      dispatch(membership.actions.getMembershipPlans());
    }
  }, [isConfigByTier]);

  useEffect(() => {
    setFieldValue('plans', plans);
    setPlansOption(plans);
  }, [plans]);

  useEffect(() => {
    if (loaded && (values.terms || values.description)) {
      setKey(Math.random());
      setTimeout(() => {
        setLoaded(true);
      }, 2000);
    }
  }, [values]);

  useEffect(() => {
    if (hasLoyalty) {
      dispatch(loyalty.actions.getProgramList({ limit: 100, page: 1 }));
    }
  }, [hasLoyalty]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Grid item>
          <Box>
            <Typography>
              {t('membership.membershipConfig.membershipNumbers')}
            </Typography>
          </Box>
          <Box mb={2}>
            <Card variant="outlined" style={{ display: 'inline-flex' }}>
              <CardContent>
                <Box
                  style={{
                    display: 'flex',
                    textAlign: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Radio
                    checked={valueNumberType === 0}
                    onChange={handleChangeRadio}
                    value={0}
                    name="numberType"
                    color="primary"
                  />
                  <Typography className={classes.mr10}>
                    {t('membership.membershipConfig.useMobile')}
                  </Typography>
                  <Radio
                    checked={valueNumberType === 1}
                    onChange={handleChangeRadio}
                    value={1}
                    name="numberType"
                    color="primary"
                  />
                  <Typography className={classes.mr10}>
                    {t('membership.membershipConfig.custom')}
                  </Typography>

                  <CustomInputField
                    customInput={Field}
                    component={TextField}
                    trimLeadingZeros
                    required
                    disabled={valueNumberType !== 1}
                    type="number"
                    removeArrows
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label={t('membership.membershipConfig.startingNumber')}
                    name="startingNumber"
                    variant="outlined"
                  />
                  <Radio
                    checked={valueNumberType === 2}
                    onChange={handleChangeRadio}
                    value={2}
                    name="numberType"
                    color="primary"
                  />
                  <Typography className={classes.mr10}>
                    {t('membership.membershipConfig.physical')}
                  </Typography>
                </Box>
                <Box
                  mt={2}
                  style={{
                    display: 'flex',
                    textAlign: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Radio
                    checked={codeType === 0}
                    onChange={handleChangeCodeType}
                    value={0}
                    name="codeType"
                    color="primary"
                  />
                  <Typography className={classes.mr10}>
                    {t('QRCode')}
                  </Typography>
                  <Radio
                    checked={codeType === 1}
                    onChange={handleChangeCodeType}
                    value={1}
                    name="codeType"
                    color="primary"
                  />
                  <Typography className={classes.mr10}>
                    {t('BarCode')}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={6}>
            <Box mb={2}>
              <Typography>
                <Field
                  type="checkbox"
                  component={Checkbox}
                  name="showHeader"
                  color="primary"
                />
                {t('membership.membershipConfig.showBusinessHeader')}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5}>
            <Box mb={2}>
              <Typography>
                <Field
                  type="checkbox"
                  component={Checkbox}
                  name="captureBirthMonth"
                  color="primary"
                />
                {t('membership.membershipConfig.captureBirthMonth')}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={6}>
            <Box mb={2}>
              <Typography>
                <Field
                  type="checkbox"
                  component={Checkbox}
                  name="autoRenewal"
                  color="primary"
                />
                {t('membership.membershipConfig.autoRenewal')}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={1} />
          <Grid item xs={5}>
            <Box mb={2}>
              <Typography>
                <Field
                  type="checkbox"
                  component={Checkbox}
                  name="showAddressInput"
                  color="primary"
                />
                {t('membership.membershipConfig.showAddressFields')}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid container item xs={6}>
            <Grid item style={{ marginRight: 10 }}>
              <Typography>
                <Field
                  type="checkbox"
                  component={Checkbox}
                  name="hasTrial"
                  color="primary"
                />
                {t('membership.membershipConfig.trialPeriod')}
              </Typography>
            </Grid>
            <Grid item>
              <CustomInputField
                customInput={Field}
                component={TextField}
                extraEndAdornments={t('days')}
                type="number"
                name="trialDays"
                variant="outlined"
                removeArrows
                style={{ width: 90 }}
              />
            </Grid>
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Box mt={4} />
        <Box style={{ position: 'relative', overflow: 'visible' }}>
          <Typography
            style={{
              position: 'absolute',
              top: -10,
              left: 20,
              background: '#fff',
              padding: '0 10px',
            }}
          >
            {t('membership.membershipConfig.oneTimeCost')}
          </Typography>
          <Box mb={2}>
            <Card variant="outlined" style={{ display: 'flex', width: 800 }}>
              <CardContent style={{ width: '100%' }}>
                <Grid container spacing={2}>
                  <Grid item container xs={12}>
                    <Grid item>
                      <Typography style={{ flexShrink: 0, marginRight: 50 }}>
                        <Field
                          type="checkbox"
                          component={Checkbox}
                          name="enableOneTimeCost"
                          color="primary"
                        />
                        {t('membership.membershipConfig.oneTimeSetupFee')}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      style={{
                        textAlign: 'center',
                        alignItems: 'center',
                        position: 'relative',
                      }}
                    >
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        required={enableOneTimeCost}
                        type="number"
                        removeArrows
                        name="oneTimeCost"
                        variant="outlined"
                        InputProps={{
                          style: {
                            paddingRight: 120,
                            width: 200,
                          },
                        }}
                      />
                      <ToggleButtonGroup
                        value={oneTimeCostType}
                        orientation="horizontal"
                        size="small"
                        exclusive
                        onChange={(e, v) => {
                          e.preventDefault();
                          setFieldValue('oneTimeCostType', v);
                        }}
                        aria-label="oneTimeCostType"
                        style={{ position: 'absolute', right: 20, top: 10 }}
                      >
                        <ToggleButton
                          style={{ width: 50 }}
                          key={0}
                          value={0}
                          aria-label="%"
                        >
                          %
                        </ToggleButton>
                        <ToggleButton
                          style={{ width: 50 }}
                          key={1}
                          value={1}
                          aria-label="$"
                        >
                          {currencySymbol}
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Box>
        <Box style={{ position: 'relative', overflow: 'visible' }}>
          <Typography
            style={{
              position: 'absolute',
              top: -10,
              left: 20,
              background: '#fff',
              padding: '0 10px',
            }}
          >
            {t('membership.membershipConfig.recurringCosts')}
          </Typography>
          <Box mb={2}>
            <Card variant="outlined" style={{ display: 'flex', width: 800 }}>
              <CardContent style={{ width: '100%' }}>
                <Grid container spacing={2}>
                  <Grid container item spacing={2} xs={8}>
                    <Grid item>
                      <Typography>
                        <Field
                          type="checkbox"
                          component={Checkbox}
                          name="enableRecurringCost"
                          color="primary"
                        />
                        {t('membership.membershipConfig.serviceFee')}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Box
                        style={{
                          display: 'flex',
                          textAlign: 'center',
                          alignItems: 'center',
                          position: 'relative',
                          overflow: 'visible',
                        }}
                      >
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          required={enableRecurringCost}
                          type="number"
                          removeArrows
                          name="recurringCost"
                          variant="outlined"
                          InputProps={{
                            style: {
                              paddingRight: 120,
                              width: 200,
                            },
                          }}
                        />
                        <ToggleButtonGroup
                          value={recurringCostType}
                          orientation="horizontal"
                          size="small"
                          exclusive
                          onChange={(e, v) => {
                            e.preventDefault();
                            setFieldValue('recurringCostType', v);
                          }}
                          aria-label="recurringCostType"
                          style={{ position: 'absolute', right: 20, top: 10 }}
                        >
                          <ToggleButton
                            style={{ width: 50 }}
                            key={0}
                            value={0}
                            aria-label="%"
                          >
                            %
                          </ToggleButton>
                          <ToggleButton
                            style={{ width: 50 }}
                            key={1}
                            value={1}
                            aria-label="$"
                          >
                            {currencySymbol}
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container item xs={4} spacing={2}>
                    <Grid item>
                      <Typography>
                        <Field
                          type="checkbox"
                          component={Checkbox}
                          name="useTax"
                          color="primary"
                        />
                        {t('membership.membershipConfig.tax')}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        extraEndAdornments="%"
                        type="number"
                        name="tax"
                        variant="outlined"
                        removeArrows
                        style={{ width: 90 }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={2}>
        <Box mb={3}>
          <CardSection
            isLoading={isLoading}
            imgIsLoading={imgIsLoading}
            merchantDetails={membershipConfig}
            merchantCreatives={membershipConfig}
            setGiftCardImage={setGiftCardImage}
            type="membership"
          />
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box mb={2}>
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>
            {t('membership.membershipConfig.noteSave')}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={9} style={{ display: 'none' }}>
        <Card variant="outlined" style={{ overflow: 'visible' }}>
          <CardContent
            style={{
              display: 'flex',
              textAlign: 'center',
              alignItems: 'center',
              position: 'relative',
              overflow: 'visible',
            }}
          >
            <Typography
              style={{
                position: 'absolute',
                top: '-13px',
                left: '20px',
                background: '#fff',
                padding: '0 10px',
              }}
            >
              {t('membership.membershipConfig.identifyPlans')}
            </Typography>
            <Grid item xs={12}>
              <Box p={2}>
                <TransactionTable
                  cols={cols}
                  rowKeys={rowKeys}
                  data={plansOption}
                  mods={mods}
                  noDataMsg={t('noData')}
                  isMinHeight={false}
                  isLoading={isLoading}
                  size="small"
                />
              </Box>
              <Typography variant="body1">
                {t('membership.membershipConfig.note')}
              </Typography>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <Typography>{t('cardEssentialInfo')}</Typography>
        </Box>
        <Box mb={2}>
          <Card variant="outlined">
            <CardContent>
              <Box pb={2}>
                {values.description ? (
                  <Field
                    component={TextField}
                    fullWidth
                    key={key}
                    multiline
                    rows={4}
                    type="text"
                    label={t('membership.programDescription')}
                    name="description"
                    variant="outlined"
                  />
                ) : (
                  <Field
                    component={TextField}
                    fullWidth
                    multiline
                    rows={4}
                    type="text"
                    label={t('membership.programDescription')}
                    name="description"
                    variant="outlined"
                  />
                )}
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Box mb={2}>
          <Card variant="outlined">
            <CardContent>
              <Box pb={2}>
                {values.terms ? (
                  <Field
                    component={TextField}
                    fullWidth
                    multiline
                    key={key}
                    rows={4}
                    type="text"
                    label={t('membership.termsAndConditions')}
                    name="terms"
                    variant="outlined"
                  />
                ) : (
                  <Field
                    component={TextField}
                    fullWidth
                    multiline
                    rows={4}
                    type="text"
                    label={t('membership.termsAndConditions')}
                    name="terms"
                    variant="outlined"
                  />
                )}
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Grid>

      {/* <Grid item xs={12}> */}
      {/*  <Box mb={2}> */}
      {/*    <Typography> */}
      {/*      Do you have multiple membership plans/tiers? */}
      {/*      <Field */}
      {/*        component={Switch} */}
      {/*        type="checkbox" */}
      {/*        name="isConfigByTier" */}
      {/*        color="primary" */}
      {/*      /> */}
      {/*    </Typography> */}
      {/*  </Box> */}
      {/* </Grid> */}
      {isConfigByTier && (
        <Grid container item xs={12}>
          {plans &&
            plans.map((plan, i) => (
              <Grid container key={plan.id}>
                <Grid item xs={12} md={4}>
                  <CustomInputField
                    customInput={Field}
                    component={TextField}
                    fullWidth
                    type="string"
                    removeArrows
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label={`${t('name')}`}
                    name={`plans[${i}].name`}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={1} />
                <Grid item xs={12} md={6}>
                  <CardSection
                    isLoading={isLoading}
                    imgIsLoading={imgIsLoading}
                    merchantDetails={plan}
                    merchantCreatives={plan}
                    setGiftCardImage={setGiftCardImage}
                    field={`plans[${i}].imageUrl`}
                  />
                </Grid>
              </Grid>
            ))}
        </Grid>
      )}
    </Grid>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getMerchantDetails: (payload) =>
    dispatch(merchant.actions.getMerchantDetails(payload)),
});

export default connect(null, mapDispatchToProps)(MembershipConfig);
