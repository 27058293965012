/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  ListItemIcon,
  MenuItem,
  Menu,
  ListItemText,
} from '@material-ui/core';
import { Cancel, LocalAtm } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';
import UserIcon from '../../../images/Memberships/user.png';
import MemberCardIcon from '../../../images/Memberships/member-card.png';
import DateIcon from '../../../images/Memberships/date.png';
import Pause from '../../../images/Memberships/pause.png';
import ViewEdit from '../../../images/Memberships/viewEdit.png';
import Coupon from '../../../images/Memberships/coupon.png';
import Members from '../../../images/Memberships/members.png';

const MembershipEditMenu = ({
  membership,
  onEditProfile,
  onEditExpirationDate,
  onPauseMembership,
  onEditPlan,
  onViewPaymentActivity,
  cancelModal,
  openSecondaryMembers,
  openCoupons,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClickEditMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseEditMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-controls={open ? `edit-button-${membership.id}` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClickEditMenu}
        cursor="pointer"
      >
        <img
          src={ViewEdit}
          alt="pause-icon"
          style={{ width: 25, height: 25 }}
        />
      </IconButton>
      <Menu
        id={`edit-menu-${membership.id}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseEditMenu}
        MenuListProps={{
          'aria-labelledby': `edit-button-${membership.id}`,
          style: { paddingTop: 0, paddingBottom: 0 },
        }}
        anchorOrigin={{
          vertical: 'top',
        }}
        transformOrigin={{
          vertical: 'top',
        }}
      >
        <MenuItem onClick={onEditProfile}>
          <ListItemIcon>
            <img
              src={UserIcon}
              alt="user-icon"
              style={{ width: 20, height: 20 }}
            />
          </ListItemIcon>
          <ListItemText>Member Profile</ListItemText>
        </MenuItem>
        {!membership.primaryMembershipId && (
          <>
            <MenuItem onClick={onEditPlan}>
              <ListItemIcon>
                <img
                  src={MemberCardIcon}
                  alt="membercard-icon"
                  style={{ width: 22, height: 22 }}
                />
              </ListItemIcon>
              <ListItemText>Member Plan</ListItemText>
            </MenuItem>

            <MenuItem onClick={onPauseMembership}>
              <ListItemIcon>
                <img
                  src={Pause}
                  alt="pause-icon"
                  style={{ width: 20, height: 20 }}
                />
              </ListItemIcon>
              <ListItemText>Pause Membership</ListItemText>
            </MenuItem>
            <MenuItem onClick={onEditExpirationDate}>
              <ListItemIcon>
                <img
                  src={DateIcon}
                  alt="date-icon"
                  style={{ width: 20, height: 20 }}
                />
              </ListItemIcon>
              <ListItemText>Expiration Date</ListItemText>
            </MenuItem>
            <MenuItem onClick={onViewPaymentActivity}>
              <ListItemIcon>
                <LocalAtm />
              </ListItemIcon>
              <ListItemText>Payment activity</ListItemText>
            </MenuItem>
            <MenuItem disabled>
              <ListItemIcon>
                <LocalAtm />
              </ListItemIcon>
              <ListItemText>Gift Cards</ListItemText>
            </MenuItem>
            {membership.plan?.allowedSecondaryMembers ? (
              <MenuItem onClick={openSecondaryMembers}>
                <ListItemIcon>
                  <img
                    src={Members}
                    alt={t('membership.membershipList.secondaryMember')}
                    style={{ width: 20 }}
                  />
                </ListItemIcon>
                <ListItemText>
                  {t('membership.membershipList.secondaryMember')}
                </ListItemText>
              </MenuItem>
            ) : null}
            {membership.hasCoupon && (
              <MenuItem onClick={openCoupons}>
                <ListItemIcon>
                  <img
                    src={Coupon}
                    alt={t('membership.membershipList.couponList')}
                    style={{ width: 20 }}
                  />
                </ListItemIcon>
                <ListItemText>Coupons</ListItemText>
              </MenuItem>
            )}
          </>
        )}
        <MenuItem onClick={cancelModal}>
          <ListItemIcon>
            <Cancel />
          </ListItemIcon>
          <ListItemText>Cancel Membership</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

MembershipEditMenu.propTypes = {
  onEditProfile: PropTypes.func.isRequired,
  onEditExpirationDate: PropTypes.func.isRequired,
  onEditPlan: PropTypes.func.isRequired,
  onPauseMembership: PropTypes.func.isRequired,
  onViewPaymentActivity: PropTypes.func.isRequired,
  cancelModal: PropTypes.func.isRequired,
  openSecondaryMembers: PropTypes.func.isRequired,
  openCoupons: PropTypes.func.isRequired,
  membership: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default MembershipEditMenu;
