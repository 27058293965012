/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  ListItemIcon,
  MenuItem,
  Menu,
  ListItemText,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Resend from '../../../images/Memberships/resend.png';
import ResendIcon from '../../../images/Memberships/resendMail.png';
import RenewalIcon from '../../../images/Memberships/renewal.png';
import { alert, membership as membershipState } from '../../../state';

const MembershipResendMenu = ({ membership, setActiveRowEmail }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClickEditMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseEditMenu = () => {
    setAnchorEl(null);
  };

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const resendEmail = async (id, type) => {
    setActiveRowEmail(membership);
    const res = await dispatch(
      membershipState.actions.resendEmail({ id, type })
    );
    if (!res.error) {
      dispatch(
        alert.actions.open({
          message: t('membership.membershipList.emailSent'),
          severity: 'success',
        })
      );
      setAnchorEl(null);
    }
  };
  return (
    <>
      <IconButton
        aria-controls={open ? `edit-button-${membership.id}` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClickEditMenu}
        cursor="pointer"
      >
        <img
          src={Resend}
          alt={t('membership.membershipList.resendEmail')}
          style={{ width: 20 }}
        />
      </IconButton>
      <Menu
        id={`edit-menu-${membership.id}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseEditMenu}
        MenuListProps={{
          'aria-labelledby': `edit-button-${membership.id}`,
        }}
        anchorOrigin={{
          vertical: 'top',
        }}
        transformOrigin={{
          vertical: 'top',
        }}
      >
        <MenuItem
          onClick={() => {
            resendEmail(membership.id, 'confirmation');
          }}
        >
          <ListItemIcon>
            <img
              src={ResendIcon}
              alt="user-icon"
              style={{ width: 20, height: 20 }}
            />
          </ListItemIcon>
          <ListItemText>Resend Confirmation Email</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            resendEmail(membership.id, 'renewal');
          }}
        >
          <ListItemIcon>
            <img
              src={RenewalIcon}
              alt="membercard-icon"
              style={{ width: 22, height: 22 }}
            />
          </ListItemIcon>
          <ListItemText>Send Renewal Reminder</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

MembershipResendMenu.propTypes = {
  membership: PropTypes.objectOf(PropTypes.any).isRequired,
  setActiveRowEmail: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default MembershipResendMenu;
