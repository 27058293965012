import * as React from 'react';
import { TextField } from 'formik-material-ui';
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Box,
  Grid,
  IconButton,
} from '@material-ui/core';
import moment from 'moment';
import DatePicker from '@mui/lab/DatePicker';
import { useDispatch } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { Close } from '@material-ui/icons';
import { CustomInputField } from '../../custom';
import api from '../../../state/membership/api';
import { alert, membership } from '../../../state';

const fieldStyle = {
  display: 'flex',
  flexDirection: 'row',
};

const ResumeMemberModal = React.forwardRef(({ onClose, item }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const openAlert = (payload) => dispatch(alert.actions.open(payload));

  const initialData = {
    firstName: item?.firstName,
    lastName: item?.lastName,
    endDate: item?.pauseEndDate,
  };
  const onSubmit = async (values, formikHelper) => {
    formikHelper.setSubmitting(true);
    try {
      await api.setMembershipResume(item?.id);
      openAlert({
        message: t('membership.messages.successfullyEditMember'),
        severity: 'success',
      });
      dispatch(membership.actions.getMembershipList());
      onClose();
    } catch (error) {
      openAlert({
        message: error?.message || t('membership.messages.failedEditMember'),
        severity: 'error',
      });
      formikHelper.setSubmitting(false);
    }
  };

  return (
    <Dialog open={!!item} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {t('membership.restartMembership.title')}
        {item?.code}
      </DialogTitle>
      <DialogContent>
        <Box py={2}>
          <Formik
            onSubmit={onSubmit}
            enableReinitialize
            initialValues={initialData}
          >
            {({
              isSubmitting,
              setFieldValue,
              setFieldTouched,
              values,
              errors,
            }) => {
              return (
                <Form>
                  <Box sx={fieldStyle}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Box pb={2}>
                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                            spacing={2}
                          >
                            <Grid item xs={12} md={6}>
                              <CustomInputField
                                customInput={Field}
                                component={TextField}
                                capitalize
                                fullWidth
                                label={t('firstName')}
                                name="firstName"
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                disabled
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <CustomInputField
                                customInput={Field}
                                component={TextField}
                                capitalize
                                fullWidth
                                label={t('lastName')}
                                name="lastName"
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                disabled
                              />
                            </Grid>
                          </Grid>
                        </Box>
                        <Box pb={2}>
                          <Grid container direction="row" spacing={2}>
                            <Grid item xs={12} md={6}>
                              <DatePicker
                                inputFormat="YYYY-MM-DD"
                                mask="____-__-__"
                                required
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                    variant: 'outlined',
                                  },
                                }}
                                renderInput={(pr) => {
                                  return (
                                    <Field
                                      readOnly
                                      inputProps={{ readOnly: true }}
                                      component={TextField}
                                      fullWidth
                                      label={`${t(
                                        'membership.restartMembership.pausedTillDate'
                                      )} *`}
                                      name="endDate"
                                      variant="outlined"
                                      {...pr}
                                      error={Boolean(errors.endDate)}
                                      helperText={errors.endDate}
                                      onKeyDown={(e) => e.preventDefault()}
                                      disabled
                                    />
                                  );
                                }}
                                value={
                                  values.endDate ? moment(values.endDate) : null
                                }
                                onChange={(val) => {
                                  setFieldValue(
                                    'endDate',
                                    val ? val.format('YYYY-MM-DD') : ''
                                  );
                                }}
                                name="endDate"
                                label={`${t(
                                  'membership.restartMembership.pausedTillDate'
                                )} *`}
                                onKeyPress={() => setFieldTouched('endDate')}
                                disabled
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      flexDirection: 'column',
                    }}
                  >
                    <Button color="primary" variant="contained" type="submit">
                      {isSubmitting ? (
                        <CircularProgress size={24} />
                      ) : (
                        t('membership.restartMembership.restartNow')
                      )}
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </DialogContent>
      <IconButton
        aria-label="clear"
        onClick={onClose}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          width: 20,
          height: 20,
        }}
      >
        <Close fontSize="small" />
      </IconButton>
    </Dialog>
  );
});

ResumeMemberModal.propTypes = {
  onClose: PropTypes.func,
  item: PropTypes.string,
};

ResumeMemberModal.defaultProps = {
  onClose: () => {},
  item: null,
};

export default ResumeMemberModal;
