import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { loyalty, merchant } from '../../../state';

const LoyaltyInitialSetup = () => {
  const {
    values,
    // setFieldValue,
    handleSubmit,
    isSubmitting,
  } = useFormikContext();
  const { t } = useTranslation();
  const activate = () => {
    // setFieldValue('status', 1);
    handleSubmit();
  };
  const items = useSelector(loyalty.selectors.selectItems);
  const { currency } = useSelector(merchant.selectors.selectMerchantDetails);

  return (
    <>
      <Box mb={2} alignItems="center">
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <Typography>{t('loyalty.programName')} </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            {values.programName}
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <Typography>{t('loyalty.codeType')} </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            {values.codeType === '0' ? t('QRCode') : t('BarCode')}
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <Typography>{t('loyalty.description')} </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            {values.description}
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <Typography>{t('loyalty.termsAndConditions')} </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            {values.terms}
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <Typography>{t('loyalty.programBasedOn')} </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            {Number(values.type) === 0
              ? t('loyalty.itemPurchasedPerOrder')
              : t('loyalty.amountSpentPerOrder')}
          </Grid>
        </Grid>
        {Number(values.type) === 0 &&
        values.partnerItemId &&
        items.find((item) => item.id === values.partnerItemId) ? (
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <Typography>{t('loyalty.selectedItem')} </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              {items.find((item) => item.id === values.partnerItemId).name}
            </Grid>
          </Grid>
        ) : null}
        {Number(values.type) === 1 ? (
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <Typography>{t('loyalty.amount')} </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              {t('cardValue', {
                amount: values.minimumAmount,
                formatParams: {
                  amount: { currency },
                },
              })}
            </Grid>
          </Grid>
        ) : null}
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <Typography>{t('loyalty.pointsEarned')} </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            {values.point}
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <Typography>{t('loyalty.rewardType')} </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            {Number(values.rewardType) === 0
              ? t('loyalty.freeItem')
              : t('loyalty.discountAmount')}
          </Grid>
        </Grid>
        {Number(values.rewardType) === 0 &&
        values.rewardItemId &&
        items.find((item) => item.id === values.rewardItemId) ? (
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <Typography>{t('loyalty.rewardItem')} </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              {items.find((item) => item.id === values.rewardItemId).name}
            </Grid>
          </Grid>
        ) : null}
        {Number(values.rewardType) === 1 ? (
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <Typography>{t('loyalty.discountAmount')} </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              {t('cardValue', {
                amount: values.discountAmount,
                formatParams: {
                  amount: { currency },
                },
              })}
            </Grid>
          </Grid>
        ) : null}
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <Typography>{t('loyalty.requiredAmount')} </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            {values.requiredAmount}
          </Grid>
        </Grid>
      </Box>
      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={activate}
          type="submit"
          disabled={isSubmitting}
        >
          {isSubmitting ? <CircularProgress size={24} /> : t('save')}
        </Button>
      </Box>
    </>
  );
};

export default LoyaltyInitialSetup;
