/* eslint-disable react/prop-types */
import * as React from 'react';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Box,
  Grid,
  IconButton,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Field, Form, Formik } from 'formik';

import { useTranslation } from 'react-i18next';
import { Close } from '@material-ui/icons';
import { CustomInputField } from '../../../custom';
import api from '../../../../state/ticket/api';
import { alert, ticket } from '../../../../state';

// eslint-disable-next-line react/prop-types
const AddVenueModal = ({ onClose, item }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const openAlert = (payload) => dispatch(alert.actions.open(payload));
  const validationSchema = () =>
    Yup.object().shape({
      name: Yup.string().required(t('validation.required')),
      address: Yup.string().required(t('validation.required')),
      city: Yup.string().required(t('validation.required')),
      state: Yup.string().required(t('validation.required')),
      zipcode: Yup.string().required(t('validation.required')),
      url: Yup.string()
        .nullable()
        .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          'Invalid url'
        ),
    });

  const onSubmit = async (values, formikHelper) => {
    formikHelper.setSubmitting(true);
    try {
      await api.addVenue({
        ...values,
      });
      openAlert({
        message: t('tickets.messages.venueAddedSuccess'),
        severity: 'success',
      });
      onClose();
      dispatch(ticket.actions.getTicketVenues());
    } catch (error) {
      console.log(error.message);
      openAlert({
        message:
          error?.message || error || t('tickets.messages.failedAddVenue'),
        severity: 'error',
      });
      formikHelper.setSubmitting(false);
    }
  };

  return (
    <Dialog open={!!item} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {item?.id ? t('tickets.venue.edit') : t('tickets.venue.add')}
      </DialogTitle>
      <DialogContent>
        <Box py={2}>
          <Formik
            onSubmit={onSubmit}
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={{
              name: item?.name,
              state: item?.state,
              address: item?.address,
              city: item?.city,
              zipcode: item?.zipcode,
              url: item?.url,
              id: item?.id,
            }}
          >
            {({ isSubmitting }) => {
              return (
                <Form>
                  <Box>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Box pb={2}>
                          <Grid
                            container
                            direction="row"
                            alignItems="flex-start"
                            spacing={2}
                          >
                            <Grid item xs={12} md={5}>
                              <CustomInputField
                                customInput={Field}
                                component={TextField}
                                capitalize
                                fullWidth
                                required
                                label={t('tickets.venue.name')}
                                name="name"
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Box pb={2}>
                          <CustomInputField
                            customInput={Field}
                            component={TextField}
                            fullWidth
                            required
                            label={t('address')}
                            name="address"
                            variant="outlined"
                          />
                        </Box>
                        <Box pb={2}>
                          <Grid container display="row" spacing={2}>
                            <Grid item xs={5}>
                              <Field
                                component={TextField}
                                label="City"
                                name="city"
                                variant="outlined"
                                required
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Field
                                component={TextField}
                                label={t('stateProvince')}
                                name="state"
                                variant="outlined"
                                required
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <Field
                                component={TextField}
                                label="Zip Code / Pin Code"
                                name="zipcode"
                                variant="outlined"
                                required
                                fullWidth
                                InputProps={{
                                  inputProps: {
                                    maxLength: '5',
                                  },
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Box pb={2}>
                          <Grid
                            container
                            direction="row"
                            alignItems="flex-start"
                            spacing={2}
                          >
                            <Grid item xs={12} md={9}>
                              <CustomInputField
                                customInput={Field}
                                component={TextField}
                                capitalize
                                fullWidth
                                label={t('tickets.venue.url')}
                                name="url"
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      flexDirection: 'column',
                    }}
                  >
                    <Button color="primary" variant="contained" type="submit">
                      {isSubmitting ? (
                        <CircularProgress size={24} />
                      ) : (
                        t('saveChanges')
                      )}
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </DialogContent>
      <IconButton
        aria-label="clear"
        onClick={onClose}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          width: 20,
          height: 20,
        }}
      >
        <Close fontSize="small" />
      </IconButton>
    </Dialog>
  );
};

export default AddVenueModal;
