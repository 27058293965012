/* eslint-disable react/forbid-prop-types,react/require-default-props */
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  makeStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { Select, TextField } from 'formik-mui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { alert, loyalty, membership } from '../../../state';

const useStyles = makeStyles(() => ({
  fullwidthChild: {
    '& > div': {
      width: '100%',
      minWidth: '300px',
    },
  },
}));
const AddMembershipLoyaltyPlan = ({ isOpen, closeModal, onReload }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const memberShipPlans = useSelector(membership.selectors.selectPlans);
  const loyaltyPlans = useSelector(loyalty.selectors.selectProgramList);
  const openAlert = (payload) => dispatch(alert.actions.open(payload));
  useEffect(() => {
    dispatch(loyalty.actions.getProgramList({ limit: 1000 }));
  }, []);

  const body = (
    <Box>
      <Formik
        validationSchema={Yup.object().shape({
          membershipPlanId: Yup.string().required(t('validation.required')),
          loyaltyProgramId: Yup.string().required(t('validation.required')),
        })}
        initialValues={{
          membershipPlanId: '',
          loyaltyProgramId: '',
          description: '',
        }}
        onSubmit={async (values, { resetForm }) => {
          setIsLoading(true);
          try {
            const resp = await dispatch(
              membership.actions.createMembershipLoyalty({
                ...values,
                renewalType: !values.renewalType ? '0' : values.renewalType,
              })
            );
            if (resp.payload) {
              openAlert({
                message: t('membership.loyaltyPlan.createdSuccess'),
                severity: 'success',
              });
              resetForm();
              if (onReload) onReload();
              closeModal(true);
            } else if (resp.error) {
              openAlert({
                message:
                  resp.error.message || t('membership.loyaltyPlan.createdFail'),
                severity: 'error',
              });
            }
          } catch (error) {
            console.log(error);
          } finally {
            setIsLoading(false);
          }
          return true;
        }}
      >
        {({ setFieldValue, handleSubmit }) => {
          return (
            <Form>
              <Box mt={2}>
                <Grid container spacing={5}>
                  <Grid item md={6} className={classes.fullwidthChild}>
                    <Field
                      component={Select}
                      as="select"
                      autoWidth={false}
                      fullWidth
                      required
                      label={t('membership.planName')}
                      name="membershipPlanId"
                      variant="outlined"
                      onChange={(event, val) => {
                        setFieldValue('membershipPlanId', val.props.value);
                      }}
                      // style={{ width: 320 }}
                      placeholder={t('membership.planName')}
                    >
                      <MenuItem value={undefined}>---</MenuItem>
                      {memberShipPlans.map(
                        (e) =>
                          e && (
                            <MenuItem value={e.id} key={e.id}>
                              {e.name}
                            </MenuItem>
                          )
                      )}
                    </Field>
                  </Grid>
                  <Grid item className={classes.fullwidthChild} md={6}>
                    <Field
                      component={Select}
                      as="select"
                      autoWidth={false}
                      fullWidth
                      required
                      label={`${t('loyalty.loyalty')} ${t(
                        'membership.planName'
                      )}`}
                      name="loyaltyProgramId"
                      variant="outlined"
                      onChange={(event, val) => {
                        setFieldValue('loyaltyProgramId', val.props.value);
                      }}
                      // style={{ width: 320 }}
                      placeholder={t(
                        'membership.issueLoyaltiesConfig.loyaltyName'
                      )}
                    >
                      <MenuItem value={undefined}>---</MenuItem>
                      {loyaltyPlans.data
                        ?.filter((p) => p.status === 1)
                        .map(
                          (e) =>
                            e && (
                              <MenuItem value={e.id} key={e.id}>
                                {e.programName}
                              </MenuItem>
                            )
                        )}
                    </Field>
                  </Grid>
                </Grid>
              </Box>
              <Box mt={2}>
                <Grid container>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      fullWidth
                      multiline
                      rows={4}
                      type="text"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label={t('description')}
                      name="description"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box mt={5}>
                <Grid container justifyContent="space-between">
                  <Grid item md={8}>
                    {t('membership.issueLoyaltyPlan.notes')}
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={handleSubmit}
                      loading={isLoading}
                      disabled={isLoading}
                      variant="contained"
                      color="primary"
                    >
                      {t('save')}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
  return (
    <Dialog open={isOpen} onClose={closeModal} fullWidth maxWidth="md">
      <DialogTitle>Associate a Loyalty to a Plan</DialogTitle>
      <DialogContent>{body}</DialogContent>
      <IconButton
        aria-label="clear"
        onClick={closeModal}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          width: 20,
          height: 20,
        }}
      >
        <Close fontSize="small" />
      </IconButton>
    </Dialog>
  );
};

AddMembershipLoyaltyPlan.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onReload: PropTypes.func,
};

export default AddMembershipLoyaltyPlan;
