/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ListItemIcon,
  MenuItem,
  Menu,
  ListItemText,
  Tooltip,
  Button,
} from '@material-ui/core';
import { Cancel } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';
import MemberPlan from '../../../images/Memberships/multiple-cards.png';
import AutoRenew from '../../../images/Memberships/auto-renewal.png';
import DateIcon from '../../../images/Memberships/expiration-date.png';
import bulkEdit from '../../../images/Memberships/bulk-edit-icon.png';

const MembershipBulk = ({ setBulkType }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClickEditMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseEditMenu = () => {
    setAnchorEl(null);
  };

  const handlePlan = (type) => {
    setBulkType(type);
    handleCloseEditMenu();
  };

  return (
    <>
      <Tooltip
        title={t('membership.membershipList.bulkEdit')}
        placement="top"
        arrow
      >
        <Button onClick={handleClickEditMenu}>
          <img
            src={bulkEdit}
            alt={t('edit')}
            style={{
              width: 25,
              cursor: 'pointer',
            }}
          />
        </Button>
      </Tooltip>
      <Menu
        id={`edit-menu-${2}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseEditMenu}
        MenuListProps={{
          'aria-labelledby': `edit-button-${2}`,
          style: { paddingTop: 0, paddingBottom: 0 },
        }}
        anchorOrigin={{
          vertical: 'top',
        }}
        transformOrigin={{
          vertical: 'top',
        }}
      >
        <MenuItem onClick={() => handlePlan('plan')}>
          <ListItemIcon>
            <img
              src={MemberPlan}
              alt="user-icon"
              style={{ width: 20, height: 20 }}
            />
          </ListItemIcon>
          <ListItemText>
            {t('membership.membershipList.memberPan')}
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handlePlan('renewal')}>
          <ListItemIcon>
            <img
              src={AutoRenew}
              alt="membercard-icon"
              style={{ width: 22, height: 22 }}
            />
          </ListItemIcon>
          <ListItemText>
            {t('membership.membershipList.autoRenewal')}
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handlePlan('expiration')}>
          <ListItemIcon>
            <img
              src={DateIcon}
              alt="date-icon"
              style={{ width: 20, height: 20 }}
            />
          </ListItemIcon>
          <ListItemText>
            {t('membership.membershipList.expirationDate')}
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handlePlan('cancel')}>
          <ListItemIcon>
            <Cancel />
          </ListItemIcon>
          <ListItemText>
            {t('membership.membershipList.cancelMembership')}
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

MembershipBulk.propTypes = {
  setBulkType: PropTypes.func.isRequired,
};

export default MembershipBulk;
