/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  MenuItem,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Delete, Edit, Visibility } from '@material-ui/icons';
import { Tooltip } from '@mui/material';
import { Select } from 'formik-mui';
import { Field, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import SEO from '../../seo';
import { membership, merchant } from '../../../state';
import { TransactionTable } from '../../custom';
import ConfirmationDialog from '../GiftCardDetails/ConfirmDialog';
import CardSection from '../GiftCardDetails/CardSection';

const MembershipImages = () => {
  const plans = useSelector(membership.selectors.selectPlans);
  const membershipConfig = useSelector(
    membership.selectors.selectMembershipConfig
  );
  const [selectedItem, setSelectedItem] = React.useState(null);
  const dispatch = useDispatch();
  // const openAlert = (payload) => dispatch(alert.actions.open(payload));
  const { handleSubmit, isSubmitting, setFieldValue } = useFormikContext();
  useEffect(() => {
    dispatch(membership.actions.getMembershipPlans());
  }, []);
  const { t } = useTranslation();

  const colsTitles = [
    t('membership.planName'),
    t('membership.images.image'),
    t('membership.images.enlarge'),
    t('membership.images.actions'),
  ];

  const rowKeys = ['name', 'imageUrl', 'createdAt', 'id'];

  const imgIsLoading = useSelector(merchant.selectors.selectImgIsLoading);
  const isLoading = useSelector(membership.selectors.selectIsLoading);
  const membershipPlans = useSelector(membership.selectors.selectPlans);
  const [isConfirm, setIsConfirm] = React.useState(false);
  const [zoom, setZoom] = React.useState(null);
  const setGiftCardImage = (payload) =>
    dispatch(merchant.actions.setGiftCardImage(payload));

  const enlarge = (imageUrl) => {
    setZoom(imageUrl || membershipConfig.imageUrl);
  };

  const mods = {
    membershipPlanId: (val) => membershipPlans.find((p) => p.id === val)?.name,
    imageUrl: (val, row) => {
      return (
        <img
          src={val || membershipConfig.imageUrl}
          style={{ width: 50 }}
          alt={row.name}
        />
      );
    },
    createdAt: (val, row) => (
      <Tooltip title={t('edit')} placement="top" arrow>
        <IconButton onClick={() => enlarge(row.imageUrl)} cursor="pointer">
          <Visibility />
        </IconButton>
      </Tooltip>
    ),
    id: (id, row) => (
      <>
        <Tooltip title={t('edit')} placement="top" arrow>
          <IconButton
            onClick={() => {
              setSelectedItem(row);
              setFieldValue('planId', id);
            }}
            cursor="pointer"
          >
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('delete')} placement="top" arrow>
          <IconButton
            disabled={!row.imageUrl}
            onClick={() => setIsConfirm(id)}
            cursor="pointer"
          >
            <Delete />
          </IconButton>
        </Tooltip>
      </>
    ),
  };

  const deleteItem = async () => {
    await setFieldValue(
      `plans[${plans?.findIndex((p) => p?.id === isConfirm)}].imageUrl`,
      ''
    );
    await handleSubmit();
    dispatch(membership.actions.getMembershipPlans());
  };

  const handleSuccessUpload = async (url) => {
    await setFieldValue(
      `plans[${plans?.findIndex((p) => p?.id === selectedItem?.id)}].imageUrl`,
      url
    );
  };

  const saveImage = async () => {
    await handleSubmit();
  };

  return (
    <>
      <SEO title={t('membership.membershipList.title')} />
      <Grid container>
        <Grid item xs={12} md={6}>
          <Field
            component={Select}
            as="select"
            autoWidth={false}
            fullWidth
            required
            label={t('membership.images.selectPlan')}
            name="planId"
            // value={values.planId}
            // key={values.planId}
            value={selectedItem?.id ? selectedItem.id : undefined}
            key={selectedItem?.id ? selectedItem.id : undefined}
            variant="outlined"
            onChange={(event, val) => {
              setSelectedItem(plans.find((p) => p.id === val.props.value));
            }}
            style={{ width: 320 }}
            placeholder={t('membership.images.selectPlan')}
          >
            <MenuItem value={undefined}>---</MenuItem>
            {plans.map(
              (opt) =>
                opt && (
                  <MenuItem value={opt.id} key={opt.id}>
                    {opt.name}
                  </MenuItem>
                )
            )}
          </Field>
        </Grid>
        <Grid item xs={12} md={6}>
          <CardSection
            isLoading={isLoading}
            merchantDetails={membershipConfig}
            merchantCreatives={
              selectedItem?.imageUrl ? selectedItem : membershipConfig
            }
            setGiftCardImage={setGiftCardImage}
            handleSuccess={handleSuccessUpload}
            imgIsLoading={imgIsLoading}
            disabled={isSubmitting || !selectedItem?.id}
          />
          <Button
            onClick={saveImage}
            variant="contained"
            color="primary"
            disabled={isSubmitting || !selectedItem?.id}
          >
            {t('add')}
          </Button>
        </Grid>
      </Grid>
      <Box py={2}>
        <TransactionTable
          cols={colsTitles}
          rowKeys={rowKeys}
          data={plans}
          mods={mods}
          isLoading={isLoading}
          noDataMsg={t('noDataMsg')}
        />
      </Box>
      <ConfirmationDialog
        open={isConfirm}
        setOpen={setIsConfirm}
        title={t('confirmation')}
        content={t('membership.images.confirmDelete')}
        actionOk={deleteItem}
      />
      <Dialog open={!!zoom} onClose={() => setZoom(null)} maxWidth="lg">
        <DialogContent>
          <img
            src={zoom}
            style={{ minWidth: 300, maxWidth: '100%', maxHeight: '90vh' }}
            alt=""
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MembershipImages;
