/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import { Box, Grid, Button, IconButton } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { ticket } from '../../../state';
import { TransactionTable } from '../../custom';
import AddVenueModal from './Modals/AddVenueModal';
import Editing from '../../../images/Memberships/editing.png';

const VenuesList = () => {
  const [params, setParams] = React.useState({ limit: 10, page: 1 });
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const colsTitles = [
    t('tickets.venue.name'),
    t('address'),
    t('city'),
    t('stateProvince'),
    t('zipPostalCode'),
    'URL',
    '',
  ];

  const rowKeys = ['name', 'address', 'city', 'state', 'zipcode', 'url', 'id'];
  const [modalItem, setModalItem] = React.useState(false);

  const mods = {
    id: (val, item) => (
      <Tooltip title={t('edit')} placement="top" arrow>
        <IconButton
          aria-haspopup="true"
          onClick={() => setModalItem(item)}
          cursor="pointer"
        >
          <img src={Editing} alt="" style={{ width: 20 }} />
        </IconButton>
      </Tooltip>
    ),
  };

  React.useEffect(() => {
    dispatch(ticket.actions.getTicketVenues(params));
  }, [params]);

  const isLoading = useSelector(ticket.selectors.selectIsLoading);
  const { data: ticketList, total } = useSelector(
    ticket.selectors.selectTicketVenues
  );

  const handleChangePage = (event, page) => {
    setParams({ ...params, page: page + 1 });
  };
  const handleChangeRowsPerPage = (event) => {
    setParams({ ...params, limit: event.target.value, page: 1 });
  };

  const pagination = {
    total,
    pageSize: params.limit,
    page: params.page,
    handleChangePage,
    handleChangeRowsPerPage,
  };

  const searchBox = () => (
    <Grid item>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setModalItem({})}
      >
        {t('tickets.venue.add')}
      </Button>
    </Grid>
  );

  const sortedTicket = useMemo(() => {
    if (ticketList.length > 0) {
      return [...ticketList].sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    }
    return [];
  }, [ticketList]);

  return (
    <>
      <Box py={2}>
        <TransactionTable
          cols={colsTitles}
          rowKeys={rowKeys}
          data={sortedTicket}
          mods={mods}
          isLoading={isLoading}
          pagination={pagination}
          searchBox={searchBox}
          noDataMsg={t('noDataMsg')}
        />
      </Box>

      <AddVenueModal item={modalItem} onClose={() => setModalItem(false)} />
    </>
  );
};
export default VenuesList;
