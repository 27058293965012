import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import CardSection from '../GiftCardDetails/CardSection';
import { merchant } from '../../../state';

const TicketEventUploadImage = () => {
  const { values, handleSubmit, isSubmitting } = useFormikContext();
  const { t } = useTranslation();
  const isLoading = false;
  const imgIsLoading = false;
  const dispatch = useDispatch();
  const setGiftCardImage = (payload) =>
    dispatch(merchant.actions.setGiftCardImage(payload));

  return (
    <Grid container>
      <Grid container item spacing={2} md={12}>
        <Grid item style={{ paddingTop: 8 }}>
          <Typography>{t('tickets.event.eventImage')}</Typography>
        </Grid>
        <Grid item xs={7}>
          <CardSection
            isLoading={isLoading}
            imgIsLoading={imgIsLoading}
            merchantDetails={values}
            merchantCreatives={values}
            setGiftCardImage={setGiftCardImage}
            type="ticket"
          />
          <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
            direction="row-reverse"
            spacing={1}
          >
            <Grid container item xs={12} md={6}>
              <Box mt={8}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <CircularProgress size={24} />
                  ) : (
                    t('saveAndContinue')
                  )}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TicketEventUploadImage;
