import { Box } from '@material-ui/core';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import moment from 'moment';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { merchant, storeCredit } from '../../../state';
import { TransactionTable } from '../../custom';

const rowKeys = ['createdAt', 'amount', 'type'];
const RedemptionsModal = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const colsTitles = [t('date'), t('amountAdded'), t('amountDeducted')];

  const { total = 0, data = [], isLoading = true } = useSelector(
    storeCredit.selectors.selectStoreCreditsRedemptionsList
  );
  const [isOpen, setIsOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(undefined);
  const [params, setParams] = React.useState({
    limit: 10,
    page: 1,
    update: new Date().getTime(),
  });

  const init = async ({ update, ...p }) => {
    dispatch(
      storeCredit.actions.getStoreCreditsRedemptionsList({
        ...p,
        storeCreditId: selected.id,
      })
    );
  };

  const onClose = () => setIsOpen(false);

  const onShowModal = (row) => {
    setSelected(row);
    setIsOpen(true);
  };

  React.useEffect(() => {
    if (isOpen && selected !== undefined) init(params);
  }, [params, isOpen, selected]);

  React.useImperativeHandle(ref, () => ({
    showModal: onShowModal,
    closeModal: onClose,
  }));
  const currency = useSelector(merchant.selectors.selectCurrency);

  const mods = {
    createdAt: (value) =>
      value && moment(`${value}Z`).format('MMM Do YYYY, HH:mm'),
    amount: (val, row) =>
      row.type === 0 &&
      t('cardValue', {
        amount: val,
        formatParams: {
          amount: { currency },
        },
      }),
    type: (val, row) =>
      (row.type === 1 || row.type === 2) &&
      t('cardValue', {
        amount: row.amount,
        formatParams: {
          amount: { currency },
        },
      }),
  };

  const pagination = {
    total,
    pageSize: params.limit,
    page: params.page,
    handleChangePage: (event, page) => {
      setParams({ ...params, page: page + 1 });
    },
    handleChangeRowsPerPage: (event) => {
      setParams({ ...params, limit: event.target.value, page: 1 });
    },
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      PaperProps={{
        style: {
          maxWidth: 600,
        },
      }}
    >
      <DialogTitle>{`Store Credits #: ${selected?.code || ''}`}</DialogTitle>
      <DialogContent>
        <Box py={2}>
          <TransactionTable
            cols={colsTitles}
            rowKeys={rowKeys}
            data={data}
            mods={mods}
            isLoading={isLoading}
            pagination={pagination}
            noDataMsg={t('noDataMsg')}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
});

export default RedemptionsModal;
