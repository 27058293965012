/* eslint-disable react/forbid-prop-types,react/require-default-props */
import React from 'react';
import { Box, Divider, Tabs } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { TabContext, TabPanel } from '@material-ui/lab';
import SwipeableViews from 'react-swipeable-views';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// import moment from 'moment';
import { navigate } from '@reach/router';
import StyledTab from '../../custom/StyledTab';
import { alert, loyalty, merchant } from '../../../state';
import LoyaltyInitialSetup from './LoyaltyInitialSetup';
import LoyaltyProgramType from './LoyaltyProgramType';
import LoyaltyDefineReward from './LoyaltyDefineReward';
import LoyaltyUploadImage from './LoyaltyUploadImage';
import LoyaltyReview from './LoyaltyReviewScreen';
import LoyaltyProgramValidation from './LoyaltyProgramValidation';
import LoyaltyPromotions from './LoyaltyPromotions';

const DefineLoyaltyProgramForm = ({
  loyaltyProgramData,
  isCreating,
  onReload,
  closeModal,
}) => {
  const { t } = useTranslation();
  const loyaltyProgram = { ...loyaltyProgramData };
  const items = useSelector(loyalty.selectors.selectItems);
  const dispatch = useDispatch();
  const [formTab, setTab] = React.useState(0);
  const openAlert = (payload) => dispatch(alert.actions.open(payload));
  const merchantDetails = useSelector((state) =>
    merchant.selectors.selectMerchantDetails(state)
  );
  React.useEffect(() => {
    if (isCreating) dispatch(loyalty.actions.getItems());
  }, []);

  const createInitialValue = () => {
    const value = {
      ...loyaltyProgram,
      programName: loyaltyProgram.programName || '',
      description: loyaltyProgram.description || '',
      partnerItemId: loyaltyProgram.partnerItemId || '',
      itemPoint: (loyaltyProgram.type === 0 && loyaltyProgram.point) || 1,
      amountPoint: (loyaltyProgram.type === 1 && loyaltyProgram.point) || 0,
      point: loyaltyProgram.point || 0,
      terms: loyaltyProgram.terms || '',
      type: loyaltyProgram.type || 1,
      rewardType: loyaltyProgram.rewardType || 2,
      rewardItemId: loyaltyProgram.rewardItemId || '',
      discountAmount: loyaltyProgram.discountAmount || 0,
      requiredAmount: loyaltyProgram.requiredAmount || 0,
      minimumAmount: loyaltyProgram.minimumAmount || 0,
      discountType: loyaltyProgram.discountType || 0,
      expirationDate: loyaltyProgram.expirationDate || '',
      formTab,
      excludeTax: loyaltyProgram.excludeTax || false,
      hideSignUpLink: loyaltyProgram.hideSignUpLink || false,
      codeType: loyaltyProgram.codeType
        ? loyaltyProgram.codeType.toString()
        : '0',
      imageUrl: loyaltyProgram.imageUrl || merchantDetails.logoUrl,
      storeCreditExpirationValue:
        loyaltyProgram.storeCreditExpirationValue || 0,
      storeCreditExpirationType: loyaltyProgram.storeCreditExpirationType || 2,
      signUpBonusPoint: loyaltyProgram.signUpBonusPoint || 0,
      enablePromotions: loyaltyProgram.enablePromotions || false,
      promotions: loyaltyProgram.promotions || {
        startDate: null,
        endDate: null,
        description: '',
        daysOfWeek: [],
        point: 0,
      },
    };
    if (isCreating) {
      value.status = formTab === 4 ? 1 : 0;
    } else {
      value.status = loyaltyProgram.status;
    }
    return value;
  };

  const createpayload = (values) => {
    const payload = {
      ...values,
      type: Number(values.type),
      rewardType: Number(values.rewardType),
      discountType: Number(values.discountType),
      point: Number(
        Number(values.type) === 0 ? values.itemPoint : values.amountPoint
      ),
      discountAmount: Number(values.discountAmount),
      requiredAmount: Number(values.requiredAmount),
      minimumAmount: Number(values.minimumAmount),
      expirationDate: values.expirationDate || null,
      formTab: undefined,
      codeType: values.codeType,
      storeCreditExpirationValue: Number(values.storeCreditExpirationValue),
      storeCreditExpirationType: Number(values.storeCreditExpirationType),
    };
    return payload;
  };

  const createLoyaltyProgram = async (values) => {
    const payload = {
      ...createpayload(values),
      partnerItemName:
        Number(values.type) === 0 && values.partnerItemId
          ? items.find((item) => item.id === values.partnerItemId).name || ''
          : '',
      rewardItemName:
        Number(values.rewardType) === 0 && values.rewardItemId
          ? items.find((item) => item.id === values.rewardItemId).name || ''
          : '',
      status: formTab === 4 ? 1 : 0,
    };
    const res = await dispatch(loyalty.actions.saveLoyaltyProgram(payload));
    if (res.payload) {
      openAlert({
        message: t('loyalty.loyaltyProgramCreatedSuccess'),
        severity: 'success',
      });
      if (formTab < 4) {
        // setLoyaltyProgram(payload);
        setTab(formTab + 1);
      } else if (res.payload.status === 1) {
        // setTimeout(() => window.location.reload(), 300);
        setTimeout(() => navigate('/dashboard/loyalty/manage'), 300);
      }
    }
    if (res.error) {
      openAlert({
        message: res.error?.message || t('updateGiftCardFailed'),
        severity: 'error',
      });
    }
  };

  const editLoyaltyProgram = async (values) => {
    const payload = {
      ...createpayload(values),
      partnerItemName: values.partnerItemName,
      rewardItemName: values.rewardItemName,
      status: Number(values.status),
    };
    const res = await dispatch(loyalty.actions.editLoyaltyProgram(payload));
    if (res.payload) {
      openAlert({
        message: t('loyalty.loyaltyProgramUpdatedSuccess'),
        severity: 'success',
      });
      if (formTab < 4) {
        // setLoyaltyProgram(payload);
        setTab(formTab + 1);
        if (onReload) onReload();
      } else {
        if (onReload) onReload();
        if (closeModal) closeModal();
      }
    }
    if (res.error) {
      openAlert({
        message: t('updateGiftCardFailed'),
        severity: 'error',
      });
    }
  };

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      validationSchema={LoyaltyProgramValidation}
      initialValues={createInitialValue()}
      onSubmit={async (values) => {
        if (isCreating) {
          createLoyaltyProgram(values);
        } else {
          editLoyaltyProgram(values);
        }
      }}
    >
      {({ setFieldValue }) => {
        return (
          <Box>
            <Tabs
              value={formTab}
              onChange={(event, newValue) => {
                setTab(newValue);
                setFieldValue('formTab', newValue);
              }}
              TabIndicatorProps={{ style: { background: '#0969da' } }}
              aria-label="Select settings tab"
            >
              <StyledTab
                disableRipple
                id="initial-setup"
                aria-controls="values"
                label={t('loyalty.initialSetup')}
              />
              <StyledTab
                disableRipple
                id="program-type"
                aria-controls="values"
                label={t('loyalty.programType')}
              />
              <StyledTab
                disableRipple
                id="define-reward"
                aria-controls="values"
                label={t('loyalty.defineRewards')}
              />
              <StyledTab
                disableRipple
                id="upload-image"
                aria-controls="images"
                label={t('loyalty.uploadImage')}
              />
              <StyledTab
                disableRipple
                id="review"
                aria-controls="images"
                label={t('loyalty.reviewAndActivate')}
              />
              <StyledTab
                disableRipple
                id="review"
                aria-controls="images"
                label={t('loyalty.promotions')}
              />
            </Tabs>
            <Divider />
            <TabContext value={formTab.toString()}>
              <SwipeableViews index={formTab} onChangeIndex={setTab}>
                <TabPanel value={formTab.toString()} index={0}>
                  <LoyaltyInitialSetup />
                </TabPanel>
                <TabPanel value={formTab.toString()} index={1}>
                  <LoyaltyProgramType />
                </TabPanel>
                <TabPanel value={formTab.toString()} index={2}>
                  <LoyaltyDefineReward isCreating={isCreating} />
                </TabPanel>
                <TabPanel value={formTab.toString()} index={3}>
                  <LoyaltyUploadImage />
                </TabPanel>
                <TabPanel value={formTab.toString()} index={4}>
                  <LoyaltyReview />
                </TabPanel>
                <TabPanel value={formTab.toString()} index={5}>
                  <LoyaltyPromotions />
                </TabPanel>
              </SwipeableViews>
            </TabContext>
          </Box>
        );
      }}
    </Formik>
  );
};

DefineLoyaltyProgramForm.propTypes = {
  loyaltyProgramData: PropTypes.object,
  isCreating: PropTypes.bool.isRequired,
  onReload: PropTypes.func,
  closeModal: PropTypes.func,
};

export default DefineLoyaltyProgramForm;
