import { Box, Button, Grid, Tooltip } from '@material-ui/core';
import {
  CheckBox,
  CheckBoxOutlineBlank,
  Delete,
  Edit,
} from '@material-ui/icons';
// import { makeStyles } from '@material-ui/styles';
// import { useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { coupons, membership } from '../../../state';
import { TransactionTable } from '../../custom';
import ConfirmationDialog from '../GiftCardDetails/ConfirmDialog';
import AddMembershipCouponPlan from './AddMembershipCouponPlan';

const IssueCouponsConfig = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [params, setParams] = React.useState({
    limit: 10,
    page: 1,
    update: new Date().getTime(),
  });
  const [isOpen, setIsOpen] = React.useState(false);
  const [itemSelected, setItemSelected] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [isConfirm, setIsConfirm] = React.useState(false);

  const colsTitles = [
    t('membership.planName'),
    t('membership.issueCouponsConfig.couponName'),
    t('membership.issueCouponsConfig.description'),
    t('membership.issueCouponsConfig.maxRedemptions'),
    t('membership.issueCouponsConfig.onRenewal'),
    t('membership.issueCouponsConfig.enable'),
  ];

  const rowKeys = [
    'membershipPlan.name',
    'couponPlan.name',
    'description',
    'maxRedemption',
    'renewalType',
    'enabled',
  ];

  const data = useSelector(membership.selectors.selectMembershipCouponPlan);
  const total = useSelector(
    membership.selectors.selectMembershipCouponPlanTotal
  );

  const handleOpenModal = (edit, item = {}) => {
    // setIsEdit(edit);
    setItemSelected(item);
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const onReload = () => setParams({ ...params, update: new Date().getTime() });

  const onUpdateStatus = async (val) => {
    await dispatch(
      membership.actions.setMembershipCouponEnable({
        id: val.id,
        enable: !val.enabled,
      })
    );
    onReload();
  };

  const onDelete = async () => {
    await dispatch(membership.actions.deleteMembershipCouponPlan(isConfirm));
    onReload();
  };

  const init = async ({ update, ...pr }) => {
    setIsLoading(true);
    await dispatch(coupons.actions.getCouponPlanList());
    await dispatch(membership.actions.getMemberShipCouponPlanList(pr));
    setIsLoading(false);
  };

  React.useEffect(() => {
    init(params);
  }, [params]);

  const mods = {
    id: (id, row) => (
      <Grid container alignItems="center" direction="row">
        <Tooltip title={t('edit')} placement="top" arrow>
          <Edit onClick={() => handleOpenModal(true, row)} cursor="pointer" />
        </Tooltip>
        <Tooltip title={t('delete')} placement="top" arrow>
          <Delete onClick={() => setIsConfirm(id)} cursor="pointer" />
        </Tooltip>
      </Grid>
    ),
    renewalType: (value) => {
      return value === 0
        ? t('membership.issueCouponsConfig.issueNewCoupon')
        : t('membership.issueCouponsConfig.continueExistingCoupon');
    },
    maxRedemption: (val) => (val === -1 ? '' : val),
    enabled: (value, row) =>
      row.enabled ? (
        <CheckBox
          style={{
            cursor: 'pointer',
          }}
          onClick={() => onUpdateStatus(row)}
        />
      ) : (
        <CheckBoxOutlineBlank
          style={{
            cursor: 'pointer',
          }}
          onClick={() => onUpdateStatus(row)}
        />
      ),
  };

  const pagination = {
    total,
    pageSize: params.limit,
    page: params.page,
    handleChangePage: (event, page) => {
      setParams({ ...params, page: page + 1 });
    },
    handleChangeRowsPerPage: (event) => {
      setParams({ ...params, limit: event.target.value, page: 1 });
    },
  };

  return (
    <>
      <ConfirmationDialog
        open={isConfirm}
        setOpen={setIsConfirm}
        title={t('confirmation')}
        content={t('deleteConfirmationQuestion')}
        actionOk={onDelete}
      />
      <AddMembershipCouponPlan
        isOpen={isOpen}
        closeModal={handleCloseModal}
        selected={itemSelected}
        onReload={onReload}
      />

      <Box py={2}>
        <TransactionTable
          cols={colsTitles}
          rowKeys={rowKeys}
          data={data}
          mods={mods}
          isLoading={isLoading}
          pagination={pagination}
          searchBox={() => (
            <Button
              disabled={isLoading}
              onClick={() => handleOpenModal(false)}
              variant="contained"
              color="primary"
            >
              {t('membership.issueCouponsConfig.addCoupon')}
            </Button>
          )}
          noDataMsg={t('noDataMsg')}
        />
      </Box>
    </>
  );
};
export default IssueCouponsConfig;
