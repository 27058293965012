import * as React from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  Box,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { Close } from '@material-ui/icons';
import { useState } from 'react';
import api from '../../../state/membership/api';
import { alert, membership } from '../../../state';

const DeactiveMemberPlanModal = React.forwardRef(({ onClose, item }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const openAlert = (payload) => dispatch(alert.actions.open(payload));

  const changePlanStatus = async () => {
    try {
      setLoading(true);
      await api.changePlanStatus({ id: item?.id, status: item?.status });
      openAlert({
        message:
          item?.status === 2
            ? t('membership.mplan.successfullyDisabledPlan')
            : t('membership.mplan.successfullyEnabledPlan'),
        severity: 'success',
      });
      setLoading(false);
      dispatch(membership.actions.getMembershipPlans());
      onClose();
    } catch (error) {
      openAlert({
        message: error?.message || t('membership.mplan.failDisablePlan'),
        severity: 'error',
      });
    }
  };

  return (
    <Dialog open={!!item} onClose={onClose} maxWidth="sm">
      <DialogContent>
        <Box>
          <Formik onSubmit={() => {}}>
            {() => {
              return (
                <Form>
                  <Box mt={2}>
                    <Typography vdisplay="block" variant="subtitle1">
                      {item?.status === 2
                        ? t('membership.mplan.deactivateTitle')
                        : t('membership.mplan.activateTitle')}
                    </Typography>
                  </Box>
                  <Box mt={4}>
                    <Grid container justifyContent="space-around">
                      <Grid item>
                        <Button
                          style={{ marginRight: 12 }}
                          onClick={onClose}
                          disabled={loading}
                          variant="contained"
                        >
                          {t('no')}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          style={{ marginRight: 12 }}
                          onClick={changePlanStatus}
                          disabled={loading}
                          variant="contained"
                          color="primary"
                        >
                          {t('yes')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </DialogContent>
      <IconButton
        aria-label="clear"
        onClick={onClose}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          width: 20,
          height: 20,
        }}
      >
        <Close fontSize="small" />
      </IconButton>
    </Dialog>
  );
});

DeactiveMemberPlanModal.propTypes = {
  onClose: PropTypes.func,
  item: PropTypes.string,
};

DeactiveMemberPlanModal.defaultProps = {
  onClose: () => {},
  item: null,
};

export default DeactiveMemberPlanModal;
