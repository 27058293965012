import React from 'react';
import { Router } from '@reach/router';
import { withPrefix } from 'gatsby';
import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { Link } from 'gatsby-theme-material-ui';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { merchant } from '../../../state';
import TicketSetup from '../Tickets/TicketSetup';
import TicketEventCreate from '../Tickets/TicketEventCreate';
import TicketEventsList from '../Tickets/TicketEventsList';
import TicketList from '../Tickets/TicketList';
import OrderList from '../Tickets/OrderList';

const TicketConfigs = () => {
  const { enableTicket } = useSelector(
    merchant.selectors.selectMerchantDetails
  );
  const { t } = useTranslation();

  return (
    <>
      {enableTicket ? (
        <Router basepath={withPrefix('dashboard/ticketing')}>
          <TicketEventsList path="/events" />
          <TicketEventCreate path="/events/create" />
          <TicketSetup path="/setup" />
          <TicketList path="/tickets" />
          <OrderList path="/orders" />
        </Router>
      ) : (
        <>
          <Box pb={2}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Typography variant="h5">{t('tickets.tickets')}</Typography>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box pb={2} mt={5}>
            <Typography variant="h5">
              {t('thankYouForYourInterestInAddingTheService', {
                service: t('tickets.tickets'),
              })}
            </Typography>
          </Box>
          <Box pb={2} mt={2}>
            <Typography variant="h5">
              {t('pleaseContact')}
              <Link href="mailto:eric@pintuna.com" style={{ color: 'blue' }}>
                eric@pintuna.com
              </Link>
              {t('forA2WeekFreeTrialOfThisApp')}
            </Typography>
          </Box>
        </>
      )}
    </>
  );
};

export default TicketConfigs;
