/* eslint-disable react/forbid-prop-types,react/require-default-props */
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { alert, coupons, merchant } from '../../../state';
import { CustomInputField } from '../../custom';
import SingleCouponBox from './SingleCouponBox';

const SendToOneEmail = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { currency } = useSelector((state) =>
    merchant.selectors.selectMerchantDetails(state)
  );
  const merchantDetails = useSelector((state) =>
    merchant.selectors.selectMerchantDetails(state)
  );
  console.log('merchantDetails', merchantDetails);
  console.log(currency);
  const [isLoading, setIsLoading] = useState(false);
  // const couponsList = useSelector(coupons.selectors.selectCouponList);
  const openAlert = (payload) => dispatch(alert.actions.open(payload));
  const [params] = React.useState({
    limit: 10,
    page: 1,
    isSingle: true,
  });
  const getCoupons = (search = '') =>
    dispatch(coupons.actions.getCouponList({ ...params, search }));
  React.useEffect(() => {
    getCoupons();
  }, [params]);
  // const { t } = useTranslation();

  return (
    <Formik
      validationSchema={Yup.object().shape({
        // code: Yup.string().required(i18n.t('validation.required')),
        firstName: Yup.string().required(t('validation.required')),
        lastName: Yup.string().required(t('validation.required')),
        email: Yup.string().required(t('validation.required')),
      })}
      initialValues={{
        code: '',
        description: '',
        firstName: '',
        lastName: '',
        email: '',
      }}
      onSubmit={async (values, { resetForm, setFieldValue }) => {
        setIsLoading(true);
        try {
          const resp = await dispatch(
            coupons.actions.sendSingleCoupon({
              ...values,
            })
          );
          if (resp.payload) {
            openAlert({
              message: t('coupons.couponCodeSentSuccessfully'),
              severity: 'success',
            });
            const { code, description } = values;
            resetForm();
            setFieldValue('code', code);
            setFieldValue('description', description);
          } else if (resp.error) {
            openAlert({
              message:
                resp.error.message || t('coupons.failedToSendCouponCode'),
              severity: 'error',
            });
          }
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
        }
        return true;
      }}
    >
      {({ setFieldValue, handleSubmit, values }) => {
        const setCouponCode = async (val) => {
          setFieldValue('code', val.code);
          setFieldValue('description', val.description);
        };
        return (
          <>
            <SingleCouponBox setCouponCode={setCouponCode} />
            <Box
              p={3}
              style={{
                border: '1px solid #ccc',
                borderRadius: 10,
                position: 'relative',
              }}
              mb={5}
            >
              <Typography
                style={{
                  position: 'absolute',
                  top: -22,
                  left: 30,
                  background: '#fff',
                  padding: 6,
                  fontSize: 18,
                  fontWeight: 'bold',
                }}
              >
                {t('coupons.step2EmailCouponToCustomer')}
              </Typography>
              <Form>
                <Box mt={2}>
                  <Grid container spacing={5}>
                    <Grid item xs={6}>
                      <Field
                        component={TextField}
                        fullWidth
                        type="text"
                        label={t('coupons.couponCode')}
                        name="code"
                        variant="outlined"
                        disabled
                        style={{ display: 'none' }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        component={TextField}
                        fullWidth
                        type="text"
                        label={t('coupons.campaignDescription')}
                        name="description"
                        variant="outlined"
                        disabled
                        style={{ display: 'none' }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Grid container spacing={5}>
                  <Grid item xs={6}>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      fullWidth
                      required
                      capitalize
                      label={t('coupons.firstName')}
                      name="firstName"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      fullWidth
                      required
                      capitalize
                      label={t('coupons.lastName')}
                      name="lastName"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box pb={2}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        trim
                        fullWidth
                        required
                        label={t('coupons.email')}
                        name="email"
                        variant="outlined"
                      />
                    </Box>
                  </Grid>
                </Grid>

                <Box mt={2}>
                  <Grid
                    container
                    spacing={5}
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Grid item>
                      <Button
                        onClick={handleSubmit}
                        loading={isLoading}
                        disabled={isLoading || !values.code}
                        variant="contained"
                        color="primary"
                      >
                        Email it!
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Form>
            </Box>
          </>
        );
      }}
    </Formik>
  );
};

export default SendToOneEmail;
