/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Button,
  MenuItem,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from '@material-ui/core';
import { TextField as FormikTextField, TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import { Close } from '@material-ui/icons';
import moment from 'moment';

import { DatePicker } from '@mui/lab';
import { Alert, AlertTitle } from '@material-ui/lab';
import { membership, alert } from '../../../../state';
import ConfirmationDialog from '../../GiftCardDetails/ConfirmDialog';
import api from '../../../../state/membership/api';

const BulkModal = ({ isOpen, closeModal, params }) => {
  const plans =
    useSelector(membership.selectors.selectPlans).filter(
      (plan) => plan.status === 1
    ) || [];

  const [loading, setLoading] = useState(false);
  const [plan, selectPlan] = useState(false);
  const [count, setCount] = useState(0);
  const [expiration, setExpiration] = useState(moment());
  const [isConfirm, setIsConfirm] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [processCount, setProcessCount] = useState({
    processed: 0,
    id: '',
  });
  const [memberShips, setMemberShips] = useState([]);
  const [type, setType] = useState('');
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      if (!params) return;
      const searchParams = { ...params };
      searchParams.isAutoRenewal =
        searchParams.isAutoRenewal === 'noFilter'
          ? ''
          : searchParams.isAutoRenewal;
      searchParams.limit = 5000;
      const res = await api.getMembershipList(searchParams);
      if (res && res.data) {
        const dataFilter = res.data.filter((item) => item.status === 0);
        setMemberShips(dataFilter);
      }
    };
    fetchData();
  }, []);

  const openAlert = (payload) => dispatch(alert.actions.open(payload));

  const bulkWithType = async (bulkType) => {
    setLoading(true);
    let processedCount = 0;
    try {
      switch (bulkType) {
        case 'plan':
          for (const item of memberShips) {
            processedCount += 1;
            setProcessCount({
              processed: processedCount,
              id: item.code,
            });
            try {
              await api.updateMembershipPlan({
                id: item?.id,
                membershipPlanId: plan,
              });
            } catch (error) {
              setErrorMessage(
                `Failed to update membership plan for ${item.code}`
              );
              console.error(
                `Failed to update membership plan for ${item.code}:`,
                error
              );
            }
          }
          break;
        case 'cancel':
          for (const item of memberShips) {
            processedCount += 1;
            setProcessCount({
              processed: processedCount,
              id: item.code,
            });
            try {
              await api.deactivateMembership(item.id);
            } catch (error) {
              setErrorMessage(
                `Failed to deactivate membership for ${item.code}`
              );
              console.error(
                `Failed to deactivate membership for ${item.code}:`,
                error
              );
            }
          }
          break;
        case 'renewal':
          for (const item of memberShips) {
            processedCount += 1;
            setProcessCount({
              processed: processedCount,
              id: item.code,
            });
            try {
              await api.cancelMembership(item.id);
            } catch (error) {
              setErrorMessage(`Failed to cancel membership for ${item.code}`);
              console.error(
                `Failed to cancel membership for ${item.code}:`,
                error
              );
            }
          }
          break;
        case 'expiration':
          for (const item of memberShips) {
            processedCount += 1;
            setProcessCount({
              processed: processedCount,
              id: item.code,
            });
            try {
              await api.updateMembershipExpirationDate({
                id: item?.id,
                expirationDate: expiration,
              });
            } catch (error) {
              setErrorMessage(
                `Failed to update expiration date for ${item.code}`
              );
              console.error(
                `Failed to update expiration date for ${item.code}:`,
                error
              );
            }
          }
          break;
        default:
          break;
      }
      setTimeout(() => {
        openAlert({
          message: t('membership.membershipList.bulkSuccessfully'),
          severity: 'success',
        });
        setLoading(false);
        closeModal();
      }, 1000);
    } catch (error) {
      openAlert({
        message:
          error?.message || error || t('membership.membershipList.failCreate'),
        severity: 'error',
      });
    }
  };

  const handleConfirm = () => {
    switch (type) {
      case 'plan':
        bulkWithType('plan');
        break;
      case 'renewal':
        bulkWithType('renewal');
        break;
      case 'expiration':
        bulkWithType('expiration');
        break;
      case 'cancel':
        bulkWithType('cancel');
        break;
      default:
        break;
    }
  };

  const renderAlert = () => {
    if (!processCount.id) return null;
    return (
      <Box mb={2}>
        <Alert severity={errorMessage ? 'error' : 'info'}>
          {errorMessage && <AlertTitle>{errorMessage}</AlertTitle>}
          {!errorMessage && (
            <AlertTitle>
              Updating {processCount.processed} of {memberShips?.length}{' '}
            </AlertTitle>
          )}
          Membership # {processCount.id}
        </Alert>
      </Box>
    );
  };

  const renderMemberPlan = () => {
    return (
      <Box>
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Typography
                variant="body1"
                gutterBottom
                style={{ marginBottom: 18 }}
              >
                This will change the plan for{' '}
                <Typography variant="body1" component="span" color="primary">
                  {count}
                </Typography>{' '}
                members.
              </Typography>
              {renderAlert()}
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                component={FormikTextField}
                onChange={(e) => selectPlan(e.target.value)}
                fullWidth
                select
                name="planId"
                variant="outlined"
                label={t('membership.images.selectPlan')}
              >
                {plans.map(
                  (opt) =>
                    opt && (
                      <MenuItem value={opt.id} key={opt.id}>
                        {opt.name}
                      </MenuItem>
                    )
                )}
              </Field>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                variant="body2"
                gutterBottom
                style={{ color: 'gray' }}
              >
                {t('membership.membershipList.bulkNote')}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box mb={2}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button
                style={{ marginRight: 12 }}
                onClick={() => {
                  setIsConfirm(true);
                  setType('plan');
                }}
                disabled={loading || !plan || !count}
                variant="contained"
                color="primary"
              >
                {t('save')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };

  const renderAutoRenew = () => {
    return (
      <Box>
        <Box>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Typography variant="body1" gutterBottom>
                This will turn off auto renewal for{' '}
                <Typography variant="body1" component="span" color="primary">
                  {count}
                </Typography>{' '}
                members.
              </Typography>
              {renderAlert()}
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid container justifyContent="flex-start">
            <Grid item>
              <Button
                style={{ marginBottom: 8 }}
                onClick={() => {
                  setType('renewal');
                  setIsConfirm(true);
                }}
                disabled={loading || !count}
                variant="contained"
                color="primary"
              >
                {t('membership.membershipList.noAutoRenewal')}
              </Button>
              <Typography variant="caption" display="block" gutterBottom my={2}>
                ({t('membership.membershipList.bulkNoteAutoRenewal')})
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };

  const renderExpirationDate = () => {
    return (
      <Box>
        <Box>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Typography variant="body1" gutterBottom>
                This will change the Expiration Date for{' '}
                <Typography variant="body1" component="span" color="primary">
                  {count}
                </Typography>{' '}
                members.
              </Typography>
              {renderAlert()}
            </Grid>
            <Grid item xs={12} md={6}>
              <DatePicker
                inputFormat="YYYY-MM-DD"
                mask="____-__-__"
                minDate={moment()}
                slotProps={{
                  textField: {
                    readOnly: true,
                    variant: 'outlined',
                  },
                }}
                value={moment(expiration)}
                renderInput={(pr) => {
                  return (
                    <Field
                      readOnly
                      inputProps={{ readOnly: true }}
                      component={TextField}
                      fullWidth
                      label={`${t(
                        'membership.editExpirationDateModal.newDate'
                      )} *`}
                      name="newExpirationDate"
                      variant="outlined"
                      {...pr}
                      onKeyDown={(e) => e.preventDefault()}
                    />
                  );
                }}
                onChange={(val) => {
                  const currentTime = moment().format('HH:mm:ss');
                  setExpiration(
                    val ? `${val.format('YYYY-MM-DD')} ${currentTime}` : ''
                  );
                }}
                name="newExpirationDate"
                label={`${t('membership.editExpirationDateModal.newDate')} *`}
              />
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button
                style={{ marginBottom: 8 }}
                onClick={() => {
                  setType('expiration');
                  setIsConfirm(true);
                }}
                disabled={loading || !expiration || !count}
                variant="contained"
                color="primary"
              >
                {t('save')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };

  const renderCancelMemberShip = () => {
    return (
      <Box>
        <Box>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Typography variant="body1" gutterBottom>
                This will cancel the membership for{' '}
                <Typography variant="body1" component="span" color="primary">
                  {count}
                </Typography>{' '}
                members.
              </Typography>
              {renderAlert()}
            </Grid>
          </Grid>
        </Box>
        <Box mb={2}>
          <Grid container justifyContent="flex-start">
            <Grid item>
              <Button
                style={{ marginBottom: 8 }}
                onClick={() => {
                  setType('cancel');
                  setIsConfirm(true);
                }}
                disabled={loading || !count}
                variant="contained"
                color="primary"
              >
                {t('membership.membershipList.cancelMembership')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };

  const renderBody = () => {
    switch (isOpen) {
      case 'plan':
        return renderMemberPlan();
      case 'renewal':
        return renderAutoRenew();
      case 'expiration':
        return renderExpirationDate();
      case 'cancel':
        return renderCancelMemberShip();
      default:
        return null;
    }
  };

  useEffect(() => {
    setCount(memberShips?.length);
  }, [isOpen, memberShips]);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={closeModal}
        fullWidth
        PaperProps={{
          style: {
            maxWidth: 650,
          },
        }}
        disableEnforceFocus
      >
        <DialogTitle>Bulk Edit</DialogTitle>
        <DialogContent>{renderBody()}</DialogContent>
        <IconButton
          aria-label="clear"
          onClick={closeModal}
          style={{
            position: 'absolute',
            top: 10,
            right: 10,
            width: 20,
            height: 20,
          }}
        >
          <Close fontSize="small" />
        </IconButton>
      </Dialog>
      <ConfirmationDialog
        open={isConfirm}
        setOpen={setIsConfirm}
        title={t('confirmation')}
        content="Are you sure ?"
        actionOk={() => handleConfirm()}
      />
    </>
  );
};

BulkModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
};

export default BulkModal;
