/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Grid, Button, Box, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { alert, membership } from '../../../state';
import { CustomInputField } from '../../custom';

const PintunaAppUrl =
  process.env.GATSBY_APP_PORTAL_URL || 'https://app.pintuna.com/';

const UrlCheckIn = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const membershipConfig = useSelector(
    membership.selectors.selectMembershipConfig
  );

  const handleCopy = () => {
    const payload = {
      message: t('copiedToClipboard'),
      severity: 'success',
    };
    dispatch(alert.actions.open(payload));
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={7}>
          <Box
            style={{ border: '1px solid #ccc', position: 'relative' }}
            p={4}
            mb={3}
          >
            <Typography
              style={{
                position: 'absolute',
                top: -22,
                left: 30,
                background: '#fff',
                padding: 6,
                fontSize: 18,
              }}
            >
              {t('checkInUrl')}
            </Typography>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs>
                <CustomInputField
                  customInput={Field}
                  component={TextField}
                  fullWidth
                  disabled
                  type="string"
                  removeArrows
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={`${t('checkInUrl')}`}
                  name="link"
                  variant="outlined"
                  value={`${PintunaAppUrl}membership/${membershipConfig.businessId}/check-in`}
                />
              </Grid>
              <Grid item>
                <CopyToClipboard
                  text={`${PintunaAppUrl}membership/${membershipConfig.businessId}/check-in`}
                  onCopy={handleCopy}
                >
                  <Button variant="contained" color="primary">
                    {t('membership.registerConfig.copyLink')}
                  </Button>
                </CopyToClipboard>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item md={7}>
          <Box
            style={{ border: '1px solid #ccc', position: 'relative' }}
            p={4}
            mb={3}
          >
            <Typography
              style={{
                position: 'absolute',
                top: -22,
                left: 30,
                background: '#fff',
                padding: 6,
                fontSize: 18,
              }}
            >
              {t('membership.freeItemPerksUrl')}
            </Typography>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs>
                <CustomInputField
                  customInput={Field}
                  component={TextField}
                  fullWidth
                  disabled
                  type="string"
                  removeArrows
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={`${t('membership.freeItemPerksUrl')}`}
                  name="link"
                  variant="outlined"
                  value={`${PintunaAppUrl}membership/${membershipConfig.businessId}/perks`}
                />
              </Grid>
              <Grid item>
                <CopyToClipboard
                  text={`${PintunaAppUrl}membership/${membershipConfig.businessId}/perks`}
                  onCopy={handleCopy}
                >
                  <Button variant="contained" color="primary">
                    {t('membership.registerConfig.copyLink')}
                  </Button>
                </CopyToClipboard>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item md={7}>
          <Box style={{ border: '1px solid #ccc', position: 'relative' }} p={4}>
            <Typography
              style={{
                position: 'absolute',
                top: -22,
                left: 30,
                background: '#fff',
                padding: 6,
                fontSize: 18,
              }}
            >
              {t('membership.cancellationUrl')}
            </Typography>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs>
                <CustomInputField
                  customInput={Field}
                  component={TextField}
                  fullWidth
                  disabled
                  type="string"
                  removeArrows
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={`${t('membership.cancellationUrl')}`}
                  name="link"
                  variant="outlined"
                  value={`${PintunaAppUrl}membership/${membershipConfig.businessId}/cancel`}
                />
              </Grid>
              <Grid item>
                <CopyToClipboard
                  text={`${PintunaAppUrl}membership/${membershipConfig.businessId}/cancel`}
                  onCopy={handleCopy}
                >
                  <Button variant="contained" color="primary">
                    {t('membership.registerConfig.copyLink')}
                  </Button>
                </CopyToClipboard>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default UrlCheckIn;
