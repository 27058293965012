import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { Close } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import RepeatTicketEventForm from '../RepeatTicketEventForm';
import { ticket } from '../../../../state';

const RepeatTicketEventModal = ({ closeModal, isOpen, getProgramList }) => {
  const ticketEvent = useSelector(ticket.selectors.selectTicketEvent);
  const { t } = useTranslation();
  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth="md"
      onClose={closeModal}
      disableEnforceFocus
    >
      <DialogTitle>
        {t('tickets.event.repeatEvent')}: {ticketEvent.title}
        {ticketEvent.tagLine && `, ${ticketEvent.tagLine}`}
      </DialogTitle>
      <DialogContent>
        <Box p={2}>
          {ticketEvent?.id && (
            <RepeatTicketEventForm
              ticketSelection={ticketEvent}
              isCreating={false}
              onReload={getProgramList}
              closeModal={closeModal}
            />
          )}
        </Box>
      </DialogContent>
      <IconButton
        aria-label="clear"
        onClick={closeModal}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          width: 20,
          height: 20,
        }}
      >
        <Close fontSize="small" />
      </IconButton>
    </Dialog>
  );
};
RepeatTicketEventModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  getProgramList: PropTypes.func.isRequired,
};

export default RepeatTicketEventModal;
