/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useRef } from 'react';
import { Box, Grid, Button, MenuItem, IconButton } from '@material-ui/core';
import { TextField, Select } from 'formik-mui';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import getSymbolFromCurrency from 'currency-symbol-map';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  ToggleButton,
} from '@mui/material';
import { ToggleButtonGroup } from '@mui/lab';
import { Close } from '@material-ui/icons';
import { alert, membership, merchant } from '../../../state';
import { CustomInputField } from '../../custom';

const initValues = {
  membershipPlanId: '',
  storeCreditConfigId: '',
  discountType: 0,
  targetScopeId: '',
};

const AddDiscountForm = ({ isOpen, closeModal }) => {
  const [loading, setLoading] = React.useState(false);
  const membershipPlans = useSelector(membership.selectors.selectPlans);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const openAlert = (payload) => dispatch(alert.actions.open(payload));

  const [initialValues] = React.useState(initValues);
  const [membershipPlan, setMembershipPlan] = React.useState({});
  const [category, setCategory] = React.useState({});
  // const isSending = useSelector(transactions.selectors.selectIsSending);
  const { currency } = useSelector(merchant.selectors.selectMerchantDetails);
  const currencySymbol = getSymbolFromCurrency(currency);
  const amountRef = useRef(null);

  const [categories, setCategories] = React.useState([]);

  useEffect(() => {
    dispatch(membership.actions.getMembershipPlans());
    const fn = async () => {
      const cats = await dispatch(merchant.actions.getCategoriesList());
      if (!cats.errors && cats.payload) {
        await setCategories(cats.payload);
      }
    };
    fn();
  }, []);

  const validationSchema = Yup.object().shape({
    membershipPlanId: Yup.string().required(
      t('membership.validates.planRequired')
    ),
    amount: Yup.number()
      .min(0.00001, t('membership.validates.amountMin'))
      .required(t('validation.required'))
      .when('discountType', {
        is: (discountType) => discountType === 0,
        then: Yup.number()
          .min(0.00001, t('membership.validates.amountMin'))
          .max(100, t('membership.validates.amountMax')),
      }),
  });

  const body = (
    <Box>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={async (values, { resetForm }) => {
          const formData = values;

          try {
            formData.discount = `${
              values.discountType === 0
                ? `${values.amount}%`
                : t('cardValue', {
                    amount: values.amount,
                    formatParams: {
                      amount: { currency: currency || 'USD' },
                    },
                  })
            } ${t('off').toLowerCase()} ${category.name || t('wholePurchase')}`;
            const response = await dispatch(
              membership.actions.createMembershipDiscount(formData)
            );

            if (response.payload) {
              openAlert({
                message: t('membership.addDiscountForm.addedSuccessfully'),
                severity: 'success',
              });
              resetForm();
              dispatch(membership.actions.getMembershipDiscountsList());
              closeModal(true);
            } else if (response.error) {
              openAlert({
                message:
                  response.error.message ||
                  t('membership.addDiscountForm.failCreate'),

                severity: 'error',
              });
            }
            setLoading(false);
          } catch (error) {
            // setLoading(false);
          }

          return true;
        }}
      >
        {({ values, setFieldValue, handleSubmit }) => {
          const handleSelectPlan = (event, val) => {
            setFieldValue('membershipPlanId', val.props.value);
            setMembershipPlan(
              membershipPlans.find((p) => p.id === val.props.value) || {}
            );
          };

          const handleSelectCategory = (event, val) => {
            setFieldValue('targetScopeId', val.props.value);
            setCategory(categories.find((p) => p.id === val.props.value) || {});
          };

          return (
            <Form>
              <Box mt={2}>
                <Grid container spacing={5}>
                  <Grid item>
                    <Field
                      component={Select}
                      as="select"
                      autoWidth={false}
                      fullWidth
                      required
                      label={t('automaticStoreCredit.selectPlan')}
                      name="membershipPlanId"
                      variant="outlined"
                      onChange={(event, val) => handleSelectPlan(event, val)}
                      style={{ width: 320 }}
                      placeholder={t('automaticStoreCredit.selectPlan')}
                    >
                      <MenuItem value={undefined}>---</MenuItem>
                      {membershipPlans.map(
                        (opt) =>
                          opt && (
                            <MenuItem
                              value={opt.id}
                              key={opt.partnerSubscriptionPlanId}
                            >
                              {opt.name}
                            </MenuItem>
                          )
                      )}
                    </Field>
                  </Grid>
                  <Grid item style={{ position: 'relative' }}>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      fullWidth
                      type="number"
                      removeArrows
                      name="amount"
                      variant="outlined"
                      required
                      label={t('membership.addDiscountForm.discount')}
                      inputRef={amountRef}
                      style={{ width: 320 }}
                      InputProps={{
                        style: {
                          paddingRight: 150,
                          minWidth: 320,
                          width: '100%',
                        },
                      }}
                    />
                    <ToggleButtonGroup
                      exclusive
                      id="roleType"
                      value={values.discountType}
                      // onChange={(e, val) => {
                      //   setFieldValue('discountType', val);
                      //   if (document.activeElement !== amountRef.current)
                      //     amountRef.current.focus();
                      // }}
                      size="small"
                      style={{ position: 'absolute', right: 30, top: 30 }}
                    >
                      <ToggleButton
                        value={0}
                        style={{ padding: '5px 25px' }}
                        onMouseDown={(e) => {
                          e.preventDefault();
                          setFieldValue('discountType', 0);
                          if (document.activeElement !== amountRef.current)
                            amountRef.current.focus();
                        }}
                      >
                        %
                      </ToggleButton>
                      <ToggleButton
                        value={1}
                        style={{ padding: '5px 25px' }}
                        onMouseDown={(e) => {
                          e.preventDefault();
                          setFieldValue('discountType', 1);
                          if (document.activeElement !== amountRef.current)
                            amountRef.current.focus();
                        }}
                      >
                        {currencySymbol}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                  <Grid item>
                    <Field
                      component={Select}
                      as="select"
                      autoWidth={false}
                      fullWidth
                      required
                      label={t('membership.addDiscountForm.category')}
                      name="targetScopeId"
                      variant="outlined"
                      onChange={(event, val) =>
                        handleSelectCategory(event, val)
                      }
                      style={{ width: 320 }}
                      placeholder="Category"
                    >
                      <MenuItem value={undefined}>---</MenuItem>
                      {categories.map(
                        (opt) =>
                          opt && (
                            <MenuItem value={opt.id} key={opt.id}>
                              {opt.name}
                            </MenuItem>
                          )
                      )}
                    </Field>
                  </Grid>
                </Grid>
              </Box>
              <Box mt={4}>
                <Grid container justifyContent="space-between">
                  <Grid item xs={7}>
                    <Box style={{ border: '1px solid #ccc', height: 38 }} p={1}>
                      {membershipPlan?.id && values.amount > 0
                        ? `${membershipPlan.name} members will get ${
                            values.discountType === 0
                              ? `${values.amount}%`
                              : t('cardValue', {
                                  amount: values.amount,
                                  formatParams: {
                                    amount: { currency },
                                  },
                                })
                          } ${t('off').toLowerCase()} ${
                            category.name || t('wholePurchase')
                          }`
                        : ''}
                    </Box>
                  </Grid>
                  <Grid item>
                    <Button
                      style={{ marginRight: 12 }}
                      onClick={handleSubmit}
                      loading={loading}
                      disabled={loading}
                      variant="contained"
                      color="primary"
                    >
                      {t('save')}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      fullWidth
      // maxWidth="lg"
      // style={{
      //   '& .MuiDialog-container': {
      //     '& .MuiPaper-root': {
      //       width: '100%',
      //       maxWidth: '1100px', // Set your width here
      //     },
      //   },
      // }}
      PaperProps={{
        style: {
          maxWidth: 1100,
        },
      }}
    >
      <DialogTitle>{t('membership.discounts.setupDiscounts')}</DialogTitle>
      <DialogContent>{body}</DialogContent>
      <IconButton
        aria-label="clear"
        onClick={closeModal}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          width: 20,
          height: 20,
        }}
      >
        <Close fontSize="small" />
      </IconButton>
    </Dialog>
  );
};

AddDiscountForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default AddDiscountForm;
