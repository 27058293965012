/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  ListItemIcon,
  MenuItem,
  Menu,
  ListItemText,
} from '@material-ui/core';
import Editing from '../../../images/Memberships/editing.png';
import Setting from '../../../images/Memberships/cycle.png';
import Delete from '../../../images/Memberships/delete.png';

const MembershipPlanEditMenu = ({
  membershipPlan,
  onEditPlan,
  onDisablePlan,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClickEditMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseEditMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-controls={
          open ? `edit-plan-button-${membershipPlan.id}` : undefined
        }
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClickEditMenu}
        cursor="pointer"
      >
        <img src={Editing} alt="" style={{ width: 20 }} />
      </IconButton>
      <Menu
        id={`edit-plan-menu-${membershipPlan.id}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseEditMenu}
        MenuListProps={{
          'aria-labelledby': `edit-plan-button-${membershipPlan.id}`,
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={onEditPlan}>
          <ListItemIcon>
            <img
              src={Setting}
              alt="edit-icon"
              style={{ width: 20, height: 20 }}
            />
          </ListItemIcon>
          <ListItemText>Edit Plan</ListItemText>
        </MenuItem>
        <MenuItem onClick={onDisablePlan}>
          <ListItemIcon>
            <img
              src={Delete}
              alt="delete-icon"
              style={{ width: 20, height: 20 }}
            />
          </ListItemIcon>
          <ListItemText>Disable Plan</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

MembershipPlanEditMenu.propTypes = {
  onEditPlan: PropTypes.func.isRequired,
  onDisablePlan: PropTypes.func.isRequired,
  membershipPlan: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default MembershipPlanEditMenu;
