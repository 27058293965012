import React, { useEffect } from 'react';
import { TextField } from 'formik-material-ui';
import { Select } from 'formik-mui';
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Box,
  Grid,
  IconButton,
  MenuItem,
  makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { Close } from '@material-ui/icons';
import api from '../../../state/membership/api';
import { alert, membership } from '../../../state';
import { CustomInputField } from '../../custom';

const fieldStyle = {
  display: 'flex',
  flexDirection: 'row',
};

const useStyles = makeStyles(() => ({
  input: {
    height: 56,
  },
}));

const EditMemberPlanModal = React.forwardRef(({ onClose, item }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const plans = useSelector(membership.selectors.selectPlans);
  const [selectedItem, setSelectedItem] = React.useState(null);

  useEffect(() => {
    dispatch(membership.actions.getMembershipPlans());
  }, []);

  useEffect(() => {
    if (plans?.length && item) {
      setSelectedItem(plans.find((p) => p.id === item.plan?.id) || {});
    }
  }, [plans, item]);

  const openAlert = (payload) => dispatch(alert.actions.open(payload));

  const initialData = {
    membershipPlanName: item?.plan?.name,
    newmembershipPlanId: null,
  };
  const onSubmit = async (values, formikHelper) => {
    formikHelper.setSubmitting(true);
    try {
      await api.updateMembershipPlan({
        id: item?.id,
        membershipPlanId: values.planId,
      });
      openAlert({
        message: t('membership.messages.successfullyEditMember'),
        severity: 'success',
      });
      dispatch(membership.actions.getMembershipList());
      onClose();
    } catch (error) {
      openAlert({
        message: error?.message || t('membership.messages.failedEditMember'),
        severity: 'error',
      });
      formikHelper.setSubmitting(false);
    }
  };

  return (
    <Dialog open={!!item} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {t('membership.editPlanModal.member')}: {item?.code}{' '}
        {t('membership.editPlanModal.name')}: {item?.firstName} {item?.lastName}
      </DialogTitle>
      <DialogContent>
        <Box py={2}>
          <Formik
            onSubmit={onSubmit}
            enableReinitialize
            initialValues={initialData}
          >
            {({ isSubmitting }) => {
              return (
                <Form>
                  <Box sx={fieldStyle}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Box pb={2}>
                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                            spacing={2}
                          >
                            <Grid item xs={12} md={6}>
                              <CustomInputField
                                customInput={Field}
                                component={TextField}
                                capitalize
                                fullWidth
                                disabled
                                label={t(
                                  'membership.editPlanModal.currentPlan'
                                )}
                                name="membershipPlanName"
                                variant="outlined"
                                InputProps={{
                                  className: classes.input,
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Field
                                component={Select}
                                as="select"
                                fullWidth
                                required
                                label={t('membership.editPlanModal.newPlan')}
                                name="planId"
                                value={
                                  selectedItem?.id ? selectedItem.id : undefined
                                }
                                key={
                                  selectedItem?.id ? selectedItem.id : undefined
                                }
                                variant="outlined"
                                onChange={(event, val) => {
                                  setSelectedItem(
                                    plans.find((p) => p.id === val.props.value)
                                  );
                                }}
                                style={{
                                  width: 268,
                                }}
                                placeholder={t(
                                  'membership.editPlanModal.newPlan'
                                )}
                              >
                                {plans.map(
                                  (opt) =>
                                    opt && (
                                      <MenuItem value={opt.id} key={opt.id}>
                                        {opt.name}
                                      </MenuItem>
                                    )
                                )}
                              </Field>
                            </Grid>
                            <Grid item xs={12} md={6} />
                            <Grid item xs={12} md={6}>
                              <p style={{ fontStyle: 'italic' }}>
                                {t('membership.editPlanModal.note')}
                              </p>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      flexDirection: 'column',
                    }}
                  >
                    <Button color="primary" variant="contained" type="submit">
                      {isSubmitting ? (
                        <CircularProgress size={24} />
                      ) : (
                        t('saveChanges')
                      )}
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </DialogContent>
      <IconButton
        aria-label="clear"
        onClick={onClose}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          width: 20,
          height: 20,
        }}
      >
        <Close fontSize="small" />
      </IconButton>
    </Dialog>
  );
});

EditMemberPlanModal.propTypes = {
  onClose: PropTypes.func,
  item: PropTypes.objectOf(PropTypes.any),
};

EditMemberPlanModal.defaultProps = {
  onClose: () => {},
  item: null,
};

export default EditMemberPlanModal;
