/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import { Box, Grid, Button, MenuItem, IconButton } from '@material-ui/core';
import { TextField, Select } from 'formik-mui';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Dialog, DialogContent, Typography } from '@mui/material';
import { Close } from '@material-ui/icons';
import { alert, membership } from '../../../state';
import { CustomInputField } from '../../custom';

const initValues = {
  membershipPlanId: '',
  name: '',
  quantity: 1,
};

const AddPerksForm = ({ isOpen, closeModal }) => {
  const [loading, setLoading] = React.useState(false);
  const membershipPlans = useSelector(membership.selectors.selectPlans);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const openAlert = (payload) => dispatch(alert.actions.open(payload));

  const [initialValues] = React.useState(initValues);
  const [plans, setPlans] = React.useState({});

  useEffect(() => {
    dispatch(membership.actions.getMembershipPlans());
  }, []);

  const validationSchema = Yup.object().shape({
    membershipPlanId: Yup.string().required(
      t('membership.validates.planRequired')
    ),
    name: Yup.string().required(t('validation.required')),
    quantity: Yup.number()
      .min(0.00001, t('membership.validates.quantityMin'))
      .required(t('validation.required')),
  });

  const body = (
    <Box>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={async (values, { resetForm }) => {
          const formData = {
            membershipPlanIds: plans,
            name: values.name,
            quantity: values.quantity,
          };

          try {
            const response = await dispatch(
              membership.actions.createMembershipFreeItemConfig(formData)
            );

            if (response.payload) {
              openAlert({
                message: t('membership.perkConfigs.addedSuccessfully'),
                severity: 'success',
              });
              resetForm();
              dispatch(membership.actions.getMembershipFreeItemConfigs());
              closeModal(true);
            } else if (response.error) {
              openAlert({
                message:
                  response.error.message ||
                  t('membership.perkConfigs.failCreate'),

                severity: 'error',
              });
            }
            setLoading(false);
          } catch (error) {
            // setLoading(false);
          }

          return true;
        }}
      >
        {({ setFieldValue, handleSubmit }) => {
          const handleSelectPlan = (event, val) => {
            setFieldValue('membershipPlanId', val.props.value);
            setPlans(
              val.props.value === 'all'
                ? membershipPlans.map((m) => m.id)
                : membershipPlans
                    .filter((p) => p.id === val.props.value)
                    .map((m) => m.id)
            );
          };

          return (
            <Form>
              <Box mt={2}>
                <Grid container xs={12} alignItems="center" spacing={2}>
                  <Grid item xs={5}>
                    <Typography>
                      {t('membership.perkConfigs.selectPlan')}:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={Select}
                      as="select"
                      autoWidth={false}
                      fullWidth
                      required
                      name="membershipPlanId"
                      variant="outlined"
                      onChange={(event, val) => handleSelectPlan(event, val)}
                      style={{ width: 386 }}
                      placeholder={t('membership.perkConfigs.selectPlan')}
                    >
                      <MenuItem value="all">All</MenuItem>
                      {membershipPlans.map(
                        (opt) =>
                          opt && (
                            <MenuItem
                              value={opt.id}
                              key={opt.partnerSubscriptionPlanId}
                            >
                              {opt.name}
                            </MenuItem>
                          )
                      )}
                    </Field>
                  </Grid>

                  <Grid item xs={5}>
                    <Typography>
                      {t('membership.perkConfigs.defineFreeItemPerk')}:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      removeArrows
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="name"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={5}>
                    <Typography>
                      {t('membership.perkConfigs.quantity')}:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      type="number"
                      removeArrows
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="quantity"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box mt={2}>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Button
                      style={{ marginRight: 12 }}
                      onClick={handleSubmit}
                      loading={loading}
                      disabled={loading}
                      variant="contained"
                      color="primary"
                    >
                      {t('save')}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );

  return (
    <Dialog open={isOpen} onClose={closeModal} maxWidth="md" fullWidth>
      <DialogContent>{body}</DialogContent>
      <IconButton
        aria-label="clear"
        onClick={closeModal}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          width: 20,
          height: 20,
        }}
      >
        <Close fontSize="small" />
      </IconButton>
    </Dialog>
  );
};

AddPerksForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default AddPerksForm;
