/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { Box, Grid, Button, TextField, Typography } from '@material-ui/core';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DateRangePicker from '@mui/lab/DateRangePicker';
import Skeleton from '@material-ui/lab/Skeleton';
import { alert, merchant, storeCredit } from '../../../state';
import { TransactionTable, DownloadCSV } from '../../custom';
import BlinkText from '../../custom/BlinkText';
import ConfirmationDialog from '../GiftCardDetails/ConfirmDialog';

const StoreCreditsList = () => {
  const { t } = useTranslation();
  const [params, setParams] = React.useState({
    limit: 10,
    page: 1,
    status: 'ACTIVE',
  });
  const [noDataMsg, setNoDataMsg] = React.useState(t('storeCredit.noData'));
  const dispatch = useDispatch();

  const colsTitles = [
    t('firstName'),
    t('lastName'),
    t('storeCredit.storeCreditsList.emailAddress'),
    t('storeCredit.storeCreditsList.storeCredit'),
    t('storeCredit.storeCreditsList.transactionDate'),
    t('storeCredit.storeCreditsList.storeCreditAmount'),
    t('storeCredit.storeCreditsList.currentBalance'),
  ];

  const rowKeys = [
    'firstName',
    'lastName',
    'email',
    'code',
    'createdAt',
    'amount',
    'balance',
  ];

  const mods = {
    createdAt: (val) => val && moment(`${val}`).format('MMM Do YYYY, HH:mm'),
    amount: (val, row) =>
      t('cardValue', {
        amount: val,
        formatParams: {
          amount: { currency: row.currency },
        },
      }),
    balance: (val, row) =>
      t('cardValue', {
        amount: val,
        formatParams: {
          amount: { currency: row.currency },
        },
      }),
  };
  const isLoading = useSelector(storeCredit.selectors.selectIsLoading);
  const { data, total, totalAmount, totalBalance } = useSelector(
    storeCredit.selectors.selectStoreCreditsList
  );
  const currency = useSelector(merchant.selectors.selectCurrency);
  const syncing = useSelector(storeCredit.selectors.selectSyncing);
  const storeCreditConfig = useSelector(
    storeCredit.selectors.selectStoreCreditConfig
  );
  const csv = useSelector(storeCredit.selectors.selectStoreCreditsCsv);
  const refLink = React.useRef();
  const [value, setValue] = useState([null, null]);
  const handleDownload = () => {
    dispatch(storeCredit.actions.downloadCSV())
      .then(() => {
        refLink.current.link.click();
      })
      .then(() =>
        dispatch(
          alert.actions.open({
            message: t('storeCredit.storeCreditsList.downloadSuccess'),
            severity: 'success',
          })
        )
      );
  };

  const search = () => {
    setParams({
      ...params,
      startDate: value[0] ? value[0].format('MM/DD/YYYY') : null,
      endDate: value[1] ? value[1].format('MM/DD/YYYY') : null,
    });
  };

  const [isConfirm, setIsConfirm] = React.useState(false);

  useEffect(() => {
    dispatch(storeCredit.actions.getStoreCreditsList(params));
  }, [params]);

  useEffect(() => {
    if (isLoading) {
      setNoDataMsg(t('storeCredit.noData'));
    }
  }, [isLoading]);

  const searchBox = () => {
    return (
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <Box mx={1}>
              <DateRangePicker
                startText={t('storeCredit.storeCreditsList.startDate')}
                endText={t('storeCredit.storeCreditsList.endDate')}
                value={value}
                onChange={(newValue) => {
                  setValue(newValue);
                }}
                renderInput={(startProps, endProps) => (
                  <>
                    <TextField {...startProps} />
                    <Box sx={{ mx: 2 }}> </Box>
                    <TextField {...endProps} />
                  </>
                )}
              />
            </Box>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={search}
              type="submit"
            >
              {t('search')}
            </Button>
          </Grid>
          <Grid item ml={2} style={{ marginLeft: 'auto' }}>
            <DownloadCSV
              handleDownload={handleDownload}
              refLink={refLink}
              data={csv}
              filename="store-credits-list"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item>
            <Box mr={4}>
              <Typography variant="h6">Count: {total}</Typography>
            </Box>
          </Grid>
          <Grid item>
            <Typography variant="h6">
              {t('storeCredit.storeCreditsList.totalAmount')}:{' '}
              {t('cardValue', {
                amount: totalAmount,
                formatParams: {
                  amount: { currency },
                },
              })}
            </Typography>
          </Grid>
          <Grid item>
            <Box ml={2}>
              <Typography variant="h6">
                {t('storeCredit.storeCreditsList.currentBalance')}:{' '}
                {t('cardValue', {
                  amount: totalBalance,
                  formatParams: {
                    amount: { currency },
                  },
                })}
              </Typography>
            </Box>
          </Grid>
          <Grid item style={{ marginLeft: 'auto' }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setIsConfirm(true)}
              disabled={isLoading || !data.length || syncing}
            >
              {t('storeCredit.storeCreditsList.clearAllBalances')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  const handleChangePage = (event, page) => {
    setParams({ ...params, page: page + 1 });
  };
  const handleChangeRowsPerPage = (event) => {
    setParams({ ...params, limit: event.target.value, page: 1 });
  };

  const pagination = {
    total,
    pageSize: params.limit,
    page: params.page,
    handleChangePage,
    handleChangeRowsPerPage,
  };
  return (
    <>
      <Box mt={2}>
        <Grid container>{syncing ? <Skeleton width="50%" /> : <></>}</Grid>
      </Box>
      <Box py={2}>
        {syncing ? (
          <BlinkText text={t('storeCredit.clearing')} />
        ) : (
          <TransactionTable
            cols={colsTitles}
            rowKeys={rowKeys}
            data={data}
            mods={mods}
            isLoading={isLoading}
            noDataMsg={noDataMsg}
            searchBox={searchBox}
            pagination={pagination}
          />
        )}
      </Box>
      <ConfirmationDialog
        open={isConfirm}
        setOpen={setIsConfirm}
        title={t('confirmation')}
        content={t('storeCredit.storeCreditsList.contentConfirmation')}
        actionOk={() => {
          setIsConfirm(false);
          dispatch(
            storeCredit.actions.clearStoreCreditsBalance({
              businessId: storeCreditConfig.businessId,
              ...params,
            })
          );
        }}
      />
    </>
  );
};

export default StoreCreditsList;
