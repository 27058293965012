/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import { Box, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Delete } from '@material-ui/icons';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  membership,
  transactions,
  storeCredit,
  // merchant,
} from '../../../state';
import { TransactionTable } from '../../custom';
import AddDiscountForm from './AddDiscountForm';
import ConfirmationDialog from '../GiftCardDetails/ConfirmDialog';

const AutomaticStoreCreditsList = () => {
  const [params] = React.useState({ limit: 10, page: 1 });
  const [itemSelected, setItemSelected] = React.useState({});
  const [isOpen, setIsOpen] = React.useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(membership.actions.getMembershipDiscountsList());
  }, [params]);

  const colsTitles = [
    t('membership.discounts.plan'),
    t('membership.discounts.discount'),
    t('membership.discounts.dateCreated'),
    '',
  ];

  const rowKeys = ['membershipPlanId', 'discount', 'createdAt', 'id'];

  const isLoading = useSelector(membership.selectors.selectIsLoading);
  const isSending = useSelector(transactions.selectors.selectIsSending);
  const membershipPlans = useSelector(membership.selectors.selectPlans);
  const discountsList = useSelector(membership.selectors.selectDiscountsList);
  const storeCreditConfig = useSelector(
    storeCredit.selectors.selectStoreCreditConfig
  );
  // const { currency } = useSelector(merchant.selectors.selectMerchantDetails);
  const [isConfirm, setIsConfirm] = React.useState(false);

  useEffect(() => {}, [membershipPlans]);

  const handleOpenModal = (edit, item = {}) => {
    setItemSelected(item);
    setIsOpen(true);
  };
  const mods = {
    membershipPlanId: (val) => membershipPlans.find((p) => p.id === val)?.name,
    createdAt: (val) => moment(`${val}Z`).format('MMMM DD, YYYY'),
    id: (id) => (
      <Tooltip title={t('delete')} placement="top" arrow>
        <Delete onClick={() => setIsConfirm(id)} cursor="pointer" />
      </Tooltip>
    ),
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const deleteItem = async () => {
    await dispatch(membership.actions.deleteMembershipDiscount(isConfirm));
    dispatch(membership.actions.getMembershipDiscountsList());
  };

  return (
    <>
      <AddDiscountForm
        planOptions={membershipPlans}
        isOpen={isOpen}
        storeCreditConfig={storeCreditConfig}
        closeModal={handleCloseModal}
        itemSelected={itemSelected}
      />

      <Button
        disabled={isSending}
        onClick={() => handleOpenModal(false)}
        variant="contained"
        color="primary"
      >
        {t('membership.discounts.addDiscount')}
      </Button>
      <Box py={2}>
        <TransactionTable
          cols={colsTitles}
          rowKeys={rowKeys}
          data={discountsList}
          mods={mods}
          isLoading={
            isLoading || (!membershipPlans.length && discountsList.length)
          }
          noDataMsg={t('noDataMsg')}
        />
      </Box>
      <ConfirmationDialog
        open={isConfirm}
        setOpen={setIsConfirm}
        title={t('confirmation')}
        content={t('membership.discounts.confirmDelete')}
        actionOk={deleteItem}
      />
    </>
  );
};

export default AutomaticStoreCreditsList;
