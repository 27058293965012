import React, { useState } from 'react';
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import SendToOneEmail from './SendToOneEmail';
import SendToManyEmail from './SendToManyEmail';
import GenerateManyCoupon from './GenerateManyCoupon';

const SendCoupon = () => {
  const [campaign, setCampaign] = useState('1');

  const { t } = useTranslation();

  const handleRadioChange = (event) => {
    setCampaign(event.target.value);
  };
  return (
    <Box>
      <Box
        p={3}
        style={{
          border: '1px solid #ccc',
          borderRadius: 10,
          position: 'relative',
        }}
      >
        <Typography
          style={{
            position: 'absolute',
            top: -22,
            left: 30,
            background: '#fff',
            padding: 6,
            fontSize: 18,
            fontWeight: 'bold',
          }}
          variant="subtitle1"
        >
          {t('action')}
        </Typography>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="1"
          name="radio-buttons-group"
          onChange={handleRadioChange}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={4}>
              <FormControlLabel
                value="1"
                control={<Radio color="primary" />}
                label={
                  <Typography variant="body1" component="span">
                    {t('coupons.sendCouponCodeSinglePerson')} <br />
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                value="2"
                control={<Radio color="primary" />}
                label={
                  <Typography variant="body1" component="span">
                    {t('coupons.sendCouponCodeToManyPeople')}
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                value="3"
                control={<Radio color="primary" />}
                label={
                  <Typography variant="body1" component="span">
                    {t('coupons.sendUniqueCouponToEachPerson')}
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </RadioGroup>
      </Box>

      <Box
        mt={5}
        p={3}
        style={{
          border: '1px solid #ccc',
          borderRadius: 10,
          position: 'relative',
        }}
      >
        {campaign === '1' && <SendToOneEmail />}
        {campaign === '2' && <SendToManyEmail />}
        {campaign === '3' && <GenerateManyCoupon />}
      </Box>
    </Box>
  );
};
export default SendCoupon;
