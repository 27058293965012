import React, { useEffect } from 'react';
import { TextField } from 'formik-mui';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, useFormikContext } from 'formik';
import {
  Box,
  Button,
  // Checkbox,
  CircularProgress,
  Grid,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { Checkbox, TextField as FormikTextField } from 'formik-material-ui';
import moment from 'moment/moment';
import DatePicker from '@mui/lab/DatePicker';

import { ticket } from '../../../state';

const LoyaltyPromotions = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    values,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
    validateField,
  } = useFormikContext();
  useEffect(() => {
    dispatch(ticket.actions.getTicketVenues({ limit: 9999 }));
  }, []);

  const points = [2, 3, 4, 5, 6, 7, 8, 9, 10];

  const daysOfWeek = [
    { value: 1, label: t('monday') },
    { value: 2, label: t('tuesday') },
    { value: 3, label: t('wednesday') },
    { value: 4, label: t('thursday') },
    { value: 5, label: t('friday') },
    { value: 6, label: t('saturday') },
    { value: 0, label: t('sunday') },
  ];

  useEffect(() => {
    let description = '';
    const { promotions } = values;

    if (
      values.enablePromotions &&
      promotions?.point &&
      promotions?.point &&
      (promotions?.daysOfWeek?.length ||
        promotions?.startDate ||
        promotions?.endDate)
    ) {
      description += `${promotions.point}X points on `;

      if (promotions?.daysOfWeek?.length) {
        // Define day names and sort daysOfWeek to place Sunday (0) at the end
        const dayNames = [
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
        ];
        const daysOfWeekSorted = promotions.daysOfWeek.slice().sort((a, b) => {
          if (Number(a) === 0) return 1;
          if (Number(b) === 0) return -1;
          return a - b;
        });
        const sortedDayNames = daysOfWeekSorted.map(
          (dayIndex) => dayNames[dayIndex]
        );

        // Handle the pluralization and conjunction for the days of the week
        if (sortedDayNames.length === 1) {
          description += `${sortedDayNames[0]}s `;
        } else if (sortedDayNames.length === 2) {
          description += `${sortedDayNames[0]}s and ${sortedDayNames[1]}s `;
        } else {
          description += `${sortedDayNames
            .slice(0, -1)
            .map((day) => `${day}s`)
            .join(', ')} and ${sortedDayNames[sortedDayNames.length - 1]}s `;
        }
      }

      if (promotions?.startDate) {
        const startDate = new Date(promotions.startDate).toLocaleDateString(
          'en-US',
          {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          }
        );

        description += `${
          promotions.endDate ? 'between' : 'from'
        } ${startDate}`;
      }
      if (promotions.endDate) {
        const endDate = new Date(promotions.endDate).toLocaleDateString(
          'en-US',
          {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          }
        );
        description += ` to ${endDate}`;
      }
    }

    setFieldValue('promotions.description', description);
  }, [
    values.promotions?.daysOfWeek,
    values.promotions?.startDate,
    values.promotions?.endDate,
    values.promotions?.point,
  ]);

  useEffect(() => {
    if (!values.enablePromotions) {
      setFieldValue('promotions', {
        startDate: null,
        endDate: null,
        description: '',
        daysOfWeek: [],
        point: 0,
      });
    }
  }, [values.enablePromotions]);

  return (
    <Box mt={3}>
      <Grid container spacing={2} xs={12} md={12}>
        <Grid item xs={12} md={2}>
          <Typography>
            <Field
              type="checkbox"
              component={Checkbox}
              name="enablePromotions"
              color="primary"
            />
            {t('loyalty.promotion')}
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={10}
          spacing={4}
          style={{
            border: '1px solid #ccc',
          }}
        >
          <Grid item xs={12} md={4}>
            <Box
              style={{
                border: '1px solid #ccc',
                position: 'relative',
              }}
              p={2}
            >
              <Typography
                style={{
                  position: 'absolute',
                  top: -15,
                  left: 15,
                  background: '#fff',
                  padding: 3,
                }}
              >
                {t('loyalty.daysOfTheWeek')}
              </Typography>
              <Grid container spacing={1}>
                {daysOfWeek.map((day) => (
                  <Grid item xs={12} key={day.value}>
                    <Field
                      type="checkbox"
                      component={Checkbox}
                      name="promotions.daysOfWeek"
                      color="primary"
                      value={day.value}
                      parse={(value) => Number(value)}
                      disabled={!values.enablePromotions}
                      onChange={(event) => {
                        const value = parseInt(event.target.value, 10);
                        const isChecked = event.target.checked;
                        if (isChecked) {
                          setFieldValue('promotions.daysOfWeek', [
                            ...values.promotions.daysOfWeek,
                            value,
                          ]);
                        } else {
                          setFieldValue(
                            'promotions.daysOfWeek',
                            values.promotions.daysOfWeek.filter(
                              (dayValue) => dayValue !== value
                            )
                          );
                        }
                      }}
                    />
                    {day.label}
                  </Grid>
                ))}
              </Grid>
              <Button
                color="primary"
                variant="contained"
                style={{
                  bottom: 15,
                  right: 15,
                  position: 'absolute',
                }}
                onClick={() => setFieldValue('promotions.daysOfWeek', [])}
              >
                {t('clear')}
              </Button>
            </Box>
          </Grid>
          <Grid container item xs={12} md={8} alignContent="start">
            <Box
              style={{
                border: '1px solid #ccc',
                position: 'relative',
                width: '100%',
              }}
              p={2}
              pb={4}
            >
              <Typography
                style={{
                  position: 'absolute',
                  top: -15,
                  left: 15,
                  background: '#fff',
                  padding: 3,
                }}
              >
                {t('loyalty.byDates')}:
              </Typography>
              <Grid
                container
                item
                xs={12}
                spacing={2}
                style={{ marginTop: 10 }}
              >
                <Grid item xs={12} md={6}>
                  <DatePicker
                    disablePast
                    inputFormat="YYYY-MM-DD"
                    disabled={!values.enablePromotions}
                    renderInput={(pr) => {
                      return (
                        <Field
                          readOnly
                          component={TextField}
                          fullWidth
                          label={`${t('loyalty.startDate')}`}
                          name="promotions.startDate"
                          variant="outlined"
                          disabled={!values.enablePromotions}
                          {...pr}
                          inputProps={{
                            ...pr.inputProps,
                            readOnly: true,
                            placeholder: '',
                          }}
                          error={
                            touched.promotions?.startDate &&
                            Boolean(errors.promotions?.startDate)
                          }
                          helperText={
                            touched.promotions?.startDate &&
                            errors.promotions?.startDate
                          }
                          onKeyDown={(e) => e.preventDefault()}
                        />
                      );
                    }}
                    value={
                      values.promotions?.startDate
                        ? moment(values.promotions?.startDate)
                        : null
                    }
                    onChange={(val) => {
                      setFieldValue('promotions.startDate', val);
                      setTimeout(() => {
                        // validateField('promotions.startDate');
                        if (values.endDate) {
                          validateField('promotions.endDate');
                        }
                      });
                      setFieldTouched('promotions.startDate', true);
                    }}
                    onBlur={() => setFieldTouched('promotions.startDate', true)}
                    name="promotions.startDate"
                    label={`${t('loyalty.startDate')}`}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DatePicker
                    disableMaskedInput
                    minDateTime={
                      values.promotions?.startDate
                        ? moment(values.promotions?.startDate)
                        : null
                    }
                    disablePast
                    inputFormat="YYYY-MM-DD"
                    disabled={!values.enablePromotions}
                    renderInput={(pr) => {
                      return (
                        <Field
                          readOnly
                          component={TextField}
                          fullWidth
                          label={`${t('loyalty.endDate')}`}
                          name="promotions.endDate"
                          disabled={!values.enablePromotions}
                          variant="outlined"
                          {...pr}
                          inputProps={{
                            ...pr.inputProps,
                            readOnly: true,
                            placeholder: '',
                          }}
                          error={
                            touched.promotions?.endDate &&
                            Boolean(errors.promotions?.endDate)
                          }
                          helperText={
                            touched.promotions?.endDate &&
                            errors.promotions?.endDate
                          }
                          onKeyDown={(e) => e.preventDefault()}
                        />
                      );
                    }}
                    value={
                      values.promotions.endDate
                        ? moment(values.promotions.endDate)
                        : null
                    }
                    onChange={(val) => {
                      setFieldValue('promotions.endDate', val);
                      setFieldTouched('promotions.endDate', true);
                    }}
                    onBlur={() => setFieldTouched('promotions.endDate', true)}
                    name="promotions.endDate"
                    label={`${t('loyalty.endDate')}`}
                  />
                </Grid>
              </Grid>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  flexDirection: 'column',
                }}
                mt={2}
                onClick={() => {
                  setFieldValue('promotions.startDate', null);
                  setFieldValue('promotions.endDate', null);
                }}
              >
                <Button color="primary" variant="contained">
                  {t('clear')}
                </Button>
              </Box>
            </Box>
            <Grid
              container
              item
              xs={12}
              style={{ marginTop: 20 }}
              spacing={2}
              alignItems="center"
            >
              <Grid item>
                <Typography>{t('loyalty.promotionPoints')}:</Typography>
              </Grid>
              <Grid item>
                <Field
                  component={FormikTextField}
                  select
                  name="promotions.point"
                  variant="outlined"
                  disabled={!values.enablePromotions}
                >
                  {points.map((val) => (
                    <MenuItem key={val} value={val}>
                      {val}X
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
            </Grid>
          </Grid>
          {values.promotions.description && (
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12}>
                <Box
                  style={{
                    border: '1px solid #ccc',
                    minHeight: 38,
                    width: '100%',
                  }}
                  p={1}
                >
                  {values.promotions.description}
                </Box>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <Box
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                flexDirection: 'column',
              }}
              mt={4}
            >
              <Button
                color="primary"
                variant="contained"
                type="submit"
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? <CircularProgress size={24} /> : t('save')}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoyaltyPromotions;
