import React from 'react';
import { Typography, Box, Link, Grid, Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SEO from '../../seo';
import { merchant } from '../../../state';
import StoreCreditsConfig from '../StoreCredits/StoreCreditsConfig';

const StoreCredits = () => {
  const { t } = useTranslation();
  const { enableStoreCredit } = useSelector(
    merchant.selectors.selectMerchantDetails
  );
  return (
    <>
      <SEO title="Store Credits" />
      {enableStoreCredit ? (
        <StoreCreditsConfig />
      ) : (
        <>
          <Box pb={2}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Typography variant="h5">
                  {t('storeCredit.storeCredits')}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box pb={2} mt={5}>
            <Typography variant="h5">
              {t('thankYouForYourInterestInAddingTheService', {
                service: t('storeCredit.storeCredits'),
              })}
            </Typography>
          </Box>
          <Box pb={2} mt={2}>
            <Typography variant="h5">
              {t('pleaseContact')}
              <Link href="mailto:eric@pintuna.com" style={{ color: 'blue' }}>
                eric@pintuna.com
              </Link>
              {t('forA2WeekFreeTrialOfThisApp')}
            </Typography>
          </Box>
        </>
      )}
    </>
  );
};

export default StoreCredits;
